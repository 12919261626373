import React, { FunctionComponent, useState } from 'react'
import { GlobeIcon, LockIcon, PeopleIcon, ScannerIcon, WarningIcon } from '../Icons/Icons'
import CustomerUpgradeRequired from './CustomerUpgradeRequired'
import EnvironmentalStats from './EnvironmentalStats'
import FeatureStatus from './FeatureStatus'

interface Props {
  upgradeRequired: boolean
  featureUsage?: FeatureUsageData
  onClose: () => void
}

export enum FeatureUsageLevel {
  Basic = 'Basic',
  Standard = 'Standard',
  Advanced = 'Advanced',
}

export interface FeatureUsageData {
  level: FeatureUsageLevel
  securePrinting: SecurePrinting
  easyPrinting: EasyPrinting
  scanning: ScanningAndCapture
  environment: Environment
}

interface SecurePrinting {
  securePrintRelease: boolean
  watermarking: boolean
  printArchiving: boolean
}

interface EasyPrinting {
  findMePrinting: boolean
  mobilityPrint: boolean
  webPrint: boolean
  emailToPrint: boolean
  directPrint: boolean
}

interface ScanningAndCapture {
  integratedScanning: boolean
  scanningDevices: number
  scanToCloud: boolean
  scanToEmail: boolean
  scanToFolder: boolean
  ocr: boolean
}

interface Environment {
  pagesReleased: number
  ssViaHoldRelease: number
  ssViaAdminPolicy: number
  ssAtDevice: number
  scheduledReporting: boolean
  printScripting: boolean
  deviceScripting: boolean
  gsViaAdminPolicy: number
  gsAtDevice: number
}

const FeatureUsage: FunctionComponent<Props> = props => {
  const [learnMoreOpen, setLearnMoreOpen] = useState(false)

  return (
    <div id='tab-impact' className='tab visible'>
      {learnMoreOpen && (
        <CustomerUpgradeRequired
          onClose={() => {
            setLearnMoreOpen(false)
          }}
        />
      )}

      <header>
        Tailored insights to help you delight your customer
        <a
          href='#'
          className='action'
          onClick={e => {
            e.preventDefault()
            props.onClose()
          }}
          aria-label='Close Feature usage tab'
        />
        {props.upgradeRequired && (
          <div className='alert warning flex-ver'>
            <i className='icon'>
              <WarningIcon />
            </i>

            <div className='message'>
              Upgrade PaperCut installation to <strong>19.1 or later</strong> to unlock insight into how they are using the product.
            </div>

            <div className='rgt'>
              <a onClick={() => setLearnMoreOpen(true)} className='upgrade btn outline x-sml'>
                Learn more
              </a>
            </div>
          </div>
        )}
      </header>

      <div className={'content threecol ' + (props.upgradeRequired ? 'upgrade-req' : '')}>
        <div className='row flex'>
          <div className='col qtr'>
            <div className='card'>
              <header>
                <i className='icon'>
                  <LockIcon />
                </i>

                <h6>Secure printing</h6>
              </header>

              <dl className='zebra'>
                <dt className='alt'>Secure print release</dt>
                <dd className='alt'>
                  <FeatureStatus used={props.featureUsage?.securePrinting.securePrintRelease ?? false} />
                </dd>
                <dt>Watermarking</dt>
                <dd>
                  <FeatureStatus used={props.featureUsage?.securePrinting.watermarking ?? false} />
                </dd>
                <dt className='alt'>Print archiving</dt>
                <dd className='alt'>
                  <FeatureStatus used={props.featureUsage?.securePrinting.printArchiving ?? false} />
                </dd>
              </dl>
            </div>
          </div>

          <div className='col qtr'>
            <div className='card'>
              <header>
                <i className='icon'>
                  <PeopleIcon />
                </i>

                <h6>Easy printing</h6>
              </header>

              <dl className='zebra'>
                <dt className='alt'>Find-Me printing</dt>
                <dd className='alt'>
                  <FeatureStatus used={props.featureUsage?.easyPrinting.findMePrinting ?? false} />
                </dd>
                <dt>Mobility Print</dt>
                <dd>
                  <FeatureStatus used={props.featureUsage?.easyPrinting.mobilityPrint ?? false} />
                </dd>
                <dt className='alt'>Web Print</dt>
                <dd className='alt'>
                  <FeatureStatus used={props.featureUsage?.easyPrinting.webPrint ?? false} />
                </dd>
                <dt>Email to Print</dt>
                <dd>
                  <FeatureStatus used={props.featureUsage?.easyPrinting.emailToPrint ?? false} />
                </dd>
                <dt className='alt'>Direct Print</dt>
                <dd className='alt'>
                  <FeatureStatus used={props.featureUsage?.easyPrinting.directPrint ?? false} />
                </dd>
              </dl>
            </div>
          </div>

          <div className='col qtr'>
            <div className='card'>
              <header>
                <i className='icon'>
                  <ScannerIcon />
                </i>

                <h6>Scanning &amp; capture</h6>
              </header>

              <dl className='zebra'>
                <dt className='alt'>Integrated Scanning</dt>
                <dd className='alt'>
                  <FeatureStatus used={props.featureUsage?.scanning.integratedScanning ?? false} />
                </dd>
                <dt>Scanning devices</dt>
                <dd>{props.featureUsage?.scanning.scanningDevices ?? false}</dd>
                <dt className='alt'>OCR</dt>
                <dd className='alt'>
                  <FeatureStatus used={props.featureUsage?.scanning.ocr ?? false} />
                </dd>
                <dt>Scan to Cloud Storage</dt>
                <dd>
                  <FeatureStatus used={props.featureUsage?.scanning.scanToCloud ?? false} />
                </dd>
                <dt className='alt'>Scan to Folder</dt>
                <dd className='alt'>
                  <FeatureStatus used={props.featureUsage?.scanning.scanToFolder ?? false} />
                </dd>
                <dt>Scan to email</dt>
                <dd>
                  <FeatureStatus used={props.featureUsage?.scanning.scanToEmail ?? false} />
                </dd>
              </dl>
            </div>
          </div>

          <div className='col qtr'>
            <div className='card'>
              <header>
                <i className='icon'>
                  <GlobeIcon />
                </i>

                <h6>Responsible printing</h6>
              </header>

              <dl className='zebra'>
                <dt className='alt'>Scheduled reporting</dt>
                <dd className='alt'>
                  <FeatureStatus used={props.featureUsage?.environment.scheduledReporting ?? false} />
                </dd>
                <dt>Print scripting</dt>
                <dd>
                  <FeatureStatus used={props.featureUsage?.environment.printScripting ?? false} />
                </dd>
                <dt className='alt'>Device scripting</dt>
                <dd className='alt'>
                  <FeatureStatus used={props.featureUsage?.environment.deviceScripting ?? false} />
                </dd>
              </dl>
              <h5>Sheets saved</h5>
              <dl className='zebra'>
                <dt className='alt'>Via hold/release</dt>
                <dd className='alt'>
                  <EnvironmentalStats
                    pagesReleased={props.featureUsage?.environment.pagesReleased ?? 0}
                    featuredPagesReleased={props.featureUsage?.environment.ssViaHoldRelease ?? 0}
                  />
                </dd>
                <dt>Via admin policy</dt>
                <dd>
                  <EnvironmentalStats
                    pagesReleased={props.featureUsage?.environment.pagesReleased ?? 0}
                    featuredPagesReleased={props.featureUsage?.environment.gsViaAdminPolicy ?? 0}
                  />
                </dd>
                <dt className='alt'>At the device</dt>
                <dd className='alt'>
                  <EnvironmentalStats pagesReleased={props.featureUsage?.environment.pagesReleased ?? 0} featuredPagesReleased={props.featureUsage?.environment.gsAtDevice ?? 0} />
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeatureUsage
