import React from 'react'

export const LoadingDots = () => {
  return (
    <i className='loader ball-beat'>
      <span />
      <span />
      <span />
    </i>
  )
}
