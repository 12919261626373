import React, { ChangeEvent, FunctionComponent, useEffect, useRef, useState } from 'react'
import { useMountEffect } from '../../hooks/MountEffect'
import { useTypedSelector } from '../../redux/reducers'
import { isDemo } from '../../redux/selectors'
import { CustomerFunction, HostData, Product } from '../Host/Data'
import { ClipboardIcon, CloudFileIcon, EmailReadIcon, ExclaimIcon, InfoIcon, LightningIcon, LinkCustomerIcon, MagicIcon, PeopleManIcon } from '../Icons/Icons'
import { LoadingDots } from '../LoadingDots/LoadingDots'
import CustomModal from '../MyModal/CustomModal'
import { getPmitcDcs, getPmitcDcDisplayName } from '../Pmitc/Data'

interface Props {
  teamID: string
  isVisible: boolean
  close: () => void
  action: CustomerFunction
  host?: HostData
  addTag?: boolean
}

const infrastructureTypes: Record<string, string> = {
  aws: 'AWS',
  azure: 'Azure',
  gcp: 'Google Cloud Platform',
  papercut: 'PaperCut Cloud Services',
  local: 'Locally hosted',
}

const TRIAL_SUBSCRIPTION = 'trial'
// const ACTIVATE_SUBSCRIPTION = 'activate';

const CustomerModal: FunctionComponent<Props> = props => {
  const demoMode = useTypedSelector(isDemo)
  const [hiveProvisioningAuthorized, setHiveProvisioningAuthorized] = useState(false)
  const [operationInProgress, setOperationInProgress] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  const [customerName, setCustomerName] = useState<string>(props.host?.name || '')
  const [newTag, setNewTag] = useState('')
  const [tags, setTags] = useState<Set<string>>(new Set<string>(props.host?.tags || []))
  const [product, setProduct] = useState(props.host?.product || Product.MF)
  const tagInputRef = useRef<HTMLInputElement>(null)
  const [tagValid, setTagValid] = useState(false)
  const [existingHiveOrg, setExistingHiveOrg] = useState(false)
  const [hiveOrgId, setHiveOrgId] = useState('')
  const addProvisionedHiveCustomerDisabled = !existingHiveOrg && !hiveProvisioningAuthorized
  const addCustomerDisabled = product === Product.Hive && (demoMode || addProvisionedHiveCustomerDisabled)
  const pmitcDcs = getPmitcDcs()
  const [pmitcDc, setPmitcDc] = useState(pmitcDcs[0])
  const [hiveSubscription] = useState(TRIAL_SUBSCRIPTION)
  const [activationCode] = useState('')
  const isEditing = props.host !== undefined
  const nameRef = useRef<HTMLInputElement>(null)

  useMountEffect(() => {
    nameRef.current?.focus()
  })

  const initInfrastructure = () => {
    const isOther = props.host && props.host.infrastructure && !infrastructureTypes[props.host.infrastructure]

    return isOther ? 'other' : props.host?.infrastructure ?? ''
  }

  const [infrastructure, setInfrastructure] = useState(initInfrastructure)
  const isOtherInfrastructure = infrastructure && !infrastructureTypes[infrastructure]

  const initOtherInfrastructure = () => {
    if (props.host) {
      return infrastructureTypes[props.host.infrastructure] ? '' : props.host.infrastructure
    }

    return ''
  }

  const [otherInfrastructure, setOtherInfrastructure] = useState(initOtherInfrastructure)

  useEffect(() => {
    if (props.addTag) {
      tagInputRef.current?.focus()
    }
  }, [props.addTag])

  const onClose = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault()
    props.close()
  }

  const addCustomer = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault()
    if (!validate()) return
    setOperationInProgress(true)
    const trial = hiveSubscription === TRIAL_SUBSCRIPTION
    props
      .action(
        product,
        {
          name: customerName,
          infrastructure: isOtherInfrastructure ? otherInfrastructure : infrastructure,
          product: product,
          tags: Array.from(tags.values()),
          existingOrg: existingHiveOrg,
          orgId: hiveOrgId,
          dataCenter: pmitcDc,
          activationCode: trial ? undefined : activationCode,
          trial: trial,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        props.host?.id
      )
      .then(success => {
        setOperationInProgress(false)
        if (success) onClose(event)
      })
      .catch(_ => setOperationInProgress(false))
  }

  const validate = () => {
    const errorsFound = []
    if (!customerName || customerName.trim().length <= 0) {
      errorsFound.push('A name is required')
    }
    if (!product) {
      errorsFound.push('You must select a product')
    }
    if (!infrastructure) {
      errorsFound.push('You must select an infrastructure environment')
    }
    if (infrastructure === 'other' && (!otherInfrastructure || otherInfrastructure.trim().length <= 0)) {
      errorsFound.push('You must enter your infrastructure environment')
    }
    if (!isEditing && product === Product.Hive && existingHiveOrg && !hiveOrgId) {
      errorsFound.push('A PaperCut Hive organization ID is required')
    }
    if (hiveSubscription !== TRIAL_SUBSCRIPTION && !activationCode) {
      errorsFound.push('An activation key is required')
    }
    setErrors(errorsFound)
    return errorsFound.length <= 0
  }

  const handleProductChange = (e: React.MouseEvent<HTMLLIElement>, newProduct: string) => {
    e.preventDefault()
    setProduct(newProduct)
    if (newProduct === Product.Hive) {
      setInfrastructure('papercut')
    } else if (infrastructure === 'papercut') {
      setInfrastructure('')
    }
  }

  const getInfrastructureClass = (inf: string) => {
    if (inf === 'papercut') {
      return product === Product.Hive ? 'active' : 'hide'
    }
    if (product === Product.Hive) {
      return 'hide'
    }
    if (inf === infrastructure) {
      return 'active'
    }

    return ''
  }

  const getProductClass = (prod: string) => {
    let className = ''
    if (prod.toLowerCase() === product.toLowerCase()) {
      className = 'active'
    } else if (isEditing) {
      className = 'disabled'
    }

    return className
  }

  const addTag = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    const newTags = tags
    newTags.add(newTag.trim())
    setTags(newTags)
    setNewTag('')
  }

  const removeTag = (tag: string) => {
    const newTags = new Set<string>(tags.values())
    newTags.delete(tag)
    setTags(newTags)
  }

  const infrastructureSelection = (key: string, linkClass?: string) => {
    return (
      <li
        data-option={key}
        className={getInfrastructureClass(key)}
        onClick={e => {
          e.preventDefault()
          setInfrastructure(key)
        }}
      >
        <a href='#' className={linkClass ? linkClass : ''}>
          {infrastructureTypes[key]}
        </a>
      </li>
    )
  }

  const connectHiveAside = (
    <div className='aside' data-option='connect-hive'>
      <ol>
        <li>
          <small>Step 1</small>
          <i className='icon thirtytwo'>
            <ClipboardIcon />
          </i>
          <div>
            <h5>Add customer</h5>
            <p>Enter a few details about your customer and their PaperCut Hive organization.</p>
          </div>
        </li>
        <li>
          <small>Step 2</small>
          <i className='icon thirtytwo'>
            <LightningIcon />
          </i>
          <div>
            <h5>Connect customer</h5>
            <p>Get your customer to add the PaperCut Multiverse Partner Remote Assistance add-on in their PaperCut Hive console.</p>
          </div>
        </li>
        <li>
          <small>Step 3</small>
          <i className='icon thirtytwo'>
            <CloudFileIcon />
          </i>
          <div>
            <h5>Save the day</h5>
            <p>You're ready! Stay on top of things with remote monitoring and management!</p>
          </div>
        </li>
      </ol>
    </div>
  )

  const provisionHiveAside = (
    <div className='aside' data-option='provision-hive'>
      <ol>
        <li>
          <small>Step 1</small>
          <i className='icon thirtytwo'>
            <PeopleManIcon />
          </i>
          <div>
            <h5>Provision customer</h5>
            <p>Enter a few details about your customer, and kick off provisioning.</p>
          </div>
        </li>
        <li>
          <small>Step 2</small>
          <i className='icon thirtytwo'>
            <MagicIcon />
          </i>
          <div>
            <h5>Weave your magic</h5>
            <p>Apply your expert knowledge and help the customer configure their environment.</p>
          </div>
        </li>
        <li>
          <small>Step 3</small>
          <i className='icon thirtytwo'>
            <CloudFileIcon />
          </i>
          <div>
            <h5>Save the day</h5>
            <p>You're ready! Stay on top of things with remote monitoring and management!</p>
          </div>
        </li>
      </ol>
    </div>
  )

  const mfngAside = (
    <div className='aside' data-option='mfng'>
      <ol>
        <li>
          <small>Step 1</small>
          <i className='icon thirtytwo'>
            <ClipboardIcon />
          </i>
          <div>
            <h5>Add customer</h5>
            <p>Enter a few details about your customer, and download a unique PaperCut Multiverse Connector installer.</p>
          </div>
        </li>
        <li>
          <small>Step 2</small>
          <i className='icon thirtytwo'>
            <LinkCustomerIcon />
          </i>
          <div>
            <h5>Link customer</h5>
            <p>Get your customer to install the Connector on their Application Server.</p>
          </div>
        </li>
        <li>
          <small>Step 3</small>
          <i className='icon thirtytwo'>
            <EmailReadIcon />
          </i>
          <div>
            <h5>Save the day</h5>
            <p>You're ready! Stay on top of things with remote monitoring and management!</p>
          </div>
        </li>
      </ol>
    </div>
  )

  const onTagInputChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const tag = e.currentTarget.value
    setNewTag(tag)
    setTagValid(tag.trim().length > 0)
  }

  const mainContent = (
    <>
      <form>
        <div className={'field'}>
          <label>Name</label>

          <input
            ref={nameRef}
            placeholder='Enter the organization/account name'
            value={customerName}
            type='text'
            autoFocus={true}
            onChange={e => setCustomerName(e.currentTarget.value)}
          />
        </div>

        <div className={isEditing || product !== Product.Hive ? 'field' : ''}>
          <label>{isEditing ? 'Product' : 'Which product are you connecting to PaperCut Multiverse?'}</label>

          <ul className='flex center option-list'>
            <li className={getProductClass(Product.NG)} onClick={e => handleProductChange(e, Product.NG)}>
              <a href='#' className='logo-ng'>
                PaperCut NG
              </a>
            </li>

            <li className={getProductClass(Product.MF)} onClick={e => handleProductChange(e, Product.MF)}>
              <a href='#' className='logo-mf'>
                PaperCut MF
              </a>
            </li>

            <li className={`${isEditing ? '' : 'option-list-connected'} ${getProductClass(Product.Hive)}`} onClick={e => handleProductChange(e, Product.Hive)}>
              <a href='#' className='logo-hive'>
                PaperCut Hive
              </a>
            </li>
          </ul>
        </div>

        <div className={`field ${product === Product.MF || product === Product.NG ? '' : 'hidden'}`}>
          <label>Infrastructure environment</label>

          <ul className='flex center option-list'>
            {infrastructureSelection('aws', 'logo-aws')}

            {infrastructureSelection('azure', 'logo-azure')}

            {infrastructureSelection('gcp', 'logo-gc')}

            {infrastructureSelection('local')}

            <li data-option='other' className={isOtherInfrastructure ? 'active' : ''} onClick={() => setInfrastructure('other')}>
              <a href='#'>Other</a>
            </li>
          </ul>

          <div className={infrastructure !== 'other' ? 'hidden' : 'option-other'}>
            <input
              type='text'
              value={otherInfrastructure}
              autoFocus={true}
              onChange={e => setOtherInfrastructure(e.currentTarget.value)}
              placeholder='Other infrastructure environment'
            />
          </div>
        </div>

        <div className={`option-list-content ${product === Product.Hive && !isEditing ? '' : 'hidden'}`}>
          <div className='field'>
            <label>Create an organization for the customer?</label>

            <ul className='flex center option-list'>
              <li data-option='provision-hive' className={existingHiveOrg ? '' : 'active'} onClick={() => setExistingHiveOrg(false)}>
                <a href='#'>Yes, create a new organization</a>
              </li>

              <li data-option='connect-hive' className={existingHiveOrg ? 'active' : ''} onClick={() => setExistingHiveOrg(true)}>
                <a href='#'>No, connect an existing organization</a>
              </li>
            </ul>
          </div>

          <div className='field'>
            <label id='hive-dc'>Data center location</label>
            <div className='select'>
              <select onChange={e => setPmitcDc(e.target.value)} value={pmitcDc} aria-labelledby='hive-dc'>
                {pmitcDcs.map(dc => (
                  <option key={dc} value={dc}>
                    {getPmitcDcDisplayName(dc)}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {!existingHiveOrg && (
            <>
              <div className='field'>
                <label className='inl' htmlFor='hive-provisioning-authorized'>
                  <input
                    type='checkbox'
                    id='hive-provisioning-authorized'
                    checked={hiveProvisioningAuthorized}
                    onChange={() => setHiveProvisioningAuthorized(!hiveProvisioningAuthorized)}
                  />
                  The customer has agreed to the{' '}
                  <a href='https://hive.papercut.com/terms-of-service' target='_blank'>
                    PaperCut Hive Terms of Service
                  </a>
                </label>
              </div>
            </>
          )}

          {existingHiveOrg && (
            <div className='field'>
              <label id='hive-org'>Organization ID</label>
              <input type='text' value={hiveOrgId} onChange={e => setHiveOrgId(e.target.value.trim())} />
            </div>
          )}

          {/* TODO Uncomment when connect existing org with authorization code is being implemented */}
          {/* <div className="field hidden" data-content="hive-existing">
                        <div className="row flex">
                        <div className="col half">
                            <label>Organization ID</label>
                            <input type="text" placeholder="e.g. DDY4NJX" value="" />
                        </div>
                        <div className="col half">
                            <label className="flex flex-ver">Authorization code 
                            <i className="icon-info">
                                <span className="tip">
                                    Your customer has a unique authorization code to create a connection between PaperCut Multiverse and Hive.
                                </span>
                            </i>
                            </label>
                            <input type="text" placeholder="0000-0000-0000" value="" />
                        </div>
                        </div>
                    </div>
                    */}

          {/* <div className={`field no-margin-bottom ${hiveSetup === "existing" ? 'hidden' : ''}`}>
                        <label>Activate PaperCut Hive now?</label>
                        <small>If you've already placed your Hive order you can activate Hive now. If not you can continue
                            to
                            create the account now, and activate anytime in the next 40 days.</small>
                        <ul className="flex center option-list">
                            <li data-option={`hive-trial`}
                                className={hiveSubscription === TRIAL_SUBSCRIPTION ? 'active' : ''}
                                onClick={() => setHiveSubscription(TRIAL_SUBSCRIPTION)}>
                                <a href="#">Continue & activate later</a>
                            </li>
                            <li data-option={`hive-activate`}
                                className={hiveSubscription === ACTIVATE_SUBSCRIPTION ? 'active' : ''}
                                onClick={() => setHiveSubscription(ACTIVATE_SUBSCRIPTION)}>
                                <a href="#">Activate now</a>
                            </li>
                        </ul>
                    </div>

                    <div
                        className={`field margin-top ${hiveSubscription === ACTIVATE_SUBSCRIPTION && hiveSetup === NEW_HIVE ? '' : 'hidden'}`}
                        data-content="hive-activate">
                        <label>Activation key</label>
                        <input type="text" placeholder="e.g. H01RT-76YYU-80K" value={activationCode}
                            onChange={(e) => setActivationCode(e.target.value)}/>
                    </div> */}
        </div>

        <div className='field'>
          <label>
            Tags
            <i className='icon-info' style={{ height: '24px' }}>
              <InfoIcon />

              <span className='tip wide'>
                Use tags to classify customers on your dashboard for easy lookup. You could classify by industry, location or level of support e.g. education, north, premium.
              </span>
            </i>
          </label>

          <div className='flex flex-nowrap'>
            <input type='text' placeholder='Enter classification tag' className='tag-new' ref={tagInputRef} value={newTag} onChange={onTagInputChanged} />

            <button className={`add-tag btn secondary ${tagValid ? '' : 'disabled'}`} disabled={!tagValid} onClick={addTag}>
              Add tag
            </button>
          </div>

          <div className='flex tag-list'>
            {Array.from(tags.values()).map(tag => (
              <div className='tag' key={tag} data-heap-redact-text>
                {tag}

                <a className='lines-button close remove' onClick={() => removeTag(tag)}>
                  <span className='lines' />
                </a>
              </div>
            ))}
          </div>
        </div>
      </form>
    </>
  )

  const footer = isEditing ? (
    <>
      <button className='btn outline' onClick={onClose}>
        Cancel
      </button>

      <button className='btn' onClick={addCustomer}>
        Save changes
      </button>
    </>
  ) : (
    <a onClick={addCustomer} className={`btn fp center ${operationInProgress ? 'loading' : ''} ${addCustomerDisabled ? 'disabled' : ''}`}>
      <LoadingDots />
      <span className='text'>Add customer</span>
    </a>
  )

  const classNameForAside = () => {
    if (product !== Product.Hive) {
      return 'mfng'
    }

    return existingHiveOrg ? 'connect-hive' : 'provision-hive'
  }

  return props.isVisible ? (
    <CustomModal
      id='popup-customer'
      close={props.close}
      width={isEditing ? '580px' : '780px'}
      parentClass='open-customer'
      additionalClassName={`basic-wide ${classNameForAside()}`}
    >
      <div className='flex flex-nowrap'>
        <div className='main'>
          <header className='center'>
            <h3>{isEditing ? 'Edit profile' : 'Add customer'}</h3>
          </header>

          {errors.length > 0 && (
            <div className='alert error'>
              <i className='icon-exclaim'>
                <ExclaimIcon />
              </i>
              <div className='message'>
                <ul>
                  {errors.map(msg => (
                    <li key={msg}>{msg}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          <div className='content'>{mainContent}</div>

          <footer>{footer}</footer>
        </div>

        {!isEditing && (
          <>
            {mfngAside} {connectHiveAside} {provisionHiveAside}
          </>
        )}
      </div>
    </CustomModal>
  ) : (
    <></>
  )
}

export default CustomerModal
