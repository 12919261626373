import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { supportEmail } from '../ContactSupport/Data'
import CustomModal from '../MyModal/CustomModal'

interface Props {
  onClose: () => void
}

const DemoTeamModal: FunctionComponent<Props> = props => {
  return (
    <CustomModal id='test' close={props.onClose}>
      <header className='center'>
        <div id='logo' className='icon-only'></div>

        <h3>PaperCut Multiverse Demo!</h3>
      </header>

      <div className='content'>
        <p>This demo team allows you to explore an up-to-date version of PaperCut Multiverse that is similar to the one that runs in production.</p>

        <p>
          Pre-populated with some sample customers, you can easily show how PaperCut Multiverse can best support all of your customers. You can reset this list at any time in{' '}
          <Link to='/my-team/settings' onClick={props.onClose}>
            My team &gt; Settings
          </Link>
          .
        </p>

        <p>You can also:</p>

        <ul style={{ listStyle: 'unset', textAlign: 'left', marginLeft: '1.3em', marginBottom: '1.3em' }}>
          <li>See how in-progress/beta features will work and feel.</li>

          <li>Invite other team members, just like a regular team, so they can give demos or have a peek before getting started.</li>
        </ul>

        <p>
          If you have any questions, concerns or suggestions, reach out to us at <a href={`mailto:${supportEmail}?subject=Demo team feedback`}>{supportEmail}</a>.
        </p>
      </div>

      <footer className='center'>
        <a href='#' className='btn'>
          <i className='loader ball-beat'>
            <span></span>
            <span></span>
            <span></span>
          </i>{' '}
          <span className='text' onClick={props.onClose}>
            Let's go!
          </span>
        </a>
      </footer>
    </CustomModal>
  )
}

export default DemoTeamModal
