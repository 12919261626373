import React, { FunctionComponent, useEffect, useState } from 'react'
import axios from 'axios'
import { EmailValidator } from '../../service/EmailValidator'
import { ExclaimIcon } from '../Icons/Icons'
import { LoadingDots } from '../LoadingDots/LoadingDots'
import Toaster from '../Toaster'

interface Props {
  teamID: string
  demoMode: boolean
}

interface List {
  profiles: Settings[]
}

interface Settings {
  name: string
  emails: string[]
  maintenanceSupportExpiringEnabled: boolean
  maintenanceSupportExpiredEnabled: boolean
  id: Number
}

function parseEmails(emailsString: string): string[] {
  const emails = new Set<string>()
  emailsString
    .split(/,\s*|\n/)
    .filter(email => email.trim().length > 0)
    .forEach(email => emails.add(email))
  return Array.from(emails.keys())
}

export const LicenseNotificationSettings: FunctionComponent<Props> = props => {
  const [loaded, setLoaded] = useState(false)
  const [emailString, setEmailString] = useState('')
  const [invalidEmails, setInvalidEmails] = useState([] as string[])
  const [settings, setSettings] = useState({
    maintenanceSupportExpiringEnabled: false,
    maintenanceSupportExpiredEnabled: false,
    emails: [] as string[],
    id: 0,
    name: '',
  } as Settings)
  const getSettings = (teamID: string) => {
    return axios
      .get<List>('/api/teams/' + teamID + '/settings/notifications/license')
      .then(result => {
        const settingsResponse = result.data.profiles
        setSettings(settingsResponse[0])
        setEmailString(settingsResponse[0].emails.join(', '))
      })
      .catch(error => {
        console.log(error)
      })
  }
  const saveSettings = () => {
    if (!valid()) {
      return
    }
    const newSettings: Settings = {
      ...settings,
      emails: parseEmails(emailString),
    }
    axios
      .put('/api/teams/' + props.teamID + '/settings/notifications/license/' + settings.id, newSettings)
      .then(_ => Toaster.notifySuccess('Changes saved.'))
      .catch(_ => Toaster.notifyFailure('Unable to save changes.'))
  }
  const valid = () => {
    let invalidatedEmails = [] as string[]
    parseEmails(emailString).forEach(email => {
      if (!EmailValidator.validate(email)) {
        invalidatedEmails.push(email)
      }
    })
    setInvalidEmails(invalidatedEmails)
    return invalidatedEmails.length === 0
  }

  useEffect(() => {
    getSettings(props.teamID).then(_ => setLoaded(true))
  }, [props.teamID])

  return (
    <div className='row flex'>
      <div className='col forty'>
        <h5>License</h5>
        <p>You will receive notifications for any of the events selected.</p>
        {/*<a href="#" className="btn outline notification-license">Preview Notification</a>*/}
      </div>
      {!loaded && (
        <div className='col sixty'>
          <div className='empty loading'>
            <LoadingDots />
          </div>
        </div>
      )}
      {loaded && (
        <div className='col sixty'>
          <div className='list'>
            <div className='surface'>
              {invalidEmails.length !== 0 && (
                <div className='alert error'>
                  <i className='icon-exclaim'>
                    <ExclaimIcon />
                  </i>

                  <div className='message' data-heap-redact-text>
                    Invalid email addresses: {invalidEmails.join(', ')}
                  </div>
                </div>
              )}

              <div className='field'>
                <label>What type of notifications should be sent?</label>
                <label className='inl highlight' htmlFor='tips'>
                  <input
                    type='checkbox'
                    id='tips'
                    checked={settings.maintenanceSupportExpiredEnabled}
                    onChange={() =>
                      setSettings({
                        ...settings,
                        maintenanceSupportExpiredEnabled: !settings.maintenanceSupportExpiredEnabled,
                      })
                    }
                  />
                  PaperCut maintenance &amp; support expired
                </label>
                <label className='inl highlight' htmlFor='errors'>
                  <input
                    type='checkbox'
                    id='errors'
                    checked={settings.maintenanceSupportExpiringEnabled}
                    onChange={() =>
                      setSettings({
                        ...settings,
                        maintenanceSupportExpiringEnabled: !settings.maintenanceSupportExpiringEnabled,
                      })
                    }
                  />
                  PaperCut maintenance &amp; support expiring (60 days)
                </label>
              </div>
              <div className={invalidEmails.length === 0 ? 'field' : 'field error'}>
                <label>Who should get notified?</label>

                <textarea placeholder='email@example.com, email@subdomain.example.com' rows={6} value={emailString} onChange={e => setEmailString(e.target.value)} />

                <small className='blk'>
                  <span>Separate multiple addresses with commas or new lines.</span>
                </small>
              </div>
              <footer>
                <a
                  href='#'
                  className='btn'
                  onClick={e => {
                    e.preventDefault()
                    if (!props.demoMode) saveSettings()
                  }}
                >
                  Save changes
                </a>
              </footer>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
