import React, { FunctionComponent, useState } from 'react'
import { useDebounce } from '../../hooks/Debounce'
import { Timestamps } from '../../service/Timestamps'
import { HostEvent, HostEventType } from '../Host/Data'
import { CalendarIcon, CustomersIcon, ErrorIcon, ExclaimIcon, LicenseIcon, RemoteManageIcon, TickIcon } from '../Icons/Icons'

interface Props {
  teamID: string
  hostEvents: HostEvent[]
  onClose: () => void
}

export const EventLog: FunctionComponent<Props> = props => {
  const [eventSearch, setEventSearch] = useState('')
  const debouncedEventSearchFilter = useDebounce(eventSearch, 500)

  const filteredHostEvents = props.hostEvents.filter(e => {
    let searchTerm = debouncedEventSearchFilter.trim().toLowerCase()
    if (searchTerm) {
      return e.type.toLowerCase().includes(searchTerm) || (e.message?.toLowerCase().includes(searchTerm) ?? false)
    }
    return true
  })

  const getStatus = (event: HostEvent) => {
    switch (event.type) {
      case HostEventType.Error:
        return (
          <div className='col status error'>
            <i className='icon sixteen'>
              <ErrorIcon />
            </i>
          </div>
        )
      case HostEventType.Warning:
        return (
          <div className='col status warning'>
            <i className='icon sixteen'>
              <ExclaimIcon />
            </i>
          </div>
        )
      case HostEventType.Good:
        return (
          <div className='col status good'>
            <i className='icon sixteen'>
              <TickIcon />
            </i>
          </div>
        )
      case HostEventType.Remote:
        return (
          <div className='col status event'>
            <i className='icon sixteen'>
              <RemoteManageIcon />
            </i>
          </div>
        )
      case HostEventType.License:
        return (
          <div className='col status'>
            <i className='icon sixteen'>
              <LicenseIcon />
            </i>
          </div>
        )
      case HostEventType.Customer:
        return (
          <div className='col status'>
            <i className='icon sixteen'>
              <CustomersIcon />
            </i>
          </div>
        )
      default:
        return (
          <div className='col status'>
            <i className='icon sixteen'>
              <CalendarIcon />
            </i>
          </div>
        )
    }
  }

  const getMessageClasses = (eventType: HostEventType) => {
    switch (eventType) {
      default:
        return 'col message'
      case HostEventType.Error:
      case HostEventType.Warning:
        return 'col message highlight'
    }
  }

  const onClose = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    props.onClose()
  }

  return (
    <div id='tab-events' className='tab visible'>
      <header>
        Log of all PaperCut activity
        <a href='#' className='action' onClick={onClose} aria-label='Close Event log tab' />
      </header>

      <div className='content'>
        <div className='row flex'>
          <div className='twothird'>
            <div className='flex flex-start btm' data-heap-redact-text>
              {props.hostEvents.length !== 0 && (
                <input type='search' placeholder='Search by message or type' value={eventSearch} onChange={e => setEventSearch(e.currentTarget.value)} />
              )}
            </div>

            <div className='list'>
              <ul>
                {filteredHostEvents.length === 0 && (
                  <li>
                    <div className='row'>
                      <div className='col status event'>
                        <i className='icon sixteen'>
                          <CalendarIcon />
                        </i>
                      </div>

                      {props.hostEvents.length === 0 && <div className='col message'>No events found.</div>}
                      {props.hostEvents.length > 0 && <div className='col message'>No matching events found.</div>}
                    </div>
                  </li>
                )}

                {filteredHostEvents.map(hostEvent => {
                  const occurredAt = Timestamps.format(hostEvent.occurredAt)

                  return (
                    <li key={new Date(hostEvent.occurredAt).toISOString()}>
                      <div className='row'>
                        {getStatus(hostEvent)}

                        <div className={getMessageClasses(hostEvent.type)} data-heap-redact-text>
                          {hostEvent.message}
                        </div>

                        <div className='col time'>{occurredAt}</div>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
