import { DataCenter, getDataCenter } from '../../env'

const dcAuProd = 'au'
const dcAuStaging = 'au-staging'
const dcEuProd = 'eu'
const dcUkProd = 'uk'
export const dcUsGenesis = 'us-genesis'

export function getPmitcDcDisplayName(dc: string): string {
  switch (dc) {
    default:
      return '?' + dc + '?'
    case dcAuProd:
      return 'Australia'
    case dcAuStaging:
      return 'Australia (staging)'
    case dcEuProd:
      return 'European Union'
    case dcUkProd:
      return 'United Kingdom'
    case dcUsGenesis:
      return 'United States'
  }
}

// Print management in the cloud (Hive/Pocket) data centers
export function getPmitcDcs(): string[] {
  // The first PMITC data center in the list should be the most appropriate for the given Multiverse data center,
  // followed by the rest in alphabetical order of display name
  // PMITC test/staging data centers must not be listed in Multiverse production data centers
  switch (getDataCenter()) {
    default:
      return [dcUsGenesis, dcAuProd, dcEuProd, dcUkProd]
    case DataCenter.Test:
    case DataCenter.Staging:
      return [dcAuStaging, dcUsGenesis]
    case DataCenter.AU:
      return [dcAuProd, dcEuProd, dcUkProd, dcUsGenesis]
    case DataCenter.EU:
      return [dcEuProd, dcAuProd, dcUkProd, dcUsGenesis]
    case DataCenter.UK:
      return [dcUkProd, dcAuProd, dcEuProd, dcUsGenesis]
  }
}

export interface AccountProfile {
  dataCenter: string
}

export interface Subscription {
  orgId: string
  orgName: string
  partnerName: string
  partnerCode: string
}
