import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type PmitcSessionState = {
  logoutLink?: string
  name?: string
  window?: Window
}
const pmitcSessionSlice = createSlice({
  name: 'pmitcSession',
  initialState: {} as PmitcSessionState,
  reducers: {
    setPmitcSession(state, action: PayloadAction<PmitcSessionState>) {
      return {
        logoutLink: action.payload.logoutLink,
        name: action.payload.name,
        window: action.payload.window,
      }
    },
  },
})

export const { setPmitcSession } = pmitcSessionSlice.actions
export default pmitcSessionSlice.reducer
