import history from '../history'
import axios from 'axios'
import { AuthType, User } from '../components/Team/Data'

export type AccessToken = string

export enum AuthStatus {
  UNAUTHORIZED,
  AUTHORIZED,
}

export interface AuthToken {
  accessToken: AccessToken
  expiresAt: number
}

export type Permission =
  | 'remoteManage'
  | 'inviteMember'
  | 'updateUser'
  | 'removeMember'
  | 'updateTeamHealthSettings'
  | 'updateTeamLicenseSettings'
  | 'uploadLicense'
  | 'viewLicenses'
  | 'manageCustomer'
  | 'updateResellerSetting'
  | 'updateTeamSettings'
  | 'createTeam'
  | 'deleteTeam'

export interface UserProfile {
  teamID: string
  userID: string
  name: string
  picture: string
  permissions: Permission[]
}

export interface InviteData {
  teamInviteCode?: string
  invitedEmail?: string
  teamID?: string
}

export interface AuthProvider {
  authorize: (data: InviteData) => void
  logout: (err?: any) => void
  parseToken: () => Promise<AuthToken>
  authorizeInviteEmail: (teamInviteCode: string) => Promise<string>
  authorizeForMultiverseAPI: (token: AuthToken, teamInviteCode: string, teamID?: string) => Promise<UserProfile>
  renewSession: () => Promise<AuthToken>
}

interface Users {
  users: User[]
}

const teamInviteCodeStorageKey = 'team-invite-code'

// TODO move state out of here into component hierarchy
export class AuthHandler {
  constructor(provider: AuthProvider) {
    this.provider = provider
  }

  private provider: AuthProvider

  private token?: AuthToken

  private userProfile?: UserProfile

  /**
   * Attempts to check the session with auth0.
   *
   *   If successful, it will use the new AuthToken.
   *   If failing that, will redirect to the login page, as configured by Auth0.
   */
  ensureAuthenticatedElseRedirect = async (teamID?: string) => {
    try {
      let token = await this.provider.renewSession()
      this.setLoginState(token)
    } catch (error) {
      console.log('failed to renew Auth0 session, error ' + JSON.stringify(error))
      this.clearLoginState()
      const urlParams = new URLSearchParams(window.location.search)
      const teamInviteCodeParam = urlParams.get('team-invite-code')
      if (teamInviteCodeParam != null) {
        sessionStorage.setItem(teamInviteCodeStorageKey, teamInviteCodeParam)
        await this.provider
          .authorizeInviteEmail(teamInviteCodeParam)
          .then(invitedEmail => this.provider.authorize({ teamInviteCode: teamInviteCodeParam, invitedEmail: invitedEmail }))
          .catch(_ => (document.location.href = '/landing' + window.location.search))
      } else {
        this.provider.authorize({ teamID })
      }
    }
  }

  logout = (error?: any) => {
    this.clearLoginState()
    this.userProfile = undefined
    this.provider.logout(error)
  }

  onLoginCallback = async (): Promise<void> => {
    try {
      let token = await this.provider.parseToken()
      this.setLoginState(token)
      const urlParams = new URLSearchParams(window.location.search)
      let teamID = urlParams.get('teamID')
      let teamInviteCode = sessionStorage.getItem(teamInviteCodeStorageKey)
      this.userProfile = await this.provider.authorizeForMultiverseAPI(token, teamInviteCode === null ? '' : teamInviteCode, teamID ?? undefined)

      sessionStorage.removeItem(teamInviteCodeStorageKey)
      history.push('/')

      if (teamInviteCode) {
        teamID = this.userProfile.teamID
        await axios.get<Users>('/api/teams/' + teamID + '/users').then(res => {
          const teamMembers = res.data.users
          for (let i = 0; i < teamMembers.length; i++) {
            if (this.userProfile?.userID === teamMembers[i].id && teamMembers[i].authType === AuthType.MFA) {
              // trigger an authentication check where the Auth0 post_login Action
              // will determine MFA is required and will prompt for MFA enrolment
              window.location.reload()
              break
            }
          }
        })
      }
    } catch (error) {
      const errMsg = JSON.stringify(error)
      console.error('error handling authentication callback: ' + errMsg)
      this.logout(error)
      return Promise.reject()
    }
  }

  private setLoginState = (token: AuthToken) => {
    this.token = token
  }

  private clearLoginState = () => {
    this.token = undefined
  }

  getTeamID = (): string => (this.userProfile === undefined ? 'invalid' : this.userProfile.teamID)

  getUserID = (): string => (this.userProfile === undefined ? 'invalid' : this.userProfile.userID)

  getUserName = (): string => (this.userProfile === undefined ? 'Loading...' : this.userProfile.name)

  getUserPicture = (): string => (this.userProfile === undefined ? '' : this.userProfile.picture)

  getPermissions = (): Permission[] => (this.userProfile === undefined ? [] : this.userProfile.permissions)

  getAuthStatus = (): AuthStatus => (this.token !== undefined ? AuthStatus.AUTHORIZED : AuthStatus.UNAUTHORIZED)

  getToken = (): AuthToken | undefined => this.token
}
