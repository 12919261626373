import React, { FunctionComponent, ReactNode, useState } from 'react'
import axios from 'axios'
import { useMatch } from 'react-router'
import { AuthHandler, AuthStatus } from '../../auth/AuthHandler'
import { DataCenter, getDataCenter } from '../../env'
import { useMountEffect } from '../../hooks/MountEffect'
import { DataCenterCheck } from './DataCenterCheck'
import PrivateRoute from './PrivateRoute'

interface PrivateRouteContainerProps {
  authHandler: AuthHandler
  children?: ReactNode
}

const PrivateRouteContainer: FunctionComponent<PrivateRouteContainerProps> = props => {
  const match = useMatch('/teams/:teamID')
  const [authStatus, setAuthStatus] = useState(props.authHandler.getAuthStatus())
  const teamID = match?.params.teamID
  const tzOffset = new Date().getTimezoneOffset()
  const isOffsetInUS = tzOffset >= 180 && tzOffset <= 600 // US region roughly covered by Brazil (180) to Hawaii (600). DST not considered.
  const [dcCheck, setDcCheck] = useState(false)

  async function checkAuth() {
    if (authStatus !== AuthStatus.AUTHORIZED) {
      await props.authHandler.ensureAuthenticatedElseRedirect(teamID)
      setAuthStatus(props.authHandler.getAuthStatus())
    }
  }

  useMountEffect(() => {
    const dc = getDataCenter()
    if (authStatus !== AuthStatus.AUTHORIZED && !isOffsetInUS && (dc === DataCenter.Test || dc === DataCenter.US)) {
      console.log('Detected not in US time zone: ' + tzOffset)
      axios
        .get<any>('/api/feature/dc-check')
        .then(async result => {
          if (result.data.on) {
            setDcCheck(true)
          } else {
            // need to catch errors here or else error will fall through and try to checkAuth again
            checkAuth().catch(_ => {})
          }
        })
        .catch(_ => {
          checkAuth()
        })
    } else {
      checkAuth()
    }
  })

  return (
    <>
      {dcCheck && (
        <DataCenterCheck
          onContinue={() => {
            setDcCheck(false)
            checkAuth()
          }}
        />
      )}
      <PrivateRoute authenticationStatus={authStatus}>{props.children}</PrivateRoute>
    </>
  )
}

export default PrivateRouteContainer
