import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type SettingsState = {
  demo: boolean
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState: { demo: false } as SettingsState,
  reducers: {
    setSettings(state, action: PayloadAction<SettingsState>) {
      return {
        demo: action.payload.demo,
      }
    },
  },
})

export const { setSettings } = settingsSlice.actions
export default settingsSlice.reducer
