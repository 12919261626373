import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type SupportState = {
  eolMajorVersion: string
  eolPlannedMajorVersion: string
}

const supportSlice = createSlice({
  name: 'support',
  initialState: {} as SupportState,
  reducers: {
    initSupportState(state, action: PayloadAction<SupportState>) {
      state.eolMajorVersion = action.payload.eolMajorVersion
      state.eolPlannedMajorVersion = action.payload.eolPlannedMajorVersion
    },
  },
})

export const { initSupportState } = supportSlice.actions
export default supportSlice.reducer
