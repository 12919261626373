import React, { FunctionComponent } from 'react'
import { HealthMetricStatus } from '../SystemHealth/Data'

interface Props {
  percentage: number
  status?: HealthMetricStatus
}

const statusClass = (status?: HealthMetricStatus) => {
  switch (status) {
    case HealthMetricStatus.Good:
      return ''
    case HealthMetricStatus.Critical:
      return 'error'
    case HealthMetricStatus.Warning:
      return 'warn'
    default:
      return ''
  }
}

export const Meter: FunctionComponent<Props> = ({ percentage, status }) => {
  return (
    <figure className='progress'>
      <span className={`fill ${statusClass(status)}`} data-width={percentage} style={{ width: percentage + '%' }} />
    </figure>
  )
}
