import { HostData, HostStatus } from '../Host/Data'
import { DisconnectedIcon, LockIconSmall, QuestionIcon } from '../Icons/Icons'
import { SmallCriticalHeartbeat, SmallGoodHeartbeat, SmallWarningHeartbeat } from '../AnimatedSvg/AnimatedSvg'
import React from 'react'
import { UserActivityData } from './UserActivity'

export enum HealthMetricStatus {
  Critical = 'Critical',
  Warning = 'Warning',
  Good = 'Good',
  None = 'None',
}

export interface ThresholdMessages {
  critical: string[]
  warning: string[]
}

export interface SystemHealthMetric {
  status?: HealthMetricStatus
  details?: string
}

interface DiskSpace {
  diskSpaceFreeMB: number
  diskSpaceTotalMB: number
  diskSpaceUsedPercentage: number
  status?: HealthMetricStatus
  metricName?: string
  details?: string
  thresholdMessages?: ThresholdMessages
}

interface CPU {
  processors?: number
  architecture?: string
  systemCPU?: number
  appServerCPU?: number
  status?: HealthMetricStatus
  metricName?: string
  details?: string
  thresholdMessages?: ThresholdMessages
}

interface Memory {
  jvmMemoryMaxMB: number
  jvmMemoryTotalMB: number
  jvmMemoryUsedMB: number
  jvmMemoryUsedPercentage?: number
  status?: HealthMetricStatus
  metricName?: string
  details?: string
  thresholdMessages?: ThresholdMessages
}

interface Database {
  totalConnections?: number
  activeConnections?: number
  maxConnections?: number
  timeToQueryMilliseconds?: number
  type?: string
  version: string
  status?: HealthMetricStatus
  metricName?: string
  details?: string
  thresholdMessages?: ThresholdMessages
}

export interface InfrastructureData {
  cpu: CPU
  diskSpace: DiskSpace
  memory: Memory
  database: Database
}

export interface SystemHealthData {
  observedTime: string
  infrastructure: InfrastructureData
  printManagement: PrintManagementData
  userActivity: UserActivityData
  status: HealthMetricStatus
}

interface PrintProvider {
  total: number
  online: number
  offline: number
  status?: HealthMetricStatus
  metricName?: string
  details?: string
  thresholdMessages?: ThresholdMessages
}

interface SiteServer {
  total: number
  online: number
  offline: number
  status?: HealthMetricStatus
  metricName?: string
  details?: string
  thresholdMessages?: ThresholdMessages
}

interface MobilePrint {
  total: number
  online: number
  offline: number
  status?: HealthMetricStatus
  metricName?: string
  details?: string
  thresholdMessages?: ThresholdMessages
}

interface Devices {
  devicesInErrorCount: number
  devicesInError: DeviceInError[]
  status?: HealthMetricStatus
  metricName?: string
  details?: string
  thresholdMessages?: ThresholdMessages
}

interface DeviceInError {
  name: string
  type?: string
  state: DeviceState
}

interface DeviceState {
  status: string
  statusDescription?: string
  lastJobSeconds?: string
}

export interface PrintManagementData {
  printProvider: PrintProvider
  siteServer: SiteServer
  mobilePrint: MobilePrint
  devices: Devices
}

export function systemHealthIcon(status?: HostStatus, health?: SystemHealthData) {
  switch (status) {
    case HostStatus.Unlinked:
      return (
        <i className='icon thirtytwo'>
          <span className='tip center'>Link this customer</span>
        </i>
      )
    case HostStatus.MonitoringDisabled:
      return (
        <i className='icon thirtytwo'>
          <LockIconSmall />
          <span className='tip center'>Customer has disabled remote monitoring</span>
        </i>
      )
    case HostStatus.MaintenanceAndSupportRequired:
      return (
        <i className='icon thirtytwo question'>
          <QuestionIcon />
          <span className='tip center'>M&S required to view health status</span>
        </i>
      )
    case HostStatus.StaleData:
      return (
        <i className='icon thirtytwo'>
          <DisconnectedIcon />
          <span className='tip center'>Disconnected</span>
        </i>
      )
  }
  switch (health?.status) {
    case HealthMetricStatus.Critical:
      return (
        <i className='icon thirtytwo'>
          <SmallCriticalHeartbeat />
          <span className='tip center'>Critical</span>
        </i>
      )
    case HealthMetricStatus.Warning:
      return (
        <i className='icon thirtytwo'>
          <SmallWarningHeartbeat />
          <span className='tip center'>Warning</span>
        </i>
      )
    case HealthMetricStatus.Good:
      return (
        <i className='icon thirtytwo'>
          <SmallGoodHeartbeat />
          <span className='tip center'>Normal</span>
        </i>
      )
  }
}

export class SystemHealthDataUtil {
  static getSystemHealthFromResponse(data: any) {
    const sysHealth = data.systemHealth
    const sysUsage = data.systemUsage
    return {
      status: data.status,
      observedTime: data.observedTime,
      infrastructure: {
        cpu: {
          processors: sysHealth.applicationServer.systemInfo.processors,
          architecture: sysHealth.applicationServer.systemInfo.architecture,
          systemCPU: sysHealth.applicationServer.systemMetrics.systemCpuLoadPercentage === 100 ? null : sysHealth.applicationServer.systemMetrics.systemCpuLoadPercentage,
          appServerCPU: sysHealth.applicationServer.systemMetrics.processCpuLoadPercentage,
          status: data.cpuStatusDetails.status,
          metricName: data.cpuStatusDetails.metricName,
          details: data.cpuStatusDetails.details,
          thresholdMessages: data.cpuStatusDetails.thresholdMessages,
        },
        diskSpace: {
          diskSpaceFreeMB: sysHealth.applicationServer.systemMetrics.diskSpaceFreeMB,
          diskSpaceTotalMB: sysHealth.applicationServer.systemMetrics.diskSpaceTotalMB,
          diskSpaceUsedPercentage: sysHealth.applicationServer.systemMetrics.diskSpaceUsedPercentage,
          status: data.diskSpaceStatusDetails.status,
          metricName: data.diskSpaceStatusDetails.metricName,
          details: data.diskSpaceStatusDetails.details,
          thresholdMessages: data.diskSpaceStatusDetails.thresholdMessages,
        },
        memory: {
          jvmMemoryMaxMB: sysHealth.applicationServer.systemMetrics.jvmMemoryMaxMB,
          jvmMemoryTotalMB: sysHealth.applicationServer.systemMetrics.jvmMemoryTotalMB,
          jvmMemoryUsedMB: sysHealth.applicationServer.systemMetrics.jvmMemoryUsedMB,
          jvmMemoryUsedPercentage: sysHealth.applicationServer.systemMetrics.jvmMemoryUsedPercentage,
          status: data.memoryStatusDetails.status,
          metricName: data.memoryStatusDetails.metricName,
          details: data.memoryStatusDetails.details,
          thresholdMessages: data.memoryStatusDetails.thresholdMessages,
        },
        database: {
          activeConnections: sysHealth.database.activeConnections,
          maxConnections: sysHealth.database.maxConnections,
          totalConnections: sysHealth.database.totalConnections,
          timeToQueryMilliseconds: sysHealth.database.timeToQueryMilliseconds,
          type: sysUsage.usage['db-type'],
          version: sysUsage.usage['db-version'],
          status: data.databaseStatusDetails.status,
          metricName: data.databaseStatusDetails.metricName,
          details: data.databaseStatusDetails.details,
          thresholdMessages: data.databaseStatusDetails.thresholdMessages,
        },
      },
      printManagement: {
        printProvider: {
          total: sysHealth.printProviders.count,
          online: sysHealth.printProviders.count - sysHealth.printProviders.offlineCount,
          offline: sysHealth.printProviders.offlineCount,
          status: data.printProviderStatusDetails.status,
          metricName: data.printProviderStatusDetails.metricName,
          details: data.printProviderStatusDetails.details,
          thresholdMessages: data.printProviderStatusDetails.thresholdMessages,
        },
        siteServer: {
          total: sysHealth.siteServers.count,
          online: sysHealth.siteServers.count - sysHealth.siteServers.offlineCount,
          offline: sysHealth.siteServers.offlineCount,
          status: data.siteServerStatusDetails.status,
          metricName: data.siteServerStatusDetails.metricName,
          details: data.siteServerStatusDetails.details,
          thresholdMessages: data.siteServerStatusDetails.thresholdMessages,
        },
        mobilePrint: {
          total: sysHealth.mobilityPrintServers.count,
          online: sysHealth.mobilityPrintServers.count - sysHealth.mobilityPrintServers.offlineCount,
          offline: sysHealth.mobilityPrintServers.offlineCount,
          status: data.mobilePrintStatusDetails.status,
          metricName: data.mobilePrintStatusDetails.metricName,
          details: data.mobilePrintStatusDetails.details,
          thresholdMessages: data.mobilePrintStatusDetails.thresholdMessages,
        },
        devices: {
          devicesInErrorCount: sysHealth.devices.inErrorCount,
          devicesInError: sysHealth.devices.inError,
          status: data.devicesStatusDetails.status,
          metricName: data.devicesStatusDetails.metricName,
          details: data.devicesStatusDetails.details,
          thresholdMessages: data.devicesStatusDetails.thresholdMessages,
        },
      },
      userActivity: {
        users: {
          registeredUsers: data.regUsers,
          activeUsers: data.activeUsers,
          activeUsersMonth: data.activeUsersMonth,
          activeUsersWeek: data.activeUsersWeek,
          activeUsers6Months: data.activeUsers6Months,
        },
        devices: {
          pcEmbedded: sysHealth.devices.count,
          otherPrinters: data.otherPrinters,
        },
        jobPrinted: {
          viaPCEmbedded: data.jobPrintedViaPcEmbedded,
          viaPCWebPrint: data.jobPrintedViaWebPrint,
          viaExternalInterface: data.jobPrintedViaExtInterface,
          viaMobilityPrint: data.jobPrintedViaMobilityPrint,
          total: data.jobPrintedTotal,
        },
      },
    }
  }

  static getFeatureUsageFromResponse(data: any) {
    let sysUsage = data.systemUsage
    if (sysUsage.usage['ver-major'] !== '') {
      return {
        level: data.featureUsageLevel,
        securePrinting: {
          securePrintRelease: sysUsage.usage['held-page-count'] !== '0',
          printArchiving: sysUsage.usage['archiving-printers'] !== '0',
          watermarking:
            sysUsage.usage['watermarking-header'] !== '0' ||
            sysUsage.usage['watermarking-footer'] !== '0' ||
            sysUsage.usage['watermarking-fullpage'] !== '0' ||
            sysUsage.usage['watermarking-custom'] !== '0',
        },
        easyPrinting: {
          findMePrinting: data.findMePrint,
          directPrint: data.directPrint,
          mobilityPrint: data.mobilityPrint,
          webPrint: data.webPrint,
          emailToPrint: data.emailToPrint,
        },
        scanning: {
          integratedScanning: data.integratedScanning,
          scanningDevices: data.scanDevices,
          scanToCloud: data.scanToCloud,
          scanToEmail: data.scanToEmail,
          scanToFolder: data.scanToFolder,
          ocr: data.scanWithOcr,
        },
        environment: {
          printScripting: data.scriptPrint,
          scheduledReporting: data.scheduleReporting,
          pagesReleased: data.pagesReleased,
          ssViaHoldRelease: data.sheetsSavedViaHoldRelease,
          ssViaAdminPolicy: data.sheetsSavedViaAdminPolicy,
          ssAtDevice: data.sheetsSavedAtDevice,
          gsViaAdminPolicy: data.grayScaleSettingsViaAdminPolicy,
          gsAtDevice: data.grayScaleSettingsAtDevice,
          deviceScripting: data.scriptDevice,
        },
      }
    }
    return undefined
  }

  static getSystemInfoFromResponse(host: HostData, data: any) {
    const sysHealth = data.systemHealth
    const sysUsage = data.systemUsage
    return {
      product: sysUsage.usage.edition, //|| host.product,
      version: sysHealth.applicationServer.systemInfo.version,
      osPlatform: sysHealth.applicationServer.systemInfo.operatingSystem,
      embeddedCount: sysHealth.devices.count,
      tags: host.tags,
    }
  }
}
