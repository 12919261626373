import React, { FunctionComponent } from 'react'
import { GlobeIcon, LockIcon, PeopleIcon } from '../../Icons/Icons'

interface Props {
  onClick: () => void
  isOpen: boolean
}

const HiveFeatureUsageSummary: FunctionComponent<Props> = props => {
  const onClicked = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    props.onClick()
  }
  return (
    <li className={props.isOpen ? ' active' : ''} onClick={onClicked}>
      <a href='#tab-impact'>
        <div className='title'>Feature usage</div>
        <div className='status'>
          <i className='icon'>
            <span className='icon thirtytwo hide'>
              <LockIcon />
            </span>
            <span className='icon thirtytwo hide'>
              <GlobeIcon />
            </span>
            <span className='icon thirtytwo hide'>
              <PeopleIcon />
            </span>
            <svg className='gauge' xmlns='http://www.w3.org/2000/svg' height='46' width='46' viewBox='0 0 200 200' data-value='50'>
              <path className='bg' stroke='#CFD9E0' d='M41 149.5a77 77 0 1 1 117.93 0' fill='none' />
              {/*<path className="meter" stroke="#26AA27" d="M41 149.5a77 77 0 1 1 117.93 0" fill="none"*/}
              {/*      stroke-dasharray="350" stroke-dashoffset="350"*/}
              {/*      style="stroke-dashoffset: 174.759px;"/>*/}
            </svg>
          </i>
          <span className='state green'>Not yet available</span>
        </div>
      </a>
    </li>
  )
}

export default HiveFeatureUsageSummary
