import React, { FunctionComponent, useEffect } from 'react'
import RouteTabLink from '../Tabs/RouteTab'
import { Route, Routes } from 'react-router-dom'
import { ImportHive } from './ImportHive'
import { useMatch, useNavigate } from 'react-router'
import ServiceProviders from './ServiceProviders'

interface Props {
  teamID: string
}

const ManageMultiverse: FunctionComponent<Props> = props => {
  const navigateTo = useNavigate()
  const match = useMatch('/manage/:subPath')
  useEffect(() => {
    if (!match) {
      navigateTo('/manage/import-hive')
    }
  }, [match, navigateTo])

  return (
    <div className='tab'>
      <header className=''>
        <div className='auto'>
          <h2>Manage PaperCut Multiverse</h2>
        </div>
        <div className='tabs'>
          <ul className='auto'>
            <RouteTabLink key='/manage/import-hive' path='/manage/import-hive'>
              Import a PaperCut Hive organization
            </RouteTabLink>
            <RouteTabLink key='/manage/invite-service-provider' path='/manage/invite-service-provider'>
              Invite a service provider
            </RouteTabLink>
          </ul>
        </div>
      </header>
      <div className='tab auto'>
        <Routes>
          <Route key='/import-hive' path='/import-hive' element={<ImportHive />} />
          <Route key='/invite-service-provider' path='/invite-service-provider' element={<ServiceProviders teamID={props.teamID} />} />
        </Routes>
      </div>
    </div>
  )
}

export default ManageMultiverse
