import React, { FunctionComponent, useEffect, useState } from 'react'
import axios from 'axios'
import { UserConsumer } from '../../auth/UserContext'
import { LanyardBGIcon, LanyardIcon, MoonIcon, SunIcon } from '../Icons/Icons'
import { LoadingDots } from '../LoadingDots/LoadingDots'
import Toaster from '../Toaster'
import PasswordModal from './PasswordModal'

interface Props {
  accessToken: string
}

interface Settings {
  appearance: string
}

const applyAppearance = (appearance: string) => {
  if (appearance === 'dark') {
    document.body.classList.add('dark')
  } else {
    document.body.classList.remove('dark')
  }
}

const getDefaultAppearance = () => {
  return localStorage.getItem('appearance') ?? 'light'
}

const setDefaultAppearance = (appearance: string) => {
  localStorage.setItem('appearance', appearance)
}

const userSettingsPath = '/api/users/settings'

const loadUserSettings = (accessToken: string): Promise<Settings> => {
  return axios
    .get(userSettingsPath, { headers: { token: accessToken } })
    .then(resp => {
      const settings: Settings = resp.data
      setDefaultAppearance(settings.appearance)

      return Promise.resolve(settings)
    })
    .catch(error => Promise.reject(error))
}

const saveUserSettings = (accessToken: string, settings: Settings) => {
  axios
    .put(userSettingsPath, settings, { headers: { token: accessToken } })
    .then(_ => {
      applyAppearance(settings.appearance)
      setDefaultAppearance(settings.appearance)
      Toaster.notifySuccess('Changes saved.')
    })
    .catch(_ => Toaster.notifyFailure('Unable to save changes.'))
}

export const loadAndApplyUserSettings = (accessToken: string) => {
  loadUserSettings(accessToken)
    .then(settings => {
      applyAppearance(settings.appearance)
    })
    .catch(_ => applyAppearance(getDefaultAppearance()))
}

const UserSettings: FunctionComponent<Props> = props => {
  const [showPasswordModal, setShowPasswordModal] = useState(false)
  const [appearance, setAppearance] = useState(getDefaultAppearance())
  const [loading, setLoading] = useState(true)

  const handleSave = () => {
    const settings: Settings = {
      appearance: appearance,
    }

    saveUserSettings(props.accessToken, settings)
  }

  const requestPasswordChange = (password: string): Promise<boolean> => {
    return new Promise<boolean>(resolve => {
      return axios
        .post('/api/users/password/change', { password: password }, { headers: { token: props.accessToken } })
        .then(_ => resolve(true))
        .catch(_ => {
          Toaster.notifyFailure('Unable to change password.')
          resolve(false)
        })
    })
  }

  useEffect(() => {
    loadUserSettings(props.accessToken)
      .then(settings => setAppearance(settings.appearance))
      .catch(_ => Toaster.notifyFailure('Unable to load settings.'))
      .finally(() => setLoading(false))
  }, [props.accessToken])

  return (
    <UserConsumer>
      {context => {
        return (
          <div id='tab-settings' className='tab'>
            {showPasswordModal && <PasswordModal reqPasswordChange={requestPasswordChange} close={() => setShowPasswordModal(false)} />}

            <header>
              <div className='auto'>
                <h2>User settings</h2>
              </div>
            </header>

            <div className='auto content'>
              <section>
                <div className='row flex'>
                  <header className='col third'>
                    <h3>Profile</h3>

                    <p>Change your password.</p>
                  </header>
                </div>

                <div className='row flex'>
                  <div className='col forty flex'>
                    <div className='id-wrap'>
                      <LanyardIcon />

                      <LanyardBGIcon />

                      <div className='id-card'>
                        <i>
                          <img src={context.profilePicture} alt='' />
                        </i>

                        <div className='user'>
                          <h4>
                            <div className='user-email' data-heap-redact-text>
                              {context.userName}
                            </div>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col sixty'>
                    <div className='surface'>
                      <div className='field'>
                        <label>Email</label>

                        <p data-heap-redact-text>{context.userName}</p>
                      </div>

                      <div className='field'>
                        <label>Password</label>

                        <a href='#' className='btn outline password' onClick={() => setShowPasswordModal(true)}>
                          Change password
                        </a>
                      </div>

                      {loading && (
                        <div className='empty loading'>
                          <LoadingDots />
                        </div>
                      )}

                      {!loading && (
                        <>
                          <div className='field'>
                            <label>Appearance</label>

                            <ul className='flex center option-list'>
                              <li id='appearance-light' className={`${appearance === 'light' ? 'active' : ''}`}>
                                <a href='#' onClick={() => setAppearance('light')}>
                                  <i className='icon thirtytwo'>
                                    <SunIcon />
                                  </i>

                                  <strong>Light</strong>
                                </a>
                              </li>

                              <li id='appearance-dark' className={`${appearance === 'dark' ? 'active' : ''}`}>
                                <a href='#' onClick={() => setAppearance('dark')}>
                                  <i className='icon thirtytwo'>
                                    <MoonIcon />
                                  </i>

                                  <strong>Dark</strong>
                                </a>
                              </li>
                            </ul>
                          </div>

                          <footer>
                            <button id='save-changes' className='btn' onClick={handleSave}>
                              Save changes
                            </button>
                          </footer>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        )
      }}
    </UserConsumer>
  )
}

export default UserSettings
