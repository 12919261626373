import React, { FunctionComponent } from 'react'
import Moment from 'react-moment'
import { useTypedSelector } from '../../redux/reducers'
import { getAccountDetails, getFeatureUsage, getHostStatus, getSystemHealth, getSystemInfo } from '../../redux/selectors'
import { NFR, TRIAL } from '../Account/Data'
import { SmallCriticalHeartbeat, SmallGoodHeartbeat, SmallWarningHeartbeat } from '../AnimatedSvg/AnimatedSvg'
import {
  BarChartIcon,
  CloudHostedIcon,
  DisconnectedIcon,
  HiveLogoIcon,
  HostedIcon,
  LicenseIcon,
  LockIconSmall,
  NFRLicenseIcon,
  OSLinuxIcon,
  OSMacIcon,
  OSWindowsIcon,
  PaperCutIcon,
  QuestionIcon,
  TrialLicenseIcon,
} from '../Icons/Icons'
import { HostData, HostDataUtil, HostStatus } from '../Host/Data'
import { HealthMetricStatus } from '../SystemHealth/Data'

interface Props {
  host: HostData
  onClose: (e: React.MouseEvent<HTMLElement>) => void
}

export const HeaderSpecs: FunctionComponent<Props> = ({ host, onClose }) => {
  const systemHealth = useTypedSelector(getSystemHealth(host.id))
  const hostStatus = useTypedSelector(getHostStatus(host.id))
  const account = useTypedSelector(getAccountDetails(host.id))
  const feature = useTypedSelector(getFeatureUsage(host.id))
  const systemInfo = useTypedSelector(getSystemInfo(host.id))
  const productName = HostDataUtil.getProductText(systemInfo?.product ?? host.product)
  const isPmitc = HostDataUtil.isPmitcProduct(host.product)

  const healthIcon = () => {
    if (isPmitc) {
      return <HiveLogoIcon /> // no health yet
    }

    switch (hostStatus) {
      case HostStatus.StaleData:
        return <DisconnectedIcon />
      case HostStatus.MonitoringDisabled:
        return <LockIconSmall />
      case HostStatus.MaintenanceAndSupportRequired:
        return <QuestionIcon />
    }

    switch (systemHealth?.status) {
      case HealthMetricStatus.Good:
        return <SmallGoodHeartbeat />
      case HealthMetricStatus.Warning:
        return <SmallWarningHeartbeat />
      case HealthMetricStatus.Critical:
        return <SmallCriticalHeartbeat />
    }
  }

  const licenseIcon = () => {
    const licenseType = account?.licenseType
    if (licenseType === TRIAL) {
      return <TrialLicenseIcon />
    } else if (licenseType === NFR) {
      return <NFRLicenseIcon />
    }
    return <LicenseIcon />
  }

  const healthClass = () => {
    if (isPmitc) {
      return 'hive'
    }
    if (hostStatus === HostStatus.StaleData || hostStatus === HostStatus.MaintenanceAndSupportRequired) {
      return 'warning'
    }
    if (hostStatus === HostStatus.MonitoringDisabled) {
      return 'disconnected'
    }

    switch (systemHealth?.status) {
      case HealthMetricStatus.Warning:
        return 'warning'
      case HealthMetricStatus.Good:
        return ''
      case HealthMetricStatus.Critical:
        return 'error'
    }
  }

  const osIcon = () => {
    const os = systemInfo?.osPlatform.toLowerCase() || ''
    if (os.includes('windows')) {
      return <OSWindowsIcon />
    }
    if (os.includes('linux')) {
      return <OSLinuxIcon />
    }
    if (os.includes('mac')) {
      return <OSMacIcon />
    }
    return <></>
  }

  return (
    <div className={`specs ${healthClass()}`}>
      <i className='icon thirtytwo health'>{healthIcon()}</i>
      <h2>
        <div className={account?.crn ? 'ellipsis' : 'ellipsis rise-less'} data-heap-redact-text>
          {host.name}
        </div>
        {account?.crn && (
          <small className='blk'>
            CRN <span className='val'>{account.crn}</span>
          </small>
        )}
      </h2>
      <div className='summary'>
        {!isPmitc && (
          <div className='col'>
            <i className='icon twentyfour'>{licenseIcon()}</i>
            <div>
              <h5>{[NFR, TRIAL].indexOf(account?.licenseType ?? '') > -1 ? 'License' : 'M&S'}</h5>
              <div className='val'>
                {account?.maintenanceAndSupportExpiryDate || account?.licenseExpiryDate ? (
                  <Moment format='D MMM YYYY' date={account?.maintenanceAndSupportExpiryDate || account?.licenseExpiryDate} />
                ) : (
                  'N/A'
                )}
              </div>
            </div>
          </div>
        )}
        {!isPmitc && (
          <div className='col'>
            <i className='icon twentyfour'>
              <BarChartIcon />
            </i>
            <div>
              <h5>Usage</h5>
              <div className='val'>{feature?.level ?? '-'}</div>
            </div>
          </div>
        )}
        <div className='col'>
          <i className='icon twentyfour'>
            <PaperCutIcon />
          </i>
          <div>
            <h5>Product</h5>
            <div className='val'>
              {productName}
              {systemInfo?.version && <span> / {systemInfo?.version.replace(/\(.*\)/, '')}</span>}
            </div>
          </div>
        </div>
        <div className='col'>
          <i className='icon twentyfour'>{isPmitc ? <CloudHostedIcon /> : <HostedIcon />}</i>
          <div>
            <h5>Hosted on</h5>
            <div className='val'>{HostDataUtil.getInfrastructureShortName(host.infrastructure)}</div>
          </div>
        </div>
        {!isPmitc && (
          <div className='col'>
            <i className='icon twentyfour'>{osIcon()}</i>
            <div>
              <h5>OS</h5>
              <div className='val'>{systemInfo?.osPlatform ?? '-'}</div>
            </div>
          </div>
        )}
      </div>

      <a href='/#' className='lines-button close' onClick={onClose} aria-label='Close'>
        <span className='lines' />
      </a>
    </div>
  )
}
