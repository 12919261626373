import React, { FunctionComponent } from 'react'

interface Props {
  pagesReleased: number
  featuredPagesReleased: number
}

const EnvironmentalStats: FunctionComponent<Props> = props => {
  let text = 'N/A'
  if (props.pagesReleased !== 0 && props.featuredPagesReleased !== 0) {
    text = Math.round((props.featuredPagesReleased / props.pagesReleased) * 1000) / 10 + '%'
  }
  return <span>{text}</span>
}

export default EnvironmentalStats
