import React, { FunctionComponent, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { OSLinuxIcon, OSWindowsIcon } from '../Icons/Icons'
import CustomModal from '../MyModal/CustomModal'
import { ConnectorDownloadURLs } from './Data'

interface Props {
  customerName: string
  downloadURL: ConnectorDownloadURLs
  isCustomerLinked: boolean
  close: () => void
}

const LinkHostModal: FunctionComponent<Props> = props => {
  const defaultButtonText = 'Copy link'
  const [linkButtonText, setLinkButtonText] = useState(defaultButtonText)
  const customerIsLinked = props.isCustomerLinked

  return (
    <CustomModal id='popup-link' close={props.close} width='675px'>
      <header className='center'>
        <h3 data-heap-redact-text>
          {customerIsLinked ? 'Relink' : 'Link'} customer {props.customerName}
        </h3>
      </header>

      <div className='content'>
        <p>Follow these steps to {customerIsLinked ? 'relink' : 'link'} this customer to your PaperCut Multiverse dashboard:</p>

        <ol>
          <li>
            <strong>Share this link</strong> for downloading the PaperCut Multiverse Connector:
            <div className='flex flex-nowrap copy-code'>
              <div className='vanish'>
                <input disabled={true} type='text' value={props.downloadURL.customerConnectorDownloadURL} aria-label='link' />
              </div>

              <CopyToClipboard
                text={props.downloadURL.customerConnectorDownloadURL}
                onCopy={() => {
                  setLinkButtonText('Copied!')
                  setTimeout(() => setLinkButtonText(defaultButtonText), 2000)
                }}
              >
                <button className='btn'>{linkButtonText}</button>
              </CopyToClipboard>
            </div>
          </li>

          <li>
            <strong>Extract</strong> the contents of the zip file onto the customer's PaperCut Application Server.
          </li>

          <li>
            <strong>Run</strong> the installer. See README.txt for platform-specific details.
          </li>
        </ol>

        <div className='divider sep'>
          <span>or</span>
        </div>

        <p>Download the Connector for:</p>

        <div className='btm flex btn-group center'>
          <a className='btn' href={props.downloadURL.windowsDownloadURL}>
            <i className='icon twentyfour'>
              <OSWindowsIcon />
            </i>
            Windows
          </a>

          <a className='btn' href={props.downloadURL.linuxDownloadURL}>
            <i className='icon twentyfour'>
              <OSLinuxIcon />
            </i>
            Linux
          </a>
        </div>

        <div className='alert pullout info'>Note: The installation package contains a unique link to your account and cannot be reused.</div>
      </div>

      <footer>
        <button className='btn secondary fp center' onClick={props.close}>
          Got it
        </button>
      </footer>
    </CustomModal>
  )
}

export default LinkHostModal
