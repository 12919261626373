import moment from 'moment'
import { MaintenanceAndSupportStatus } from '../components/Account/Data'
import { HostStatus } from '../components/Host/Data'
import { HealthMetricStatus } from '../components/SystemHealth/Data'
import { RootState } from './reducers'

export const getHost = (hostId: string, state: RootState) => state.hosts.find(h => h.id === hostId)
export const getSystemHealth = (hostId: string) => (state: RootState) => getHost(hostId, state)?.systemHealth
export const getAccountDetails = (hostId: string) => (state: RootState) => getHost(hostId, state)?.accountDetails
export const getFeatureUsage = (hostId: string) => (state: RootState) => getHost(hostId, state)?.featureUsage
export const getSystemInfo = (hostId: string) => (state: RootState) => getHost(hostId, state)?.systemInfo
export const getConnectionStatus = (hostId: string) => (state: RootState) => getHost(hostId, state)?.connectionStatus
export const getNotificationPause = (hostId: string) => (state: RootState) => state.pauses.find(p => p.customerId === hostId)
export const isFeatureOn = (feature: string) => (state: RootState) => state.features[feature]
export const getPmitcSessionLogoutLink = (state: RootState) => state.pmitcSession.logoutLink
export const getPmitcSessionName = (state: RootState) => state.pmitcSession.name
export const getPmitcSessionWindow = (state: RootState) => state.pmitcSession.window
export const isDemo = (state: RootState) => state.settings.demo

export const getHostStatus = (hostId: string) => (state: RootState) => {
  const remoteMonitoringEnabled = getHost(hostId, state)?.hostConfig.remoteMonitoring ?? true
  const systemHealth = getSystemHealth(hostId)(state)
  const systemHealthDataAvailable = systemHealth !== undefined
  const customerIsLinked = systemHealthDataAvailable || !remoteMonitoringEnabled
  const maintenanceSupportStatus = getAccountDetails(hostId)(state)?.maintenanceAndSupportStatus ?? MaintenanceAndSupportStatus.None
  const isStaleData = systemHealth && !isDemo(state) ? moment(systemHealth.observedTime).isBefore(moment().subtract(20, 'minutes')) : false

  if (!customerIsLinked) {
    return HostStatus.Unlinked
  }
  if (!remoteMonitoringEnabled) {
    return HostStatus.MonitoringDisabled
  }
  if (maintenanceSupportStatus === MaintenanceAndSupportStatus.Expired) {
    return HostStatus.MaintenanceAndSupportRequired
  }
  if (isStaleData) {
    return HostStatus.StaleData
  }
  return HostStatus.Good
}

export const getHostHealthSortValue = (hostId: string) => (state: RootState) => {
  // Reverse sort order: Critical -> Disconnected -> M&S Expired -> Warning -> Remote Disabled -> Unlinked -> Good -> No Status
  let h = getHost(hostId, state)
  // shouldn't happen
  if (!h) return -5
  if (!h.hostConfig.remoteMonitoring) return 2
  if (!h.systemHealth) return 1
  if (h.accountDetails?.maintenanceAndSupportStatus === MaintenanceAndSupportStatus.Expired) return 4
  const isStaleData = h.systemHealth && !isDemo(state) ? moment(h.systemHealth.observedTime).isBefore(moment().subtract(10, 'minutes')) : false
  if (isStaleData) return 5
  switch (h.systemHealth.status) {
    case HealthMetricStatus.Critical:
      return 6
    case HealthMetricStatus.Warning:
      return 3
    case HealthMetricStatus.Good:
      return 0
    case HealthMetricStatus.None:
      return -1
  }
  return -999
}
