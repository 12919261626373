import React, { useState } from 'react'
import axios from 'axios'
import CustomModal from '../MyModal/CustomModal'
import { getPmitcDcs, getPmitcDcDisplayName, Subscription } from '../Pmitc/Data'
import { TeamSettingsData } from '../TeamSettings/TeamSettings'
import Toaster from '../Toaster'

export const ImportHive = () => {
  const [teamID, setTeamID] = useState('')
  const [orgID, setOrgID] = useState('')
  const pmitcDcs = getPmitcDcs()
  const [pmitcDc, setPmitcDc] = useState(pmitcDcs[0])
  const [name, setName] = useState('')
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [orgName, setOrgName] = useState('')
  const [orgPartnerName, setOrgPartnerName] = useState('')
  const [orgPartnerCode, setOrgPartnerCode] = useState('')
  const [teamName, setTeamName] = useState('')
  const [teamPublicName, setTeamPublicName] = useState('')
  const [teamResellerCode, setTeamResellerCode] = useState('')
  const [importing, setImporting] = useState(false)
  const canImport = teamID !== '' && orgID !== '' && name.trim() !== ''

  const notifyFailure = (operation: string, e: any) => {
    let message = `Unable to ${operation}.`
    if (e.response.data) {
      message = `Unable to ${operation}: ${e.response.data.trim()}.`
    }

    Toaster.notifyFailure(message)
  }

  const importHive = () => {
    setImporting(true)
    axios
      .post('/api/manage/hive-import', {
        teamID: teamID,
        orgID: orgID,
        dataCenter: pmitcDc,
        name: name.trim(),
      })
      .then(_ => {
        Toaster.notifySuccess('Organization imported.')
        setTeamID('')
        setOrgID('')
        setPmitcDc(pmitcDcs[0])
        setName('')
      })
      .catch(e => notifyFailure('import organization', e))
      .finally(() => setImporting(false))
  }

  const confirmImportDetails = () => {
    axios
      .get<TeamSettingsData>(`/api/manage/teams/${teamID}/settings`)
      .then(teamResult => {
        setTeamName(teamResult.data.name)
        setTeamPublicName(teamResult.data.publicName)
        if (teamResult.data.resellerCode) {
          setTeamResellerCode(teamResult.data.resellerCode)
        }

        axios
          .get<Subscription>(`/api/pmitc/data-centers/${pmitcDc}/orgs/${orgID}/subscription`)
          .then(orgResult => {
            setOrgName(orgResult.data.orgName)
            setOrgPartnerName(orgResult.data.partnerName)
            setOrgPartnerCode(orgResult.data.partnerCode)
            setShowConfirmModal(true)
          })
          .catch(e => notifyFailure('get subscription details', e))
      })
      .catch(e => notifyFailure('get team details', e))
  }

  const handleClose = () => setShowConfirmModal(false)

  const labelClasses = 'col third'
  const valueClasses = 'col third center'

  const confirmMessage = (
    <>
      <p>Confirm the following details are correct before continuing.</p>

      <div className='table alt'>
        <div className='thead row'>
          <div className={labelClasses} />

          <div className={valueClasses}>
            <h6>PaperCut Multiverse</h6>
          </div>

          <div className={valueClasses}>
            <h6>PaperCut Hive</h6>
          </div>
        </div>

        <div className='tbody'>
          <div className='row'>
            <div className={labelClasses}>
              <strong>ID</strong>
            </div>

            <div className={valueClasses}>{teamID}</div>

            <div className={valueClasses}>{orgID}</div>
          </div>

          <div className='row'>
            <div className={labelClasses}>
              <strong>Customer name</strong>
            </div>

            <div className={valueClasses} data-heap-redact-text>
              {name}
            </div>

            <div className={valueClasses} data-heap-redact-text>
              {orgName}
            </div>
          </div>

          <div className='row'>
            <div className={labelClasses}>
              <strong>Partner name</strong>
            </div>

            <div className={valueClasses} data-heap-redact-text>
              {teamName}
              {teamPublicName !== teamName ? ' (' + teamPublicName + ')' : ''}
            </div>

            <div className={valueClasses} data-heap-redact-text>
              {orgPartnerName}
            </div>
          </div>

          <div className='row'>
            <div className={labelClasses}>
              <strong>Partner code</strong>
            </div>

            <div className={valueClasses} data-heap-redact-text>
              {teamResellerCode}
            </div>

            <div className={valueClasses} data-heap-redact-text>
              {orgPartnerCode}
            </div>
          </div>
        </div>
      </div>
    </>
  )

  const confirmAction = async () => {
    await importHive()
    handleClose()
  }

  return (
    <>
      <div className='row flex'>
        <div className='threeqtr col'>
          <div className='surface'>
            <div>
              <h4>Instructions:</h4>
              <ol>
                <li>
                  <p>Create a PaperCut Hive customer for the PaperCut Multiverse instance below:</p>
                  <div className='field'>
                    <label>PaperCut Multiverse team ID</label>
                    <input type='text' value={teamID} onChange={e => setTeamID(e.target.value.trim())} />
                  </div>
                  <div className='field'>
                    <label>PaperCut Hive organization ID</label>
                    <input type='text' value={orgID} onChange={e => setOrgID(e.target.value.trim())} />
                  </div>
                  <div className='field'>
                    <label id='hive-dc'>PaperCut Hive data center</label>
                    <div className='select'>
                      <select onChange={e => setPmitcDc(e.target.value)} value={pmitcDc} aria-labelledby='hive-dc'>
                        {pmitcDcs.map(dc => (
                          <option key={dc} value={dc}>
                            {getPmitcDcDisplayName(dc)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='field'>
                    <label>Customer name</label>
                    <input type='text' value={name} onChange={e => setName(e.target.value)} />
                  </div>
                  <button className={`btn ${canImport && !importing ? '' : 'disabled'}`} onClick={confirmImportDetails} disabled={!canImport || importing}>
                    Import
                  </button>
                </li>
                <li>
                  Ask the Service Provider to get the PaperCut Multiverse Partner Remote Assistance add-on connected in the PaperCut Hive organization, and validate that the add-on
                  configuration page shows up correctly. After that, they should be able to access the organization via PaperCut Multiverse using Manage PaperCut. Note the known
                  issue: If there is an existing admin with the same email address as the PaperCut Multiverse user, they may need to delete that admin so they can manage through
                  PaperCut Multiverse (aliases are okay).
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      {showConfirmModal && (
        <CustomModal id='popup-delete' close={handleClose} width='720px'>
          <form>
            <header className='center'>
              <h3>Import PaperCut Hive organization</h3>
            </header>

            <div className='content'>{confirmMessage}</div>

            <footer>
              <button className='btn outline' onClick={handleClose}>
                Cancel
              </button>

              <button className='btn' onClick={confirmAction}>
                <span className='text'>Confirm</span>
              </button>
            </footer>
          </form>
        </CustomModal>
      )}
    </>
  )
}
