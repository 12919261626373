import React from 'react'
import sprite from '../../assets/img/sprite.svg'

interface Props {
  name: string
  viewBox: string
  width?: string
  height?: string
}

const Icon = (props: Props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={props.width ? props.width : undefined}
      height={props.height ? props.height : undefined}
      viewBox={props.viewBox}
    >
      <use xlinkHref={`${sprite}#${props.name}`} />
    </svg>
  )
}

export default Icon

export const PrinterIcon = () => <Icon name='printer' viewBox='0 0 48 48' />
export const OSWindowsIcon = () => <Icon name='os-win' viewBox='0 0 48 48' />
export const OSLinuxIcon = ({ height }: { height?: string }) => <Icon name='os-linux' viewBox='0 0 24 24' height={height} />
export const OSMacIcon = () => <Icon name='os-apple' viewBox='0 0 24 24' />
export const AccountIcon = () => <Icon name='id' viewBox='0 0 35 35' />
export const HeartRateIcon = () => <Icon name='heartrate' viewBox='0 0 46 46' />
export const TickIcon = () => <Icon name='tick' viewBox='0 0 18 18' />
export const LockIcon = () => <Icon name='lock' viewBox='0 0 16 21' />
export const GlobeIcon = () => <Icon name='globe' viewBox='0 0 19 19' />
export const PeopleIcon = () => <Icon name='people' viewBox='0 0 21 13' />
export const PeopleManIcon = () => <Icon name='people-man' viewBox='0 0 24 24' />
export const MagicIcon = () => <Icon name='magic' viewBox='0 0 24 24' />
export const CloudFileIcon = () => <Icon name='cloud-file' viewBox='0 0 24 24' />
export const BriefcaseIcon = () => <Icon name='briefcase' viewBox='0 0 45 45' />
export const ContactIcon = () => <Icon name='contact' viewBox='0 0 35 35' />
export const ErrorIcon = () => <Icon name='error' viewBox='0 0 18 18' />
export const WarningIcon = () => <Icon name='exclaim' viewBox='0 0 20 20' />
export const ServerIcon = () => <Icon name='server' viewBox='0 0 48 48' />
export const ExclaimIcon = () => <Icon name='exclaim' viewBox='0 0 20 20' />
export const PaperCutIcon = () => <Icon name='papercut' viewBox='0 0 18 24' />
export const FindMeIcon = () => <Icon name='findme' viewBox='0 0 17 24' />
export const ByodIcon = () => <Icon name='byod' viewBox='0 0 73 47' />
export const CloudPrintIcon = () => <Icon name='cloud-print' viewBox='0 0 64 86' />
export const DeForestIcon = () => <Icon name='deforest' viewBox='0 0 22 24' />
export const CO2Icon = () => <Icon name='co2' viewBox='0 0 46 53' />
export const ChargeIcon = () => <Icon name='charge' viewBox='0 0 48 48' />
export const UserIcon = () => <Icon name='people' viewBox='0 0 21 13' />
export const ScannerIcon = () => <Icon name='scanner' viewBox='0 0 24 24' />
export const RemoteWarningIcon = () => <Icon name='remote-warning' viewBox='0 0 21 20' />
export const RemoteManageIcon = () => <Icon name='remote-manage' viewBox='0 0 21 20' />
export const DisconnectIcon = () => <Icon name='disconnect' viewBox='0 0 17 20' />
export const AnyEnvironment = () => <Icon name='any-environment' viewBox='0 0 24 24' />
export const RemoteRocket = () => <Icon name='remote-rocket' viewBox='0 0 24 24' />
export const Insights = () => <Icon name='insights' viewBox='0 0 24 24' />
export const ClipboardIcon = () => <Icon name='paper-write' viewBox='0 0 24 24' />
export const LinkCustomerIcon = () => <Icon name='download-thick-box' viewBox='0 0 24 24' />
export const EmailReadIcon = () => <Icon name='email-action-read-document' viewBox='0 0 24 24' />
export const CalendarIcon = () => <Icon name='calendar' viewBox='0 0 29 32' />
export const LightningIcon = () => <Icon name='thunder' viewBox='0 0 20 24' />
export const HelpIcon = () => <Icon name='help' viewBox='0 0 22 22' />
export const InfoIcon = () => <Icon name='info' viewBox='0 0 6 12' />
export const ConstructionSign = () => <Icon name='construction-sign' viewBox='0 0 24 24' />
export const MegaphoneIcon = () => <Icon name='megaphone' viewBox='0 0 19 20' />
export const MeshIcon = () => <Icon name='mesh' viewBox='0 0 48 48' />
export const DisconnectedIcon = () => <Icon name='heart-stop' viewBox='0 0 46 10' />
export const GiftIcon = () => <Icon name='gift' viewBox='0 0 24 24' />
export const BellIcon = () => <Icon name='bell' viewBox='0 0 24 24' />
export const PiggyBankIcon = () => <Icon name='pig' viewBox='0 0 32 32' />
export const AccountManagerIcon = () => <Icon name='pre-sales' viewBox='0 0 32 20' />
export const AdminCrownIcon = () => <Icon name='crown' viewBox='0 0 32 32' />
export const TechServicesIcon = () => <Icon name='tech-support' viewBox='0 0 32 32' />
export const AdminIcon = () => <Icon name='admin' viewBox='0 0 32 26' />
export const CustomersIcon = () => <Icon name='ids' viewBox='0 0 24 24' />
export const BlinkIcon = () => <Icon name='blink' viewBox='0 0 24 24' />
export const GroupIcon = () => <Icon name='group' viewBox='0 0 24 24' />
export const BooksIcon = () => <Icon name='books' viewBox='0 0 24 24' />
export const ShopIcon = () => <Icon name='shop' viewBox='0 0 24 24' />
export const LicenseIcon = () => <Icon name='license' viewBox='0 0 24 24' />
export const TrialLicenseIcon = () => <Icon name='license-trial' viewBox='0 0 24 24' />
export const NFRLicenseIcon = () => <Icon name='license-nfr' viewBox='0 0 24 24' />
export const QuestionIcon = () => <Icon name='question' viewBox='0 0 7 12' />
export const BarChartIcon = () => <Icon name='barchart' viewBox='0 0 24 24' />
export const HostedIcon = () => <Icon name='hosted' viewBox='0 0 24 24' />
export const CloudHostedIcon = () => <Icon name='cloud-hosted' viewBox='0 0 28 23' />
export const EdgeMeshIcon = () => <Icon name='edge-mesh' viewBox='0 0 48 48' />
export const HiveLogoIcon = () => <Icon name='hive' viewBox='0 0 32 28' />
export const NoBellIcon = () => <Icon name='no-bell' viewBox='0 0 16 16' />
export const EventIcon = () => <Icon name='event' viewBox='0 0 18 20' />
export const SunIcon = () => <Icon name='sun' viewBox='0 0 32 32' />
export const MoonIcon = () => <Icon name='moon' viewBox='0 0 32 31' />
export const LanyardIcon = () => (
  <svg className='lanyard' width='26px' height='26px' viewBox='0 0 26 26' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <path d='M19,1 L1.98828125,24.234375 C6.55940271,17.7992553 14.5306238,11.7266698 24.7031739,6.3046879' id='Path' stroke='#e3e7eb' strokeWidth='2.5' />
    </g>
  </svg>
)
export const LanyardBGIcon = () => (
  <svg className='lanyard-bg' width='392px' height='272px' viewBox='0 0 392 272' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <path
        d='M196.988281,71.234375 C201.559403,64.7992553 209.530624,58.7266698 219.703174,53.3046879 C269.82434,26.5900437 373.385649,15.6696525 387,55 C396,81 388,140 335,172 C282,204 143.44463,268.796172 70.6835938,270.023438 C41.9108886,270.508749 15.7047293,262.182238 6,240 C-1,224 -5,192 60.7578125,186.246094 C126.515625,180.492188 133.316034,180.667748 160,179 C176,178 271.088937,177.879706 315.972656,145.46875 C345.696421,124.00494 395.028875,59.3300032 364.554688,25.34375 C323,-21 240.85419,11.1593637 214,48 L196.988281,71.234375 Z'
        id='Path-2'
        stroke='#e3e7eb'
        strokeWidth='2.5'
      />
    </g>
  </svg>
)
const FeatureGaugeIcon = (level: number) => (
  <svg className='gauge' xmlns='http://www.w3.org/2000/svg' height='46' width='46' viewBox='0 0 200 200' data-value='90'>
    <path className='bg' stroke='#CFD9E0' d='M41 149.5a77 77 0 1 1 117.93 0' fill='none' />
    <path className='meter' stroke='#26AA27' d='M41 149.5a77 77 0 1 1 117.93 0' fill='none' strokeDasharray='350' strokeDashoffset='350' style={{ strokeDashoffset: level }} />
  </svg>
)
export const AdvancedFeatureGaugeIcon = () => FeatureGaugeIcon(34.9518)
export const StandardFeatureGaugeIcon = () => FeatureGaugeIcon(174.759)
export const BasicFeatureGaugeIcon = () => FeatureGaugeIcon(244.662)
export const UpgradeGaugeIcon = () => (
  <svg className='gauge' xmlns='http://www.w3.org/2000/svg' height='46' width='46' viewBox='0 0 200 200' data-value='0'>
    <path className='bg' stroke='#CFD9E0' d='M41 149.5a77 77 0 1 1 117.93 0' fill='none' />
  </svg>
)
export const MessagesIcon = () => <Icon name='messages' viewBox='0 0 27 24' />
export const CertificateIcon = () => <Icon name='cert' viewBox='0 0 24 24' />
export const TagIcon = () => <Icon name='tag' viewBox='0 0 24 24' />
export const MfdSmallIcon = () => <Icon name='mfd-sml' viewBox='0 0 24 24' />
export const ReceiptIcon = () => <Icon name='receipt' viewBox='0 0 20 24' />
export const HourglassIcon = () => <Icon name='hourglass' viewBox='0 0 18 24' />
export const MfdIcon = () => <Icon name='mfd' viewBox='0 0 32 32' />
export const LaptopIcon = () => <Icon name='laptop' viewBox='0 0 32 26' />
export const TechIcon = () => <Icon name='tech' viewBox='0 0 24 23' />
export const LockIconSmall = () => <Icon name='lock' viewBox='0 0 16 21' width='12px' height='12px' />
export const PasswordIcon = () => <Icon name='password' viewBox='0 0 32 21' />
export const EncryptIcon = () => <Icon name='encrypt' viewBox='0 0 26 32' />
export const EnabledIcon = () => <Icon name='enabled' viewBox='0 0 18 19' />
export const DisabledIcon = () => <Icon name='disabled' viewBox='0 0 18 18' />
export const DocumentSvg = () => (
  <svg width='163px' height='163px' viewBox='0 0 163 163' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
    {/*<!-- Generator: Sketch 61.2 (89653) - https://sketch.com -->*/}
    <defs>
      <polygon id='path-1' points='0.550198953 0.113089005 1.38789529 0.113089005 1.38789529 40.4213613 0.550198953 40.4213613' />
      <polygon id='path-3' points='0.431581152 0.113089005 1.26927749 0.113089005 1.26927749 40.4213613 0.431581152 40.4213613' />
      <polygon id='path-5' points='0.312544503 0.113089005 1.15024084 0.113089005 1.15024084 40.4213613 0.312544503 40.4213613' />
      <polygon id='path-7' points='0.193507853 0.113089005 1.03120419 0.113089005 1.03120419 40.4213613 0.193507853 40.4213613' />
      <polygon id='path-9' points='0.0745549738 0.113089005 0.912251309 0.113089005 0.912251309 40.4213613 0.0745549738 40.4213613' />
      <polygon id='path-11' points='0.79321466 0.113089005 1.63091099 0.113089005 1.63091099 40.4213613 0.79321466 40.4213613' />
      <polygon id='path-13' points='0.67417801 0.113089005 1.51187435 0.113089005 1.51187435 40.4213613 0.67417801 40.4213613' />
      <polygon id='path-15' points='0.520209424 0.57382199 46.0783246 0.57382199 46.0783246 45.8729215 0.520209424 45.8729215' />
      <polygon id='path-17' points='0.520209424 0.57382199 52.2031414 0.57382199 52.2031414 51.798534 0.520209424 51.798534' />
      <polygon id='path-19' points='0.520209424 0.57382199 58.328377 0.57382199 58.328377 57.7238115 0.520209424 57.7238115' />
      <polygon id='path-21' points='0.520209424 0.57382199 64.4536126 0.57382199 64.4536126 63.6490052 0.520209424 63.6490052' />
      <polygon id='path-23' points='0.520209424 0.57382199 70.5784293 0.57382199 70.5784293 69.5742827 0.520209424 69.5742827' />
      <polygon id='path-25' points='0.520209424 0.57382199 76.7036649 0.57382199 76.7036649 75.4994764 0.520209424 75.4994764' />
      <polygon id='path-27' points='0.520209424 0.57382199 81.2171728 0.57382199 81.2171728 81.2707853 0.520209424 81.2707853' />
      <polygon id='path-29' points='0.520209424 0.57382199 81.2171728 0.57382199 81.2171728 81.2707853 0.520209424 81.2707853' />
      <polygon id='path-31' points='0.520209424 0.57382199 81.2171728 0.57382199 81.2171728 81.2707853 0.520209424 81.2707853' />
      <polygon id='path-33' points='0.578931937 0.154554974 80.3794764 0.154554974 80.3794764 78.7576963 0.578931937 78.7576963' />
      <polygon id='path-35' points='0.00217801047 0.215874346 73.6779058 0.215874346 73.6779058 72.893822 0.00217801047 72.893822' />
      <polygon id='path-37' points='0.263539267 0.277277487 67.8140314 0.277277487 67.8140314 67.0299476 0.263539267 67.0299476' />
      <polygon id='path-39' points='0.524984293 0.338596859 61.9501571 0.338596859 61.9501571 61.1660733 0.524984293 61.1660733' />
      <polygon id='path-41' points='0.785926702 0.4 56.0862827 0.4 56.0862827 55.302199 0.785926702 55.302199' />
      <polygon id='path-43' points='0.209591623 0.461319372 49.384712 0.461319372 49.384712 49.4383246 0.209591623 49.4383246' />
      <polygon id='path-45' points='0.470534031 0.522638743 43.5208377 0.522638743 43.5208377 43.5744503 0.470534031 43.5744503' />
    </defs>
    <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Group-127'>
        <g id='Group-31' transform='translate(0.000000, 0.326702)'>
          <path
            d='M40.729801,123.241047 C45.7677068,123.241047 45.7677068,127.848377 50.8056126,127.848377 C55.8426806,127.848377 55.8426806,123.241047 60.878911,123.241047 C65.9184921,123.241047 65.9184921,127.848377 70.9580733,127.848377 C75.9984921,127.848377 75.9984921,123.241047 81.0380733,123.241047'
            id='Stroke-1'
            stroke='#C7E3A2'
            strokeWidth='3.437'
          />
          <path
            d='M40.729801,129.863288 C45.7677068,129.863288 45.7677068,134.46978 50.8056126,134.46978 C55.8426806,134.46978 55.8426806,129.863288 60.878911,129.863288 C65.9184921,129.863288 65.9184921,134.46978 70.9580733,134.46978 C75.9984921,134.46978 75.9984921,129.863288 81.0380733,129.863288'
            id='Stroke-3'
            stroke='#C7E3A2'
            strokeWidth='3.437'
          />
          <path
            d='M40.729801,136.485529 C45.7677068,136.485529 45.7677068,141.092021 50.8056126,141.092021 C55.8426806,141.092021 55.8426806,136.485529 60.878911,136.485529 C65.9184921,136.485529 65.9184921,141.092021 70.9580733,141.092021 C75.9984921,141.092021 75.9984921,136.485529 81.0380733,136.485529'
            id='Stroke-5'
            stroke='#C7E3A2'
            strokeWidth='3.437'
          />
          <path
            d='M40.729801,143.107351 C45.7677068,143.107351 45.7677068,147.714681 50.8056126,147.714681 C55.8426806,147.714681 55.8426806,143.107351 60.878911,143.107351 C65.9184921,143.107351 65.9184921,147.714681 70.9580733,147.714681 C75.9984921,147.714681 75.9984921,143.107351 81.0380733,143.107351'
            id='Stroke-7'
            stroke='#C7E3A2'
            strokeWidth='3.437'
          />
          <path
            d='M40.729801,149.729592 C45.7677068,149.729592 45.7677068,154.336921 50.8056126,154.336921 C55.8426806,154.336921 55.8426806,149.729592 60.878911,149.729592 C65.9184921,149.729592 65.9184921,154.336921 70.9580733,154.336921 C75.9984921,154.336921 75.9984921,149.729592 81.0380733,149.729592'
            id='Stroke-9'
            stroke='#C7E3A2'
            strokeWidth='3.437'
          />
          <path
            d='M40.729801,156.351749 C45.7677068,156.351749 45.7677068,160.958241 50.8056126,160.958241 C55.8426806,160.958241 55.8426806,156.351749 60.878911,156.351749 C65.9184921,156.351749 65.9184921,160.958241 70.9580733,160.958241 C75.9984921,160.958241 75.9984921,156.351749 81.0380733,156.351749'
            id='Stroke-11'
            stroke='#C7E3A2'
            strokeWidth='3.437'
          />
          <path
            d='M121.426764,121.618848 L81.1184921,121.618848 C81.1184921,99.3570681 99.1649843,81.3097382 121.426764,81.3097382 L121.426764,121.618848 Z'
            id='Fill-13'
            fill='#00C2AC'
          />
          <path
            d='M121.426764,121.452398 L121.426764,161.76067 C99.1649843,161.76067 81.1184921,143.714178 81.1184921,121.452398 L121.426764,121.452398 Z'
            id='Fill-15'
            fill='#70BC1E'
          />
          <path
            d='M40.729801,60.0609005 C30.0709529,60.0609005 21.4309529,51.4200628 21.4309529,40.7612147 C21.4309529,30.1032042 30.0709529,21.4623665 40.729801,21.4623665 C51.3886492,21.4623665 60.0286492,30.1032042 60.0286492,40.7612147 C60.0286492,51.4200628 51.3886492,60.0609005 40.729801,60.0609005 M40.729801,0.53252356 C18.5124188,0.53252356 0.501109948,18.5438325 0.501109948,40.7612147 C0.501109948,62.9785969 18.5124188,80.9899058 40.729801,80.9899058 C62.9471832,80.9899058 80.9584921,62.9785969 80.9584921,40.7612147 C80.9584921,18.5438325 62.9471832,0.53252356 40.729801,0.53252356'
            id='Fill-17'
            fill='#BBE7C2'
          />
          <path
            d='M80.9584084,121.578806 C80.9584084,99.3614241 62.9479372,81.3501152 40.7297173,81.3501152 C18.5123351,81.3501152 0.501026178,99.3614241 0.501026178,121.578806 L21.4308691,121.578806 C21.4308691,110.919958 30.0708691,102.279958 40.7297173,102.279958 C51.3885654,102.279958 60.0285654,110.919958 60.0285654,121.578806 L80.9584084,121.578806 Z'
            id='Fill-19'
            fill='#00A900'
          />
          <path d='M121.426764,159.990534 C142.618806,159.990534 159.798283,142.811058 159.798283,121.618178' id='Stroke-21' stroke='#008715' strokeWidth='4.695' />
          <path
            d='M121.426764,152.916356 C127.629906,152.916356 133.410848,151.111958 138.274513,147.999079 C146.963937,142.437613 152.723937,132.701068 152.723937,121.618346'
            id='Stroke-23'
            stroke='#008715'
            strokeWidth='4.695'
          />
          <path
            d='M121.426764,145.842513 C126.227602,145.842513 130.702576,144.446073 134.466346,142.036859 C141.192209,137.731937 145.650429,130.196021 145.650429,121.618848'
            id='Stroke-25'
            stroke='#008715'
            strokeWidth='4.695'
          />
          <path
            d='M121.426764,138.768335 C124.825298,138.768335 127.993466,137.779853 130.658178,136.074304 C135.419644,133.026764 138.576084,127.691476 138.576084,121.618178'
            id='Stroke-27'
            stroke='#008715'
            strokeWidth='4.695'
          />
          <path
            d='M121.426764,131.694157 C123.422995,131.694157 125.284356,131.113634 126.85001,130.111749 C129.647079,128.321592 131.501738,125.186094 131.501738,121.618346'
            id='Stroke-29'
            stroke='#008715'
            strokeWidth='4.695'
          />
        </g>
        <g id='Group-75' transform='translate(0.837696, 121.792670)'>
          <g id='Group-54' transform='translate(36.020942, 0.000000)'>
            <mask id='mask-2' fill='white'>
              <use xlinkHref='#path-1' />
            </mask>
            <g id='Clip-53' />
            <line x1='0.96904712' y1='69.528712' x2='0.96904712' y2='-27.941445' id='Stroke-52' stroke='#27AA27' strokeWidth='3.437' mask='url(#mask-2)' />
          </g>
          <g id='Group-57' transform='translate(30.157068, 0.000000)'>
            <mask id='mask-4' fill='white'>
              <use xlinkHref='#path-3' />
            </mask>
            <g id='Clip-56' />
            <line x1='0.850429319' y1='69.528712' x2='0.850429319' y2='-27.941445' id='Stroke-55' stroke='#27AA27' strokeWidth='3.437' mask='url(#mask-4)' />
          </g>
          <g id='Group-60' transform='translate(24.293194, 0.000000)'>
            <mask id='mask-6' fill='white'>
              <use xlinkHref='#path-5' />
            </mask>
            <g id='Clip-59' />
            <line x1='0.73139267' y1='69.528712' x2='0.73139267' y2='-27.941445' id='Stroke-58' stroke='#27AA27' strokeWidth='3.437' mask='url(#mask-6)' />
          </g>
          <g id='Group-63' transform='translate(18.429319, 0.000000)'>
            <mask id='mask-8' fill='white'>
              <use xlinkHref='#path-7' />
            </mask>
            <g id='Clip-62' />
            <line x1='0.612356021' y1='69.528712' x2='0.612356021' y2='-27.941445' id='Stroke-61' stroke='#27AA27' strokeWidth='3.437' mask='url(#mask-8)' />
          </g>
          <g id='Group-66' transform='translate(12.565445, 0.000000)'>
            <mask id='mask-10' fill='white'>
              <use xlinkHref='#path-9' />
            </mask>
            <g id='Clip-65' />
            <line x1='0.493403141' y1='69.528712' x2='0.493403141' y2='-27.941445' id='Stroke-64' stroke='#27AA27' strokeWidth='3.437' mask='url(#mask-10)' />
          </g>
          <g id='Group-69' transform='translate(5.863874, 0.000000)'>
            <mask id='mask-12' fill='white'>
              <use xlinkHref='#path-11' />
            </mask>
            <g id='Clip-68' />
            <line x1='1.21206283' y1='69.528712' x2='1.21206283' y2='-27.941445' id='Stroke-67' stroke='#27AA27' strokeWidth='3.437' mask='url(#mask-12)' />
          </g>
          <g id='Group-72'>
            <mask id='mask-14' fill='white'>
              <use xlinkHref='#path-13' />
            </mask>
            <g id='Clip-71' />
            <line x1='1.09302618' y1='69.528712' x2='1.09302618' y2='-27.941445' id='Stroke-70' stroke='#27AA27' strokeWidth='3.437' mask='url(#mask-14)' />
          </g>
        </g>
        <polygon id='Fill-76' fill='#00A800' points='40.8099686 121.905508 0.500858639 162.21378 0.500858639 121.905508' />
        <g id='Group-126' transform='translate(40.209424, 0.326702)'>
          <g id='Group-80'>
            <mask id='mask-16' fill='white'>
              <use xlinkHref='#path-15' />
            </mask>
            <g id='Clip-79' />
            <line x1='46.0783246' y1='-50.6598534' x2='-53.7089005' y2='45.8729215' id='Stroke-78' stroke='#A1DAA6' strokeWidth='2.545' mask='url(#mask-16)' />
          </g>
          <g id='Group-83'>
            <mask id='mask-18' fill='white'>
              <use xlinkHref='#path-17' />
            </mask>
            <g id='Clip-82' />
            <line x1='52.2031414' y1='-44.7342408' x2='-47.5840838' y2='51.798534' id='Stroke-81' stroke='#A1DAA6' strokeWidth='2.545' mask='url(#mask-18)' />
          </g>
          <g id='Group-86'>
            <mask id='mask-20' fill='white'>
              <use xlinkHref='#path-19' />
            </mask>
            <g id='Clip-85' />
            <line x1='58.328377' y1='-38.8089634' x2='-41.4588482' y2='57.7238115' id='Stroke-84' stroke='#A1DAA6' strokeWidth='2.545' mask='url(#mask-20)' />
          </g>
          <g id='Group-89'>
            <mask id='mask-22' fill='white'>
              <use xlinkHref='#path-21' />
            </mask>
            <g id='Clip-88' />
            <line x1='64.4536126' y1='-32.8837696' x2='-35.3336126' y2='63.6490052' id='Stroke-87' stroke='#A1DAA6' strokeWidth='2.545' mask='url(#mask-22)' />
          </g>
          <g id='Group-92'>
            <mask id='mask-24' fill='white'>
              <use xlinkHref='#path-23' />
            </mask>
            <g id='Clip-91' />
            <line x1='70.5784293' y1='-26.9584921' x2='-29.2087958' y2='69.5742827' id='Stroke-90' stroke='#A1DAA6' strokeWidth='2.545' mask='url(#mask-24)' />
          </g>
          <g id='Group-95'>
            <mask id='mask-26' fill='white'>
              <use xlinkHref='#path-25' />
            </mask>
            <g id='Clip-94' />
            <line x1='76.7036649' y1='-21.0332984' x2='-23.0835602' y2='75.4994764' id='Stroke-93' stroke='#A1DAA6' strokeWidth='2.545' mask='url(#mask-26)' />
          </g>
          <g id='Group-98'>
            <mask id='mask-28' fill='white'>
              <use xlinkHref='#path-27' />
            </mask>
            <g id='Clip-97' />
            <line x1='82.8285654' y1='-15.1080209' x2='-16.9586597' y2='81.4247539' id='Stroke-96' stroke='#A1DAA6' strokeWidth='2.545' mask='url(#mask-28)' />
          </g>
          <g id='Group-101'>
            <mask id='mask-30' fill='white'>
              <use xlinkHref='#path-29' />
            </mask>
            <g id='Clip-100' />
            <line x1='88.953801' y1='-9.18282723' x2='-10.8334241' y2='87.3499476' id='Stroke-99' stroke='#A1DAA6' strokeWidth='2.545' mask='url(#mask-30)' />
          </g>
          <g id='Group-104'>
            <mask id='mask-32' fill='white'>
              <use xlinkHref='#path-31' />
            </mask>
            <g id='Clip-103' />
            <line x1='95.0786178' y1='-3.25754974' x2='-4.70860733' y2='93.2752251' id='Stroke-102' stroke='#A1DAA6' strokeWidth='2.545' mask='url(#mask-32)' />
          </g>
          <g id='Group-107' transform='translate(0.837696, 2.513089)'>
            <mask id='mask-34' fill='white'>
              <use xlinkHref='#path-33' />
            </mask>
            <g id='Clip-106' />
            <line x1='100.366157' y1='0.154554974' x2='0.578931937' y2='96.6873298' id='Stroke-105' stroke='#A1DAA6' strokeWidth='2.545' mask='url(#mask-34)' />
          </g>
          <g id='Group-110' transform='translate(7.539267, 8.376963)'>
            <mask id='mask-36' fill='white'>
              <use xlinkHref='#path-35' />
            </mask>
            <g id='Clip-109' />
            <line x1='99.7894031' y1='0.215874346' x2='0.00217801047' y2='96.7486492' id='Stroke-108' stroke='#A1DAA6' strokeWidth='2.545' mask='url(#mask-36)' />
          </g>
          <g id='Group-113' transform='translate(13.403141, 14.240838)'>
            <mask id='mask-38' fill='white'>
              <use xlinkHref='#path-37' />
            </mask>
            <g id='Clip-112' />
            <line x1='100.050764' y1='0.277277487' x2='0.263539267' y2='96.8100524' id='Stroke-111' stroke='#A1DAA6' strokeWidth='2.545' mask='url(#mask-38)' />
          </g>
          <g id='Group-116' transform='translate(19.267016, 20.104712)'>
            <mask id='mask-40' fill='white'>
              <use xlinkHref='#path-39' />
            </mask>
            <g id='Clip-115' />
            <line x1='100.312209' y1='0.338596859' x2='0.524984293' y2='96.8713717' id='Stroke-114' stroke='#A1DAA6' strokeWidth='2.545' mask='url(#mask-40)' />
          </g>
          <g id='Group-119' transform='translate(25.130890, 25.968586)'>
            <mask id='mask-42' fill='white'>
              <use xlinkHref='#path-41' />
            </mask>
            <g id='Clip-118' />
            <line x1='100.573152' y1='0.4' x2='0.785926702' y2='96.9327749' id='Stroke-117' stroke='#A1DAA6' strokeWidth='2.545' mask='url(#mask-42)' />
          </g>
          <g id='Group-122' transform='translate(31.832461, 31.832461)'>
            <mask id='mask-44' fill='white'>
              <use xlinkHref='#path-43' />
            </mask>
            <g id='Clip-121' />
            <line x1='99.9968168' y1='0.461319372' x2='0.209591623' y2='96.9940942' id='Stroke-120' stroke='#A1DAA6' strokeWidth='2.545' mask='url(#mask-44)' />
          </g>
          <g id='Group-125' transform='translate(37.696335, 37.696335)'>
            <mask id='mask-46' fill='white'>
              <use xlinkHref='#path-45' />
            </mask>
            <g id='Clip-124' />
            <line x1='100.257759' y1='0.522638743' x2='0.470534031' y2='97.0554136' id='Stroke-123' stroke='#A1DAA6' strokeWidth='2.545' mask='url(#mask-46)' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
