import { TypedUseSelectorHook, useSelector } from 'react-redux'
import { combineReducers } from 'redux'
import accessReducer from './access'
import featuresReducer from './features'
import hostsReducer from './hosts'
import hostFilterReducer from './hostsFilter'
import notificationPauseReducer from './notificationPause'
import pmitcSessionReducer from './pmitcSession'
import settingsReducer from './settings'
import slideoutReducer from './slideout'
import supportReducer from './support'

export const rootReducer = combineReducers({
  filter: hostFilterReducer,
  hosts: hostsReducer,
  features: featuresReducer,
  slideout: slideoutReducer,
  access: accessReducer,
  pmitcSession: pmitcSessionReducer,
  pauses: notificationPauseReducer,
  support: supportReducer,
  settings: settingsReducer,
})

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
export type RootState = ReturnType<typeof rootReducer>
