import React, { ReactNode } from 'react'

export enum MaintenanceAndSupportStatus {
  Current = 'Current',
  Expiring = 'Expiring',
  Expired = 'Expired',
  None = 'None',
}

export interface AccountDetails {
  // note this should only be used when wanting system detected override, otherwise use product in SystemInfo
  paperCutEdition: string
  paperCutVersion: string
  maintenanceAndSupportStatus: MaintenanceAndSupportStatus
  maintenanceAndSupportExpiryDate?: Date
  organisationType: string
  licenseType: string
  registeredUsers: string
  licensedMFDs?: any
  licensedSFPs?: any
  licensedConnectors?: any
  organisation: string
  licensedReleaseStations: number
  crn: string
  lastUpdate: Date
  licenseExpiryDate?: Date
  orderReference: string
  growsTreesPlanted: number
}

export const NOT_AVAILABLE: string = 'N/A'

export const TRIAL = 'Trial'
export const NFR = 'NFR'

export class AccountDataUtil {
  static getLicensedDeviceCategoryTypeAndCounts = (type: string, typeMap?: any, account?: AccountDetails): ReactNode => {
    if (!account) {
      return null
    }

    if (account.licenseType === TRIAL || account.licenseType === NFR) {
      return (
        <>
          <dt>{type}</dt>
          <dd>Unlimited</dd>
        </>
      )
    }

    if (typeMap && Object.keys(typeMap).length > 0) {
      return (
        <>
          <dt>{type}</dt>
          <dd>{AccountDataUtil.getLicensedDeviceCategoryCounts(true, typeMap, account)}</dd>
        </>
      )
    }

    return null
  }

  static getLicensedDeviceCategoryCounts = (showUsed: boolean, typeMap?: any, account?: AccountDetails): ReactNode => {
    if (!account) {
      return null
    }

    if (account.licenseType === TRIAL || account.licenseType === NFR) {
      return <>Unlimited</>
    }

    if (typeMap && Object.keys(typeMap).length > 0) {
      return (
        <>
          {Array.from(Object.keys(typeMap)).map(dev => {
            let devLicensed = typeMap[dev]
            if (devLicensed === undefined) {
              return null
            }

            return (
              <React.Fragment key={dev}>
                {dev}{' '}
                {showUsed && (
                  <>
                    {devLicensed.used} / {devLicensed.licensed}
                  </>
                )}{' '}
                {!showUsed && <>({devLicensed.licensed})</>}
                <br />
              </React.Fragment>
            )
          })}
        </>
      )
    }

    return null
  }

  static getAccountDetailsFromResponse = (data: any) => {
    let mnsExpiryDate = undefined
    if (data.maintenanceSupportExpiryDate != null) {
      mnsExpiryDate = new Date(data.maintenanceSupportExpiryDate)
    }

    let licenseExpiryDate = undefined
    if (data.licenseExpiryDate != null) {
      licenseExpiryDate = new Date(data.licenseExpiryDate)
    }

    return {
      licensedMFDs: data.licensedMfds,
      licensedSFPs: data.licensedSfps,
      licensedConnectors: data.licensedConnectors,
      organisationType: data.orgType,
      licenseType: data.licenseType,
      registeredUsers: data.registeredUsers,
      maintenanceAndSupportStatus: data.maintenanceSupportStatus,
      maintenanceAndSupportExpiryDate: mnsExpiryDate,
      paperCutEdition: data.pcEdition,
      paperCutVersion: data.paperCutVersion,
      organisation: data.org,
      licensedReleaseStations: data.licensedReleaseStations,
      crn: data.crn,
      lastUpdate: new Date(data.lastUpdate),
      licenseExpiryDate: licenseExpiryDate,
      orderReference: data.orderReference,
      growsTreesPlanted: data.growsTreesPlanted,
    }
  }
}
