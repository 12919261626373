import React, { FunctionComponent, useState } from 'react'
import { AccountManagerIcon, AdminIcon, TechServicesIcon } from '../Icons/Icons'

export const RoleDetails: FunctionComponent = () => {
  const [adminOpen, setAdminOpen] = useState(true)
  const [accountManagerOpen, setAccountManagerOpen] = useState(false)
  const [accountManagerROOpen, setAccountManagerROOpen] = useState(false)
  const [techServicesOpen, setTechServicesOpen] = useState(false)
  const [techServicesROOpen, setTechServicesROOpen] = useState(false)

  return (
    <div className='surface sticky'>
      <h4 className='dip'>Roles and permissions</h4>
      <span className='subdued'>Find out what each role can do. Users can have more than one role where applicable.</span>
      <hr className='divider' />
      <ul className='row'>
        <li className='flex flex-nowrap dip'>
          <div className='col flex-noshrink'>
            <i className='icon thirtytwo'>
              <AdminIcon />
            </i>
          </div>
          <div className='col'>
            <h5>
              <a href='#role-admin' className={` ${adminOpen ? 'alt open' : 'alt'}`} data-toggle='collapsable' onClick={() => setAdminOpen(!adminOpen)}>
                Admin
              </a>
            </h5>
            <div id='role-admin' className={` ${adminOpen ? 'collapsable-content' : 'collapsable-content hidden'}`}>
              Can invite/remove users; set required user authentication; manage user permissions; configure the team profile; configure all notifications settings; can perform all
              Account manager &amp; Tech services actions.
            </div>
          </div>
        </li>

        <li className='flex flex-nowrap dip'>
          <div className='col flex-noshrink'>
            <i className='icon thirtytwo'>
              <AccountManagerIcon />
            </i>
          </div>
          <div className='col'>
            <h5>
              <a href='#role-account' className={`alt ${accountManagerOpen ? 'open' : ''}`} data-toggle='collapsable' onClick={() => setAccountManagerOpen(!accountManagerOpen)}>
                Account manager
              </a>
            </h5>

            <div id='role-account' className={`collapsable-content ${accountManagerOpen ? '' : 'hidden'}`}>
              Can view all PaperCut licenses (if available); view details of connected environments; apply licenses to connected environments; configure license notifications.
            </div>
          </div>
        </li>

        <li className='flex flex-nowrap dip'>
          <div className='col flex-noshrink'>
            <i className='icon thirtytwo'>
              <AccountManagerIcon />
            </i>
          </div>
          <div className='col'>
            <h5>
              <a
                href='#role-account-ro'
                className={`alt ${accountManagerROOpen ? 'open' : ''}`}
                data-toggle='collapsable'
                onClick={() => setAccountManagerROOpen(!accountManagerROOpen)}
              >
                Account manager (read-only)
              </a>
            </h5>

            <div id='role-account-ro' className={`collapsable-content ${accountManagerROOpen ? '' : 'hidden'}`}>
              Can view all PaperCut licenses (if available); view details of connected environments.
            </div>
          </div>
        </li>

        <li className='flex flex-nowrap'>
          <div className='col flex-noshrink'>
            <i className='icon thirtytwo'>
              <TechServicesIcon />
            </i>
          </div>
          <div className='col'>
            <h5>
              <a href='#role-techservices' className={`alt ${techServicesOpen ? 'open' : ''}`} data-toggle='collapsable' onClick={() => setTechServicesOpen(!techServicesOpen)}>
                Tech services
              </a>
            </h5>

            <div id='role-techservices' className={`collapsable-content ${techServicesOpen ? '' : 'hidden'}`}>
              Can remotely manage PaperCut environments; remotely manage MFDs; add new customers; view details of connected environments; configure health notifications; configure
              system health thresholds.
            </div>
          </div>
        </li>

        <li className='flex flex-nowrap'>
          <div className='col flex-noshrink'>
            <i className='icon thirtytwo'>
              <TechServicesIcon />
            </i>
          </div>
          <div className='col'>
            <h5>
              <a
                href='#role-techservices-ro'
                className={`alt ${techServicesROOpen ? 'open' : ''}`}
                data-toggle='collapsable'
                onClick={() => setTechServicesROOpen(!techServicesROOpen)}
              >
                Tech services (read-only)
              </a>
            </h5>

            <div id='role-techservices-ro' className={`collapsable-content ${techServicesROOpen ? '' : 'hidden'}`}>
              Can view details of connected environments.
            </div>
          </div>
        </li>
      </ul>
    </div>
  )
}
