import React from 'react'
import styles from './AccountSummary.module.css'
import { AccountIcon, WarningIcon } from '../Icons/Icons'
import { MaintenanceAndSupportStatus } from '../Account/Data'

interface Props {
  onClick: () => void
  isOpen: boolean
  dataAvailable: boolean
  badge: string
}

class AccountSummary extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props)
    this.onClick = this.onClick.bind(this)
  }

  render() {
    let statusClass = ''
    let accountText = ''
    if (!this.props.dataAvailable) {
      statusClass = 'warning'
    } else {
      switch (this.props.badge) {
        case MaintenanceAndSupportStatus.Current:
        case MaintenanceAndSupportStatus.Expired:
        case MaintenanceAndSupportStatus.Expiring:
          statusClass = this.props.badge.toLowerCase()
          accountText = 'M&S ' + statusClass
          break
        case 'NFR':
        case 'Trial':
          statusClass = 'license'
          accountText = this.props.badge + ' license'
          break
      }
    }

    return (
      <li className={`${styles.featurePanel}` + (this.props.isOpen ? ' active' : '')}>
        <a href='#tab-account' onClick={this.onClick}>
          <div className='title'>Account</div>
          <div className={`status ` + statusClass}>
            <i className='icon'>
              <AccountIcon />
            </i>
            {!this.props.dataAvailable && (
              <div className='label'>
                <span className='state'>
                  <i className='icon twelve'>
                    <WarningIcon />
                  </i>
                </span>
                Data not available
              </div>
            )}
            <span className='state'>{accountText}</span>
          </div>
        </a>
      </li>
    )
  }

  onClick(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault()
    if (this.props.dataAvailable) {
      this.props.onClick()
    }
  }
}

export default AccountSummary
