import React, { FunctionComponent } from 'react'
interface Props {
  onClick: () => void
  isOpen: boolean
}

const HiveServiceHealthSummary: FunctionComponent<Props> = props => {
  const onClicked = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    props.onClick()
  }
  return (
    <li className={props.isOpen ? ' active' : ''} onClick={onClicked}>
      <a href='#tab-health'>
        <div className='title'>Service health</div>
        <div className='status good'>
          <i className='icon idle'></i>
          <span className='state'>Not yet available</span>
        </div>
      </a>
    </li>
  )
}

export default HiveServiceHealthSummary
