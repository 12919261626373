import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HostData } from '../components/Host/Data'
import { HostSlideoutTab } from '../components/HostSlideout/Data'

export type SlideoutState = {
  host?: HostData
  startTab?: HostSlideoutTab
}

const slideoutSlice = createSlice({
  name: 'slideout',
  initialState: {} as SlideoutState,
  reducers: {
    showHost: {
      reducer: (state, action: PayloadAction<SlideoutState>) => {
        state.host = action.payload.host
        state.startTab = action.payload.startTab
      },
      prepare: (host: HostData | undefined, startTab?: HostSlideoutTab) => {
        return {
          payload: {
            host,
            startTab,
          },
        }
      },
    },
    clearHost(state) {
      state.host = undefined
      state.startTab = undefined
    },
  },
})

export const { showHost, clearHost } = slideoutSlice.actions
export default slideoutSlice.reducer
