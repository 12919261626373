import React, { FunctionComponent, ReactNode } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'

interface Props {
  path: string
  children?: ReactNode
}

const RouteTabLink: FunctionComponent<Props> = props => {
  const location = useLocation()
  const normalisedPath = props.path.replace('/*', '')
  return (
    <li className={location.pathname.endsWith(normalisedPath) ? 'active' : ''}>
      <Link to={normalisedPath}>{props.children}</Link>
    </li>
  )
}

export default RouteTabLink
