import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AccountDetails } from '../components/Account/Data'
import { ConnectionStatus, HostData, SystemInfoData } from '../components/Host/Data'
import { SystemHealthData } from '../components/SystemHealth/Data'
import { FeatureUsageData } from '../components/FeatureUsage/FeatureUsage'

const getHostForUpdate = (state: HostsState, id: string): HostState => {
  let host = state.find(s => s.id === id)
  if (!host) {
    host = {
      id: id,
      hostConfig: {
        remoteMonitoring: true,
      },
    }
    state.push(host)
  }
  return host
}

const hostsSlice = createSlice({
  name: 'hosts',
  initialState: [] as HostsState,
  reducers: {
    initHosts(state, action: PayloadAction<HostData[]>) {
      state.filter(s => action.payload.find(h => h.id === s.id))
    },
    updateAccountDetails: {
      reducer: (state, action: PayloadAction<{ id: string; accountDetails: AccountDetails }>) => {
        let host = getHostForUpdate(state, action.payload.id)
        host.accountDetails = action.payload.accountDetails
      },
      prepare: (id: string, accountDetails: AccountDetails) => {
        return {
          payload: { id, accountDetails },
        }
      },
    },
    updateSystemHealth: {
      reducer: (state, action: PayloadAction<{ id: string; systemHealth: SystemHealthData }>) => {
        let host = getHostForUpdate(state, action.payload.id)
        host.systemHealth = action.payload.systemHealth
      },
      prepare: (id: string, systemHealth: SystemHealthData) => {
        return {
          payload: { id, systemHealth },
        }
      },
    },
    updateDevices: {
      reducer: (state, action: PayloadAction<{ id: string; devices: boolean }>) => {
        let host = getHostForUpdate(state, action.payload.id)
        host.hostConfig.devices = action.payload.devices
      },
      prepare: (id: string, devices: boolean) => {
        return {
          payload: { id, devices },
        }
      },
    },
    updateRemoteMonitoring: {
      reducer: (state, action: PayloadAction<{ id: string; remoteMonitoring: boolean }>) => {
        let host = getHostForUpdate(state, action.payload.id)
        host.hostConfig.remoteMonitoring = action.payload.remoteMonitoring
      },
      prepare: (id: string, remoteMonitoring: boolean) => {
        return {
          payload: { id, remoteMonitoring },
        }
      },
    },
    updateConnectionStatus: {
      reducer: (state, action: PayloadAction<{ id: string; connectionStatus: ConnectionStatus }>) => {
        let host = getHostForUpdate(state, action.payload.id)
        host.connectionStatus = action.payload.connectionStatus
      },
      prepare: (id: string, connectionStatus: ConnectionStatus) => {
        return {
          payload: { id, connectionStatus },
        }
      },
    },
    updateFeatureUsage: {
      reducer: (state, action: PayloadAction<{ id: string; featureUsage: FeatureUsageData }>) => {
        let host = getHostForUpdate(state, action.payload.id)
        host.featureUsage = action.payload.featureUsage
      },
      prepare: (id: string, featureUsage: FeatureUsageData) => {
        return {
          payload: { id, featureUsage },
        }
      },
    },
    updateSystemInfo: {
      reducer: (state, action: PayloadAction<{ id: string; systemInfo: SystemInfoData }>) => {
        let host = getHostForUpdate(state, action.payload.id)
        host.systemInfo = action.payload.systemInfo
        host.systemInfo.osPlatform = action.payload.systemInfo.osPlatform?.replace(/\((unknown)?\)/g, '') || ''
      },
      prepare: (id: string, systemInfo: SystemInfoData) => {
        return {
          payload: { id, systemInfo },
        }
      },
    },
  },
})

export type HostState = {
  id: string
  systemInfo?: SystemInfoData
  accountDetails?: AccountDetails
  systemHealth?: SystemHealthData
  featureUsage?: FeatureUsageData
  connectionStatus?: ConnectionStatus
  hostConfig: HostConfig
}
export type HostsState = HostState[]
export interface HostConfig {
  remoteMonitoring: boolean
  devices?: boolean
}

export const {
  initHosts,
  updateAccountDetails,
  updateSystemInfo,
  updateSystemHealth,
  updateFeatureUsage,
  updateConnectionStatus,
  updateDevices,
  updateRemoteMonitoring,
} = hostsSlice.actions
export default hostsSlice.reducer
