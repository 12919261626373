import React, { FunctionComponent, useState } from 'react'
import { PaperCutIcon } from '../Icons/Icons'
import { HealthMetricStatus, PrintManagementData } from './Data'
import HealthMetricStatusIcon from './HealthMetricStatusIcon'

interface Props {
  data: PrintManagementData
  onExpand: () => void
  onShrink: () => void
}

export const PrintManagement: FunctionComponent<Props> = props => {
  const [expanded, setExpanded] = useState(false)

  const getHighlight = (status?: HealthMetricStatus): string => {
    if (status === HealthMetricStatus.Critical) {
      return 'level-error'
    }

    if (status === HealthMetricStatus.Warning) {
      return 'level-warning'
    }

    return ''
  }

  const onExpandButtonClicked = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    if (expanded) {
      props.onShrink()
    } else {
      props.onExpand()
    }
    setExpanded(!expanded)
  }

  const devicesInError = props.data.devices.devicesInError && props.data.devices.devicesInError.length > 0
  const devicesHighlight = getHighlight(props.data.devices.status)
  const mobilityHighlight = getHighlight(props.data.mobilePrint.status)
  const providerHighlight = getHighlight(props.data.printProvider.status)
  const siteHighlight = getHighlight(props.data.siteServer.status)

  return (
    <div className={`col third ${expanded ? 'open-card' : ''}`}>
      <div className='card'>
        <header>
          <a href='#' className='expand' onClick={onExpandButtonClicked}>
            <span className='tip'>Expand any health metric to get all the details</span>Expand
          </a>
          <i className='icon'>
            <PaperCutIcon />
          </i>
          <h6>Print management</h6>
        </header>
        <div className='compact'>
          <div className={`set ${providerHighlight}`}>
            <h5>Print providers</h5>
            <div className='status'>
              <HealthMetricStatusIcon
                status={props.data.printProvider.status}
                details={props.data.printProvider.details}
                thresholdMessages={props.data.printProvider.thresholdMessages}
              />
            </div>
          </div>
          <div className={`set alt ${siteHighlight}`}>
            <h5>
              <span>Site servers</span>
            </h5>
            <div className='status'>
              <HealthMetricStatusIcon status={props.data.siteServer.status} details={props.data.siteServer.details} thresholdMessages={props.data.siteServer.thresholdMessages} />
            </div>
          </div>
          <div className={`set ${mobilityHighlight}`}>
            <h5>BYOD / Mobility Print</h5>
            <div className='status'>
              <HealthMetricStatusIcon
                status={props.data.mobilePrint.status}
                details={props.data.mobilePrint.details}
                thresholdMessages={props.data.mobilePrint.thresholdMessages}
              />
            </div>
          </div>
          <div className={`set alt ${devicesHighlight}`}>
            <h5>Devices</h5>
            <div className='status'>
              <HealthMetricStatusIcon status={props.data.devices.status} details={props.data.devices.details} thresholdMessages={props.data.devices.thresholdMessages} />
            </div>
          </div>
        </div>
        <div className='expanded'>
          <div className='col half flex'>
            <div className='set'>
              <h5>Print providers</h5>
              <div className='stats'>
                <ul>
                  <li>
                    Total <span className='num'>{props.data.printProvider.total}</span>
                  </li>
                  <li>
                    Online <span className='num'>{props.data.printProvider.online}</span>
                  </li>
                  <li className={props.data.printProvider.metricName !== '' ? providerHighlight : ''}>
                    Offline
                    <span className='num'>{props.data.printProvider.offline}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className='set'>
              <h5>
                <span>Site servers</span>
              </h5>
              <div className='stats'>
                <ul>
                  <li>
                    Total <span className='num'>{props.data.siteServer.total}</span>
                  </li>
                  <li>
                    Online <span className='num'>{props.data.siteServer.online}</span>
                  </li>
                  <li className={props.data.siteServer.metricName !== '' ? siteHighlight : ''}>
                    Offline
                    <span className='num'>{props.data.siteServer.offline}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className='set'>
              <h5>BYOD / Mobility Print</h5>
              <div className='stats'>
                <ul>
                  <li>
                    Total <span className='num'>{props.data.mobilePrint.total}</span>
                  </li>
                  <li>
                    Online <span className='num'>{props.data.mobilePrint.online}</span>
                  </li>
                  <li className={props.data.mobilePrint.metricName !== '' ? mobilityHighlight : ''}>
                    Offline
                    <span className='num'>{props.data.mobilePrint.offline}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='col half flex'>
            <div className='set'>
              <h5>Devices in error</h5>
              <div className='stats'>
                <ul>
                  {!devicesInError && <li>No devices are in error.</li>}
                  {devicesInError &&
                    props.data.devices.devicesInError.map(device => (
                      <li key={device.name} className={devicesHighlight}>
                        {device.name}

                        <span className='num'>
                          <small className='error'>{device.state.status}</small>
                        </span>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrintManagement
