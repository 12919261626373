import React, { FunctionComponent, useState } from 'react'
import { AuthType, User, UserRole } from './Data'
import TeamMember from './TeamMember'
import InviteModal from './InviteModal'
import AuthRole from '../../role'
import { RoleDetails } from './RoleDetails'
import EditMembersAuthModal from './EditMembersAuthModal'
import { LoadingDots } from '../LoadingDots/LoadingDots'

interface Props {
  users: User[]
  teamID: string
  sendInviteEmail: (email: string, roles: UserRole[]) => Promise<void | number>
  removeMember: (user: User) => void
  saveAuthType: (users: User[], authType: string) => void
  handleRoleChange: (user: User, newRoles: UserRole[]) => void
  isLoading: boolean
}

const MyTeamView: FunctionComponent<Props> = props => {
  const [inviteModalOpen, setInviteModalOpen] = useState(false)
  const [editAuthModalOpen, setEditAuthModalOpen] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState<User[]>([])
  const [selectAll, setSelectAll] = useState(false)

  const openInviteModal = () => {
    setInviteModalOpen(true)
  }

  const closeInviteModal = () => {
    setInviteModalOpen(false)
  }

  const openEditAuthModal = () => {
    setEditAuthModalOpen(true)
  }

  const closeEditAuthModal = () => {
    setEditAuthModalOpen(false)
  }

  const handleTeamCheckboxChange = () => {
    setSelectedUsers(selectAll ? [] : props.users)
    setSelectAll(!selectAll)
  }

  const handleMemberCheckboxChange = (user: User, checked: boolean) => {
    let userIDs = [...selectedUsers]

    if (checked) {
      userIDs.push(user)
    } else {
      userIDs = userIDs.filter(u => u.id !== user.id)
    }

    setSelectedUsers(userIDs)
    setSelectAll(props.users.length === userIDs.length)
  }

  const saveMembersAuthType = () => {
    props.saveAuthType(selectedUsers, AuthType.MFA)
    clearCheckboxes()
    closeEditAuthModal()
  }

  const saveMemberAuthType = (user: User, authType: string) => {
    let users: User[] = [user]
    props.saveAuthType(users, authType)
  }

  const editMembersAuthModalTitle = `Enable 2FA for (${selectedUsers.length})
        selected team member${selectedUsers.length > 1 ? 's' : ''}?`

  const clearCheckboxes = () => {
    setSelectedUsers([])
    setSelectAll(false)
  }

  const teamMembers = props.users.map(u => {
    return (
      <li key={u.id}>
        <TeamMember
          user={u}
          teamID={props.teamID}
          removeMember={props.removeMember}
          handleCheckboxChange={handleMemberCheckboxChange}
          checkboxState={selectedUsers.indexOf(u) >= 0}
          handleSaveAuthType={saveMemberAuthType}
          handleRoleChange={props.handleRoleChange}
          onActionMenuClick={clearCheckboxes}
          handleReinvite={props.sendInviteEmail}
        />
      </li>
    )
  })

  return (
    <>
      <InviteModal sendInviteEmail={props.sendInviteEmail} isOpen={inviteModalOpen} close={closeInviteModal} />

      <EditMembersAuthModal isOpen={editAuthModalOpen} title={editMembersAuthModalTitle} close={closeEditAuthModal} onSave={saveMembersAuthType} />
      {props.isLoading && (
        <div className='empty loading'>
          <LoadingDots />
        </div>
      )}
      {!props.isLoading && (
        <div className='row flex' id='tab-teamlist'>
          <div className='threeqtr col'>
            <div className='flex flex-start btm'>
              {AuthRole.hasPermission('inviteMember') && (
                <div className='rgt flex flex-start flex-nowrap'>
                  <div className='btn-group no-margin-bottom'>
                    <button onClick={openEditAuthModal} className={`btn secondary ${selectedUsers.length > 0 ? '' : 'disabled'}`}>
                      Enable 2FA
                    </button>
                    <button onClick={openInviteModal} className='btn invite'>
                      Invite team member
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className='list condensed'>
              {AuthRole.hasPermission('updateUser') && (
                <>
                  <header>
                    <div className='flex flex-nowrap'>
                      <div className='col check'>
                        <input type='checkbox' id='team-check-all' name='team-check' checked={selectAll} onChange={handleTeamCheckboxChange} />
                      </div>
                      <div className={`col username`}>Team member</div>
                      <div className={`col mfa`}>2FA</div>
                      <div className={`col level`}>Roles</div>
                      <div className='col actions'></div>
                    </div>
                  </header>
                  <ul>{teamMembers}</ul>
                </>
              )}
              {!AuthRole.hasPermission('updateUser') && (
                <>
                  <header>
                    <div className='flex flex-nowrap'>
                      <div className={`col username-twocolumns`}>Team member</div>
                      <div className='col level'>Roles</div>
                    </div>
                  </header>
                  <ul>{teamMembers}</ul>
                </>
              )}
            </div>
          </div>
          <div className='qtr col'>
            <RoleDetails />
          </div>
        </div>
      )}
    </>
  )
}

export default MyTeamView
