import React, { FunctionComponent } from 'react'
import CustomModal from '../MyModal/CustomModal'

interface Props {
  isOpen: boolean
  title: string
  close: () => void
  onSave: () => void
}

const EditMembersAuthModal: FunctionComponent<Props> = props => {
  const handleEnable2FA = async () => {
    props.onSave()
    props.close()
  }

  return props.isOpen ? (
    <CustomModal id='popup-edit-auth' close={props.close}>
      <header className='center'>
        <h3>{props.title}</h3>
      </header>

      <div className='content'>
        <p className='dip'>Selected team members will be:</p>
        <ul className='disc'>
          <li>Set up with two-factor authentication</li>
          <li>Informed of the authentication change by email</li>
          <li>Prompted to use Google Authenticator (or similar) when they next log in</li>
        </ul>
        <small>
          <strong>Note:</strong> Any selected team members already set up with 2FA won't be affected.
        </small>
      </div>

      <footer className=''>
        <button className='btn outline' onClick={props.close}>
          Cancel
        </button>
        <button className='btn'>
          <span className='text' onClick={handleEnable2FA}>
            Enable 2FA
          </span>
        </button>
      </footer>
    </CustomModal>
  ) : (
    <></>
  )
}

export default EditMembersAuthModal
