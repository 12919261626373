import React, { FunctionComponent, ReactNode, useState } from 'react'
import { useClickOutside } from '../UseClickOutside/UseClickOutside'

interface Props {
  onClick?: () => void
  children?: ReactNode
}

const Hamburger: FunctionComponent<Props> = ({ children, onClick }) => {
  const [open, setOpen] = useState(false)
  const ref = useClickOutside(() => setOpen(false))

  return (
    <div
      ref={ref}
      className={'more ' + (open ? 'open' : '')}
      onClick={e => {
        e.stopPropagation()
        setOpen(true)
        if (onClick) {
          onClick()
        }
      }}
    >
      <i>
        <span className='tip'>{children}</span>
      </i>
    </div>
  )
}

export default Hamburger
