import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { getHostEvents } from '../../api/host'
import { useDebounce } from '../../hooks/Debounce'
import { Timestamps } from '../../service/Timestamps'
import { HostEvent, HostEventType } from '../Host/Data'
import { CalendarIcon, CustomersIcon, ErrorIcon, ExclaimIcon, LicenseIcon, RemoteManageIcon, TickIcon } from '../Icons/Icons'
import { LoadingDots } from '../LoadingDots/LoadingDots'
import Toaster from '../Toaster'

interface Props {
  teamID: string
  hostID: string
}

export const EventLogTab: FunctionComponent<Props> = props => {
  const [hostEvents, setHostEvents] = useState<HostEvent[]>([])
  const [eventSearch, setEventSearch] = useState('')
  const [loaded, setLoaded] = useState(false)
  const [waiting, setWaiting] = useState(true)
  const debouncedEventSearchFilter = useDebounce(eventSearch, 500)

  const filteredHostEvents = hostEvents.filter(e => {
    let searchTerm = debouncedEventSearchFilter.trim().toLowerCase()
    if (searchTerm) {
      return e.type.toLowerCase().includes(searchTerm) || (e.message?.toLowerCase().includes(searchTerm) ?? false)
    }
    return true
  })

  const getHostEventsCallback = useCallback((teamId: string, hostId: string) => {
    setWaiting(true)
    getHostEvents(teamId, hostId)
      .then(hostEventsResponse => {
        setHostEvents(hostEventsResponse)
        setLoaded(true)
      })
      .catch(_ => Toaster.notifyFailure('Unable to load events.'))
      .finally(() => setWaiting(false))
  }, [])

  useEffect(() => {
    getHostEventsCallback(props.teamID, props.hostID)
    const hostEventTimer = setInterval(() => getHostEventsCallback(props.teamID, props.hostID), 60 * 1000)
    return () => {
      clearInterval(hostEventTimer)
    }
  }, [props.hostID, props.teamID, getHostEventsCallback])

  const getStatus = (event: HostEvent) => {
    switch (event.type) {
      case HostEventType.Error:
        return (
          <div className='col status error'>
            <i className='icon sixteen'>
              <ErrorIcon />
            </i>
          </div>
        )
      case HostEventType.Warning:
        return (
          <div className='col status warning'>
            <i className='icon sixteen'>
              <ExclaimIcon />
            </i>
          </div>
        )
      case HostEventType.Good:
        return (
          <div className='col status good'>
            <i className='icon sixteen'>
              <TickIcon />
            </i>
          </div>
        )
      case HostEventType.Remote:
        return (
          <div className='col status event'>
            <i className='icon sixteen'>
              <RemoteManageIcon />
            </i>
          </div>
        )
      case HostEventType.License:
        return (
          <div className='col status'>
            <i className='icon sixteen'>
              <LicenseIcon />
            </i>
          </div>
        )
      case HostEventType.Customer:
        return (
          <div className='col status'>
            <i className='icon sixteen'>
              <CustomersIcon />
            </i>
          </div>
        )
      default:
        return (
          <div className='col status event'>
            <i className='icon sixteen'>
              <CalendarIcon />
            </i>
          </div>
        )
    }
  }

  const getMessageClasses = (eventType: HostEventType) => {
    switch (eventType) {
      default:
        return 'col message'
      case HostEventType.Error:
      case HostEventType.Warning:
        return 'col message highlight'
    }
  }

  return (
    <div id='tab-events' className='tab visible'>
      <header className='flex flex-ver'>
        <h2>Event log</h2>
      </header>

      <div className='content open'>
        <div className='row flex'>
          {!loaded && waiting && (
            <div className='empty loading'>
              <LoadingDots />
            </div>
          )}
          <div className='col twothird'>
            <div className='flex flex-start btm'>
              {hostEvents.length !== 0 && <input type='search' placeholder='Search by message or type' value={eventSearch} onChange={e => setEventSearch(e.currentTarget.value)} />}
            </div>
            <div className='list'>
              <ul>
                {loaded && filteredHostEvents.length === 0 && (
                  <li>
                    <div className='row'>
                      <div className='col status event'>
                        <i className='icon sixteen'>
                          {' '}
                          <CalendarIcon />
                        </i>
                      </div>

                      {hostEvents.length === 0 && <div className='col message'>No events found.</div>}
                      {hostEvents.length > 0 && <div className='col message'>No matching events found.</div>}
                    </div>
                  </li>
                )}

                {filteredHostEvents.map(hostEvent => {
                  const occurredAt = Timestamps.format(hostEvent.occurredAt)

                  return (
                    <li key={new Date(hostEvent.occurredAt).toISOString()}>
                      <div className='row'>
                        {getStatus(hostEvent)}

                        <div className={getMessageClasses(hostEvent.type)} data-heap-redact-text>
                          {hostEvent.message}
                        </div>

                        <div className='col time'>{occurredAt}</div>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
