import auth0, { Auth0DecodedHash, Auth0UserProfile, AuthorizeOptions } from 'auth0-js'
import axios, { AxiosError } from 'axios'
import { Auth0Config, GetAppConfig } from '../env'
import { AccessToken, AuthProvider, AuthToken, InviteData, UserProfile } from './AuthHandler'

export interface Auth0Token extends AuthToken {
  profile: Auth0UserProfile
}

/**
 * Auth0 authentication provider
 */
class Auth0Provider implements AuthProvider {
  auth0: auth0.WebAuth

  constructor(conf: Auth0Config) {
    this.auth0 = new auth0.WebAuth({
      domain: conf.Auth0Domain,
      clientID: conf.Auth0ClientID,
      responseType: 'token',
      scope: 'openid profile email',
      audience: conf.Auth0Audience,
    })
  }

  authorize = (data: InviteData) => {
    let redirectUri = `${window.location.origin}/callback?path=${window.location.pathname}`
    if (data.teamInviteCode) {
      redirectUri += '&signUp=1'
    }
    if (data.teamID) {
      redirectUri += '&teamID=' + data.teamID
    }

    const authOption: AuthorizeOptions = {
      redirectUri: redirectUri,
    }

    if (data.invitedEmail) {
      authOption.login_hint = data.invitedEmail
    }

    this.auth0.authorize(authOption)
  }

  logout = (err?: any) => {
    fetch('/api/logout', {
      method: 'POST',
      credentials: 'include',
    }).finally(async () => {
      if (err) {
        let paramName = err.code ? 'err_code' : 'err'
        let paramVal = err.code ? err.code : JSON.stringify(err)
        await this.auth0.logout({
          returnTo: `${window.location.origin}/landing?${paramName}=` + encodeURIComponent(paramVal),
        })
        return
      }
      await this.auth0.logout({
        returnTo: `${window.location.origin}`,
      })
    })
  }

  parseToken = async (): Promise<AuthToken> => {
    let authResult = await this.parseHash()
    if (authResult === null) {
      throw new Error('invalid auth result returned')
    }
    return this.setSession(authResult)
  }

  authorizeInviteEmail = async (teamInviteCode: string): Promise<string> => {
    const urlParams = new URLSearchParams()
    urlParams.append('inviteCode', teamInviteCode)
    return new Promise<string>((resolve, reject) => {
      axios
        .get('/api/invite-email?' + urlParams.toString())
        .then(resp => {
          let email = resp.data
          resolve(email)
        })
        .catch((error: AxiosError) => {
          reject(error)
        })
    })
  }

  authorizeForMultiverseAPI = async (token: AuthToken, teamInviteCode: string, teamID?: string): Promise<UserProfile> => {
    // The response sets a cookie to be used to authenticate with our APIs
    // TODO raise alert when non-200 is returned
    const urlParams = new URLSearchParams()
    urlParams.append('token', token.accessToken)
    if (teamInviteCode !== null && teamInviteCode !== '') {
      urlParams.append('team-invite-code', teamInviteCode)
    }
    // @ts-ignore
    return new Promise<UserProfile>((resolve, reject) => {
      let url = '/api'
      if (teamID) {
        url += '/teams/' + teamID
      }
      url += '/authorization'
      axios
        .get<any>(url + '?' + urlParams.toString())
        .then(resp => {
          const auth0Token = token as Auth0Token
          const profile: UserProfile = {
            teamID: resp.data.teamID,
            userID: resp.data.userID,
            name: auth0Token.profile.name,
            picture: auth0Token.profile.picture,
            permissions: resp.data.permissions,
          }
          localStorage.setItem('teamID', resp.data.teamID)
          resolve(profile)
        })
        .catch((error: AxiosError) => {
          if (error.response) {
            reject(error.response.data)
          } else {
            reject(error)
          }
        })
    })
  }

  renewSession = async (): Promise<Auth0Token> => {
    let authHash = await this.checkSession()
    return this.decodeHashToToken(authHash)
  }

  private parseHash = (): Promise<Auth0DecodedHash | null> => {
    return new Promise((res, rej) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) {
          rej(err)
        } else {
          res(authResult)
        }
      })
    })
  }

  private checkSession = (): Promise<Auth0DecodedHash> => {
    return new Promise((res, rej) => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          res(authResult)
        } else {
          rej(err)
        }
      })
    })
  }

  private getProfile = (accessToken: AccessToken): Promise<Auth0UserProfile> => {
    return new Promise((res, rej) => {
      this.auth0.client.userInfo(accessToken, (err, prof) => {
        if (err) {
          rej(err)
        } else {
          res(prof)
        }
      })
    })
  }

  private decodeHashToToken = async (authResult: Auth0DecodedHash): Promise<Auth0Token> => {
    if (!authResult.accessToken) {
      throw new Error('no access token returned from auth0')
    }

    let expiresIn
    if (authResult.expiresIn === undefined) {
      console.error('error retrieving expiry time for session, will default 30m from now')
      expiresIn = 30 * 60
    } else {
      expiresIn = authResult.expiresIn
    }

    let profile
    try {
      profile = await this.getProfile(authResult.accessToken)
    } catch (error) {
      console.error('error retrieving user profile')
      throw error
    }

    // Set the time that the access token will expire at
    let expiresAt = expiresIn * 1000 + new Date().getTime()

    return {
      accessToken: authResult.accessToken,
      expiresAt: expiresAt,
      profile: profile,
    }
  }

  private setSession = async (authResult: Auth0DecodedHash): Promise<AuthToken> => {
    return this.decodeHashToToken(authResult)
  }
}

const Auth0ProviderInst: AuthProvider = new Auth0Provider(GetAppConfig().auth0Config)
export default Auth0ProviderInst
