import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HostFilter } from '../components/HostPropertiesFilter/Data'

type HostPropertiesFilterState = {
  filter?: HostFilter
  showFilterPanel: boolean
}

const hostFilterSlice = createSlice({
  name: 'hostsFilter',
  initialState: {
    filter: undefined,
    showFilterPanel: false,
  } as HostPropertiesFilterState,
  reducers: {
    setHostPropertiesFilter(state, action: PayloadAction<HostFilter | undefined>) {
      state.filter = action.payload
    },
    clearHostPropertiesFilter(state) {
      state.filter = undefined
    },
    toggleFilterPanel(state) {
      state.showFilterPanel = !state.showFilterPanel
    },
  },
})

export const { setHostPropertiesFilter, toggleFilterPanel, clearHostPropertiesFilter } = hostFilterSlice.actions
export default hostFilterSlice.reducer
