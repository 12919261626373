import React from 'react'
import styles from './LoadingPage.module.css'
import { LoadingDots } from '../LoadingDots/LoadingDots'

export default class LoadingPage extends React.Component {
  render() {
    return (
      <div className={styles.spinnerContainer}>
        <div className='empty loading'>
          <LoadingDots />
        </div>
      </div>
    )
  }
}
