import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react'
import { connect, shallowEqual, useDispatch } from 'react-redux'
import mf from '../../assets/img/mf.jpg'
import noCustomersBackground from '../../assets/img/no-customers-background.png'
import { useDebounce } from '../../hooks/Debounce'
import { useLocalStorageState } from '../../hooks/LocalStorageState'
import { HostsState } from '../../redux/hosts'
import { toggleFilterPanel } from '../../redux/hostsFilter'
import { useTypedSelector } from '../../redux/reducers'
import { isDemo } from '../../redux/selectors'
import AuthRole from '../../role'
import { AccessConfig } from '../Access/Data'
import { HostList, View } from '../CompactView/HostList'
import HiveOrg from '../Hive/HiveOrg'
import Host from '../Host'
import { AddCustomerFunction, EditCustomerFunction, HostData, HostDataUtil, Product } from '../Host/Data'
import { HostFilter } from '../HostPropertiesFilter/Data'
import { AccountIcon, AccountManagerIcon, AdvancedFeatureGaugeIcon, CalendarIcon, TechServicesIcon } from '../Icons/Icons'
import { StatusBoard } from '../StatusBoard/StatusBoard'
import TourModal from '../TourModal/TourModal'
import CustomerModal from './CustomerModal'

interface Props {
  teamID: string
  hosts: HostData[]
  addCustomer: AddCustomerFunction
  editCustomer: EditCustomerFunction
  deleteCustomer: (ID: string) => void
  openAccessFrame: (config: AccessConfig) => void
  migrating: boolean
  view?: string
}

function getHostFilter(hostsStates: HostsState, filterParams: HostFilter) {
  /* Return <0 if fullVersion < inputVersion, >0 if fullVersion > inputVersion or 0 if equal */
  function compareVersions(fullVersion: string, inputVersion: string): number {
    let parts1 = fullVersion.split(/[^\d]+/)
    let parts2 = inputVersion.split(/[^\d]+/)

    for (let i = 0; i < parts2.length; i++) {
      if (parts1[i] !== parts2[i]) return +parts1[i] - +parts2[i]
    }
    return 0
  }

  function getHost(hostData: HostData) {
    return hostsStates.find(h => h.id === hostData.id)
  }

  function crnPropFilter(hostData: HostData): boolean {
    return (
      !filterParams.crn ||
      (getHost(hostData)
        ?.accountDetails?.crn.toLowerCase()
        .includes(filterParams.crn.toLowerCase()) ??
        false)
    )
  }

  function devicesMinFilter(hostData: HostData): boolean {
    return !filterParams.devicesMin || (getHost(hostData)?.systemInfo?.embeddedCount ?? -1) >= +filterParams.devicesMin
  }

  function devicesMaxFilter(hostData: HostData): boolean {
    return !filterParams.devicesMax || (getHost(hostData)?.systemInfo?.embeddedCount ?? 999999) <= +filterParams.devicesMax
  }

  function osFilter(hostData: HostData): boolean {
    return (
      !filterParams.operatingSystem ||
      (getHost(hostData)
        ?.systemInfo?.osPlatform.toLowerCase()
        .includes(filterParams.operatingSystem.toLowerCase()) ??
        false)
    )
  }

  function productVersionMin(hostData: HostData): boolean {
    if (!filterParams.versionMin) return true
    let host = getHost(hostData)
    if (!host) return false
    let version = HostDataUtil.getVersion(host.systemInfo, host.accountDetails)
    return version !== '' && compareVersions(version, filterParams.versionMin) >= 0
  }

  function productVersionMax(hostData: HostData) {
    if (!filterParams.versionMax) return true
    let host = getHost(hostData)
    if (!host) return false
    let version = HostDataUtil.getVersion(host.systemInfo, host.accountDetails)
    return version !== '' && compareVersions(version, filterParams.versionMax) <= 0
  }

  function productFilter(hostData: HostData): boolean {
    let allProducts = Object.entries(filterParams.products).filter(entry => filterParams.products.hasOwnProperty(entry[0]))
    if (allProducts.every(entry => !entry[1])) return true
    let edition = getHost(hostData)?.systemInfo?.product.toUpperCase() || hostData.product.toUpperCase()
    return edition !== '' && allProducts.some(entry => entry[0].toUpperCase() === edition && entry[1])
  }

  return (host: HostData) => {
    return crnPropFilter(host) && productFilter(host) && devicesMinFilter(host) && devicesMaxFilter(host) && osFilter(host) && productVersionMin(host) && productVersionMax(host)
  }
}

const displayBlockAmount = 50

const MyHosts: FunctionComponent<Props> = props => {
  const [displayAddCustomerForm, setDisplayAddCustomerForm] = useState(false)
  const [searchFilter, setSearchFilter] = useState('')
  const [showTour, setShowTour] = useState(false)

  const [filteredHosts, setFilteredHosts] = useState(props.hosts)
  const [limit, setLimit] = useState(displayBlockAmount)
  const [compactView, setCompactView] = useLocalStorageState('compact-view', true)
  const [compactViewType, setCompactViewType] = useLocalStorageState('compact-view-type', View.HealthView)
  const filterActive = useTypedSelector(root => root.filter.filter !== undefined)
  const demoMode = useTypedSelector(isDemo)

  let noCustomers = props.hosts === null || props.hosts.length === 0
  let propsFilter = useTypedSelector(state => state.filter.filter, shallowEqual)
  const hostsStates: HostsState = useTypedSelector(state => {
    return state.hosts
  })

  let dispatch = useDispatch()

  const debouncedSearchFilter = useDebounce(searchFilter, 500)
  useEffect(() => {
    let newFilteredHosts = props.hosts.filter(it => {
      let searchTerm = debouncedSearchFilter.toLowerCase().trim()
      return !searchTerm || it.name.toLowerCase().includes(searchTerm) || searchTerm.split(/\s+/).every(word => it.tags?.find(tag => tag.toLowerCase().includes(word)))
    })
    if (propsFilter) {
      newFilteredHosts = newFilteredHosts.filter(getHostFilter(hostsStates, propsFilter))
    }
    setFilteredHosts(newFilteredHosts)
  }, [debouncedSearchFilter, props.hosts, propsFilter, hostsStates])

  const modifySearchFilter = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchFilter(event.target.value)
  }

  const openAddCustomerForm = () => {
    setDisplayAddCustomerForm(true)
  }

  const closeAddCustomerForm = () => {
    setDisplayAddCustomerForm(false)
  }

  const onboarding = () => {
    if (props.hosts !== null && props.hosts.length !== 0) return
    return (
      <>
        <div className='onboarding empty reveal'>
          <div className='feat-tabs'>
            <ul className='flex center'>
              <li>
                <div className='title'>Account</div>
                <div className='status current'>
                  <i className='icon'>
                    <svg viewBox='0 0 35 35'>
                      <AccountIcon />
                    </svg>
                  </i>
                </div>
              </li>
              <li>
                <div className='title'>System health</div>
                <div className='status error'>
                  <i className='icon'>
                    <svg viewBox='0 0 61 32'>
                      <g className='heartrate' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
                        <path
                          d='M2,19.5 C3.47009988,19.478132 8.04481123,19.478132 15.724134,19.5 L20.224134,15.3106924 L25.2161846,24.7188566 L30.1798209,2 L35.1434573,29.7674913 L37.9798209,19.5 L39.224134,15.3106924 L43.224134,19.5 L58.8016338,19.5'
                          className='beat'
                          stroke='#49BB45'
                          strokeWidth='3'
                        />
                      </g>
                    </svg>
                  </i>
                </div>
              </li>
              <li>
                <div className='title'>Feature usage</div>
                <div className='status'>
                  <i className='icon'>
                    <AdvancedFeatureGaugeIcon />
                  </i>
                </div>
              </li>
              <li>
                <div className='title'>Event log</div>
                <div className='status'>
                  <i className='icon'>
                    <CalendarIcon />
                  </i>
                </div>
              </li>
            </ul>
            <div>
              <h4>Manage everything</h4>
              <p>Use a single dashboard to manage your customers, their system health, and more!</p>
            </div>
          </div>
          <div id='first'>
            <figure>
              <img src={noCustomersBackground} alt='' />
            </figure>
            <h1>Welcome to PaperCut Multiverse!</h1>
            {AuthRole.hasPermission('manageCustomer') && (
              <>
                <p>Get started by connecting customers to your dashboard.</p>
                <p>
                  <a onClick={openAddCustomerForm} className='btn add big'>
                    Add customer
                  </a>
                </p>
              </>
            )}
            {!AuthRole.hasPermission('manageCustomer') && <p>Contact your Admin to add customers to your dashboard.</p>}
            <small>
              Need to know more?{' '}
              <a href='#' onClick={() => setShowTour(true)} className='tour action'>
                Take the tour
              </a>
            </small>
          </div>
          <div className='feat-remote'>
            <figure className='browser'>
              <span className='close' />
              <span className='shrink' />
              <span className='expand' />
              <img src={mf} alt='MF' />
            </figure>
            <h4>Support remotely</h4>
            <p>Give remote support to your customers and keep on top of their print performance.</p>
          </div>
        </div>
        {showTour && (
          <div className='open-start'>
            <TourModal isLoggedOn={true} onClose={() => setShowTour(false)} />
          </div>
        )}
      </>
    )
  }

  if (props.view === 'status') return <StatusBoard teamID={props.teamID} hosts={props.hosts} />

  return (
    <>
      {noCustomers && onboarding()}
      {!noCustomers && (
        <>
          <div className='flex flex-start btm'>
            <div className='push flex flex-start flex-nowrap'>
              <div className='search'>
                <input onChange={modifySearchFilter} value={searchFilter} type='search' placeholder='Search by name or tag' size={15} />
              </div>

              <div className={`sift ${filterActive ? ' on' : ''}`}>
                <a href='#' className='funnel' onClick={() => dispatch(toggleFilterPanel())}>
                  Filter
                </a>
              </div>
            </div>

            <div className='flex flex-start flex-nowrap rgt'>
              {compactView && (
                <div className='toggle'>
                  <a
                    href='#'
                    onClick={e => {
                      e.preventDefault()
                      setCompactViewType(View.HealthView)
                    }}
                    className={`view-environment has-tip ${compactViewType === View.HealthView ? 'active' : ''}`}
                    aria-label='Switch to tech services view'
                  >
                    <i className='icon twentyfour'>
                      <TechServicesIcon />
                    </i>
                  </a>

                  <a
                    href='#'
                    onClick={e => {
                      e.preventDefault()
                      setCompactViewType(View.AccountView)
                    }}
                    className={`view-account has-tip ${compactViewType === View.AccountView ? 'active' : ''}`}
                    aria-label='Switch to account manager view'
                  >
                    <i className='icon twentyfour'>
                      <AccountManagerIcon />
                    </i>
                  </a>
                </div>
              )}

              {!demoMode && (
                <div className='view btn-group'>
                  <a
                    href='#'
                    onClick={e => {
                      e.preventDefault()
                      setCompactView(false)
                    }}
                    className={`view-comfort ${compactView ? '' : 'active'}`}
                    aria-label='Switch to comfort view'
                  />

                  <a
                    href='#'
                    onClick={e => {
                      e.preventDefault()
                      setCompactView(true)
                    }}
                    className={`view-list ${compactView ? 'active' : ''}`}
                    aria-label='Switch to compact view'
                  />
                </div>
              )}

              {AuthRole.hasPermission('manageCustomer') && (
                <button className={'btn add ' + (props.migrating ? 'disabled' : '')} onClick={openAddCustomerForm}>
                  Add customer
                </button>
              )}
            </div>
          </div>

          {!(demoMode || compactView) && (
            <section>
              {filteredHosts.slice(0, limit).map(data => {
                if (data.product === Product.Hive) {
                  return <HiveOrg key={data.id} data={data} teamID={props.teamID} editCustomer={props.editCustomer} deleteCustomer={props.deleteCustomer} />
                }

                return (
                  <Host
                    key={data.id}
                    data={data}
                    teamID={props.teamID}
                    active={noCustomers}
                    deleteCustomer={props.deleteCustomer}
                    editCustomer={props.editCustomer}
                    openAccessFrame={props.openAccessFrame}
                  />
                )
              })}
              {!filteredHosts.length && <p className='big'>No matching customers found.</p>}
            </section>
          )}

          <HostList
            view={compactViewType}
            hosts={filteredHosts}
            teamID={props.teamID}
            deleteCustomer={props.deleteCustomer}
            openAccessFrame={props.openAccessFrame}
            enabled={compactView || demoMode}
            limit={limit}
          />
        </>
      )}

      {limit < filteredHosts.length && (
        <div className='auto center'>
          <span>
            {limit} of {filteredHosts.length} customers
          </span>
          <div>
            <button className='btn secondary' onClick={() => setLimit(limit + displayBlockAmount)}>
              Load more
            </button>
          </div>
        </div>
      )}

      {displayAddCustomerForm && <CustomerModal teamID={props.teamID} action={props.addCustomer} close={closeAddCustomerForm} isVisible={displayAddCustomerForm} />}
    </>
  )
}

export default connect()(MyHosts)
