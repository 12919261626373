import React, { FunctionComponent, useEffect, useState } from 'react'
import axios, { AxiosResponse } from 'axios'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Reseller } from '../ContactSupport/Data'
import { TeamMonitoring } from '../TeamMonitoring/TeamMonitoring'
import { TeamSettings, TeamSettingsData } from '../TeamSettings/TeamSettings'
import Toaster from '../Toaster'
import { isPendingMember, User, UserRole } from './Data'
import MyTeamView from './MyTeamView'
import TeamNavBar from './TeamNavBar'

interface Props {
  teamID: string
  team: TeamSettingsData
  modifyTeamSettings: (newSettings: TeamSettingsData) => Promise<boolean>
  numberOfCustomers: number
  parents: Reseller[]
}

const MyTeamContainer: FunctionComponent<Props> = props => {
  const [users, setUsers] = useState<User[]>([])
  const [loadingUsers, setLoadingUsers] = useState(true)

  useEffect(() => {
    const userPromise = axios
      .get<{ users: User[] }>('/api/teams/' + props.teamID + '/users')
      .then(resp => {
        setUsers(u => u.concat(resp.data.users))
      })
      .catch(_ => Toaster.notifyFailure('Unable to load team members.'))

    const pendingUserPromise = axios
      .get<{ pendingUsers: User[] }>('/api/teams/' + props.teamID + '/pendingusers')
      .then(resp => {
        if (resp.data.pendingUsers) {
          setUsers(u => u.concat(resp.data.pendingUsers))
        }
      })
      .catch(_ => Toaster.notifyFailure('Unable to load pending team members.'))

    Promise.all([userPromise, pendingUserPromise]).finally(() => setLoadingUsers(false))
  }, [props.teamID])

  const sendInviteEmail = (email: string, roles: UserRole[]): Promise<void | number> => {
    const params = new URLSearchParams()
    params.append('email', email)
    return new Promise<void | number>((resolve, reject) => {
      return axios
        .post<{ email: string; roles: UserRole[] }, AxiosResponse<User>>('/api/teams/' + props.teamID + '/invite', { email: email, roles: roles })
        .then(resp => {
          updateUsers(resp.data)
          resolve()
        })
        .catch(error => {
          reject(error.response.status)
        })
    })
  }

  const updateUsers = (user: User) => {
    let updatedUsers = users.filter(u => u.email !== user.email)
    setUsers([...updatedUsers, user])
  }

  const removeMember = (user: User) => {
    let path = '/users/' + user.id + '/user'
    if (isPendingMember(user)) {
      path = '/pendingusers/' + user.id
    }

    axios
      .delete('/api/teams/' + props.teamID + path)
      .then(_ => {
        let userList = users.filter(u => {
          if (u.id !== user.id) {
            return u
          }
          return null
        })
        setUsers(userList)
      })
      .catch(_ => Toaster.notifyFailure('Unable to remove team member.'))
  }

  const saveAuthType = (members: User[], type: string) => {
    let pendingUserIDs: string[] = []
    let userIDs: string[] = []

    members.forEach(u => {
      if (isPendingMember(u)) {
        pendingUserIDs.push(u.id)
      } else {
        userIDs.push(u.id)
      }
    })

    if (userIDs.length > 0) {
      saveUserAuthTypeToPath(userIDs, type, '/api/teams/' + props.teamID + '/users/mfa')
    }

    if (pendingUserIDs.length > 0) {
      saveUserAuthTypeToPath(pendingUserIDs, type, '/api/teams/' + props.teamID + '/pendingusers/mfa')
    }
  }

  const saveUserAuthTypeToPath = (ids: string[], type: string, path: string) => {
    axios
      .post(path, { user_ids: ids, type: type })
      .then(_ => {
        let userList = users.map(user => {
          if (ids.indexOf(user.id) >= 0) {
            user.authType = type
          }
          return user
        })
        setUsers(userList)
      })
      .catch(_ => Toaster.notifyFailure('Unable to update authentication.'))
  }

  const handleRoleChange = (user: User, newRoles: UserRole[]) => {
    let path = '/users/' + user.id + '/role'
    if (isPendingMember(user)) {
      path = '/pendingusers/' + user.id + '/roles'
    }

    axios
      .put('/api/teams/' + props.teamID + path, { roles: newRoles })
      .then(_ => {
        const userList = users.map(u => {
          if (u.id === user.id) {
            u.roles = newRoles
          }
          return u
        })
        setUsers(userList)
        Toaster.notifySuccess('Roles updated.')
      })
      .catch(_ => Toaster.notifyFailure('Unable to update roles.'))
  }

  users.sort((a, b) => {
    const one = a.email.toLowerCase()
    const two = b.email.toLowerCase()
    return one < two ? -1 : one > two ? 1 : 0
  })

  return (
    <div>
      <TeamNavBar />
      <div className='auto'>
        <Routes>
          <Route
            index
            element={
              <MyTeamView
                sendInviteEmail={sendInviteEmail}
                users={users}
                teamID={props.teamID}
                removeMember={removeMember}
                saveAuthType={saveAuthType}
                handleRoleChange={handleRoleChange}
                isLoading={loadingUsers}
              />
            }
          />
          <Route
            path='settings'
            element={
              <TeamSettings
                team={props.team}
                modifyTeamSettings={props.modifyTeamSettings}
                teamID={props.teamID}
                numberOfCustomers={props.numberOfCustomers}
                parents={props.parents}
              />
            }
          />
          <Route path='monitoring' element={<TeamMonitoring teamID={props.teamID} />} />
          <Route path='*' element={<Navigate to='.' />} />
        </Routes>
      </div>
    </div>
  )
}

export default MyTeamContainer
