import React, { FunctionComponent, useEffect } from 'react'
import { HostData, HostStatus } from '../Host/Data'
import { useTypedSelector } from '../../redux/reducers'
import { Host } from './Host'
import { getHostHealthSortValue, getHostStatus } from '../../redux/selectors'
import { DisconnectedIcon } from '../Icons/Icons'

interface Props {
  hosts: HostData[]
  teamID: string
}

export const StatusBoard: FunctionComponent<Props> = props => {
  const filteredHosts = useTypedSelector(s =>
    s.hosts.filter(h => {
      const hostStatus = getHostStatus(h.id)(s)
      return hostStatus !== HostStatus.Unlinked && hostStatus !== HostStatus.MonitoringDisabled
    })
  )
  const healthSortValueMap = useTypedSelector(s => new Map(s.hosts.map(h => [h.id, getHostHealthSortValue(h.id)(s)])))

  useEffect(() => {
    const descendingSortMultiplier = -1
    const nameSort = (a: HostData, b: HostData) => a.name.localeCompare(b.name) * descendingSortMultiplier
    props.hosts.sort((a: HostData, b: HostData) => {
      const systemHealthA = healthSortValueMap.get(a.id) ?? -999
      const systemHealthB = healthSortValueMap.get(b.id) ?? -999
      const diff = (systemHealthA - systemHealthB) * descendingSortMultiplier
      return diff === 0 ? nameSort(a, b) : diff
    })
  }, [props.hosts, healthSortValueMap])

  if (filteredHosts.length > 0) {
    return (
      <div className='list grid'>
        <ul>
          {props.hosts
            .filter(h => filteredHosts.find(f => f.id === h.id))
            .map(host => (
              <Host data={host} key={host.id} />
            ))}
        </ul>
      </div>
    )
  }

  return (
    <div className='empty'>
      <div>
        <i className='health disconnected icon'>
          <DisconnectedIcon />
        </i>
      </div>
      <h5>Looks like you don't have any monitored customers</h5>
      <p>You may want to consider inviting or connecting some customers to get going with the Status board!</p>
    </div>
  )
}
