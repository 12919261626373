import React, { FunctionComponent, useState } from 'react'
import { ServerIcon } from '../Icons/Icons'
import { HealthMetricStatus, InfrastructureData } from './Data'
import HealthMetricStatusIcon from './HealthMetricStatusIcon'

interface Props {
  data: InfrastructureData
  onExpand: () => void
  onShrink: () => void
}

export const Infrastructure: FunctionComponent<Props> = props => {
  const [expanded, setExpanded] = useState(false)

  const formatMemory = (mb: number): string => {
    return Math.round(mb / 10) / 100 + ' GB'
  }

  const formatPercent = (percentage?: number): string => {
    if (percentage == null) {
      return 'Unknown'
    }

    return percentage.toFixed(2) + '%'
  }

  const getHighlight = (status?: HealthMetricStatus): string => {
    if (status === HealthMetricStatus.Critical) {
      return 'level-error'
    }

    if (status === HealthMetricStatus.Warning) {
      return 'level-warning'
    }

    return ''
  }

  const onExpandButtonClicked = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    if (expanded) {
      props.onShrink()
    } else {
      props.onExpand()
    }
    setExpanded(!expanded)
  }

  let cpuHighlight = getHighlight(props.data.cpu.status)
  let isMetricAppServerCPU = props.data.cpu.metricName === 'appServerCPU' || props.data.cpu.metricName === 'cpu'
  let isMetricSystemCPU = props.data.cpu.metricName === 'systemCPU' || props.data.cpu.metricName === 'cpu'
  let dbInfo = props.data.database.version !== '' ? props.data.database.version.substring(0, 35) : props.data.database.type
  let dbHighlight = getHighlight(props.data.database.status)
  let isMetricDBConnections = props.data.database.metricName === 'databaseConnections' || props.data.database.metricName === 'database'
  let isMetricDBResponseTime = props.data.database.metricName === 'databaseResponseTime' || props.data.database.metricName === 'database'
  let diskHighlight = getHighlight(props.data.diskSpace.status)
  let memHighlight = getHighlight(props.data.memory.status)

  return (
    <div className={`col third ${expanded ? 'open-card' : ''}`}>
      <div className='card'>
        <header>
          <a href='#' className='expand' onClick={onExpandButtonClicked}>
            Expand
            <span className='tip'> Expand health metric to get all details </span>
          </a>
          <i className='icon'>
            <ServerIcon />
          </i>
          <h6>Infrastructure</h6>
        </header>
        <div className='compact'>
          <div className={`set ${dbHighlight}`}>
            <h5>
              <span>Database</span>
            </h5>
            <div className='status'>
              <HealthMetricStatusIcon status={props.data.database.status} details={props.data.database.details} thresholdMessages={props.data.database.thresholdMessages} />
            </div>
          </div>
          <div className={`set alt ${diskHighlight}`}>
            <h5>
              <span>Disk space</span>
            </h5>
            <div className='status'>
              <HealthMetricStatusIcon status={props.data.diskSpace.status} details={props.data.diskSpace.details} thresholdMessages={props.data.diskSpace.thresholdMessages} />
            </div>
          </div>
          <div className={`set ${cpuHighlight}`}>
            <h5>
              <span>CPU</span>
            </h5>
            <div className='status'>
              <HealthMetricStatusIcon status={props.data.cpu.status} details={props.data.cpu.details} thresholdMessages={props.data.cpu.thresholdMessages} />
            </div>
          </div>
          <div className={`set alt ${memHighlight}`}>
            <h5>Application memory</h5>
            <div className='status'>
              <HealthMetricStatusIcon status={props.data.memory.status} details={props.data.memory.details} thresholdMessages={props.data.memory.thresholdMessages} />
            </div>
          </div>
        </div>
        <div className='expanded'>
          <div className='set'>
            <h5>
              <span>Database</span>
              <small>{dbInfo}</small>
            </h5>
            <div className='stats'>
              <ul>
                <li>
                  <span className='label'>Max connections</span>

                  <span className='num'>{props.data.database.maxConnections}</span>
                </li>

                <li className={isMetricDBConnections ? dbHighlight : ''}>
                  <span className='label'>Connections used</span>

                  <span className='num'>{props.data.database.totalConnections}</span>
                </li>

                <li className={isMetricDBResponseTime ? dbHighlight : ''}>
                  <span className='label'>Response time</span>

                  <span className='num'>{props.data.database.timeToQueryMilliseconds} ms</span>
                </li>
              </ul>
            </div>
          </div>
          <div className='set alt'>
            <h5>
              <span>Disk space</span>
            </h5>
            <div className='stats'>
              <ul>
                <li>
                  <span className='label'>Total</span>

                  <span className='num'>{formatMemory(props.data.diskSpace.diskSpaceTotalMB)}</span>
                </li>

                <li className={props.data.diskSpace.metricName !== '' ? diskHighlight : ''}>
                  <span className='label'>Free</span>

                  <span className='num'>
                    {formatMemory(props.data.diskSpace.diskSpaceFreeMB)} ({formatPercent(100 - props.data.diskSpace.diskSpaceUsedPercentage)})
                  </span>
                </li>

                <li>
                  <span className='label'>Used</span>

                  <span className='num'>
                    {formatMemory(props.data.diskSpace.diskSpaceTotalMB - props.data.diskSpace.diskSpaceFreeMB)} ({formatPercent(props.data.diskSpace.diskSpaceUsedPercentage)})
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className='set'>
            <h5>
              <span>CPU</span> <small>{props.data.cpu.architecture}</small>
            </h5>
            <div className='stats'>
              <ul>
                <li>
                  <span className='label'>Cores/processors</span>

                  <span className='num'>{props.data.cpu.processors}</span>
                </li>

                <li className={isMetricSystemCPU ? cpuHighlight : ''}>
                  <span className='label'>System CPU</span>

                  <span className='num'>{formatPercent(props.data.cpu.systemCPU)}</span>
                </li>

                <li className={isMetricAppServerCPU ? cpuHighlight : ''}>
                  <span className='label'>Application Server CPU</span>

                  <span className='num'>{formatPercent(props.data.cpu.appServerCPU)}</span>
                </li>
              </ul>
            </div>
          </div>
          <div className='set alt'>
            <h5>Application memory</h5>
            <div className='stats'>
              <ul>
                <li>
                  <span className='label'>Max</span>

                  <span className='num'>{formatMemory(props.data.memory.jvmMemoryMaxMB)}</span>
                </li>

                <li>
                  <span className='label'>Allocated</span>

                  <span className='num'>{formatMemory(props.data.memory.jvmMemoryTotalMB)}</span>
                </li>

                <li className={props.data.memory.metricName !== '' ? memHighlight : ''}>
                  <span className='label'>Used</span>

                  <span className='num'>
                    {formatMemory(props.data.memory.jvmMemoryUsedMB)} ({formatPercent(props.data.memory.jvmMemoryUsedPercentage)})
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Infrastructure
