import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import { shallowEqual, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { getHostAccountDetails, getHostEvents } from '../../api/host'
import mfScreenshot from '../../assets/img/mf.jpg'
import { Features } from '../../features'
import { updateAccountDetails } from '../../redux/hosts'
import { useTypedSelector } from '../../redux/reducers'
import { getAccountDetails, getFeatureUsage, getHost, getNotificationPause, getSystemHealth, isDemo, isFeatureOn } from '../../redux/selectors'
import AuthRole from '../../role'
import { Timestamps } from '../../service/Timestamps'
import { AccessConfig, MfdData } from '../Access/Data'
import Account from '../Account'
import { MaintenanceAndSupportStatus, NFR, TRIAL } from '../Account/Data'
import AccountSummary from '../AccountSummary'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import EventLog from '../EventLog'
import EventLogSummary from '../EventLogSummary'
import FeatureUsage from '../FeatureUsage'
import { FeatureUsageLevel } from '../FeatureUsage/FeatureUsage'
import FeatureUsageSummary from '../FeatureUsageSummary'
import Hamburger from '../Hamburger'
import { DisconnectIcon, ExclaimIcon, MfdSmallIcon, NoBellIcon, RemoteManageIcon, RemoteWarningIcon } from '../Icons/Icons'
import PauseNotificationsModal from '../PauseNotificationsModal/PauseNotificationsModal'
import SystemHealth from '../SystemHealth'
import { HealthMetricStatus } from '../SystemHealth/Data'
import SystemHealthSummary from '../SystemHealthSummary'
import Toaster from '../Toaster'
import { ConnectionStatus, ConnectorDownloadURLs, EditCustomerFunction, HostData, HostDataUtil, HostEvent } from './Data'
import LinkHostModal from './LinkHostModal'
import SystemInfo from './SystemInfo'

interface Props {
  data: HostData
  teamID: string
  active: boolean
  deleteCustomer: (ID: string) => void
  editCustomer: EditCustomerFunction
  openAccessFrame: (config: AccessConfig) => void
}

const systemHealthPanelName = 'system-health'
const featureUsagePanelName = 'feature-usage'
const eventLogPanelName = 'event-log'
const accountPanelName = 'account'

const Host: FunctionComponent<Props> = props => {
  const demoMode = useTypedSelector(isDemo)
  const hostId = props.data.id
  const remoteMonitoringEnabled = useTypedSelector(state => getHost(hostId, state)?.hostConfig.remoteMonitoring ?? true)
  const [showLinkingModal, setShowLinkingModal] = useState(false)
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)
  const [showPauseNotificationsModal, setShowPauseNotificationsModal] = useState(false)
  const [activePanel, setActivePanel] = useState('')
  const accountDetails = useTypedSelector(getAccountDetails(hostId), shallowEqual)
  const systemHealth = useTypedSelector(getSystemHealth(hostId), shallowEqual)
  const featureUsage = useTypedSelector(getFeatureUsage(hostId), shallowEqual)
  const systemHealthDataAvailable = systemHealth !== undefined
  const customerIsLinked = systemHealthDataAvailable || !remoteMonitoringEnabled
  const usageDataAvailable = featureUsage !== undefined
  const accountDetailsAvailable = accountDetails !== undefined
  const [hostEvents, setHostEvents] = useState<HostEvent[]>([])
  const hasDevices = useTypedSelector(state => getHost(hostId, state)?.hostConfig.devices ?? false)
  const [downloadURLs, setDownloadURLs] = useState<ConnectorDownloadURLs>({
    windowsDownloadURL: '#',
    customerConnectorDownloadURL: '#',
    linuxDownloadURL: '#',
  })
  const notificationPauseFinishTime = useTypedSelector(getNotificationPause(hostId))?.finishesAt

  const connectionState = useTypedSelector(state => state.hosts.find(h => h.id === hostId)?.connectionStatus ?? ConnectionStatus.Live)
  const connectionLive = connectionState === ConnectionStatus.Live
  const connectionEnabled = connectionState !== ConnectionStatus.Disabled

  const systemInfo = useTypedSelector(
    state =>
      state.hosts.find(h => h.id === hostId)?.systemInfo ?? {
        product: props.data.product,
        version: '',
        osPlatform: '',
        embeddedCount: 0,
      },
    shallowEqual
  )
  const dispatch = useDispatch()

  const canRemoteManage = AuthRole.hasPermission('remoteManage')
  const isAccessEnabled = canRemoteManage && (connectionLive || (hasDevices && !connectionEnabled))

  const fetchAccountDetails = useCallback(
    (forHostID: string, signal: AbortSignal) => {
      getHostAccountDetails(props.teamID, forHostID, signal).then(accountDetailsResponse => dispatch(updateAccountDetails(forHostID, accountDetailsResponse)))
    },
    [props.teamID, dispatch]
  )

  const getMfds = async () => {
    const mfds: MfdData = { list: [] }
    await axios
      .get<any>('/api/teams/' + props.teamID + '/hosts/' + hostId + '/devices')
      .then(resp => {
        resp.data.forEach((dev: any) => {
          mfds.list.push({
            name: dev.deviceName,
            subName: dev.deviceType,
            url: dev.remoteURL,
          })
        })
        if (mfds.list.length === 0) {
          mfds.note = 'To add MFDs, work with your customer to configure the PaperCut Multiverse Connector.'
        }
      })
      .catch(_ => Toaster.notifyFailure('Unable to load MFD list.'))

    return mfds
  }

  const getHostEventsCallback = useCallback(() => {
    getHostEvents(props.teamID, hostId).then(events => setHostEvents(events))
  }, [props.teamID, hostId])

  useEffect(() => {
    const randomIntervalSeconds = (min: number, max: number) => {
      return 1000 * randomInt(min, max)
    }

    const randomInt = (min: number, max: number) => {
      return Math.floor(Math.random() * (max - min + 1)) + min
    }

    const controller = new AbortController()

    if (activePanel === eventLogPanelName) {
      getHostEventsCallback()
      const hostEventTimer = setInterval(() => getHostEventsCallback(), randomIntervalSeconds(50, 70))

      return () => {
        clearInterval(hostEventTimer)
      }
    } else if (activePanel === accountPanelName) {
      fetchAccountDetails(hostId, controller.signal)
      const accountTimer = setInterval(() => fetchAccountDetails(hostId, controller.signal), randomIntervalSeconds(120, 240))

      return () => {
        controller.abort()
        clearInterval(accountTimer)
      }
    }
  }, [activePanel, hostId, fetchAccountDetails, getHostEventsCallback])

  useEffect(() => {
    if (showLinkingModal && !demoMode) {
      axios.get<ConnectorDownloadURLs>('/api/teams/' + props.teamID + '/customers/' + hostId + '/download-link').then(resp => setDownloadURLs(resp.data))
    }
  }, [props.teamID, hostId, showLinkingModal, demoMode])

  const onPanelClicked = (panelName: string) => {
    if (activePanel === panelName) {
      setActivePanel('')
      return
    }

    setActivePanel(panelName)
  }

  const onPanelClosed = () => {
    setActivePanel('')
  }

  const getRemoteIcons = () => {
    switch (connectionState) {
      case ConnectionStatus.Live:
        return (
          <>
            <i className='icon'>
              <RemoteManageIcon />
            </i>

            {hasDevices && (
              <i className='icon'>
                <MfdSmallIcon />
              </i>
            )}
          </>
        )
      case ConnectionStatus.Disconnected:
        return (
          <i className='icon'>
            <RemoteWarningIcon />

            <span className='tip'>PaperCut Multiverse is unable to connect to the customer environment. Work with your customer to troubleshoot this issue.</span>
          </i>
        )
      case ConnectionStatus.Disabled:
        return (
          <>
            <i className='icon'>
              <RemoteWarningIcon />

              <span className='tip'>This customer has disabled access to the PaperCut admin console. Contact the customer to enable access.</span>
            </i>

            {hasDevices && (
              <i className='icon'>
                <MfdSmallIcon />
              </i>
            )}
          </>
        )
    }
  }

  const getRemoteText = () => {
    switch (connectionState) {
      case ConnectionStatus.Live:
        return <span>Remote manage</span>
      case ConnectionStatus.Disconnected:
        return (
          <span>
            Remote manage<small>Currently unavailable</small>
          </span>
        )
      case ConnectionStatus.Disabled:
        if (hasDevices) {
          return (
            <span>
              Remote manage<small>MFD only</small>
            </span>
          )
        }

        return (
          <span>
            Remote manage<small>Disabled by customer</small>
          </span>
        )
    }
  }

  const getRemoteButton = () => {
    if (!customerIsLinked) {
      return (
        <a className='btn secondary disabled'>
          <i className='icon'>
            <DisconnectIcon />
          </i>
          <span>
            Not connected<small>Customer not linked</small>
          </span>
        </a>
      )
    }

    if (!canRemoteManage) {
      return (
        <a className='btn secondary disabled'>
          <i className='icon'>
            <RemoteWarningIcon />
            <span className='tip'>To gain access to Remote Management, contact your Admin.</span>
          </i>
          <span>
            Remote manage<small>Not available</small>
          </span>
        </a>
      )
    }

    return (
      <a className={`btn secondary ${isAccessEnabled ? '' : 'disabled'}`} onClick={openAccess}>
        {getRemoteIcons()}
        {getRemoteText()}
      </a>
    )
  }

  const getRemoteImage = () => {
    let className = 'locked'
    if (connectionLive) {
      className = ''
    } else if (customerIsLinked && connectionEnabled && canRemoteManage) {
      className = 'warning'
    }

    return (
      <figure className={className}>
        <img src={mfScreenshot} alt='MF' />
      </figure>
    )
  }

  const getAccessStatus = () => {
    if (!connectionEnabled) {
      return 'disabled'
    }

    if (connectionState === ConnectionStatus.Disconnected) {
      return 'unavailable'
    }

    return ''
  }

  const openAccess = () => {
    const config: AccessConfig = { hostID: hostId, hostName: props.data.name, components: [] }

    config.components.push({
      name: HostDataUtil.getProductText(systemInfo.product || props.data.product),
      subName: HostDataUtil.getVersion(systemInfo, accountDetails),
      disabledStatus: getAccessStatus(),
      url: props.data.remoteManageLink,
    })

    if (hasCurrentMaintenanceAndSupport()) {
      config.getMfds = getMfds
    } else {
      config.getMfds = () => {
        return new Promise<MfdData>((resolve, _) => {
          resolve({
            list: [],
            note: 'PaperCut Maintenance & Support is required for this customer',
          })
        })
      }
    }

    props.openAccessFrame(config)
  }

  function hasCurrentMaintenanceAndSupport() {
    return accountDetails?.maintenanceAndSupportStatus !== MaintenanceAndSupportStatus.Expired
  }

  const isStaleData = systemHealth ? moment(systemHealth.observedTime).isBefore(moment().subtract(20, 'minutes')) : false
  const maintenanceAndSupportStatus = accountDetails?.maintenanceAndSupportStatus ?? MaintenanceAndSupportStatus.None
  let badge = maintenanceAndSupportStatus !== MaintenanceAndSupportStatus.None ? maintenanceAndSupportStatus : ''
  if (accountDetails?.licenseType === NFR || accountDetails?.licenseType === TRIAL) {
    badge = accountDetails?.licenseType
  }

  const shorterPausesEnabled = useTypedSelector(isFeatureOn(Features.NotificationPauseTesting))

  return (
    <article id={hostId} className={`customer ${customerIsLinked ? '' : 'pending fwd'}`}>
      {showLinkingModal && <LinkHostModal downloadURL={downloadURLs} customerName={props.data.name} isCustomerLinked={false} close={() => setShowLinkingModal(false)} />}

      {showDeleteConfirmationModal && (
        <ConfirmationModal
          title={`Remove ${props.data.name}`}
          message={`Do you really want to remove customer ${props.data.name}?`}
          yesLabel='Remove customer'
          onClose={() => setShowDeleteConfirmationModal(false)}
          onYes={() => props.deleteCustomer(hostId)}
        />
      )}

      <div className='flex flex-nowrap fp'>
        {/* The remote management section */}
        <div className='remote'>
          <div className='window'>
            {getRemoteImage()}

            {getRemoteButton()}
          </div>
        </div>

        <header>
          <div className='specs'>
            <h4>
              {props.data.name}
              {notificationPauseFinishTime && (
                <a href='#' onClick={() => setShowPauseNotificationsModal(true)}>
                  <i className='icon twentyfour no-bell'>
                    <span className='tip center'>Notifications paused until: {Timestamps.format(notificationPauseFinishTime)}</span>

                    <NoBellIcon />
                  </i>
                </a>
              )}
            </h4>

            <SystemInfo teamID={props.teamID} data={systemInfo} host={props.data} editCustomer={props.editCustomer} account={accountDetails} />

            {AuthRole.hasPermission('manageCustomer') && (
              <Hamburger>
                <ul>
                  <li className='notify'>
                    <a onClick={() => setShowPauseNotificationsModal(true)}>Notifications - {notificationPauseFinishTime ? 'Paused' : 'On'}</a>
                  </li>

                  <li className='trash'>
                    <a onClick={() => setShowDeleteConfirmationModal(true)}>Remove customer</a>
                  </li>
                </ul>
              </Hamburger>
            )}
          </div>

          {!remoteMonitoringEnabled && (
            <div className='alert info flex-ver'>
              <div className='message'>The customer has disabled remote monitoring.</div>
            </div>
          )}
          {remoteMonitoringEnabled && isStaleData && (
            <div className='alert error flex-ver'>
              <i className='icon-exclaim'>
                <ExclaimIcon />
              </i>

              <div className='message'>PaperCut Multiverse is having trouble connecting to this customer environment. Check that the Connector is running as expected.</div>
            </div>
          )}

          {!customerIsLinked && (
            <div className='link-customer'>
              <div className='link-1'>
                <p>
                  Link to your customer's PaperCut environment here. If your customer needs more info, you can find downloadable PDFs in the{' '}
                  <Link to='/info-center'>Info Center</Link>.
                </p>

                <button className={'btn link heartbeat'} onClick={() => setShowLinkingModal(true)}>
                  Link customer
                </button>
              </div>
            </div>
          )}

          <div className={'tabs article-nav'}>
            <ul>
              <AccountSummary onClick={() => onPanelClicked(accountPanelName)} isOpen={activePanel === accountPanelName} dataAvailable={accountDetailsAvailable} badge={badge} />

              <SystemHealthSummary
                dataAvailable={systemHealthDataAvailable}
                status={systemHealth?.status ?? HealthMetricStatus.Good}
                isStaleData={isStaleData}
                onClick={() => onPanelClicked(systemHealthPanelName)}
                hasMaintenanceAndSupport={hasCurrentMaintenanceAndSupport()}
                isOpen={activePanel === systemHealthPanelName}
                remoteMonitoringEnabled={remoteMonitoringEnabled}
              />

              <FeatureUsageSummary
                onClick={() => onPanelClicked(featureUsagePanelName)}
                isOpen={activePanel === featureUsagePanelName}
                dataAvailable={usageDataAvailable}
                level={featureUsage?.level ?? FeatureUsageLevel.Basic}
                remoteMonitoringEnabled={remoteMonitoringEnabled}
              />

              <EventLogSummary onClick={() => onPanelClicked(eventLogPanelName)} isOpen={activePanel === eventLogPanelName} />
            </ul>
          </div>
        </header>
      </div>

      {activePanel === accountPanelName && accountDetails && (
        <Account
          teamID={props.teamID}
          account={accountDetails}
          hostData={props.data}
          onClose={onPanelClosed}
          editCustomer={props.editCustomer}
          upgradeRequired={HostDataUtil.newVersionRequired(HostDataUtil.getVersion(systemInfo, accountDetails))}
          remoteEnabled={connectionEnabled}
          remoteMonitoringEnabled={remoteMonitoringEnabled}
        />
      )}

      {activePanel === systemHealthPanelName && systemHealth && (
        <SystemHealth systemHealth={systemHealth} onClose={onPanelClosed} hasMaintenanceAndSupport={hasCurrentMaintenanceAndSupport()} />
      )}

      {activePanel === featureUsagePanelName && <FeatureUsage featureUsage={featureUsage} upgradeRequired={!usageDataAvailable} onClose={onPanelClosed} />}

      {activePanel === eventLogPanelName && <EventLog teamID={props.teamID} hostEvents={hostEvents} onClose={onPanelClosed} />}

      <PauseNotificationsModal
        close={() => setShowPauseNotificationsModal(false)}
        host={props.data}
        isVisible={showPauseNotificationsModal}
        finishTime={notificationPauseFinishTime}
        teamID={props.teamID}
        testing={shorterPausesEnabled}
      />
    </article>
  )
}

export default Host
