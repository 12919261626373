import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type FeaturesState = {
  [name: string]: boolean
}

const featuresSlice = createSlice({
  name: 'features',
  initialState: {} as FeaturesState,
  reducers: {
    setFeatureEnabled: {
      reducer: (state, action: PayloadAction<{ feature: string; enabled: boolean }>) => {
        state[action.payload.feature] = action.payload.enabled
      },
      prepare: (feature: string, enabled: boolean) => {
        return {
          payload: { feature, enabled },
        }
      },
    },
  },
})

export const { setFeatureEnabled } = featuresSlice.actions
export default featuresSlice.reducer
