import React, { FunctionComponent, useState } from 'react'
import { DocumentSvg } from '../Icons/Icons'

export enum Language {
  English = 'en',
  Catalan = 'ca',
  ChineseInChina = 'zh-CN',
  ChineseInHongKong = 'zh-HK',
  Danish = 'da',
  Dutch = 'nl',
  French = 'fr',
  German = 'de',
  Hungarian = 'hu',
  Italian = 'it',
  Japanese = 'ja',
  Polish = 'pl',
  PortugueseInPortugal = 'pt-PT',
  Russian = 'ru',
  Slovak = 'sk',
  SpanishInSpain = 'es-ES',
  Swedish = 'sv',
  Thai = 'th',
  Turkish = 'tr',
}

interface LanguageDisplay {
  lang: Language
  display: string
}

const languages: LanguageDisplay[] = [
  { lang: Language.English, display: 'English' },
  { lang: Language.Catalan, display: 'català' },
  { lang: Language.Danish, display: 'dansk' },
  { lang: Language.German, display: 'Deutsch' },
  { lang: Language.SpanishInSpain, display: 'español (España)' },
  { lang: Language.French, display: 'français' },
  { lang: Language.Hungarian, display: 'magyar' },
  { lang: Language.Italian, display: 'italiano' },
  { lang: Language.Japanese, display: '日本語' },
  { lang: Language.Dutch, display: 'Nederlands' },
  { lang: Language.Polish, display: 'polski' },
  { lang: Language.PortugueseInPortugal, display: 'português (Portugal)' },
  { lang: Language.Russian, display: 'русский' },
  { lang: Language.Slovak, display: 'slovenčina' },
  { lang: Language.Swedish, display: 'svenska' },
  { lang: Language.Thai, display: 'ไทย' },
  { lang: Language.Turkish, display: 'Türkçe' },
  { lang: Language.ChineseInChina, display: '中文 (中国)' },
  { lang: Language.ChineseInHongKong, display: '中文 (香港)' },
]

interface Props {
  title: string
  type: string
  description: string
  linkText: string
  id?: string
  urls?: Map<Language, string>
}

export const Document: FunctionComponent<Props> = props => {
  const [isLangOpen, setLangOpen] = useState(false)

  const isLangSingle = props.urls?.size === 1

  const LangLink: FunctionComponent<LanguageDisplay> = item => {
    const url = props.urls?.get(item.lang)
    if (!url) {
      return <></>
    }

    return (
      <li>
        <a id={isLangSingle || !props.id ? props.id : props.id + '-' + item.lang} href={url} target='_blank' rel='noopener noreferrer'>
          {item.display}
        </a>
      </li>
    )
  }

  return (
    <li>
      <div className={`lang ${isLangSingle ? 'single' : ''} ${isLangOpen ? 'open-lang' : ''}`} style={{ overflow: 'hidden' }}>
        <h6 onClick={() => setLangOpen(!isLangOpen)}>{props.linkText}</h6>
        <ul>
          {languages.map(item => (
            <LangLink key={item.lang} lang={item.lang} display={item.display} />
          ))}
        </ul>
      </div>

      <div className='row row-link' data-type={props.type.toLowerCase()}>
        <h6>{props.type}</h6>
        <h4>{props.title}</h4>
        <p>{props.description}</p>
        <DocumentSvg />
      </div>
    </li>
  )
}
