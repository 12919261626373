import React, { FunctionComponent } from 'react'

interface Props {
  on?: number
  off?: number
}

export const OnOffMeter: FunctionComponent<Props> = ({ on = 0, off = 0 }) => {
  const onPercentage = !on && !off ? 0 : (on / (on + off)) * 100
  const offPercentage = !on && !off ? 0 : (off / (on + off)) * 100
  return (
    <figure className='progress alt'>
      <span className='fill' data-width={onPercentage} style={{ width: onPercentage + '%' }} />
      <span className='fill error' data-width={offPercentage} style={{ width: offPercentage + '%' }} />
    </figure>
  )
}
