import { Permission } from '../auth/AuthHandler'

class AuthRole {
  static userPermissions: string[]

  static setPermissions(permissions: Permission[]) {
    this.userPermissions = permissions
  }

  static hasPermission(permission: Permission): boolean {
    return this.userPermissions.includes(permission)
  }
}
export default AuthRole
