import React, { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react'
import axios from 'axios'
import { EmailValidator } from '../../service/EmailValidator'
import { SmallCriticalHeartbeat, SmallGoodHeartbeat, SmallWarningHeartbeat } from '../AnimatedSvg/AnimatedSvg'
import { ExclaimIcon } from '../Icons/Icons'
import { LoadingDots } from '../LoadingDots/LoadingDots'
import Toaster from '../Toaster'

interface Props {
  teamID: string
  onThresholdsLinkClick: () => void
  demoMode: boolean
}

interface Settings {
  id: number
  name: string
  // to be deprecated
  overall: boolean
  overallCritical: boolean
  overallNormal: boolean
  overallWarning: boolean
  cpu: boolean
  database: boolean
  diskspace: boolean
  memory: boolean
  devices: boolean
  printProviders: boolean
  mobilityPrintServers: boolean
  siteServers: boolean
  recipients: string[]
}

interface List {
  profiles: Settings[]
}

function parseEmails(emailsString: string): string[] {
  const emails = new Set<string>()
  emailsString
    .split(/,\s*|\n/)
    .filter(email => email.trim().length > 0)
    .forEach(email => emails.add(email))
  return Array.from(emails.keys())
}

export const HealthNotificationSettings: FunctionComponent<Props> = ({ teamID, onThresholdsLinkClick, demoMode }) => {
  const [loaded, setLoaded] = useState(false)
  const [recipientString, setRecipientString] = useState('')
  const [invalidEmails, setInvalidEmails] = useState([] as string[])
  const [cpuSettings, setCpuSettings] = useState(false)
  const [databaseSettings, setDatabaseSettings] = useState(false)
  const [diskspaceSettings, setDiskspaceSettings] = useState(false)
  const [memorySettings, setMemorySettings] = useState(false)
  const [devicesSettings, setDevicesSettings] = useState(false)
  const [mobilityPrintServersSettings, setMobilityPrintServersSettings] = useState(false)
  const [printProvidersSettings, setPrintProviderSettings] = useState(false)
  const [siteServersSettings, setSiteServersSettings] = useState(false)
  const [overallSelected, setOverallSelected] = useState(false)
  const [settings, setSettings] = useState({
    id: 0,
    name: '',
    overall: false,
    overallCritical: false,
    overallNormal: false,
    overallWarning: false,
    recipients: [] as string[],
  })

  const getSettings = (forTeamID: string) => {
    return axios
      .get<List>('/api/teams/' + forTeamID + '/settings/notifications/health')
      .then(result => {
        const settingsResponse = result.data.profiles[0]
        setSettings({
          id: settingsResponse.id,
          name: settingsResponse.name,
          overall: settingsResponse.overall,
          overallCritical: settingsResponse.overallCritical,
          overallNormal: settingsResponse.overallNormal,
          overallWarning: settingsResponse.overallWarning,
          recipients: settingsResponse.recipients,
        })
        const allEnabled = settingsResponse.overall
        if (allEnabled) setOverallSelected(allEnabled)
        setCpuSettings(settingsResponse.cpu || allEnabled)
        setDatabaseSettings(settingsResponse.database || allEnabled)
        setDiskspaceSettings(settingsResponse.diskspace || allEnabled)
        setMemorySettings(settingsResponse.memory || allEnabled)
        setDevicesSettings(settingsResponse.devices || allEnabled)
        setMobilityPrintServersSettings(settingsResponse.mobilityPrintServers || allEnabled)
        setPrintProviderSettings(settingsResponse.printProviders || allEnabled)
        setSiteServersSettings(settingsResponse.siteServers || allEnabled)
        setRecipientString(settingsResponse.recipients.join(', '))
      })
      .catch(_ => {
        Toaster.notifyFailure('Unable to load health notification settings.')
      })
  }

  useEffect(
    () =>
      setOverallSelected(
        [cpuSettings, databaseSettings, diskspaceSettings, memorySettings, devicesSettings, mobilityPrintServersSettings, printProvidersSettings, siteServersSettings].every(
          bool => bool
        )
      ),
    [cpuSettings, databaseSettings, diskspaceSettings, memorySettings, devicesSettings, mobilityPrintServersSettings, printProvidersSettings, siteServersSettings]
  )

  const saveSettings = () => {
    if (!valid()) {
      return
    }

    const newSettings: Settings = {
      ...settings,
      overall: overallSelected,
      recipients: parseEmails(recipientString),
      cpu: cpuSettings,
      database: databaseSettings,
      diskspace: diskspaceSettings,
      memory: memorySettings,
      devices: devicesSettings,
      mobilityPrintServers: mobilityPrintServersSettings,
      printProviders: printProvidersSettings,
      siteServers: siteServersSettings,
    }
    axios
      .put('/api/teams/' + teamID + '/settings/notifications/health/' + settings.id, newSettings)
      .then(_ => Toaster.notifySuccess('Changes saved.'))
      .catch(_ => Toaster.notifyFailure('Unable to save changes.'))
  }

  const valid = () => {
    let invalidatedEmails = [] as string[]
    parseEmails(recipientString).forEach(email => {
      if (!EmailValidator.validate(email)) {
        invalidatedEmails.push(email)
      }
    })
    setInvalidEmails(invalidatedEmails)

    return invalidatedEmails.length === 0
  }

  useEffect(() => {
    getSettings(teamID).then(_ => setLoaded(true))
  }, [teamID])

  const healthParameter = (name: string, id: string, setting: boolean, setFunc: Dispatch<SetStateAction<boolean>>) => {
    const [criticalId, warningId, normalId] = [id + 'Critical', id + 'Warning', id + 'Normal']
    return (
      <div className='row flex'>
        <div className='col forty row-header'>
          <label className='inl' htmlFor={id}>
            <input type='checkbox' id={id} checked={setting} onChange={() => setFunc(!setting)} />
            {name}
          </label>
        </div>

        <div className='col fifth center'>
          <label htmlFor={criticalId} className='disabled'>
            <input type='checkbox' id={criticalId} checked={setting && settings.overallCritical} readOnly aria-label={`${name} critical`} />
          </label>
        </div>

        <div className='col fifth center'>
          <label htmlFor={warningId} className='disabled'>
            <input type='checkbox' id={warningId} checked={setting && settings.overallWarning} readOnly aria-label={`${name} warning`} />
          </label>
        </div>

        <div className='col fifth center'>
          <label htmlFor={normalId} className='disabled'>
            <input type='checkbox' id={normalId} checked={setting && settings.overallNormal} readOnly aria-label={`${name} back to normal`} />
          </label>
        </div>
      </div>
    )
  }

  const handleOverall = () => {
    const enabled = !overallSelected
    setCpuSettings(enabled)
    setDatabaseSettings(enabled)
    setDevicesSettings(enabled)
    setDiskspaceSettings(enabled)
    setMobilityPrintServersSettings(enabled)
    setMemorySettings(enabled)
    setPrintProviderSettings(enabled)
    setSiteServersSettings(enabled)
  }

  return (
    <div className='row flex'>
      <div className='col forty'>
        <h4>Notifications</h4>
        <h5>System health</h5>
        <p>
          You will receive critical or warning notifications if the defined{' '}
          <a onClick={onThresholdsLinkClick} style={{ cursor: 'pointer' }}>
            thresholds
          </a>{' '}
          for selected events are exceeded for 10 minutes. You will not receive another alert while an outstanding alert condition remains.
        </p>
        <p>Choose the health parameters you wish to be notified about as well as the health statuses you are interested in.</p>
      </div>

      {!loaded && (
        <div className='col sixty'>
          <div className='empty loading'>
            <LoadingDots />
          </div>
        </div>
      )}
      {loaded && (
        <div className='col sixty'>
          <div className='list'>
            <div className='surface'>
              {invalidEmails.length !== 0 && (
                <div className='alert error'>
                  <i className='icon-exclaim'>
                    <ExclaimIcon />
                  </i>

                  <div className='message' data-heap-redact-text>
                    Invalid email addresses: {invalidEmails.join(', ')}
                  </div>
                </div>
              )}

              <div className='field'>
                <div className='row flex'>
                  <div className='col forty freq'>
                    <label>What type of notifications should be sent?</label>
                  </div>

                  <div className='col fifth center'>
                    <i className='health error icon'>
                      <SmallCriticalHeartbeat />
                    </i>
                    <h5>Critical</h5>
                  </div>

                  <div className='col fifth center'>
                    <i className='health warning icon'>
                      <SmallWarningHeartbeat />
                    </i>
                    <h5>Warning</h5>
                  </div>

                  <div className='col fifth center'>
                    <i className='health icon'>
                      <SmallGoodHeartbeat />
                    </i>
                    <h5>Back to normal</h5>
                  </div>
                </div>

                <div className='row flex'>
                  <div className='col forty'>
                    <label className='inl highlight' htmlFor='overall'>
                      <input type='checkbox' id='overall' checked={overallSelected} onChange={handleOverall} />
                      All parameters
                    </label>
                  </div>

                  <div className='col fifth center'>
                    <input
                      type='checkbox'
                      id='overallCritical'
                      checked={settings.overallCritical}
                      onChange={() =>
                        setSettings({
                          ...settings,
                          overallCritical: !settings.overallCritical,
                        })
                      }
                      aria-label='All parameters critical'
                    />
                  </div>

                  <div className='col fifth center'>
                    <input
                      type='checkbox'
                      id='overallWarning'
                      checked={settings.overallWarning}
                      onChange={() =>
                        setSettings({
                          ...settings,
                          overallWarning: !settings.overallWarning,
                        })
                      }
                      aria-label='All parameters warning'
                    />
                  </div>

                  <div className='col fifth center'>
                    <input
                      type='checkbox'
                      id='overallNormal'
                      checked={settings.overallNormal}
                      onChange={() =>
                        setSettings({
                          ...settings,
                          overallNormal: !settings.overallNormal,
                        })
                      }
                      aria-label='All parameters back to normal'
                    />
                  </div>
                </div>

                <hr className='header-divider' />

                {healthParameter('Database', 'database', databaseSettings, setDatabaseSettings)}
                {healthParameter('Disk space', 'diskspace', diskspaceSettings, setDiskspaceSettings)}
                {healthParameter('CPU', 'cpu', cpuSettings, setCpuSettings)}
                {healthParameter('Memory', 'memory', memorySettings, setMemorySettings)}

                {healthParameter('Print providers', 'printProviders', printProvidersSettings, setPrintProviderSettings)}
                {healthParameter('Site servers', 'siteServers', siteServersSettings, setSiteServersSettings)}
                {healthParameter('BYOD/Mobility Print servers', 'mobilityPrint', mobilityPrintServersSettings, setMobilityPrintServersSettings)}
                {healthParameter('Devices', 'devices', devicesSettings, setDevicesSettings)}
              </div>

              <div className={invalidEmails.length === 0 ? 'field' : 'field error'}>
                <div>
                  <label>Who should get notified?</label>
                </div>

                <textarea placeholder='email@example.com, email@subdomain.example.com' rows={6} value={recipientString} onChange={e => setRecipientString(e.target.value)} />

                <small className='blk'>
                  <span>Separate multiple addresses with commas or new lines.</span>
                </small>
              </div>

              <footer>
                <a
                  href='#'
                  className='btn'
                  onClick={e => {
                    e.preventDefault()
                    if (!demoMode) {
                      saveSettings()
                    }
                  }}
                >
                  Save changes
                </a>
              </footer>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
