import React, { ChangeEvent, FunctionComponent, useState } from 'react'
import axios from 'axios'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Link } from 'react-router-dom'
import { useTypedSelector } from '../../redux/reducers'
import { isDemo } from '../../redux/selectors'
import AuthRole from '../../role'
import { EmailValidator } from '../../service/EmailValidator'
import { Reseller, supportEmail } from '../ContactSupport/Data'
import { DeleteTeamModal } from '../DeleteTeamModal/DeleteTeamModal'
import { EncryptIcon, ExclaimIcon, InfoIcon, PasswordIcon } from '../Icons/Icons'
import { AuthType, getSupportId } from '../Team/Data'
import Toaster from '../Toaster'
import styles from './TeamSettings.module.css'

interface Props {
  team: TeamSettingsData
  teamID: string
  modifyTeamSettings: (newSettings: TeamSettingsData) => Promise<boolean>
  numberOfCustomers: number
  parents: Reseller[]
}

export interface TeamSettingsData {
  name: string
  publicName: string
  supportEmail: string
  colour?: string
  initials?: string
  defaultAuthType: AuthType
  resellerCode?: string
  demoMode?: boolean
}

const Colour = {
  green: '#33A451',
  blue: '#4383F2',
  yellow: '#F6BA06',
  red: '#E64336',
  purple: '#31346B',
}

export const getInitials = (name: string) => {
  return name
    .split(' ', 3)
    .map(s => s.charAt(0).toUpperCase())
    .join('')
}

export const TeamSettings: FunctionComponent<Props> = props => {
  const [busy, setBusy] = useState(false)
  const [publicName, setPublicName] = useState(props.team.publicName)
  const [useAltPublicName, setUseAltPublicName] = useState(props.team.publicName !== props.team.name)
  const [teamName, setTeamName] = useState(props.team.name)
  const [teamEmail, setTeamEmail] = useState(props.team.supportEmail)
  const [colour, setColour] = useState(props.team.colour || Colour.green)
  const [initials, setInitials] = useState(props.team.initials || getInitials(props.team.name))
  const [authType, setAuthType] = useState<AuthType>(props.team.defaultAuthType)
  const defaultCopyInfoLabel = 'Copy info'
  const [copyInfoLabel, setCopyInfoLabel] = useState(defaultCopyInfoLabel)
  const [errors, setErrors] = useState<string[]>([])
  const [showDeleteTeamModal, setShowDeleteTeamModal] = useState(false)
  const demoMode = useTypedSelector(isDemo)
  const blockDeleteTeam = props.numberOfCustomers > 0 && !demoMode
  const canDeleteTeam = AuthRole.hasPermission('deleteTeam')
  const canUpdateTeamSettings = AuthRole.hasPermission('updateTeamSettings')
  const infoSubject = `Request to update information for team ${getSupportId(props.teamID)}`

  const handleTeamName = (event: ChangeEvent<HTMLInputElement>) => {
    setTeamName(event.target.value)
  }

  const handlePublicNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPublicName(event.target.value)
  }

  const handleTeamEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTeamEmail(event.target.value)
  }

  const changeColour = (c: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    setColour(c)
  }

  const handleSaveTeamProfile = () => {
    const validationErrors: string[] = []
    if (teamName.trim() === '') {
      validationErrors.push('Enter a valid team name.')
    }
    if (useAltPublicName && publicName.trim() === '') {
      validationErrors.push('Enter a valid customer-facing team name.')
    }
    if (teamEmail && !EmailValidator.validate(teamEmail)) {
      validationErrors.push('Enter a valid email address.')
    }
    if (initials.trim() === '') {
      validationErrors.push('Enter initials.')
    }
    setErrors(validationErrors)
    if (validationErrors.length > 0) {
      return
    }

    setBusy(true)
    props
      .modifyTeamSettings({
        name: teamName.trim(),
        publicName: useAltPublicName ? publicName : teamName.trim(),
        supportEmail: teamEmail,
        colour: colour,
        initials: initials,
        defaultAuthType: props.team.defaultAuthType,
      })
      .then(_ => Toaster.notifySuccess('Team profile updated.'))
      .catch(_ => Toaster.notifyFailure('Unable to update team profile.'))
      .finally(() => setBusy(false))
  }

  const handleToggleUseAltPublicName = () => {
    setUseAltPublicName(!useAltPublicName)
  }

  const handleSaveTeamAuthType = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    setBusy(true)
    props
      .modifyTeamSettings({
        name: props.team.name,
        publicName: props.team.publicName,
        supportEmail: props.team.supportEmail,
        colour: props.team.colour,
        initials: props.team.initials,
        defaultAuthType: authType,
      })
      .then(_ => Toaster.notifySuccess('Default authentication updated.'))
      .catch(_ => Toaster.notifyFailure('Unable to update default authentication.'))
      .finally(() => setBusy(false))
  }

  function resetDemoData(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault()
    setBusy(true)
    deleteDemoData()
      .then(_ => {
        addDemoData()
          .then(__ => {
            Toaster.notifySuccess('Demo data reset.')
            setTimeout(() => window.location.replace('/'), 3000)
          })
          .finally(() => setBusy(false))
      })
      .catch(_ => {
        Toaster.notifyFailure('Unable to reset demo data.')
        setBusy(false)
      })
  }

  function addDemoData(): Promise<void> {
    return axios.post(`/api/teams/${props.teamID}/demo`, {})
  }

  function deleteDemoData(): Promise<void> {
    return axios.delete(`/api/teams/${props.teamID}/demo`, {})
  }

  const getInfoText = () => {
    const partners = props.parents.map(parent => parent.name)

    return (
      'Support/team ID: ' +
      getSupportId(props.teamID) +
      (props.team.resellerCode ? '\nReseller code: ' + props.team.resellerCode : '') +
      (partners.length > 0 ? '\nAuthorized Partner: ' + partners : '')
    )
  }

  return (
    <>
      <div className='tab'>
        <div className='row flex'>
          <div className='col forty'>
            <h4>Profile</h4>

            {canUpdateTeamSettings && <p>Change your company/team details.</p>}
            {!canUpdateTeamSettings && <p>Contact your Admin to update company/team details.</p>}
          </div>

          <div className='col sixty'>
            <div className='surface'>
              {errors.length > 0 && (
                <div className='field'>
                  {errors.map(error => (
                    <div key={error} className='alert error'>
                      <i className='icon-exclaim'>
                        <ExclaimIcon />
                      </i>
                      <div className='message'>{error}</div>
                    </div>
                  ))}
                </div>
              )}

              <div className='field'>
                <label>Team name</label>

                {canUpdateTeamSettings && (
                  <div className='flex flex-nowrap'>
                    <input type='text' value={teamName} onChange={handleTeamName} placeholder='Enter team name' name='teamNameInput' />
                  </div>
                )}
                {!canUpdateTeamSettings && <p data-heap-redact-text>{teamName}</p>}
              </div>

              <div className='field'>
                {canUpdateTeamSettings && (
                  <label className='inl'>
                    <input type='checkbox' checked={useAltPublicName} onChange={handleToggleUseAltPublicName} />
                    Use alternative customer facing team name
                  </label>
                )}

                {useAltPublicName && (
                  <>
                    <label>
                      Customer-facing team name{' '}
                      <i className='icon-info'>
                        <InfoIcon />
                        <span className='tip wide'>
                          <span className='blk'>
                            This will be used to identify you to customers, for example in the Connector settings page so they know who has access to their environment.
                          </span>
                        </span>
                      </i>
                    </label>

                    {canUpdateTeamSettings && (
                      <div className='flex flex-nowrap'>
                        <input type='text' value={publicName} onChange={handlePublicNameChange} placeholder='Enter customer facing team name' name='orgNameInput' />
                      </div>
                    )}
                    {!canUpdateTeamSettings && <p data-heap-redact-text>{publicName}</p>}
                  </>
                )}
              </div>

              <div className='field'>
                <label>Support email</label>

                {canUpdateTeamSettings && (
                  <div className='flex flex-nowrap'>
                    <input type='text' value={teamEmail} onChange={handleTeamEmailChange} placeholder='Enter support email' name='orgEmailInput' />
                  </div>
                )}
                {!canUpdateTeamSettings && <p data-heap-redact-text>{teamEmail || '-'}</p>}
              </div>

              {canUpdateTeamSettings && (
                <div className='field' data-set='team-avatar'>
                  <label>Team avatar</label>
                  <div className='flex row'>
                    <div className='col center preview'>
                      <i className='avatar' style={{ background: colour }}>
                        {initials}
                      </i>
                      <small className='blk'>Preview</small>
                    </div>

                    <div className='col flex-grow'>
                      <div className='fieldset'>
                        <div className='field'>
                          <label id='team-initial-label'>
                            Team initials <small>max 3 characters</small>
                          </label>

                          <input
                            type='text'
                            value={initials}
                            onChange={e => setInitials(e.target.value)}
                            maxLength={3}
                            className='trim'
                            name='team-initial'
                            aria-labelledby='team-initial-label'
                          />
                        </div>

                        <div>
                          <label>Background color</label>
                          <ul className='flex option-list bare' data-list='avatar'>
                            {Object.entries(Colour).map(([name, c]) => (
                              <li key={c} className={colour === c ? 'active' : ''}>
                                <a href='#' onClick={changeColour(c)} aria-label={name}>
                                  <i className='avatar' style={{ background: c }} />
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {canUpdateTeamSettings && (
                <div className='field rgt flex flex-nowrap'>
                  <button className={`${styles.save} btn ${busy ? 'disabled' : ''}`} onClick={handleSaveTeamProfile}>
                    Save changes
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {canUpdateTeamSettings && (
        <>
          <hr className='divider' />
          <div className='row flex'>
            <div className='col forty'>
              <h4>Default authentication</h4>

              <p>Change the default authentication method for new team members.</p>
            </div>

            <div className='col sixty'>
              <div className='surface'>
                <div className='field'>
                  <ul className='flex center option-list'>
                    <li className={`${authType === AuthType.Password ? 'active' : ''}`}>
                      <a
                        href='#'
                        onClick={e => {
                          e.preventDefault()
                          setAuthType(AuthType.Password)
                        }}
                      >
                        <i className='icon thirtytwo'>
                          <PasswordIcon />
                        </i>

                        <strong>Single-factor authentication</strong>

                        <small className='blk'>Standard username/password</small>
                      </a>
                    </li>

                    <li className={`${authType === AuthType.MFA ? 'active' : ''}`}>
                      <a
                        href='#'
                        onClick={e => {
                          e.preventDefault()
                          setAuthType(AuthType.MFA)
                        }}
                      >
                        <i className='icon thirtytwo'>
                          <EncryptIcon />
                        </i>

                        <strong>Two-factor authentication</strong>

                        <small className='blk'>Most secure option</small>
                      </a>
                    </li>
                  </ul>
                </div>

                <footer>
                  <a id='save' href='#' className={`btn ${busy ? 'disabled' : ''}`} onClick={handleSaveTeamAuthType}>
                    Save changes
                  </a>
                </footer>
              </div>
            </div>
          </div>
        </>
      )}

      <hr className='divider' />

      <div className='row flex'>
        <div className='col forty'>
          <h4>Team information</h4>

          <p>Team information that should be included in support requests.</p>

          {(props.team.resellerCode || props.parents.length > 0) && (
            <p>
              Is this information out of date? <a href={`mailto:${supportEmail}?subject=${infoSubject}`}>Let us know</a>.
            </p>
          )}
        </div>

        <div className='col sixty'>
          <div className='surface'>
            <div className='field'>
              <div className='field'>
                <label>Support/team ID</label>

                <p>{getSupportId(props.teamID)}</p>
              </div>

              {props.team.resellerCode && (
                <div className='field'>
                  <label>Reseller code</label>

                  <p data-heap-redact-text>{props.team.resellerCode}</p>
                </div>
              )}

              {props.parents.length > 0 && (
                <div className='field'>
                  <label>Authorized Partner</label>

                  {props.parents.map(parent => (
                    <p key={parent.id} data-heap-redact-text>
                      {parent.name}
                    </p>
                  ))}
                </div>
              )}
            </div>

            <footer>
              <CopyToClipboard
                text={getInfoText()}
                onCopy={() => {
                  setCopyInfoLabel('Copied!')
                  setTimeout(() => setCopyInfoLabel(defaultCopyInfoLabel), 2000)
                }}
              >
                <button className='btn outline'>{copyInfoLabel}</button>
              </CopyToClipboard>
            </footer>
          </div>
        </div>
      </div>

      {demoMode && canUpdateTeamSettings && (
        <>
          <hr className='divider' />
          <div className='row flex'>
            <div className='col forty'>
              <h4>Reset demo data</h4>
            </div>

            <div className='col sixty'>
              <div className='surface'>
                <div className='field'>
                  <label>Reset your demo data</label>

                  <p>
                    By resetting your demo data, all your customers will be deleted and be refreshed with the latest data set. This may include additional customers with data that
                    show new states, information and/or features.
                  </p>

                  <p>Once this operation completes, your browser will reload into the customer list.</p>
                </div>

                <footer>
                  <a href='#' className={`btn ${busy ? 'disabled' : ''}`} onClick={resetDemoData}>
                    Reset data
                  </a>
                </footer>
              </div>
            </div>
          </div>
        </>
      )}

      {canDeleteTeam && (
        <>
          <hr className='divider' />
          <div className='row flex'>
            <div className='col forty'>
              <h4>Delete team</h4>

              <p>Permanently delete this entire team.</p>
            </div>

            <div className='col sixty'>
              <div className='surface'>
                <div className='field'>
                  <p>You can't undo this action. When you delete a team, you can't get it back, so before you click the button be confident it's the right thing to do.</p>
                </div>

                {blockDeleteTeam && (
                  <div className='alert field'>
                    <div className='message'>
                      <p>Before you can delete the team, you need to remove all customers.</p>
                      <p>
                        To do this, go to{' '}
                        <Link to='/my-customers/list' aria-label='My customers'>
                          My customers
                        </Link>{' '}
                        and remove each customer through the kebab menu.
                      </p>
                    </div>
                  </div>
                )}

                <footer>
                  <a
                    href='#'
                    className={`btn ${blockDeleteTeam || busy ? 'disabled' : ''}`}
                    onClick={e => {
                      e.preventDefault()
                      setShowDeleteTeamModal(true)
                    }}
                  >
                    Delete team
                  </a>
                </footer>
              </div>
            </div>
          </div>
        </>
      )}

      {showDeleteTeamModal && <DeleteTeamModal teamID={props.teamID} onClose={() => setShowDeleteTeamModal(false)} />}
    </>
  )
}
