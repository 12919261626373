import { Dispatch, SetStateAction, useEffect, useState } from 'react'

export function useLocalStorageState<S>(name: string, defaultState: S): [S, Dispatch<SetStateAction<S>>] {
  const [state, setState] = useState(() => {
    let initialState
    try {
      initialState = JSON.parse(window.localStorage.getItem(name) || String(defaultState))
    } catch (e) {
      initialState = defaultState
    }
    return initialState
  })

  useEffect(() => {
    window.localStorage.setItem(name, JSON.stringify(state))
  }, [name, state])
  return [state, setState]
}
