import React, { FunctionComponent, useState } from 'react'
import axios from 'axios'
import { useLocation, useParams } from 'react-router'
import { GetDataCenterDisplayName } from '../../env'
import { useMountEffect } from '../../hooks/MountEffect'
import { HttpStatus } from '../../service/HttpStatus'
import { supportEmail } from '../ContactSupport/Data'
import { ExclaimIcon, RemoteManageIcon } from '../Icons/Icons'
import { LoadingDots } from '../LoadingDots/LoadingDots'
import Toaster from '../Toaster'

interface OrgCustomer {
  dataCenter: string
  teamId: string
  customerId: string
}

interface AddonDetails {
  serviceProviderName: string
  serviceProviderEmail: string
}

interface AddonSettings {
  managementEnabled: boolean
}

interface Connection {
  orgCustomer: OrgCustomer
  details?: AddonDetails
  settings?: AddonSettings
}

export const Org: FunctionComponent = () => {
  const [authorized, setAuthorized] = useState(false)
  const [loading, setLoading] = useState(true)
  const [connections, setConnections] = useState<Connection[]>()

  const { dataCenter, orgId } = useParams()

  const query = new URLSearchParams(useLocation().search)
  const token = query.get('token') || ''
  const dcPath = '/api/pmitc/data-centers/' + dataCenter
  const orgPath = dcPath + '/orgs/' + orgId
  const problemSubject = 'Issue with Partner Remote Assistance add-on for PaperCut Hive organization ' + orgId

  const addTokenQueryParam = (url: string) => url + '?token=' + encodeURIComponent(token)

  const detailsPath = (orgCustomer: OrgCustomer) => {
    const orgDcPath = orgPath + '/multiverse-data-centers/' + orgCustomer.dataCenter
    const orgTeamPath = orgDcPath + '/teams/' + orgCustomer.teamId
    const orgCustomerPath = orgTeamPath + '/customers/' + orgCustomer.customerId

    return orgCustomerPath + '/addon'
  }

  const settingsPath = (orgCustomer: OrgCustomer) => detailsPath(orgCustomer) + '/settings'

  const loadConnections = async (): Promise<Connection[]> => {
    return axios
      .get(addTokenQueryParam(orgPath))
      .then(response => {
        return Promise.all(
          (response.data as OrgCustomer[]).map(orgCustomer =>
            Promise.all([loadDetails(orgCustomer), loadSettings(orgCustomer)]).then(
              ([details, settings]) =>
                ({
                  orgCustomer: orgCustomer,
                  details: details,
                  settings: settings,
                } as Connection)
            )
          )
        )
      })
      .catch(err => {
        console.log('failed to load connections: ' + err)
        Toaster.notifyFailure('Unable to load support partner configuration.')
        return Promise.reject(err)
      })
  }

  const loadDetails = async (orgCustomer: OrgCustomer): Promise<AddonDetails | void> => {
    return axios
      .get(addTokenQueryParam(detailsPath(orgCustomer)))
      .then(response => response.data)
      .catch(err => {
        console.log('failed to load details: ' + err)
        return Promise.resolve()
      })
  }

  const loadSettings = async (orgCustomer: OrgCustomer): Promise<AddonSettings | void> => {
    return axios
      .get(addTokenQueryParam(settingsPath(orgCustomer)))
      .then(response => response.data)
      .catch(err => {
        console.log('failed to load settings: ' + err)
        return Promise.resolve()
      })
  }

  const orgCustomerKey = (orgCustomer: OrgCustomer): string => {
    return orgCustomer.dataCenter + '-' + orgCustomer.teamId + '-' + orgCustomer.customerId
  }

  const updateSettings = async (orgCustomer: OrgCustomer, managementEnabled: boolean) => {
    const settings = {
      managementEnabled: managementEnabled,
    } as AddonSettings

    const updatedConnections = []
    for (const conn of connections!) {
      if (conn.orgCustomer === orgCustomer) {
        updatedConnections.push({
          orgCustomer: orgCustomer,
          settings: settings,
          details: conn.details,
        } as Connection)
      } else {
        updatedConnections.push(conn)
      }
    }
    setConnections(updatedConnections)

    return axios.put(addTokenQueryParam(settingsPath(orgCustomer)), JSON.stringify(settings))
  }

  useMountEffect(() => {
    loadConnections()
      .then(conns => {
        setAuthorized(true)
        if (conns) {
          setConnections(conns)
        }
      })
      .catch(err => {
        if (err.response?.status === HttpStatus.Unauthorized) {
          setAuthorized(false)
        }
      })
      .finally(() => setLoading(false))
  })

  return (
    <div className='add-on'>
      {loading && (
        <div className='empty loading'>
          <LoadingDots />
        </div>
      )}
      {!loading && (
        <div className='flex row'>
          {!authorized && <div>Unauthorized</div>}
          {authorized && (
            <article className='col fp'>
              <p>
                Support partners have access to your usage statistics and performance information so they can catch issues early and support you remotely. Find out who your
                friendly tech reps are under <strong>Administrators</strong> on the <strong>Settings</strong> page.
              </p>

              {connections?.map(conn => (
                <React.Fragment key={orgCustomerKey(conn.orgCustomer)}>
                  <hr className='divider' />

                  {conn.details && (
                    <div className='btm'>
                      <h4 data-heap-redact-text>{conn.details.serviceProviderName}</h4>

                      <p className='dip'>
                        They are using the PaperCut Multiverse <strong>{GetDataCenterDisplayName(conn.orgCustomer.dataCenter)}</strong> data center.
                        {conn.details.serviceProviderEmail && (
                          <span data-heap-redact-attributes='href'>
                            {' '}
                            Contact them at{' '}
                            <a href={`mailto:${conn.details.serviceProviderEmail}`} data-heap-redact-text>
                              {conn.details.serviceProviderEmail}
                            </a>
                            .
                          </span>
                        )}
                      </p>

                      {!conn.settings && (
                        <div className='alert warning'>
                          <i className='icon warning'>
                            <ExclaimIcon />
                          </i>

                          <div className='message'>
                            Unable to retrieve settings. If this persists, contact <a href={`mailto:${supportEmail}?subject=${problemSubject}`}>PaperCut Multiverse support</a>.
                          </div>
                        </div>
                      )}
                      {conn.settings && (
                        <div className='list condensed solid'>
                          <ul>
                            <li>
                              <div className='row'>
                                <div className='col flex-grow threeqtr no-margin-bottom'>
                                  <div className='flex flex-ver'>
                                    <div className='push'>
                                      <i className='icon thirtytwo'>
                                        <RemoteManageIcon />
                                      </i>
                                    </div>

                                    <div>
                                      <h5>Admin console</h5>
                                      Enable access to your organization
                                    </div>
                                  </div>
                                </div>

                                <div className='col push flex flex-end'>
                                  <div className='switch'>
                                    <input
                                      type='checkbox'
                                      id={'management-' + orgCustomerKey(conn.orgCustomer)}
                                      checked={conn.settings.managementEnabled}
                                      onChange={() => updateSettings(conn.orgCustomer, !conn.settings!.managementEnabled)}
                                    />

                                    <label htmlFor={'management-' + orgCustomerKey(conn.orgCustomer)} />
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                </React.Fragment>
              ))}
            </article>
          )}
        </div>
      )}
    </div>
  )
}
