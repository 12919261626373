import React, { FunctionComponent, ReactNode } from 'react'
import Moment from 'react-moment'
import { MaintenanceAndSupportStatus } from '../Account/Data'

export interface LicenseDetails {
  pcEdition: string
  maintenanceSupportStatus: MaintenanceAndSupportStatus
  maintenanceSupportExpiryDate?: Date
  orgType: string
  licenseType: string
  licensedMfds: Map<string, number>
  licensedSfps: Map<string, number>
  licensedConnectors: number
  paperCutVersion: string
  registeredUsers: number
  org: string
  licensedReleaseStations: number
  crn: string
  growsTreesPlanted: number
}

interface Props {
  data: LicenseDetails
}

export const LicenseDetailsRow: FunctionComponent<Props> = ({ data }) => {
  let msClass = ''
  switch (data.maintenanceSupportStatus) {
    case 'Expiring':
      msClass = 'expiring'
      break
    case 'Expired':
      msClass = 'expired'
      break
  }

  const getLicensedDeviceCategoryCounts = (type: string, typeMap?: any): ReactNode => {
    if (typeMap && Object.keys(typeMap).length > 0) {
      return (
        <>
          <dt>{type}</dt>
          <dd>
            {Array.from(Object.keys(typeMap)).map(dev => {
              let devLicensed = typeMap[dev]
              if (devLicensed === undefined) {
                return null
              }
              return (
                <React.Fragment key={dev}>
                  {dev} {devLicensed.licensed}
                  <br />
                </React.Fragment>
              )
            })}
          </dd>
        </>
      )
    }
    return null
  }

  return (
    <div id='tab-account' className='tab'>
      <div className='content'>
        <div className='details'>
          <div className='row flex fh'>
            <div className='col third'>
              <header className='flex'>
                <h6>Profile</h6>
              </header>
              <dl className='zebra'>
                {data.crn && (
                  <>
                    <dt>CRN</dt>
                    <dd>{data.crn}</dd>
                  </>
                )}
                <dt>Organization</dt>
                <dd data-heap-redact-text>{data.org}</dd>
              </dl>
            </div>
            <div className='col third'>
              <header className='flex'>
                <h6>Key license info</h6>
              </header>
              <dl className='zebra'>
                <dt>Product</dt>
                <dd>
                  PaperCut {data.pcEdition} / {data.paperCutVersion}
                </dd>
                <dt>Type</dt>
                <dd>{data.orgType}</dd>
                <dt>M&amp;S</dt>
                <dd>
                  {data.maintenanceSupportExpiryDate && (
                    <span>
                      <Moment format='D MMM YYYY' date={data.maintenanceSupportExpiryDate} />
                      <br />
                      {msClass ? <div className={`flag ${msClass}`}>{msClass}</div> : <div />}
                    </span>
                  )}
                  {!data.maintenanceSupportExpiryDate && <span>None</span>}
                </dd>
              </dl>
            </div>
            <div className='col third'>
              <header className='flex'>
                <h6>License details</h6>
              </header>
              <dl className='zebra'>
                <dt>Users</dt>
                <dd>{data.registeredUsers}</dd>
                {getLicensedDeviceCategoryCounts('MFDs', data.licensedMfds)}
                {getLicensedDeviceCategoryCounts('SFPs', data.licensedSfps)}
                {getLicensedDeviceCategoryCounts('Connectors', data.licensedConnectors)}
                <dt>Release stations</dt>
                <dd>{data.licensedReleaseStations}</dd>
                {data.growsTreesPlanted > 0 && (
                  <>
                    <dt>Grows trees</dt>
                    <dd>{data.growsTreesPlanted}</dd>
                  </>
                )}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
