import React, { FunctionComponent, useEffect, useState } from 'react'
import axios from 'axios'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { Features } from '../../features'
import { useTypedSelector } from '../../redux/reducers'
import {
  getAccountDetails,
  getConnectionStatus,
  getFeatureUsage,
  getHost,
  getHostStatus,
  getNotificationPause,
  getSystemHealth,
  getSystemInfo,
  isDemo,
  isFeatureOn,
} from '../../redux/selectors'
import { showHost } from '../../redux/slideout'
import AuthRole from '../../role'
import { Timestamps } from '../../service/Timestamps'
import { AccessConfig } from '../Access/Data'
import { AccountDetails, MaintenanceAndSupportStatus, NFR, TRIAL } from '../Account/Data'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import Hamburger from '../Hamburger'
import { ConnectionStatus, ConnectorDownloadURLs, HostData, HostDataUtil, HostStatus, SystemInfoData } from '../Host/Data'
import LinkHostModal from '../Host/LinkHostModal'
import { HostSlideoutTab } from '../HostSlideout/Data'
import { LicenseIcon, MfdSmallIcon, NFRLicenseIcon, NoBellIcon, RemoteManageIcon, RemoteWarningIcon, TrialLicenseIcon } from '../Icons/Icons'
import PauseNotificationsModal from '../PauseNotificationsModal/PauseNotificationsModal'
import { HealthMetricStatus, systemHealthIcon } from '../SystemHealth/Data'

interface Props {
  host: HostData
  deleteCustomer: (ID: string) => void
  openAccessFrame: (config: AccessConfig) => void
  teamID: string
}

export const Host: FunctionComponent<Props> = props => {
  const demoMode = useTypedSelector(isDemo)
  const hostId = props.host.id
  const systemInfo = useTypedSelector(getSystemInfo(hostId))
  const accountDetails = useTypedSelector(getAccountDetails(hostId))
  const featureUsage = useTypedSelector(getFeatureUsage(hostId))
  const systemHealth = useTypedSelector(getSystemHealth(hostId))
  const connectionStatus = useTypedSelector(getConnectionStatus(hostId))
  const hasDevices = useTypedSelector(state => getHost(hostId, state)?.hostConfig.devices ?? false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showLinkingModal, setShowLinkingModal] = useState(false)
  const [downloadURLs, setDownloadURLs] = useState<ConnectorDownloadURLs>({
    windowsDownloadURL: '#',
    customerConnectorDownloadURL: '',
    linuxDownloadURL: '#',
  })
  const maintenanceSupportStatus = accountDetails?.maintenanceAndSupportStatus ?? MaintenanceAndSupportStatus.None
  const hostStatus = useTypedSelector(getHostStatus(hostId))
  const customerIsLinked = hostStatus !== HostStatus.Unlinked
  const [showPauseNotificationsModal, setShowPauseNotificationsModal] = useState(false)
  const notificationPauseFinishTime = useTypedSelector(getNotificationPause(hostId))?.finishesAt
  const shorterPausesEnabled = useTypedSelector(isFeatureOn(Features.NotificationPauseTesting))
  const eol = useTypedSelector(state => state.support.eolMajorVersion)
  const eolPlanned = useTypedSelector(state => state.support.eolPlannedMajorVersion)

  const getMajorVersion = () => {
    const ver = HostDataUtil.getVersion(systemInfo, accountDetails)
    return ver.substring(0, ver.indexOf('.'))
  }

  const getVersion = (sysInfo?: SystemInfoData, accDetails?: AccountDetails) => {
    const ver = HostDataUtil.getVersion(sysInfo, accDetails, true)
    return ver && <span> / {ver}</span>
  }

  useEffect(() => {
    if (showLinkingModal && !demoMode) {
      axios.get<ConnectorDownloadURLs>('/api/teams/' + props.teamID + '/customers/' + hostId + '/download-link').then(resp => {
        setDownloadURLs(resp.data)
      })
    }
  }, [props.teamID, hostId, showLinkingModal, demoMode])

  const licenseIcon = () => {
    const licenseType = accountDetails?.licenseType
    if (licenseType === TRIAL) {
      return <TrialLicenseIcon />
    } else if (licenseType === NFR) {
      return <NFRLicenseIcon />
    }
    return <LicenseIcon />
  }

  function licenseText() {
    const licenseType = accountDetails?.licenseType
    if (licenseType === 'Trial') {
      return 'Trial license'
    } else if (licenseType === 'NFR') {
      return 'NFR license'
    }

    switch (maintenanceSupportStatus) {
      case MaintenanceAndSupportStatus.Expiring:
        return 'M&S Expiring'
      case MaintenanceAndSupportStatus.Expired:
        return 'M&S Expired'
      case MaintenanceAndSupportStatus.Current:
        return 'M&S Current'
      default:
        return 'M&S N/A'
    }
  }

  const maintenanceAndSupportClass = () => {
    if (maintenanceSupportStatus === MaintenanceAndSupportStatus.Expired) {
      return 'expired'
    } else if (maintenanceSupportStatus === MaintenanceAndSupportStatus.Expiring) {
      return 'expiring'
    }
    return ''
  }

  const statusClass = () => {
    switch (hostStatus) {
      case HostStatus.Unlinked:
        return 'link-req'
      case HostStatus.MaintenanceAndSupportRequired:
      case HostStatus.StaleData:
        return 'warning'
      case HostStatus.MonitoringDisabled:
        return 'disconnected'
    }
    switch (systemHealth?.status) {
      case HealthMetricStatus.Critical:
        return 'error'
      case HealthMetricStatus.Warning:
        return 'warning'
    }
    return ''
  }

  const remoteAccessIcon = () => {
    if (!customerIsLinked) return
    const cannotRemote = !AuthRole.hasPermission('remoteManage')
    const disconnected = connectionStatus === ConnectionStatus.Disconnected
    const disabled = connectionStatus === ConnectionStatus.Disabled
    let clazz = ''
    if (disconnected) {
      clazz = 'disabled'
    } else if (disabled) {
      clazz = 'error'
    }
    let toolTipText = ''
    if (cannotRemote) {
      toolTipText = 'To gain access to Remote Management, contact your Admin.'
    } else if (disabled) {
      toolTipText = 'The customer has disabled remote access.'
    } else if (disconnected) {
      toolTipText = 'PaperCut Multiverse is having trouble connecting to this customer. Try refreshing the page.'
    }

    return (
      <i className={`icon twenty ${clazz}`}>
        {disabled || cannotRemote ? <RemoteWarningIcon /> : <RemoteManageIcon />}

        {toolTipText && <span className='tip'>{toolTipText}</span>}
      </i>
    )
  }

  const dispatch = useDispatch()

  const openHost = (e: React.MouseEvent<HTMLElement, MouseEvent>, startTab?: HostSlideoutTab) => {
    e.stopPropagation()
    if (customerIsLinked) {
      dispatch(showHost(props.host, startTab))
    } else {
      setShowLinkingModal(true)
    }
  }

  const displayMnSOrLicenseExpiryDate = () => {
    if (accountDetails?.maintenanceAndSupportExpiryDate) {
      return <Moment format='DD MMM YYYY' date={accountDetails?.maintenanceAndSupportExpiryDate} />
    }

    if (accountDetails?.licenseExpiryDate) {
      return <Moment format='DD MMM YYYY' date={accountDetails?.licenseExpiryDate} />
    }

    return <>-</>
  }

  const onNoBellIconClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation()
    setShowPauseNotificationsModal(true)
  }

  return (
    <>
      <div className={`row row-link ${statusClass()}`} id={hostId} onClick={e => openHost(e)}>
        <div className={`col customer-status ${statusClass()}`}>
          <a onClick={e => openHost(e, HostSlideoutTab.SystemHealth)}>{systemHealthIcon(hostStatus, systemHealth)}</a>
        </div>

        <div className={`col customer-cert ${maintenanceAndSupportClass()}`}>
          {customerIsLinked && (
            <i className='icon thirtytwo'>
              {licenseIcon()}

              <span className='tip center'>{licenseText()}</span>
            </i>
          )}
        </div>

        <div className='col customer-name'>
          <div className='ellipsis' data-heap-redact-text>
            {props.host.name}

            {!customerIsLinked && <span>Link this customer to get started</span>}
          </div>

          {notificationPauseFinishTime && (
            <a onClick={onNoBellIconClick}>
              <i className='icon twentyfour no-bell'>
                <span className='tip center'>Notifications paused until: {Timestamps.format(notificationPauseFinishTime)}</span>

                <NoBellIcon />
              </i>
            </a>
          )}
        </div>

        <div className='col customer-devices'>{systemInfo?.embeddedCount ?? '-'}</div>

        <div className='col customer-access'>
          {remoteAccessIcon()}

          {connectionStatus !== ConnectionStatus.Disconnected && hasDevices && (
            <i className='icon twentyfour'>
              <MfdSmallIcon />
            </i>
          )}
        </div>

        <div className='col customer-product'>
          <div className='ellipsis'>
            {HostDataUtil.getProductText(systemInfo?.product ?? props.host.product)}

            {getVersion(systemInfo, accountDetails)}
          </div>

          {getMajorVersion() && getMajorVersion() <= eol && (
            <div className='flag red has-tip'>
              EOL
              <span className='tip'>This version has reached end of life.</span>
            </div>
          )}

          {getMajorVersion() && getMajorVersion() <= eolPlanned && getMajorVersion() > eol && (
            <div className='flag yellow has-tip'>
              EOL planned
              <span className='tip'>This version is nearing end of life.</span>
            </div>
          )}
        </div>

        <div className='col customer-platform'>
          <span>
            {systemInfo?.osPlatform && <>{systemInfo?.osPlatform} / </>}

            {HostDataUtil.getInfrastructureShortName(props.host.infrastructure)}
          </span>
        </div>

        <div className='col customer-expiry'>{displayMnSOrLicenseExpiryDate()}</div>

        <div className='col customer-usage'>{featureUsage?.level ?? '-'}</div>

        <div className='col customer-actions'>
          {!customerIsLinked && (
            <a href='#' onClick={() => setShowLinkingModal(true)} className='btn sml connect outline'>
              Link
            </a>
          )}

          {AuthRole.hasPermission('manageCustomer') && (
            <Hamburger>
              <ul>
                <li className='notify'>
                  <a onClick={() => setShowPauseNotificationsModal(true)}>Notifications - {notificationPauseFinishTime ? 'Paused' : 'On'}</a>
                </li>

                {customerIsLinked && (
                  <li className='relink'>
                    <a onClick={() => setShowLinkingModal(true)}>Relink customer</a>{' '}
                  </li>
                )}

                <li className='trash'>
                  <a onClick={() => setShowConfirmationModal(true)}>Remove customer</a>
                </li>
              </ul>
            </Hamburger>
          )}
        </div>
      </div>

      {showConfirmationModal && (
        <ConfirmationModal
          title={`Remove ${props.host.name}`}
          message={`Do you really want to remove customer ${props.host.name}?`}
          yesLabel='Remove customer'
          onClose={() => setShowConfirmationModal(false)}
          onYes={() => props.deleteCustomer(props.host.id)}
        />
      )}

      {showLinkingModal && <LinkHostModal downloadURL={downloadURLs} customerName={props.host.name} isCustomerLinked={customerIsLinked} close={() => setShowLinkingModal(false)} />}

      <PauseNotificationsModal
        close={() => setShowPauseNotificationsModal(false)}
        finishTime={notificationPauseFinishTime}
        host={props.host}
        isVisible={showPauseNotificationsModal}
        teamID={props.teamID}
        testing={shorterPausesEnabled}
      />
    </>
  )
}
