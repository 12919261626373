import React, { FunctionComponent, useState } from 'react'
import { UserIcon } from '../Icons/Icons'

interface Props {
  data: UserActivityData
  onExpand: () => void
  onShrink: () => void
}

interface Users {
  registeredUsers: number
  activeUsers: number
  activeUsersMonth: number
  activeUsersWeek: number
  activeUsers6Months: number
}

interface Devices {
  pcEmbedded: number
  otherPrinters: number
}

interface JobPrinted {
  viaPCEmbedded: number
  viaPCWebPrint: number
  viaExternalInterface: number
  viaMobilityPrint: number
  total: number
}

export interface UserActivityData {
  users: Users
  devices: Devices
  jobPrinted: JobPrinted
}

export const UserActivity: FunctionComponent<Props> = props => {
  const [expanded, setExpanded] = useState(false)

  const onExpandButtonClicked = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    if (expanded) {
      props.onShrink()
    } else {
      props.onExpand()
    }
    setExpanded(!expanded)
  }

  return (
    <div className={`col third ${expanded ? 'open-card' : ''}`}>
      <div className='card'>
        <header>
          <a href='#' className='expand' onClick={onExpandButtonClicked}>
            Expand
          </a>
          <i className='icon'>
            <UserIcon />
          </i>
          <h6>User Activity</h6>
        </header>
        <div className='compact'>
          <div className='set'>
            <h5>Users</h5>
            <div className='status'>{props.data.users.registeredUsers}</div>
          </div>
          <div className='set alt'>
            <h5>Devices</h5>
            <div className='status'>{props.data.devices.pcEmbedded + props.data.devices.otherPrinters}</div>
          </div>
          <div className='set'>
            <h5>Jobs printed</h5>
            <div className='status'>
              {props.data.jobPrinted.viaPCEmbedded + props.data.jobPrinted.viaPCWebPrint + props.data.jobPrinted.viaExternalInterface + props.data.jobPrinted.viaMobilityPrint}
            </div>
          </div>
        </div>
        <div className='expanded'>
          <div className='set'>
            <h5>Users</h5>
            <div className='stats'>
              <ul>
                <li>
                  Registered users <span className='num'>{props.data.users.registeredUsers}</span>
                </li>

                <li>
                  Active users (last month)
                  <span className='num'>{props.data.users.activeUsers}</span>
                </li>
              </ul>
            </div>
          </div>
          <div className='set alt'>
            <h5>Devices</h5>
            <div className='stats'>
              <ul>
                <li>
                  PaperCut embeddeds <span className='num'>{props.data.devices.pcEmbedded}</span>
                </li>
                <li>
                  Other printers <span className='num'>{props.data.devices.otherPrinters}</span>
                </li>
              </ul>
            </div>
          </div>
          <div className='set'>
            <h5>Jobs printed</h5>
            <div className='status'>
              {props.data.jobPrinted.viaPCEmbedded + props.data.jobPrinted.viaPCWebPrint + props.data.jobPrinted.viaExternalInterface + props.data.jobPrinted.viaMobilityPrint}
            </div>
            <div className='stats'>
              <ul>
                <li>
                  Via PaperCut embedded
                  <span className='num'>{props.data.jobPrinted.viaPCEmbedded}</span>
                </li>

                <li>
                  Via PaperCut Web Print
                  <span className='num'>{props.data.jobPrinted.viaPCWebPrint}</span>
                </li>

                <li>
                  Via external interface
                  <span className='num'>{props.data.jobPrinted.viaExternalInterface}</span>
                </li>

                <li>
                  Via Mobility Print
                  <span className='num'>{props.data.jobPrinted.viaMobilityPrint}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserActivity
