import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react'
import moment from 'moment'
import Moment from 'react-moment'
import { useTypedSelector } from '../../redux/reducers'
import { isDemo } from '../../redux/selectors'
import AuthRole from '../../role'
import { EditCustomerFunction, HostData, HostDataUtil } from '../Host/Data'
import { InfoIcon } from '../Icons/Icons'
import CustomerModal from '../MyHosts/CustomerModal'
import styles from './Account.module.css'
import ApplyLicenseModal, { checkApplyLicense } from './ApplyLicenseModal'
import { AccountDataUtil, AccountDetails } from './Data'

interface Props {
  teamID: string
  upgradeRequired: boolean
  account: AccountDetails
  hostData: HostData
  editCustomer: EditCustomerFunction
  onClose: () => void
  remoteEnabled: boolean
  remoteMonitoringEnabled: boolean
}

const NOT_AVAILABLE = 'N/A'

const Account: FunctionComponent<Props> = props => {
  const demoMode = useTypedSelector(isDemo)
  const [showEditCustomer, setShowEditCustomer] = useState(false)

  const [applyLicenseCheck, setApplyLicenseCheck] = useState('Checking requirements')
  const [applyLicenseModalOpen, setApplyLicenseModalOpen] = useState(false)
  const applyLicenseAvailable = applyLicenseCheck === '' || demoMode
  const canUploadLicenses = AuthRole.hasPermission('uploadLicense')
  const canManageCustomer = AuthRole.hasPermission('manageCustomer')

  let msClass = ''
  switch (props.account.maintenanceAndSupportStatus) {
    case 'Expiring':
      msClass = 'maintenance-support-warning'
      break
    case 'Expired':
      msClass = 'maintenance-support-error'
      break
  }

  const footerClass = canManageCustomer ? '' : styles.emptyFooter

  const newVersionRequiredProperty = (propertyValue: any): ReactNode => {
    if (propertyValue) {
      return propertyValue
    }

    return props.upgradeRequired ? <em>Requires version 19.1+</em> : NOT_AVAILABLE
  }

  const getLicensedReleaseStationCount = (count: number): ReactNode => {
    if (count === -1) {
      return 'Unlimited'
    }

    return newVersionRequiredProperty(count)
  }

  const onClose = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    props.onClose()
  }

  useEffect(() => {
    if (canUploadLicenses && !demoMode) {
      checkApplyLicense(props.teamID, props.hostData.id).then(result => setApplyLicenseCheck(result))
    }
  }, [canUploadLicenses, props.teamID, props.hostData.id, props.remoteEnabled, demoMode])

  return (
    <div id='tab-account' className='tab'>
      <header>
        Customer license summary and key details
        {!props.remoteMonitoringEnabled && <span className='last-update'> (Last updated {moment(props.account.lastUpdate).fromNow()})</span>}
        <a href='#' className='action' onClick={onClose} aria-label='Close Account tab' />
      </header>

      <div className='content'>
        <div className='col fp details'>
          <div className='row flex fh'>
            <div className='col third'>
              <header className='flex'>
                <h6>Profile</h6>
              </header>
              <dl className='zebra'>
                <dt>Organization</dt>
                <dd data-heap-redact-text>{props.hostData.name}</dd>
                <dt>Infrastructure</dt>
                <dd data-heap-redact-text>{HostDataUtil.getInfrastructureText(props.hostData.infrastructure)}</dd>
                {props.hostData.tags && props.hostData.tags.length > 0 && (
                  <>
                    <dt className='alt'>Tags</dt>
                    <dd className='alt'>
                      <ul>
                        {props.hostData.tags?.map((tag, index) => (
                          <li key={index}>
                            <div className='tag' data-heap-redact-text>
                              {tag}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </dd>
                  </>
                )}
              </dl>
              <footer className={footerClass}>
                {canManageCustomer && (
                  <a
                    className='edit-customer btn'
                    onClick={e => {
                      e.preventDefault()
                      setShowEditCustomer(true)
                    }}
                  >
                    Edit profile
                  </a>
                )}

                {showEditCustomer && (
                  <CustomerModal
                    key={props.hostData.id}
                    teamID={props.teamID}
                    action={props.editCustomer}
                    close={() => setShowEditCustomer(false)}
                    isVisible={showEditCustomer}
                    host={props.hostData}
                  />
                )}
              </footer>
            </div>
            <div className='col third'>
              <header className='flex'>
                <h6>License</h6>
              </header>
              <dl className='zebra'>
                <dt>Licensed to</dt>
                <dd data-heap-redact-text>{newVersionRequiredProperty(props.account.organisation)}</dd>
                <dt>
                  CRN
                  <i className='icon-info'>
                    <InfoIcon />
                    <span className='tip'>
                      <span className='blk'>Customer Reference Number</span>
                    </span>
                  </i>
                </dt>
                <dd>{newVersionRequiredProperty(props.account.crn)}</dd>
                <dt>Licensed product</dt>
                <dd>
                  PaperCut {props.account.paperCutEdition.toUpperCase()} / {props.account.paperCutVersion}
                </dd>
                {props.account?.maintenanceAndSupportExpiryDate && (
                  <>
                    <dt>Maintenance &amp; support</dt>
                    <dd>
                      <span>
                        <Moment format='D MMM YYYY' date={props.account?.maintenanceAndSupportExpiryDate} />
                        <br />
                        <span className={msClass}>{props.account?.maintenanceAndSupportStatus}</span>
                      </span>
                    </dd>
                  </>
                )}
                {props.account?.licenseExpiryDate && (
                  <>
                    <dt>License expiry</dt>
                    <dd>
                      <Moment format='D MMM YYYY' date={props.account?.licenseExpiryDate} />
                    </dd>
                  </>
                )}
                {!props.account?.maintenanceAndSupportExpiryDate && !props.account?.licenseExpiryDate && (
                  <>
                    <dt>Maintenance &amp; support</dt>
                    <dd>{props.upgradeRequired ? <em>Requires version 19.1+</em> : NOT_AVAILABLE}</dd>
                  </>
                )}
                <dt>License type</dt>
                <dd>{newVersionRequiredProperty(props.account.licenseType)}</dd>
              </dl>

              <footer className={canUploadLicenses ? '' : styles.emptyFooter}>
                {canUploadLicenses && (
                  <>
                    <span className='has-tip'>
                      <button className={`btn ${applyLicenseAvailable ? '' : 'disabled'}`} onClick={() => setApplyLicenseModalOpen(true)}>
                        Apply license
                      </button>

                      {!applyLicenseAvailable && <span className='tip'>{applyLicenseCheck}</span>}
                    </span>
                  </>
                )}

                {applyLicenseModalOpen && <ApplyLicenseModal teamID={props.teamID} hostID={props.hostData.id} onClose={() => setApplyLicenseModalOpen(false)} />}
              </footer>
            </div>
            <div className='col third'>
              <header className='flex'>
                <h6>Printers &amp; users</h6>
              </header>
              <dl className='zebra'>
                <dt>Registered users</dt>
                <dd>{newVersionRequiredProperty(props.account.registeredUsers)}</dd>
                {AccountDataUtil.getLicensedDeviceCategoryTypeAndCounts('Licensed MFDs', props.account.licensedMFDs, props.account)}
                {AccountDataUtil.getLicensedDeviceCategoryTypeAndCounts('Licensed SFPs', props.account.licensedSFPs, props.account)}
                {AccountDataUtil.getLicensedDeviceCategoryTypeAndCounts('Connectors', props.account.licensedConnectors, props.account)}
                <dt>Release stations</dt>
                <dd>{getLicensedReleaseStationCount(props.account.licensedReleaseStations)}</dd>
              </dl>
              <footer className={styles.emptyFooter} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Account
