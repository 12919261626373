import React, { FunctionComponent } from 'react'
import { AccountManagerIcon, AdminIcon, TechServicesIcon } from '../Icons/Icons'
import { UserRole } from './Data'

interface RoleProps {
  onRolesChange: (states: UserRole[]) => void
  roles: UserRole[]
}

const RoleSelection: FunctionComponent<RoleProps> = props => {
  let updatedRoles = [...props.roles]

  const removeRole = (role: UserRole, roles: UserRole[]) => {
    return roles.filter(r => {
      return r !== role
    })
  }

  const onAccountManagerRoleClicked = () => {
    updatedRoles = removeRole(UserRole.Admin, updatedRoles)
    if (props.roles.includes(UserRole.AccountManager) || props.roles.includes(UserRole.AccountManagerRO)) {
      updatedRoles = removeRole(UserRole.AccountManager, updatedRoles)
      updatedRoles = removeRole(UserRole.AccountManagerRO, updatedRoles)
    } else {
      updatedRoles.push(UserRole.AccountManager)
    }
    props.onRolesChange(updatedRoles)
  }

  const onTechServicesRoleClicked = () => {
    updatedRoles = removeRole(UserRole.Admin, updatedRoles)
    if (props.roles.includes(UserRole.TechServices) || props.roles.includes(UserRole.TechServicesRO)) {
      updatedRoles = removeRole(UserRole.TechServices, updatedRoles)
      updatedRoles = removeRole(UserRole.TechServicesRO, updatedRoles)
    } else {
      updatedRoles.push(UserRole.TechServices)
    }
    props.onRolesChange(updatedRoles)
  }

  const onAdminRoleClicked = () => {
    if (props.roles.includes(UserRole.Admin)) {
      updatedRoles = removeRole(UserRole.Admin, updatedRoles)
    } else {
      updatedRoles.push(UserRole.Admin)
    }
    updatedRoles = removeRole(UserRole.AccountManager, updatedRoles)
    updatedRoles = removeRole(UserRole.TechServices, updatedRoles)
    updatedRoles = removeRole(UserRole.AccountManagerRO, updatedRoles)
    updatedRoles = removeRole(UserRole.TechServicesRO, updatedRoles)
    props.onRolesChange(updatedRoles)
  }

  const isAccountReadOnly = () => {
    return updatedRoles.includes(UserRole.AccountManagerRO)
  }

  const setAccountReadOnly = (b: boolean) => {
    if (b) {
      updatedRoles.push(UserRole.AccountManagerRO)
      updatedRoles = removeRole(UserRole.AccountManager, updatedRoles)
    } else {
      updatedRoles = removeRole(UserRole.AccountManagerRO, updatedRoles)
      updatedRoles.push(UserRole.AccountManager)
    }
    props.onRolesChange(updatedRoles)
  }

  const isTechSvsReadOnly = () => {
    return updatedRoles.includes(UserRole.TechServicesRO)
  }

  const setTechSvsReadOnly = (b: boolean) => {
    if (b) {
      updatedRoles.push(UserRole.TechServicesRO)
      updatedRoles = removeRole(UserRole.TechServices, updatedRoles)
    } else {
      updatedRoles = removeRole(UserRole.TechServicesRO, updatedRoles)
      updatedRoles.push(UserRole.TechServices)
    }
    props.onRolesChange(updatedRoles)
  }

  return (
    <div className='field'>
      <label>
        User role/permissions
        <small>Select all roles that apply</small>
      </label>
      <ul className='flex center option-list many'>
        <li className={props.roles.includes(UserRole.Admin) ? 'active' : ''} data-option='admin' onClick={onAdminRoleClicked}>
          <a href='#'>
            <i className='icon thirtytwo'>
              <AdminIcon />
            </i>
            <strong>Admin</strong>
          </a>
        </li>
      </ul>
      <div className='divider sep'>
        <span>or</span>
      </div>
      <ul className='flex center option-list many'>
        <li className={props.roles.includes(UserRole.AccountManager) || props.roles.includes(UserRole.AccountManagerRO) ? 'active' : ''} onClick={onAccountManagerRoleClicked}>
          <a href='#'>
            <i className='icon thirtytwo'>
              <AccountManagerIcon />
            </i>
            <strong>Account manager</strong>
          </a>
          <div className='options'>
            <label className='inl' htmlFor='new-role-account-full' onClick={event => event.stopPropagation()}>
              <input type='radio' id='new-role-account-full' checked={!isAccountReadOnly()} onChange={_ => setAccountReadOnly(false)} />
              Full access
            </label>
            <label className='inl' htmlFor='new-role-account-read' onClick={event => event.stopPropagation()}>
              <input type='radio' id='new-role-account-read' checked={isAccountReadOnly()} onChange={_ => setAccountReadOnly(true)} />
              Read-only
            </label>
          </div>
        </li>
        <li className={props.roles.includes(UserRole.TechServices) || props.roles.includes(UserRole.TechServicesRO) ? 'active' : ''} onClick={onTechServicesRoleClicked}>
          <a href='#'>
            <i className='icon thirtytwo'>
              <TechServicesIcon />
            </i>
            <strong>Tech services</strong>
          </a>
          <div className='options'>
            <label className='inl' htmlFor='new-role-tech-full' onClick={event => event.stopPropagation()}>
              <input type='radio' id='new-role-tech-full' checked={!isTechSvsReadOnly()} onChange={_ => setTechSvsReadOnly(false)} />
              Full access
            </label>
            <label className='inl' htmlFor='new-role-tech-read' onClick={event => event.stopPropagation()}>
              <input type='radio' id='new-role-tech-read' checked={isTechSvsReadOnly()} onChange={_ => setTechSvsReadOnly(true)} />
              Read-only
            </label>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default RoleSelection
