import React, { FunctionComponent, useEffect, useState } from 'react'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../redux/reducers'
import { showHost } from '../../redux/slideout'
import { getAccountDetails, getHostStatus, getSystemHealth } from '../../redux/selectors'
import { HostData, HostStatus } from '../Host/Data'
import { HostSlideoutTab } from '../HostSlideout/Data'
import { HealthMetricStatus, SystemHealthData, systemHealthIcon } from '../SystemHealth/Data'

interface Props {
  data: HostData
}

export const Host: FunctionComponent<Props> = ({ data }) => {
  const hostStatus = useTypedSelector(getHostStatus(data.id))
  const systemHealth = useTypedSelector(getSystemHealth(data.id))
  const maintenanceSupportExpiry = useTypedSelector(r => getAccountDetails(data.id)(r)?.maintenanceAndSupportExpiryDate)
  const customerIsLinked = hostStatus !== HostStatus.Unlinked
  const [criticalMetrics, setCriticalMetrics] = useState<string[]>([])
  const [warningMetrics, setWarningMetrics] = useState<string[]>([])

  useEffect(() => {
    const getMetricDisplayName = (key: string) => {
      switch (key) {
        case 'cpu':
          return 'CPU'
        case 'diskSpace':
          return 'disk space'
        case 'printProvider':
          return 'print provider'
        case 'mobilePrint':
          return 'mobile print'
        case 'siteServer':
          return 'site server'
        case 'database':
        case 'memory':
        case 'devices':
          return key
        default:
          return 'unknown'
      }
    }

    if (systemHealth && hostStatus === HostStatus.Good) {
      let criticalMetricsFound: string[] = []
      let warningMetricsFound: string[] = []
      for (const [k, v] of Object.entries(systemHealth.infrastructure).concat(Object.entries(systemHealth.printManagement))) {
        let concerningMetrics
        if (v.status === HealthMetricStatus.Critical) {
          concerningMetrics = criticalMetricsFound
        } else if (v.status === HealthMetricStatus.Warning) {
          concerningMetrics = warningMetricsFound
        } else {
          continue
        }
        concerningMetrics.push(getMetricDisplayName(k))
      }
      setCriticalMetrics(criticalMetricsFound)
      setWarningMetrics(warningMetricsFound)
    } else {
      const warningMessage = (status: HostStatus) => {
        switch (status) {
          case HostStatus.MaintenanceAndSupportRequired:
            return 'M&S required'
          case HostStatus.MonitoringDisabled:
            return 'Monitoring disabled'
          case HostStatus.StaleData:
            return 'Disconnected'
        }
      }
      let warning = warningMessage(hostStatus)
      setCriticalMetrics([])
      setWarningMetrics(warning ? [warning] : [])
    }
  }, [hostStatus, systemHealth])

  const dispatch = useDispatch()
  const openHost = () => {
    dispatch(showHost(data, HostSlideoutTab.SystemHealth))
  }

  const statusClass = (status?: HostStatus, health?: SystemHealthData) => {
    switch (status) {
      case HostStatus.Unlinked:
        return 'link-req'
      case HostStatus.MaintenanceAndSupportRequired:
      case HostStatus.MonitoringDisabled:
      case HostStatus.StaleData:
        return 'warning'
    }
    switch (health?.status) {
      case HealthMetricStatus.Critical:
        return 'error'
      case HealthMetricStatus.Warning:
        return 'warning'
    }
    return ''
  }

  return (
    <li id={data.id} key={data.id} onClick={openHost}>
      <div className={`row row-link ${customerIsLinked ? '' : 'link-req'} ${statusClass(hostStatus, systemHealth) === 'error' ? 'error' : ''}`}>
        <div className={`col customer-status ${statusClass(hostStatus, systemHealth)}`}>{systemHealthIcon(hostStatus, systemHealth)}</div>

        <div className='col customer-name'>
          <div className='ellipsis' data-heap-redact-text>
            {data.name}
          </div>
        </div>

        {customerIsLinked && (
          <>
            <div className='col customer-expiry'>
              <span className='label subdued'>M&amp;S expiry</span>

              {maintenanceSupportExpiry ? <Moment format='D MMM YYYY' date={maintenanceSupportExpiry} /> : '-'}
            </div>

            {warningMetrics.length > 0 && (
              <div className='col customer-warnings'>
                <div className='ellipsis'>
                  <span className='label subdued'>Warning</span>

                  {warningMetrics.join(', ')}
                </div>
              </div>
            )}

            {criticalMetrics.length > 0 && (
              <div className='col customer-critical'>
                <div className='ellipsis'>
                  <span className='label subdued'>Critical</span>

                  {criticalMetrics.join(', ')}
                </div>
              </div>
            )}
          </>
        )}
        {!customerIsLinked && <div className='col'>Link this customer to get started</div>}
      </div>
    </li>
  )
}
