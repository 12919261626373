import React, { FunctionComponent } from 'react'
import { CalendarIcon } from '../Icons/Icons'

interface Props {
  onClick: () => void
  isOpen: boolean
}

const EventLogSummary: FunctionComponent<Props> = props => {
  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    props.onClick()
  }

  return (
    <li className={props.isOpen ? 'active' : ''}>
      <a href='#tab-events' onClick={onClick}>
        <div className='title'>Event log</div>

        <div className='status'>
          <i className='icon'>
            <CalendarIcon />
          </i>

          <span className='state'> </span>
        </div>
      </a>
    </li>
  )
}

export default EventLogSummary
