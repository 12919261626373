import React, { FunctionComponent } from 'react'
import 'c3/c3.css'
import { SystemHealthData, HealthMetricStatus, SystemHealthMetric } from '../SystemHealth/Data'
import { ExclaimIcon } from '../Icons/Icons'
import { HostStatus } from '../Host/Data'
import { Meter } from '../Visualizations/Meter'
import { DonutChart } from '../Visualizations/DonutChart'
import { OnOffMeter } from '../Visualizations/OnOffMeter'

interface Props {
  systemHealth: SystemHealthData
  hostStatus: HostStatus
}

export const SystemHealthTab: FunctionComponent<Props> = props => {
  const msRequired = props.hostStatus === HostStatus.MaintenanceAndSupportRequired
  let systemHealth = props.systemHealth
  if (msRequired) {
    systemHealth = {
      status: HealthMetricStatus.None,
      observedTime: '',
      infrastructure: {
        cpu: {
          appServerCPU: 0,
          architecture: '',
          details: '',
          metricName: '',
          processors: 0,
          status: HealthMetricStatus.None,
          systemCPU: 0,
        },
        database: {
          activeConnections: 0,
          details: '',
          maxConnections: 0,
          metricName: '',
          status: HealthMetricStatus.None,
          timeToQueryMilliseconds: 0,
          totalConnections: 0,
          type: '',
          version: '',
        },
        diskSpace: {
          details: '',
          diskSpaceFreeMB: 0,
          diskSpaceTotalMB: 0,
          diskSpaceUsedPercentage: 0,
          metricName: '',
          status: HealthMetricStatus.None,
        },
        memory: {
          details: '',
          jvmMemoryMaxMB: 0,
          jvmMemoryTotalMB: 0,
          jvmMemoryUsedMB: 0,
          jvmMemoryUsedPercentage: 0,
          metricName: '',
          status: HealthMetricStatus.None,
        },
      },
      printManagement: {
        devices: {
          details: '',
          devicesInError: [],
          devicesInErrorCount: 0,
          metricName: '',
          status: HealthMetricStatus.None,
        },
        mobilePrint: {
          details: '',
          metricName: '',
          offline: 0,
          online: 0,
          status: HealthMetricStatus.None,
          total: 0,
        },
        printProvider: {
          details: '',
          metricName: '',
          offline: 0,
          online: 0,
          status: HealthMetricStatus.None,
          total: 0,
        },
        siteServer: {
          details: '',
          metricName: '',
          offline: 0,
          online: 0,
          status: HealthMetricStatus.None,
          total: 0,
        },
      },
      userActivity: {
        devices: {
          otherPrinters: 0,
          pcEmbedded: 0,
        },
        jobPrinted: {
          total: 0,
          viaExternalInterface: 0,
          viaMobilityPrint: 0,
          viaPCEmbedded: 0,
          viaPCWebPrint: 0,
        },
        users: {
          activeUsers: 0,
          activeUsers6Months: 0,
          activeUsersMonth: 0,
          activeUsersWeek: 0,
          registeredUsers: 0,
        },
      },
    }
  }

  const cpu = systemHealth.infrastructure.cpu
  const database = systemHealth.infrastructure.database
  const memory = systemHealth.infrastructure.memory
  const diskSpace = systemHealth.infrastructure.diskSpace
  const jobsPrinted = systemHealth.userActivity.jobPrinted
  const devices = systemHealth.userActivity.devices
  const greaterCPU = ((cpu.appServerCPU ?? 0) > (cpu.systemCPU ?? 0) ? cpu.appServerCPU : cpu.systemCPU) ?? 0
  const dbRespError = database.details?.includes('response') ?? false
  const dbConnError = database.details?.includes('connection') ?? false
  const databasePercentage = ((database.totalConnections ?? 0) / (database.maxConnections || 1)) * 100

  const formatNumber = (value: number) => {
    return value.toFixed(2)
  }

  const formatPercent = (value: number, total: number) => {
    if (total === 0) return '-'

    return formatNumber((value / total) * 100) + '%'
  }

  return (
    <div id='tab-health' className='tab visible'>
      {msRequired && (
        <div className='alert warning flex-ver pin'>
          <i className='icon'>
            <ExclaimIcon />
          </i>
          <div className='message'>In order to view the system health status and receive health alerts, PaperCut Maintenance &amp; Support is required for this customer.</div>
        </div>
      )}

      <header className='flex flex-ver'>
        <h2>System health</h2>
        <div className='btn-group'>
          <a href='https://www.papercut.com/kb/' rel='noopener noreferrer' target='_blank' className='btn secondary'>
            Knowledge Base
          </a>
        </div>
      </header>

      <div className={`content open ${msRequired ? 'upgrade-req' : ''}`}>
        <div className='row flex'>
          <div className='col twothird'>
            <div className='card'>
              <header>
                <h4>Infrastructure</h4>
              </header>

              <div className='set'>
                <div className='row flex'>
                  <div className='threeqtr col'>
                    <h5 style={{ width: '100%' }}>
                      <span>Database</span>
                      {healthStatusDetails(database)}
                      {!(dbRespError && dbConnError) && (
                        <small className='rgt ellipsis' style={{ maxWidth: '180px' }}>
                          {database.version.replace(/- \(.*\)/, '')}
                        </small>
                      )}
                    </h5>
                    <Meter percentage={databasePercentage} status={dbConnError ? database.status : HealthMetricStatus.Good} />
                    <div className='stats'>
                      <ul className='flex'>
                        <li>
                          <span className='label'>Max connections</span>
                          <span className='num'>{database.maxConnections}</span>
                        </li>
                        <li className='active'>
                          <span className='label'>Connections used</span>
                          <span className='num'>{database.totalConnections}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='qtr col center flex flex-col'>
                    <div data-type='db-response' className={dbRespError ? statusClass(database.status) : ''}>
                      <h5 className='fp center'>Response time</h5>
                      <div className='val flex center flex-baseline'>
                        <span className='num'>{database.timeToQueryMilliseconds}</span>
                        <small>ms</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='set'>
                <h5 style={{ width: '100%' }}>
                  <span>Disk space</span>
                  {healthStatusDetails(diskSpace)}
                </h5>
                <Meter percentage={diskSpace.diskSpaceUsedPercentage} status={diskSpace.status} />
                <div className='stats'>
                  <ul className='flex'>
                    <li>
                      <span className='label'>Total</span>
                      <span className='num'>{formatNumber(diskSpace.diskSpaceTotalMB / 1000)}GB</span>
                    </li>
                    <li>
                      <span className='label'>Free</span>
                      <span className='num error'>{formatNumber(diskSpace.diskSpaceFreeMB / 1000)}GB</span>
                    </li>
                    <li className='active'>
                      <span className='label'>Used</span>
                      <span className='num'>{formatNumber(((diskSpace.diskSpaceUsedPercentage / 100) * systemHealth.infrastructure.diskSpace.diskSpaceTotalMB) / 1000)}GB</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='set'>
                <h5 style={{ width: '100%' }}>
                  <span>CPU</span>
                  {healthStatusDetails(cpu)}
                  <small className='rgt'>{cpu.architecture}</small>
                </h5>
                <Meter percentage={greaterCPU} status={cpu.status} />
                <div className='stats'>
                  <ul className='flex'>
                    <li>
                      <span className='label'>Cores/processors</span>
                      <span className='num'>{cpu.processors}</span>
                    </li>
                    <li className={cpu.systemCPU === greaterCPU ? 'active' : ''}>
                      <span className='label'>System CPU</span>
                      <span className='num'>{cpu.systemCPU}%</span>
                    </li>
                    <li className={cpu.appServerCPU === greaterCPU ? 'active' : ''}>
                      <span className='label'>Application Server CPU</span>
                      <span className='num'>{cpu.appServerCPU}%</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='set'>
                <h5 style={{ width: '100%' }}>
                  <span>Application memory</span>
                  {healthStatusDetails(memory)}
                </h5>
                <Meter percentage={memory.jvmMemoryUsedPercentage ?? 0} status={memory.status} />
                <div className='stats'>
                  <ul className='flex'>
                    <li>
                      <span className='label'>Max</span>
                      <span className='num'>{memory.jvmMemoryMaxMB}MB</span>
                    </li>
                    <li>
                      <span className='label'>Allocated</span>
                      <span className='num'>{memory.jvmMemoryTotalMB}MB</span>
                    </li>
                    <li className='active'>
                      <span className='label'>Used</span>
                      <span className='num'>{memory.jvmMemoryUsedMB}MB</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <hr className='divider' />
            <div className='card'>
              <header>
                <h4>User activity</h4>
              </header>

              <div className='row flex center margin-top'>
                <div className='col qtr'>
                  <span className='num med'>{systemHealth.userActivity.users.registeredUsers}</span>
                  <small className='blk'>Registered users</small>
                </div>
                <div className='col qtr'>
                  <span className='num med'>{systemHealth.userActivity.users.activeUsers6Months}</span>
                  <small className='blk'>Active users (6 months)</small>
                </div>
                <div className='col qtr'>
                  <span className='num med'>{systemHealth.userActivity.users.activeUsersMonth}</span>
                  <small className='blk'>Active users (last month)</small>
                </div>
                <div className='col qtr'>
                  <span className='num med'>{systemHealth.userActivity.users.activeUsersWeek}</span>
                  <small className='blk'>Active users (last week)</small>
                </div>
              </div>

              <hr className='divider' />

              <div className='row flex'>
                <div className='col half'>
                  <div className='set'>
                    <h5>Jobs printed</h5>
                    <div className='row flex'>
                      <div className='col center'>
                        <DonutChart
                          id='pages-printed'
                          style={{ maxHeight: '100px', position: 'relative' }}
                          data={[
                            ['Embedded', (jobsPrinted.viaPCEmbedded / jobsPrinted.total) * 100],
                            ['Web Print', (jobsPrinted.viaPCWebPrint / jobsPrinted.total) * 100],
                            ['External', (jobsPrinted.viaExternalInterface / jobsPrinted.total) * 100],
                            ['Mobility Print', (jobsPrinted.viaMobilityPrint / jobsPrinted.total) * 100],
                          ]}
                        />
                        <strong className='num'>{jobsPrinted.total}</strong>
                        <small className='subdued blk'>Total jobs printed</small>
                      </div>
                      <div className='col flex-grow'>
                        <div className='stats'>
                          <ul>
                            <li>
                              <span className='label'>Via PaperCut embedded</span>
                              <span className='num'>{formatPercent(jobsPrinted.viaPCEmbedded, jobsPrinted.total)}</span>
                            </li>
                            <li>
                              <span className='label'>Via PaperCut Web Print</span>
                              <span className='num'>{formatPercent(jobsPrinted.viaPCWebPrint, jobsPrinted.total)}</span>
                            </li>
                            <li>
                              <span className='label'>Via external interface</span>
                              <span className='num'>{formatPercent(jobsPrinted.viaExternalInterface, jobsPrinted.total)}</span>
                            </li>
                            <li>
                              <span className='label'>Via Mobility Print</span>
                              <span className='num'>{formatPercent(jobsPrinted.viaMobilityPrint, jobsPrinted.total)}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col half'>
                  <div className='set'>
                    <h5>Devices</h5>
                    <div className='stats'>
                      <ul>
                        <li>
                          <span className='label'>PaperCut embeddeds</span>
                          <span className='num'>{devices.pcEmbedded}</span>
                        </li>
                        <li>
                          <span className='label'>Other printers</span>
                          <span className='num'>{devices.otherPrinters}</span>
                        </li>
                        <li className='row-total'>
                          <span className='label'>Total</span>
                          <span className='num'>{devices.pcEmbedded + devices.otherPrinters}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {systemHealth.printManagement && (
            <div className='col third flex flex-col'>
              <div className='card btm'>
                <header>
                  <h4>Print management</h4>
                </header>

                {systemHealth.printManagement.printProvider && (
                  <div className='set'>
                    <h5>
                      <span>Print providers</span>
                    </h5>
                    {healthStatusDetails(systemHealth.printManagement.printProvider, false)}
                    <OnOffMeter on={systemHealth.printManagement.printProvider.online} off={systemHealth.printManagement.printProvider.offline} />
                    <div className='stats'>
                      <ul className='flex'>
                        <li>
                          <span className='label'>Total</span>
                          <span className='num'>{systemHealth.printManagement.printProvider.total}</span>
                        </li>
                        <li>
                          <span className='label'>Online</span>
                          <span className='num'>{systemHealth.printManagement.printProvider.online}</span>
                        </li>
                        <li>
                          <span className='label'>Offline</span>
                          <span className='num'>{systemHealth.printManagement.printProvider.offline}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
                {systemHealth.printManagement.siteServer && (
                  <div className='set'>
                    <h5>
                      <span>Site servers</span>
                    </h5>
                    {healthStatusDetails(systemHealth.printManagement.siteServer, false)}
                    <OnOffMeter on={systemHealth.printManagement.siteServer.online} off={systemHealth.printManagement.siteServer.offline} />
                    <div className='stats'>
                      <ul className='flex'>
                        <li>
                          <span className='label'>Total</span>
                          <span className='num'>{systemHealth.printManagement.siteServer.total}</span>
                        </li>
                        <li>
                          <span className='label'>Online</span>
                          <span className='num'>{systemHealth.printManagement.siteServer.online}</span>
                        </li>
                        <li>
                          <span className='label'>Offline</span>
                          <span className='num'>{systemHealth.printManagement.siteServer.offline}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
                {systemHealth.printManagement.mobilePrint && (
                  <div className='set'>
                    <h5>
                      <span>BYOD / Mobility Print</span>
                    </h5>
                    {healthStatusDetails(systemHealth.printManagement.mobilePrint, false)}
                    <OnOffMeter on={systemHealth.printManagement.mobilePrint.online} off={systemHealth.printManagement.mobilePrint.offline} />
                    <div className='stats'>
                      <ul className='flex'>
                        <li>
                          <span className='label'>Count</span>
                          <span className='num'>{systemHealth.printManagement.mobilePrint.total}</span>
                        </li>
                        <li>
                          <span className='label'>Online</span>
                          <span className='num'>{systemHealth.printManagement.mobilePrint.online}</span>
                        </li>
                        <li>
                          <span className='label'>Offline</span>
                          <span className='num'>{systemHealth.printManagement.mobilePrint.offline}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
              {systemHealth.printManagement.devices && (
                <div className='card'>
                  <header>
                    <h4>Offline MFDs/printers</h4>
                  </header>
                  {healthStatusDetails(systemHealth.printManagement.devices, false)}
                  <div className='set'>
                    <div className='stats'>
                      {!systemHealth.printManagement.devices.devicesInErrorCount && <span>There are no devices in error</span>}
                      <ul>
                        {systemHealth.printManagement.devices.devicesInError?.map(d => (
                          <li className='status-offline' key={d.name}>
                            {d.name}
                            <span className='num' />
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const statusClass = (status?: HealthMetricStatus) => {
  switch (status) {
    case HealthMetricStatus.Warning:
      return 'warning'
    case HealthMetricStatus.Critical:
      return 'error'
    default:
      return ''
  }
}

const healthStatusDetails = (metric: SystemHealthMetric, inline: boolean = true) => {
  return (
    metric.details && (
      <div className={`alert unpinned ${statusClass(metric.status)} ${inline ? 'inl' : 'fp'}`}>
        <i className='icon-exclaim'>
          <ExclaimIcon />
        </i>
        <div className='message'>{metric.details}</div>
      </div>
    )
  )
}
