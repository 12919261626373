import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AccessConfig } from '../components/Access/Data'

type AccessState = {
  accessConfig?: AccessConfig
}

const accessSlice = createSlice({
  name: 'access',
  initialState: {} as AccessState,
  reducers: {
    setAccessConfig(state, action: PayloadAction<AccessConfig | undefined>) {
      state.accessConfig = action.payload
    },
  },
})

export const { setAccessConfig } = accessSlice.actions
export default accessSlice.reducer
