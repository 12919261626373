import { FeatureUsageData } from '../FeatureUsage/FeatureUsage'
import React, { FunctionComponent } from 'react'
import { GlobeIcon, LockIcon, PeopleIcon, ScannerIcon } from '../Icons/Icons'
import { HostStatus } from '../Host/Data'

interface Props {
  customerName: string
  hostStatus: HostStatus
  featureUsage?: FeatureUsageData
}

export const HiveFeatureUsageTab: FunctionComponent<Props> = _ => {
  return (
    <div id='tab-impact' className='tab visible'>
      <header className='flex flex-ver'>
        <h2>Feature usage</h2>
      </header>

      <div className='content upgrade-req coming-soon tmp-stronger-fade'>
        <span className='sample'>Need this?</span>

        <hr />

        <div className='row flex'>
          <div className='col qtr'>
            <div className='card fh zebra'>
              <header>
                <i className='icon'>
                  <LockIcon />
                </i>
                <h6>Secure printing</h6>
              </header>
              <div className='compact'>
                <div className='set'>
                  <h5>Secure print release</h5>
                  <div className='status'></div>
                </div>
                <div className='set'>
                  <h5>Watermarking</h5>
                  <div className='status'></div>
                </div>
                <div className='set'>
                  <h5>Print Archiving</h5>
                  <div className='status'></div>
                </div>
              </div>
            </div>
          </div>

          <div className='col qtr'>
            <div className='card fh zebra'>
              <header>
                <i className='icon'>
                  <PeopleIcon />
                </i>
                <h6>Easy printing</h6>
              </header>
              <div className='compact'>
                <div className='set'>
                  <h5>Find-Me printing</h5>
                  <div className='status'></div>
                </div>
                <div className='set'>
                  <h5>Mobility Print</h5>
                  <div className='status'></div>
                </div>
                <div className='set'>
                  <h5>Web Print</h5>
                  <div className='status'></div>
                </div>
                <div className='set'>
                  <h5>Email to Print</h5>
                  <div className='status'></div>
                </div>
              </div>
            </div>
          </div>

          <div className='col qtr'>
            <div className='card fh zebra'>
              <header>
                <i className='icon'>
                  <ScannerIcon />
                </i>
                <h6>Scanning &amp; capture</h6>
              </header>
              <div className='compact'>
                <div className='set'>
                  <h5>Integrated Scanning</h5>
                  <div className='status'></div>
                </div>
                <div className='set'>
                  <h5>Scanning devices</h5>
                  <div className='status'></div>
                </div>
                <div className='set'>
                  <h5>OCR</h5>
                  <div className='status'></div>
                </div>
                <div className='set'>
                  <h5>Scan to Cloud Storage</h5>
                  <div className='status'></div>
                </div>
                <div className='set'>
                  <h5>Scan to Folder</h5>
                  <div className='status'></div>
                </div>
              </div>
            </div>
          </div>

          <div className='col qtr'>
            <div className='card fh zebra'>
              <header>
                <i className='icon'>
                  <GlobeIcon />
                </i>
                <h6>Responsible printing</h6>
              </header>
              <div className='compact'>
                <div className='set'>
                  <h5>Reporting (scheduled)</h5>
                  <div className='status'></div>
                </div>
                <div className='set'>
                  <h5>Print scripting</h5>
                  <div className='status'></div>
                </div>
                <div className='set'>
                  <h5>Device scripting</h5>
                  <div className='status'></div>
                </div>
                <h4>Sheets saved</h4>
                <div className='set'>
                  <h5>Via hold/release</h5>
                  <div className='status'></div>
                </div>
                <div className='set'>
                  <h5>Via admin policy</h5>
                  <div className='status'></div>
                </div>
                <div className='set'>
                  <h5>At device</h5>
                  <div className='status'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
