import { FeatureUsageData } from '../FeatureUsage/FeatureUsage'
import React, { FunctionComponent, useState } from 'react'
import { BarChartIcon, ExclaimIcon, GlobeIcon, LockIcon, PeopleIcon, ScannerIcon } from '../Icons/Icons'
import FeatureStatus from '../FeatureUsage/FeatureStatus'
import EnvironmentalStats from '../FeatureUsage/EnvironmentalStats'
import CustomerUpgradeRequired from '../FeatureUsage/CustomerUpgradeRequired'
import { HostStatus } from '../Host/Data'

interface Props {
  customerName: string
  hostStatus: HostStatus
  featureUsage?: FeatureUsageData
}

export const FeatureUsageTab: FunctionComponent<Props> = props => {
  const upgradeRequired = props.featureUsage === undefined
  const [upgradeRequiredModalDisplay, setUpgradeRequiredModalDisplay] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const learnMoreClicked = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    setUpgradeRequiredModalDisplay(true)
  }
  const upgradeModalClose = () => {
    setUpgradeRequiredModalDisplay(false)
  }

  return (
    <div id='tab-impact' className={`tab visible ` + (upgradeRequired ? 'upgrade-req' : '')}>
      {upgradeRequiredModalDisplay && <CustomerUpgradeRequired onClose={upgradeModalClose} />}

      {upgradeRequired && (
        <div className='alert warning flex-ver pin'>
          <i className='icon'>
            <ExclaimIcon />
          </i>
          <div className='message' data-heap-redact-text>
            Upgrade {props.customerName}'s PaperCut NG/MF installation to <strong>19.1 onwards</strong> to unlock insight into how they are using the product.{' '}
            <a href='#' className='upgrade btn outline x-sml' onClick={learnMoreClicked}>
              Learn more
            </a>
          </div>
        </div>
      )}
      <header className='flex flex-ver'>
        <h2>Feature usage</h2>
      </header>
      <div className='content'>
        {!upgradeRequired && (
          <div className='pullout'>
            <div className='row flex flex-nowrap'>
              <div className='col qtr flex flex-nowrap flex-ver center'>
                <div className='push'>
                  <i className='icon thirtytwo'>
                    <BarChartIcon />
                  </i>
                </div>
                <div data-heap-redact-text>
                  <small className='blk'>{props.customerName}</small>
                  <h4>{props.featureUsage?.level} feature usage</h4>
                </div>
              </div>
              <div className='divider-ver' />
              <div className='col threeqtr'>
                <h5>How do we calculate this?</h5>
                To make sure your customer is getting the most out of PaperCut, we've identified key features that have the biggest impact and weigh the features accordingly.{' '}
                {!showMore && (
                  <a
                    href='#'
                    onClick={e => {
                      e.preventDefault()
                      setShowMore(true)
                    }}
                  >
                    More info
                  </a>
                )}
                <div id='feat-usage' className={`collapsable-content margin-top ${showMore ? '' : 'hidden'}`}>
                  <ul className='disc'>
                    <li>
                      <strong>Basic:</strong> Your customer might be missing out on the benefit of some of our high-impact features... this could be a great opportunity to add more
                      value with PaperCut.
                    </li>
                    <li>
                      <strong>Standard:</strong> Your customer is getting value from some of our high-impact features. They have enabled at least two of{' '}
                      <strong>secure print release</strong>, <strong>Find-Me printing</strong>, <strong>Integrated Scanning</strong>, or <strong>scheduled reporting</strong>.
                    </li>
                    <li>
                      <strong>Advanced:</strong> Your customer is getting value from a wide range of PaperCut features — they have enabled <strong>five</strong> or more features,
                      including two that are high-impact!
                    </li>
                  </ul>
                  <a
                    href='#feat-usage'
                    className='action show-less'
                    data-toggle='collapsable'
                    onClick={e => {
                      e.preventDefault()
                      setShowMore(false)
                    }}
                  >
                    Show less
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}

        <hr />

        <div className='row flex'>
          <div className='col qtr'>
            <div className='card fh zebra'>
              <header>
                <i className='icon'>
                  <LockIcon />
                </i>
                <h6>Secure printing</h6>
              </header>
              <div className='compact'>
                <div className='set'>
                  <h5>Secure print release</h5>
                  <div className='status'>
                    <FeatureStatus used={props.featureUsage?.securePrinting.securePrintRelease ?? false} />
                  </div>
                </div>
                <div className='set'>
                  <h5>Watermarking</h5>
                  <div className='status'>
                    <FeatureStatus used={props.featureUsage?.securePrinting.watermarking ?? false} />
                  </div>
                </div>
                <div className='set'>
                  <h5>Print archiving</h5>
                  <div className='status'>
                    <FeatureStatus used={props.featureUsage?.securePrinting.printArchiving ?? false} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col qtr'>
            <div className='card fh zebra'>
              <header>
                <i className='icon'>
                  <PeopleIcon />
                </i>
                <h6>Easy printing</h6>
              </header>
              <div className='compact'>
                <div className='set'>
                  <h5>Find-Me printing</h5>
                  <div className='status'>
                    <FeatureStatus used={props.featureUsage?.easyPrinting.findMePrinting ?? false} />
                  </div>
                </div>
                <div className='set'>
                  <h5>Mobility Print</h5>
                  <div className='status'>
                    <FeatureStatus used={props.featureUsage?.easyPrinting.mobilityPrint ?? false} />
                  </div>
                </div>
                <div className='set'>
                  <h5>Web Print</h5>
                  <div className='status'>
                    <FeatureStatus used={props.featureUsage?.easyPrinting.webPrint ?? false} />
                  </div>
                </div>
                <div className='set'>
                  <h5>Email to Print</h5>
                  <div className='status'>
                    <FeatureStatus used={props.featureUsage?.easyPrinting.emailToPrint ?? false} />
                  </div>
                </div>
                <div className='set'>
                  <h5>Direct Print</h5>
                  <div className='status'>
                    <FeatureStatus used={props.featureUsage?.easyPrinting.directPrint ?? false} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col qtr'>
            <div className='card fh zebra'>
              <header>
                <i className='icon'>
                  <ScannerIcon />
                </i>
                <h6>Scanning &amp; capture</h6>
              </header>
              <div className='compact'>
                <div className='set'>
                  <h5>Integrated Scanning</h5>
                  <div className='status'>
                    <FeatureStatus used={props.featureUsage?.scanning.integratedScanning ?? false} />
                  </div>
                </div>
                <div className='set'>
                  <h5>Scanning devices</h5>
                  <div className='status'>{props.featureUsage?.scanning.scanningDevices ?? 0}</div>
                </div>
                <div className='set'>
                  <h5>OCR</h5>
                  <div className='status'>
                    <FeatureStatus used={props.featureUsage?.scanning.ocr ?? false} />
                  </div>
                </div>
                <div className='set'>
                  <h5>Scan to Cloud Storage</h5>
                  <div className='status'>
                    <FeatureStatus used={props.featureUsage?.scanning.scanToCloud ?? false} />
                  </div>
                </div>
                <div className='set'>
                  <h5>Scan to Folder</h5>
                  <div className='status'>
                    <FeatureStatus used={props.featureUsage?.scanning.scanToFolder ?? false} />
                  </div>
                </div>
                <div className='set'>
                  <h5>Scan to email</h5>
                  <div className='status'>
                    <FeatureStatus used={props.featureUsage?.scanning.scanToEmail ?? false} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col qtr'>
            <div className='card fh zebra'>
              <header>
                <i className='icon'>
                  <GlobeIcon />
                </i>
                <h6>Responsible printing</h6>
              </header>
              <div className='compact'>
                <div className='set'>
                  <h5>Scheduled reporting</h5>
                  <div className='status'>
                    <FeatureStatus used={props.featureUsage?.environment.scheduledReporting ?? false} />
                  </div>
                </div>
                <div className='set'>
                  <h5>Print scripting</h5>
                  <div className='status'>
                    <FeatureStatus used={props.featureUsage?.environment.printScripting ?? false} />
                  </div>
                </div>
                <div className='set'>
                  <h5>Device scripting</h5>
                  <div className='status'>
                    <FeatureStatus used={props.featureUsage?.environment.deviceScripting ?? false} />
                  </div>
                </div>
                <h4>Sheets saved</h4>
                <div className='set'>
                  <h5>Via hold/release</h5>
                  <div className='status'>
                    <EnvironmentalStats
                      pagesReleased={props.featureUsage?.environment.pagesReleased ?? 0}
                      featuredPagesReleased={props.featureUsage?.environment.ssViaHoldRelease ?? 0}
                    />
                  </div>
                </div>
                <div className='set'>
                  <h5>Via admin policy</h5>
                  <div className='status'>
                    <EnvironmentalStats
                      pagesReleased={props.featureUsage?.environment.pagesReleased ?? 0}
                      featuredPagesReleased={props.featureUsage?.environment.gsViaAdminPolicy ?? 0}
                    />
                  </div>
                </div>
                <div className='set'>
                  <h5>At the device</h5>
                  <div className='status'>
                    <EnvironmentalStats
                      pagesReleased={props.featureUsage?.environment.pagesReleased ?? 0}
                      featuredPagesReleased={props.featureUsage?.environment.gsAtDevice ?? 0}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
