import React, { ReactNode } from 'react'

interface Props {
  href: string
  styleId: string
  title: string
  description: string
  documents: Array<ReactNode>
  initialOpenState?: boolean
}

interface State {
  isOpened: boolean
}

class DocumentCategory extends React.Component<Props, State> {
  static defaultProps = {
    initialOpenState: true,
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      isOpened: !!this.props.initialOpenState,
    }
    this.changePolicyState = this.changePolicyState.bind(this)
  }

  changePolicyState() {
    if (this.state.isOpened) {
      this.setState({
        isOpened: false,
      })
    } else {
      this.setState({
        isOpened: true,
      })
    }
  }

  render() {
    return (
      <div className='list grid collapsable' data-type='resources'>
        <header>
          <a href={this.props.href} data-toggle='collapsable' className={` ${this.state.isOpened ? 'open' : ''}`} onClick={this.changePolicyState}>
            <h3>{this.props.title}</h3>
            <p>{this.props.description}</p>
          </a>
        </header>

        <ul id={this.props.styleId} className={` ${this.state.isOpened ? 'collapsable-content' : 'collapsable-content hidden'}`}>
          {this.props.documents}
        </ul>
      </div>
    )
  }
}

export default DocumentCategory
