class FeatureToggle {
  static isOn(teamID: string, name: string): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      fetch('/api/teams/' + teamID + '/feature/' + name, {
        method: 'GET',
        credentials: 'include',
      })
        .then(res => {
          return res.json()
        })
        .then(body => {
          resolve(body.on)
        })
        .catch(err => {
          console.log('failed to retrieve feature toggle ' + name + ', error ' + err)
          resolve(false)
        })
    })
  }
}

export default FeatureToggle
