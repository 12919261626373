import { useEffect, useState } from 'react'

export function useDebounce(value: string, delayInMilliseconds: number): string {
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delayInMilliseconds)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delayInMilliseconds])

  return debouncedValue
}
