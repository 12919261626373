import React, { FunctionComponent } from 'react'
import '../../assets/css/css/styles.css'
import CustomModal from '../MyModal/CustomModal'

interface Props {
  onContinue: () => void
}

export const DataCenterCheck: FunctionComponent<Props> = props => {
  return (
    <div id='page' className='auto'>
      <CustomModal id='data-center' close={props.onContinue}>
        <header>
          <img alt='PaperCut Multiverse logo' src='/img/logo-light.png' />
        </header>
        <div className='content'>
          <p>We've detected you may not be in the Americas.</p>
          <p>
            PaperCut Multiverse operates in multiple regions and has multiple data centers to support this. Each account is tied to a specific data center and must be accessed
            through its corresponding URL. You can switch to one of our other data centers now by selecting one below:
          </p>
          <div className='field'>
            <div className='select'>
              <select onChange={e => (window.location.href = e.currentTarget.value)}>
                <option>Select a data center</option>
                <option value='https://au.multiverse.papercut.com'>AU - https://au.multiverse.papercut.com</option>
                <option value='https://eu.multiverse.papercut.com'>EU - https://eu.multiverse.papercut.com</option>
                <option value='https://uk.multiverse.papercut.com'>UK - https://uk.multiverse.papercut.com</option>
              </select>
            </div>
          </div>

          <p>
            If you believe you need the US data center, you can continue on to <strong>https://multiverse.papercut.com</strong> using the button below.
          </p>
          <div className='center'>
            <a href='#' onClick={props.onContinue} className='btn fp'>
              Continue
            </a>
          </div>
        </div>
      </CustomModal>
    </div>
  )
}
