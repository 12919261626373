import React, { FunctionComponent } from 'react'
import styles from './SystemHealthSummary.module.css'
import { DisconnectedIcon } from '../Icons/Icons'
import { CriticalHeartbeat, GoodHeartbeat, WarningHeartbeat } from '../AnimatedSvg/AnimatedSvg'
import { HealthMetricStatus } from '../SystemHealth/Data'

interface Props {
  onClick: () => void
  isOpen: boolean
  dataAvailable: boolean
  status: HealthMetricStatus
  hasMaintenanceAndSupport: boolean
  isStaleData: boolean
  remoteMonitoringEnabled: boolean
}

export const SystemHealthSummary: FunctionComponent<Props> = props => {
  let statusClass = ''
  let statusText: string
  if (!props.remoteMonitoringEnabled) {
    statusClass = 'blocked'
    statusText = ''
  } else if (!props.hasMaintenanceAndSupport) {
    statusClass = 'blocked'
    statusText = 'M&S required'
  } else if (props.dataAvailable) {
    statusText = props.isStaleData ? 'Connection lost' : props.status.toString()
    switch (props.status) {
      case HealthMetricStatus.Critical:
        statusClass = 'error'
        break
      case HealthMetricStatus.Warning:
        statusClass = 'warning'
    }
  } else {
    statusClass = 'warning'
    statusText = 'Data not available'
  }

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    if (props.dataAvailable) {
      props.onClick()
    }
  }
  return (
    <li className={(props.remoteMonitoringEnabled ? `${styles.featurePanel}` : `${styles.disabledFeaturePanel}`) + (props.isOpen ? ' active' : '')}>
      <a href='#' className={props.remoteMonitoringEnabled ? '' : 'disabled'} onClick={onClick}>
        <div className='title'>System health</div>
        <div className={`status ${statusClass}`}>
          {(!props.hasMaintenanceAndSupport || !props.remoteMonitoringEnabled) && <i className='icon' />}
          {props.hasMaintenanceAndSupport && props.remoteMonitoringEnabled && (
            <i className='icon'>
              {(props.isStaleData && <DisconnectedIcon />) ||
                (props.status === HealthMetricStatus.Good && <GoodHeartbeat />) ||
                (props.status === HealthMetricStatus.Warning && <WarningHeartbeat />) ||
                (props.status === HealthMetricStatus.Critical && <CriticalHeartbeat />)}
            </i>
          )}
          <span className='state'>{statusText}</span>
        </div>
      </a>
    </li>
  )
}

export default SystemHealthSummary
