import React, { FunctionComponent, useState } from 'react'
import { EncryptIcon, PasswordIcon } from '../Icons/Icons'
import CustomModal from '../MyModal/CustomModal'
import { AuthType } from './Data'

interface Props {
  isOpen: boolean
  title: string
  close: () => void
  onSave: (authType: string) => void
  memberAuthType: string
}

const EditMemberAuthModal: FunctionComponent<Props> = props => {
  const [selectedAuthType, setSelectedAuthType] = useState(props.memberAuthType)
  const saveAuthTypeUpdate = async () => {
    props.onSave(selectedAuthType)
    props.close()
  }

  return props.isOpen ? (
    <CustomModal id='popup-edit-auth' close={props.close}>
      <header className='center'>
        <h3 data-heap-redact-text>{props.title}</h3>
      </header>

      <div className='content'>
        <div className='field'>
          <label>Authentication mode</label>
          <ul className='flex center option-list'>
            <li className={`${selectedAuthType !== AuthType.MFA ? 'active' : ''}`}>
              <a href='#' onClick={() => setSelectedAuthType(AuthType.Password)}>
                <i className='icon thirtytwo'>
                  <PasswordIcon />
                </i>
                <strong>Single-factor authentication</strong>
                <small className='blk'>Standard username/password</small>
              </a>
            </li>
            <li className={`${selectedAuthType === AuthType.MFA ? 'active' : ''}`}>
              <a href='#' onClick={() => setSelectedAuthType(AuthType.MFA)}>
                <i className='icon thirtytwo'>
                  <EncryptIcon />
                </i>
                <strong>Two-factor authentication</strong>
                <small className='blk'>Most secure option</small>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <footer className=''>
        <button id='cancel' className='btn outline' onClick={props.close}>
          Cancel
        </button>

        <button id='save' className='btn' onClick={saveAuthTypeUpdate}>
          <span className='text'>Save changes</span>
        </button>
      </footer>
    </CustomModal>
  ) : (
    <></>
  )
}

export default EditMemberAuthModal
