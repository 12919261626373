import React, { FunctionComponent, useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import { useTypedSelector } from '../../redux/reducers'
import { isDemo } from '../../redux/selectors'
import { MaintenanceAndSupportStatus } from '../Account/Data'
import { LoadingDots } from '../LoadingDots/LoadingDots'
import Toaster from '../Toaster'
import { LicenseDetails, LicenseDetailsRow } from './LicenseDetailsRow'

interface Props {
  data: License
  teamID: string
}

export interface License {
  id: number
  orgName: string
  edition: string
  orgType: string
  supportStatus: MaintenanceAndSupportStatus
  supportExpiry?: Date
  deviceCount: number
  crn: string
}

export const LicenseRow: FunctionComponent<Props> = ({ data, teamID }) => {
  const [isOpen, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [details, setDetails] = useState<LicenseDetails>()
  const demoMode = useTypedSelector(isDemo)
  let msClass = ''
  switch (data.supportStatus) {
    case MaintenanceAndSupportStatus.Expired:
      msClass = 'expired'
      break
    case MaintenanceAndSupportStatus.Expiring:
      msClass = 'expiring'
      break
  }
  const getLicenseDetails = (id: number) => {
    if (!details) {
      setLoading(true)
      axios
        .get<LicenseDetails>('/api/teams/' + teamID + '/licenses/' + id)
        .then(result => {
          return setDetails(result.data)
        })
        .catch(_ => {
          Toaster.notifyFailure('Unable to load license details.')
          setOpen(false)
        })
        .finally(() => setLoading(false))
    }
  }

  function handleClick() {
    setOpen(!isOpen)
    getLicenseDetails(data.id)
  }

  return (
    <li className={isOpen ? 'open' : ''}>
      <div className='row row-link' onClick={handleClick}>
        <div className='col customer-ref'>{data.crn}</div>
        <div className='col customer-name' data-heap-redact-text>
          {data.orgName}
        </div>
        <div className='col customer-cert'>PaperCut {data.edition}</div>
        <div className='col customer-type'>{data.orgType}</div>
        <div className='col customer-devices'>{data.deviceCount}</div>
        <div className='col customer-expiry'>
          {data.supportStatus === MaintenanceAndSupportStatus.None && <div>None</div>}
          {data.supportStatus !== MaintenanceAndSupportStatus.None && (
            <>
              <div>{moment(data.supportExpiry).format('DD MMM YYYY')}</div>
              {msClass ? <div className={`flag ${msClass}`}>{msClass}</div> : <div />}
            </>
          )}
        </div>
        <div className='col customer-actions'>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={demoMode ? '#' : `/api/teams/${teamID}/licenses/${data.id}/download`}
            onClick={e => {
              if (demoMode) e.preventDefault()
              e.stopPropagation()
            }}
            className='btn sml connect secondary'
          >
            Download
          </a>
        </div>
      </div>
      {isOpen &&
        (loading ? (
          <div className='row row-link'>
            <div className='empty loading' style={{ padding: '40px' }}>
              <LoadingDots />
            </div>
          </div>
        ) : (
          details && <LicenseDetailsRow data={details} />
        ))}
    </li>
  )
}
