import React, { ChangeEvent, FunctionComponent, useState } from 'react'
import { EmailValidator } from '../../service/EmailValidator'
import { ExclaimIcon } from '../Icons/Icons'
import CustomModal from '../MyModal/CustomModal'
import Toaster from '../Toaster'
import { UserRole } from './Data'
import RoleSelection from './RoleSelection'

interface Props {
  isOpen: boolean
  close: () => void
  sendInviteEmail: (email: string, roles: UserRole[]) => Promise<void | number>
  reinviteEmail?: string
  reinviteRoles?: UserRole[]
}

const InviteModal: FunctionComponent<Props> = props => {
  const [email, setEmail] = useState(props.reinviteEmail ?? '')
  const [sendingInvitation, setSendingInvitation] = useState(false)
  const [roles, setRoles] = useState<UserRole[]>(props.reinviteRoles ?? [])

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value)
  }

  const canSendInvite = () => {
    return email !== '' && roles.length > 0
  }

  const checkError = (res: number) => {
    switch (res) {
      case 409:
        Toaster.notifyFailure(`${email} is already in the team.`)
        break
      default:
        Toaster.notifyFailure('Unable to send invitation.')
    }
  }

  const sendEmailSuccess = () => {
    Toaster.notifySuccess('Invitation sent.')
    setEmail('')
    setRoles([])
    if (props.reinviteEmail) {
      props.close()
    }
  }

  const sendInvite = () => {
    if (!EmailValidator.validate(email)) {
      Toaster.notifyFailure('Invalid email address.')
      return
    }
    setSendingInvitation(true)
    props
      .sendInviteEmail(email.toLowerCase(), roles)
      .then(sendEmailSuccess)
      .catch(error => {
        checkError(error)
      })
      .finally(() => {
        setSendingInvitation(false)
      })
  }

  const handleClose = () => {
    setEmail('')
    setRoles([])
    props.close()
  }

  return props.isOpen ? (
    <CustomModal id='popup-team' close={handleClose}>
      <header className='center'>
        {!props.reinviteEmail && (
          <>
            <h3>Invite team member</h3>

            <p>Add team members and assign their roles and permissions.</p>
          </>
        )}

        {props.reinviteEmail && <h3 data-heap-redact-text>Reinvite {props.reinviteEmail}</h3>}
      </header>

      <div className='alert error hide'>
        <i className='icon-exclaim'>
          <ExclaimIcon />
        </i>

        <div className='message' />
      </div>

      <div className='content'>
        {!props.reinviteEmail && (
          <div className='field' data-name='user-email'>
            <label>Email address</label>
            <input id='email' type='text' placeholder='email@example.com' value={email} onChange={handleEmailChange} />
          </div>
        )}

        <RoleSelection onRolesChange={setRoles} roles={roles} />
      </div>
      <footer className=''>
        <button className={`btn fp center ${canSendInvite() && !sendingInvitation ? '' : 'disabled'}`} disabled={!canSendInvite() || sendingInvitation} onClick={sendInvite}>
          {props.reinviteEmail ? 'Resend invitation' : 'Send invitation'}
        </button>
      </footer>
    </CustomModal>
  ) : (
    <></>
  )
}

export default InviteModal
