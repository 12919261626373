import React, { FunctionComponent, useState } from 'react'
import { LogAccessEvent } from '../../api/events'
import canonDevice from '../../assets/img/mfd-canon.png'
import mf from '../../assets/img/mf.jpg'
import { defaultAuthHandler } from '../../auth'
import { useMountEffect } from '../../hooks/MountEffect'
import { useTypedSelector } from '../../redux/reducers'
import { isDemo } from '../../redux/selectors'
import { LaptopIcon, MfdIcon } from '../Icons/Icons'
import { AccessConfig, LinkData, MfdData } from './Data'

interface Props {
  config: AccessConfig
  close: () => void
  closeWithoutConfirmation: () => void
}

const AccessFrame: FunctionComponent<Props> = props => {
  const demoMode = useTypedSelector(isDemo)
  const config = props.config
  const components = config.components

  const teamID = defaultAuthHandler.getTeamID()
  const userName = defaultAuthHandler.getUserName()

  const showMfds = config.getMfds !== undefined
  const isNavNeeded = showMfds || components.length > 1
  const autoEmbed = components.length > 0 && !components[0].disabledStatus

  const [currentLink, setCurrentLink] = useState(autoEmbed ? components[0] : null)
  const [mfds, setMfds] = useState<MfdData>()
  const [navVisible, setNavVisible] = useState(isNavNeeded)

  const logEvent = (link: LinkData) => {
    LogAccessEvent(teamID, config.hostID, userName, link.name)
  }

  useMountEffect(() => {
    if (currentLink) {
      logEvent(currentLink)
    }

    if (config.getMfds) {
      config.getMfds().then(mfdsResponse => setMfds(mfdsResponse))
    }
  })

  const embed = (component: LinkData) => {
    logEvent(component)
    setCurrentLink(component)
  }

  const getComponentClass = (component: LinkData) => {
    if (component.disabledStatus) {
      return 'disabled'
    }

    if (currentLink && currentLink.url === component.url) {
      return 'active'
    }

    return ''
  }

  return (
    <aside className={`visible ${currentLink ? '' : 'nav-only'}`}>
      {navVisible && (
        <nav>
          <header>
            <h3>{config.hostName}</h3>

            <small>Remote management</small>
          </header>

          {!currentLink && (
            <a onClick={props.closeWithoutConfirmation} className='lines-button close'>
              <span className='lines' />
            </a>
          )}

          {components.length > 0 && (
            <div data-cat='components'>
              <header>
                <i className='icon thirtytwo'>
                  <LaptopIcon />
                </i>

                <h5>PaperCut components</h5>
              </header>

              <ul data-heap-redact-text>
                {components.map(component => (
                  <li key={component.url} className={getComponentClass(component)}>
                    {component.disabledStatus && (
                      <span>
                        {component.name} ({component.disabledStatus})
                      </span>
                    )}

                    {!component.disabledStatus && component === currentLink && <span>{component.name}</span>}

                    {!component.disabledStatus && component !== currentLink && <a onClick={() => embed(component)}>{component.name}</a>}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {showMfds && (
            <div data-cat='mfds'>
              <header>
                <i className='icon thirtytwo'>
                  <MfdIcon />
                </i>

                <h5>Multifunction devices</h5>

                <small>MFDs available: {mfds?.list.length ?? '...'}</small>
              </header>

              {mfds?.note && <small>{mfds.note}</small>}

              {mfds && mfds.list.length > 0 && (
                <ul data-heap-redact-text>
                  {mfds.list.map(mfd => (
                    <li key={mfd.url}>
                      <a href={demoMode ? canonDevice : mfd.url} target='_blank' rel='noopener noreferrer' onClick={() => logEvent(mfd)}>
                        {mfd.name} - {mfd.subName}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
        </nav>
      )}

      <header className={`${isNavNeeded ? '' : 'no-toggle'}`}>
        {isNavNeeded && (
          <a aria-label='Toggle navigation' className='nav-toggle lines-button' onClick={() => setNavVisible(!navVisible)}>
            <span className='lines' />
          </a>
        )}

        {currentLink && (
          <>
            <div className='row flex'>
              <div className='col' data-heap-redact-text>
                {navVisible && (
                  <>
                    {currentLink.name}

                    {currentLink.subName && <h5>{currentLink.subName}</h5>}
                  </>
                )}
                {!navVisible && (
                  <>
                    {config.hostName}

                    <h5>
                      {currentLink.name}

                      {currentLink.subName && ` - ${currentLink.subName}`}
                    </h5>
                  </>
                )}
              </div>
            </div>

            <a style={{ position: 'absolute', top: '12px', right: '42px' }} href={currentLink.url} className='fullscreen' target='_blank' rel='noopener noreferrer'>
              Full screen
            </a>
          </>
        )}

        <a onClick={props.close} className='lines-button close'>
          <span className='lines' />
        </a>
      </header>

      {currentLink && <div className='window'>{demoMode ? <img src={mf} alt='mf' /> : <iframe title={currentLink.name} src={currentLink.url} />}</div>}
    </aside>
  )
}

export default AccessFrame
