import React, { FunctionComponent } from 'react'
import { ErrorIcon, TickIcon, WarningIcon } from '../Icons/Icons'
import { HealthMetricStatus, ThresholdMessages } from './Data'

interface Props {
  status?: HealthMetricStatus
  details?: string
  thresholdMessages?: ThresholdMessages
}

export const HealthMetricStatusIcon: FunctionComponent<Props> = props => {
  return (
    <i
      className={`icon sixteen 
            ${props.status === HealthMetricStatus.Critical ? 'error' : ''}
            ${props.status === HealthMetricStatus.Warning ? 'warning' : ''}
            ${props.status === HealthMetricStatus.None ? 'idle' : ''}
            `}
    >
      {props.status === HealthMetricStatus.Good && (
        <div>
          <TickIcon />

          {props.thresholdMessages && (
            <span className='tip'>
              <ul>
                <li key='title'>
                  <b>Alert thresholds</b>
                </li>

                {props.thresholdMessages.critical &&
                  props.thresholdMessages.critical.map(message => (
                    <li key={message} className='sml level-error'>
                      {message}
                    </li>
                  ))}

                {props.thresholdMessages.warning &&
                  props.thresholdMessages.warning.map(message => (
                    <li key={message} className='sml level-warning'>
                      {message}
                    </li>
                  ))}
              </ul>
            </span>
          )}
        </div>
      )}

      {props.status === HealthMetricStatus.Warning && (
        <div>
          <WarningIcon />
          <span className='tip'>{props.details}</span>
        </div>
      )}

      {props.status === HealthMetricStatus.Critical && (
        <div>
          <ErrorIcon />
          <span className='tip'>{props.details}</span>
        </div>
      )}

      {props.status === HealthMetricStatus.None && (
        <div>
          <ErrorIcon />
        </div>
      )}
    </i>
  )
}

export default HealthMetricStatusIcon
