import React, { FunctionComponent, useState } from 'react'
import AuthRole from '../../../role'
import { Timestamps } from '../../../service/Timestamps'
import { EditCustomerFunction, HostData } from '../../Host/Data'
import { NoBellIcon } from '../../Icons/Icons'
import CustomerModal from '../../MyHosts/CustomerModal'

interface Props {
  data: HostData
  teamID: string
  editCustomer: EditCustomerFunction
  notificationPauseFinishTime?: Date
}

const HiveSystemInfo: FunctionComponent<Props> = props => {
  const [showEditCustomer, setShowEditCustomer] = useState(false)

  const addTagClicked = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    setShowEditCustomer(true)
  }

  return (
    <>
      <h4>
        <span className='ellipsis' data-heap-redact-text>
          {props.data.name}
        </span>

        {props.notificationPauseFinishTime && (
          <i className='icon twentyfour no-bell'>
            <span className='tip center'>Notifications paused until: {Timestamps.format(props.notificationPauseFinishTime)}</span>

            <NoBellIcon />
          </i>
        )}
      </h4>

      <dl className='icons'>
        <dt>Tags</dt>
        <dd data-heap-redact-text>
          {props.data.tags?.map(tag => (
            <div key={tag} className='tag'>
              {' '}
              {tag}{' '}
            </div>
          ))}

          {AuthRole.hasPermission('manageCustomer') && (
            <a title={'Add tags'} className='btn add outline x-sml' onClick={addTagClicked}>
              {props.data.tags && props.data.tags.length > 0 ? '＋' : 'Add tag'}
            </a>
          )}
        </dd>

        <dt>PaperCut</dt>

        <dd>PaperCut Hive</dd>

        <dt>Hosted</dt>

        <dd>
          <span>Hosted on PaperCut Cloud Services</span>
        </dd>
      </dl>

      {showEditCustomer && (
        <CustomerModal
          key={props.data.id}
          teamID={props.teamID}
          action={props.editCustomer}
          close={() => setShowEditCustomer(false)}
          isVisible={showEditCustomer}
          host={props.data}
          addTag={true}
        />
      )}
    </>
  )
}

export default HiveSystemInfo
