import React, { FunctionComponent, useState } from 'react'
import serviceProviderInvite from '../../assets/img/invite.png'
import ServiceProviderInviteModal from './ServiceProviderInviteModal'

interface Props {
  teamID: string
}

const ServiceProviders: FunctionComponent<Props> = props => {
  const [inviteServiceProviderModelOpen, setInviteServiceProviderModelOpen] = useState(false)
  const onInviteServiceProviderModalClose = () => {
    setInviteServiceProviderModelOpen(false)
  }

  const onInviteServiceProviderButtonClicked = () => {
    setInviteServiceProviderModelOpen(true)
  }

  return (
    <div id='tab-resellers' className='tab'>
      <div className='auto'>
        <div className='row flex'>
          <div className='threeqtr col'>
            <div className='list'></div>
          </div>

          <div className='qtr col'>
            <div className='empty'>
              <figure aria-labelledby='invite'>
                <img src={serviceProviderInvite} alt='' />
              </figure>

              <p id='invite'>Give your service providers access by inviting them to PaperCut Multiverse.</p>

              <button className='btn invite-reseller' onClick={onInviteServiceProviderButtonClicked}>
                Invite service provider
              </button>
            </div>
          </div>
        </div>

        {inviteServiceProviderModelOpen && <ServiceProviderInviteModal teamID={props.teamID} onClose={onInviteServiceProviderModalClose} />}
      </div>
    </div>
  )
}

export default ServiceProviders
