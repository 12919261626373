import React, { FunctionComponent } from 'react'
import { AdvancedFeatureGaugeIcon, BasicFeatureGaugeIcon, StandardFeatureGaugeIcon, UpgradeGaugeIcon } from '../Icons/Icons'
import styles from './FeatureUsageSummary.module.css'
import { FeatureUsageLevel } from '../FeatureUsage/FeatureUsage'

interface Props {
  onClick: () => void
  isOpen: boolean
  dataAvailable: boolean
  level: FeatureUsageLevel
  remoteMonitoringEnabled: boolean
}

const FeatureUsageSummary: FunctionComponent<Props> = props => {
  let onClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    props.onClick()
  }

  let statusClass = ''
  let statusSpan = <span className='state green'>{props.level}</span>
  let featureGaugeIcon = <UpgradeGaugeIcon />
  if (!props.remoteMonitoringEnabled) {
    statusClass = 'blocked'
    statusSpan = <span className='state'></span>
  } else if (props.dataAvailable) {
    featureGaugeIcon = <BasicFeatureGaugeIcon />
    if (props.level === FeatureUsageLevel.Standard) {
      featureGaugeIcon = <StandardFeatureGaugeIcon />
    } else if (props.level === FeatureUsageLevel.Advanced) {
      featureGaugeIcon = <AdvancedFeatureGaugeIcon />
    }
  } else {
    statusClass = 'upgrade'
    statusSpan = <span className='state'>Upgrade required</span>
  }
  return (
    <li className={(props.remoteMonitoringEnabled ? `${styles.featurePanel}` : `${styles.disabledFeaturePanel}`) + (props.isOpen ? ' active' : '')}>
      <a href='#' className={props.remoteMonitoringEnabled ? '' : 'disabled'} onClick={onClick}>
        <div className='title'>Feature usage</div>
        <div className={`status ` + statusClass}>
          <i className='icon'>{featureGaugeIcon}</i>
          {statusSpan}
        </div>
      </a>
    </li>
  )
}

export default FeatureUsageSummary
