import React, { FunctionComponent, useState } from 'react'
import { Link } from 'react-router-dom'
import { defaultAuthHandler } from '../../auth'
import { UserConsumer } from '../../auth/UserContext'
import { useTypedSelector } from '../../redux/reducers'
import { getPmitcSessionLogoutLink, getPmitcSessionName, getPmitcSessionWindow } from '../../redux/selectors'
import PmitcSessionModal from '../PmitcSessionModal/PmitcSessionModal'
import { useClickOutside } from '../UseClickOutside/UseClickOutside'
import styles from './UserActions.module.css'

export const UserActions: FunctionComponent = () => {
  const openClass = 'open-user'

  const handleOnClick = () => {
    document.body.classList.toggle(openClass)
  }

  const handleLogout = () => {
    if (pmitcSessionWindow && !pmitcSessionWindow.closed) {
      setShowEndPmitcSessionConfirmModal(true)
    } else {
      if (pmitcSessionWindow && pmitcSessionLogoutLink) {
        // Tab is closed so open a new tab to log out in case they did not
        pmitcSessionWindow = window.open(pmitcSessionLogoutLink) || undefined
      }

      defaultAuthHandler.logout()
    }
  }

  const ref = useClickOutside(() => document.body.classList.remove(openClass))
  const [showEndPmitcSessionConfirmModal, setShowEndPmitcSessionConfirmModal] = useState(false)
  const pmitcSessionLogoutLink = useTypedSelector(getPmitcSessionLogoutLink)
  const pmitcSessionName = useTypedSelector(getPmitcSessionName)
  let pmitcSessionWindow = useTypedSelector(getPmitcSessionWindow)

  return (
    <UserConsumer>
      {userProps => {
        return (
          <div ref={ref} className='nav-account' onClick={handleOnClick} data-heap-redact-attributes='aria-label'>
            <a id='user-menu' href='#' aria-label={userProps.userName}>
              <i>
                <img src={userProps.profilePicture} alt='' />
              </i>

              <div className='user'>
                <strong className={styles.userName}>
                  <span data-heap-redact-text>{userProps.userName}</span>
                </strong>
              </div>
            </a>

            <div className='user-menu'>
              <ul>
                <li>
                  <Link className={styles.userMenuItem} to='/user-settings'>
                    User settings
                  </Link>
                </li>

                <li>
                  <a className={styles.userMenuItem} onClick={() => handleLogout()}>
                    Sign out
                  </a>
                </li>
              </ul>
            </div>

            {showEndPmitcSessionConfirmModal && (
              <PmitcSessionModal
                customer={pmitcSessionName}
                onClose={() => setShowEndPmitcSessionConfirmModal(false)}
                onYes={() => {
                  if (pmitcSessionWindow && pmitcSessionLogoutLink) {
                    pmitcSessionWindow.location.href = pmitcSessionLogoutLink
                  }

                  defaultAuthHandler.logout()
                }}
                exiting={true}
              />
            )}
          </div>
        )
      }}
    </UserConsumer>
  )
}

export default UserActions
