import React, { FunctionComponent, useState } from 'react'
import AuthRole from '../../../role'
import { EditCustomerFunction, HostData } from '../../Host/Data'
import CustomerModal from '../../MyHosts/CustomerModal'
import styles from './HiveAccount.module.css'

interface Props {
  data: HostData
  onClose: () => void
  teamID: string
  editCustomer: EditCustomerFunction
}

const HiveAccount: FunctionComponent<Props> = props => {
  const [showEditCustomer, setShowEditCustomer] = useState(false)
  const canManage = AuthRole.hasPermission('manageCustomer')
  const footerClass = canManage ? '' : styles.emptyFooter

  const onClose = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    props.onClose()
  }

  return (
    <div id='tab-account' className='tab'>
      <header>
        Key customer details
        <a href='#' className='action' onClick={onClose} aria-label='Close Account tab' />
      </header>

      <div className='content'>
        <div className='col fp details'>
          <div className='row flex fh'>
            <div className='col third'>
              <header className='flex'>
                <h6>Profile</h6>
              </header>

              <dl className='zebra'>
                <dt className='alt'>Organization</dt>
                <dd className='alt' data-heap-redact-text>
                  {props.data.name}
                </dd>
                <dt className='alt'>Tags</dt>
                <dd className='alt'>
                  <ul>
                    {props.data.tags?.map(value => (
                      <li key={value}>
                        <div className='tag' data-heap-redact-text>
                          {value}
                          <a href='#' className='lines-button close remove'>
                            <span className='lines'></span>
                          </a>
                        </div>
                      </li>
                    ))}
                  </ul>
                </dd>
              </dl>

              <footer className={footerClass}>
                {canManage && (
                  <a
                    href='#'
                    className='edit-customer btn'
                    onClick={e => {
                      e.preventDefault()
                      setShowEditCustomer(true)
                    }}
                  >
                    Edit profile
                  </a>
                )}

                {showEditCustomer && (
                  <CustomerModal
                    key={props.data.id}
                    teamID={props.teamID}
                    isVisible={showEditCustomer}
                    close={() => setShowEditCustomer(false)}
                    action={props.editCustomer}
                    host={props.data}
                  />
                )}
              </footer>
            </div>

            <div className='col third coming-soon'>
              <span className='sample'>Need this?</span>

              <header className='flex'>
                <h6>Key subscription info</h6>
              </header>

              <dl className='zebra'>
                <dt className='alt'>Issued to</dt>
                <dd className='alt'></dd>
                <dt className='alt'>Organization ID</dt>
                <dd className='alt'></dd>
                <dt className='alt'>Product</dt>
                <dd className='alt'></dd>
                <dt className='alt'>Subscription</dt>
                <dd className='alt'></dd>
                <dt className='alt'>Valid between</dt>
                <dd className='alt'></dd>
                <dt className='alt'>Reference number</dt>
                <dd className='alt'></dd>
                <dt className='alt'>Subscription type</dt>
                <dd className='alt'></dd>
              </dl>

              <footer className={styles.emptyFooter} />
            </div>

            <div className='col third coming-soon'>
              <span className='sample'>Need this?</span>

              <header className='flex'>
                <h6>Entitlements</h6>
              </header>

              <dl className='zebra'>
                <dt className='alt'>Active users</dt>
                <dd className='alt'></dd>
                <dt className='alt'>Devices with print release only</dt>
                <dd className='alt'></dd>
                <dt className='alt'>Devices with Full Embedded app</dt>
                <dd className='alt'></dd>
                <dt className='alt'>Devices with Lite Release app</dt>
                <dd className='alt'></dd>
              </dl>

              <footer className={styles.emptyFooter} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HiveAccount
