import React, { FunctionComponent } from 'react'
import { ExclaimIcon } from '../Icons/Icons'
import CustomModal from '../MyModal/CustomModal'

interface Props {
  title: string
  message: string | React.ReactNode
  yesLabel?: string
  onClose: () => void
  onYes: () => void
  cannotUndoWarning?: boolean
}

const ConfirmationModal: FunctionComponent<Props> = ({ title, message, yesLabel = 'Confirm', onClose, onYes, cannotUndoWarning = true }) => {
  const confirmAction = async () => {
    await onYes()
    onClose()
  }

  return (
    <CustomModal id='popup-delete' close={onClose}>
      <form>
        <header className='center'>
          <h3 data-heap-redact-text>{title}</h3>
        </header>

        {cannotUndoWarning && (
          <div className='alert error'>
            <i className='icon-exclaim'>
              <ExclaimIcon />
            </i>

            <div className='message'>This action cannot be undone.</div>
          </div>
        )}

        <div className='content' data-heap-redact-text>
          {typeof message === 'string' || message instanceof String ? <p>{message}</p> : message}
        </div>

        <footer>
          <button id='cancel' className='btn outline' onClick={onClose}>
            Cancel
          </button>

          <button id='confirm' className={`btn ${cannotUndoWarning ? 'danger' : ''}`} onClick={confirmAction}>
            <span className='text'>{yesLabel}</span>
          </button>
        </footer>
      </form>
    </CustomModal>
  )
}

export default ConfirmationModal
