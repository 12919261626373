// Hook
import { useEffect, useRef } from 'react'

export function useClickOutside(callback: EventListener) {
  const container = useRef<HTMLDivElement>(null)

  function handleEvent(e: Event) {
    if (container.current && e.target !== null) {
      if (!container.current.contains(e.target as Node)) {
        callback(e)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleEvent, true)
    document.addEventListener('touchstart', handleEvent, true)

    return () => {
      document.removeEventListener('click', handleEvent, true)
      document.removeEventListener('touchstart', handleEvent, true)
    }
  })

  return container
}
