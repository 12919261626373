import React, { FunctionComponent } from 'react'
import discuss from '../../assets/img/discuss.png'
import { supportEmail } from '../ContactSupport/Data'
import { Document, Language } from './Document'
import DocumentCategory from './DocumentCategory'

const InfoCenter: FunctionComponent = () => {
  return (
    <div id='tab-hub' className='tab'>
      <header>
        <div className='auto'>
          <h2>Info Center</h2>

          <p>
            In the Info Center, you will find everything you need to get a deeper understanding about key aspects of PaperCut Multiverse, and content you can distribute to your
            customers to ensure their confidence in the platform.
          </p>
        </div>
      </header>

      <div className='auto'>
        <div className='row flex'>
          <div className='sixty col'>
            <DocumentCategory
              href={'#hub-policy'}
              styleId={'hub-policy'}
              title={'For your customers'}
              description={'Find all the information you need to give your customers confidence in PaperCut Multiverse, including a one-pager, transparency about data, and more.'}
              initialOpenState={true}
              documents={[
                <Document
                  key='intro'
                  title={'Introducing PaperCut Multiverse'}
                  type={'Policy'}
                  description={'An introduction to our remote service platform for PaperCut resellers.'}
                  linkText={'Download PDF'}
                  urls={
                    new Map([
                      [Language.English, 'https://storage.googleapis.com/us.artifacts.multiverse-usa.appspot.com/Introducing%20PaperCut%20Multiverse%20(Customers).pdf'],
                      [
                        Language.Catalan,
                        'https://storage.googleapis.com/us.artifacts.multiverse-usa.appspot.com/Introducing%20PaperCut%20Multiverse%20(Customers)%20(Catalan).pdf',
                      ],
                      [Language.Danish, 'https://storage.googleapis.com/us.artifacts.multiverse-usa.appspot.com/Introducing%20PaperCut%20Multiverse%20(Customers)%20(Danish).pdf'],
                      [Language.Dutch, 'https://storage.googleapis.com/us.artifacts.multiverse-usa.appspot.com/Introducing%20PaperCut%20Multiverse%20(Customers)%20(Dutch).pdf'],
                      [Language.French, 'https://storage.googleapis.com/us.artifacts.multiverse-usa.appspot.com/Introducing%20PaperCut%20Multiverse%20(Customers)%20(French).pdf'],
                      [Language.German, 'https://storage.googleapis.com/us.artifacts.multiverse-usa.appspot.com/Introducing%20PaperCut%20Multiverse%20(Customers)%20(German).pdf'],
                      [
                        Language.Italian,
                        'https://storage.googleapis.com/us.artifacts.multiverse-usa.appspot.com/Introducing%20PaperCut%20Multiverse%20(Customers)%20(Italian).pdf',
                      ],
                      [Language.Polish, 'https://storage.googleapis.com/us.artifacts.multiverse-usa.appspot.com/Introducing%20PaperCut%20Multiverse%20(Customers)%20(Polish).pdf'],
                      [
                        Language.PortugueseInPortugal,
                        'https://storage.googleapis.com/us.artifacts.multiverse-usa.appspot.com/Introducing%20PaperCut%20Multiverse%20(Customers)%20(Portuguese).pdf',
                      ],
                      [
                        Language.Russian,
                        'https://storage.googleapis.com/us.artifacts.multiverse-usa.appspot.com/Introducing%20PaperCut%20Multiverse%20(Customers)%20(Russian).pdf',
                      ],
                      [Language.Slovak, 'https://storage.googleapis.com/us.artifacts.multiverse-usa.appspot.com/Introducing%20PaperCut%20Multiverse%20(Customers)%20(Slovak).pdf'],
                      [
                        Language.SpanishInSpain,
                        'https://storage.googleapis.com/us.artifacts.multiverse-usa.appspot.com/Introducing%20PaperCut%20Multiverse%20(Customers)%20(Spanish).pdf',
                      ],
                      [
                        Language.Swedish,
                        'https://storage.googleapis.com/us.artifacts.multiverse-usa.appspot.com/Introducing%20PaperCut%20Multiverse%20(Customers)%20(Swedish).pdf',
                      ],
                    ])
                  }
                />,
                <Document
                  key='protecting-data'
                  title={'PaperCut Multiverse - Protecting your data & privacy'}
                  type={'Policy'}
                  description={'Learn about how we handle your data and how you can control access to your environment.'}
                  linkText={'Download PDF'}
                  urls={
                    new Map([
                      [
                        Language.English,
                        'https://storage.googleapis.com/us.artifacts.multiverse-usa.appspot.com/PaperCut%20Multiverse%20-%20Protecting%20your%20data%20%26%20privacy%20(Customers).pdf',
                      ],
                      [
                        Language.Catalan,
                        'https://storage.googleapis.com/us.artifacts.multiverse-usa.appspot.com/PaperCut%20Multiverse%20-%20Protecting%20your%20data%20%26%20privacy%20(Customers)%20(Catalan).pdf',
                      ],
                      [
                        Language.ChineseInChina,
                        'https://storage.googleapis.com/us.artifacts.multiverse-usa.appspot.com/PaperCut%20Multiverse%20-%20Protecting%20your%20data%20%26%20privacy%20(Customers)%20(Chinese%2C%20China).pdf',
                      ],
                      [
                        Language.ChineseInHongKong,
                        'https://storage.googleapis.com/us.artifacts.multiverse-usa.appspot.com/PaperCut%20Multiverse%20-%20Protecting%20your%20data%20%26%20privacy%20(Customers)%20(Chinese%2C%20Hong%20Kong).pdf',
                      ],
                      [
                        Language.Danish,
                        'https://storage.googleapis.com/us.artifacts.multiverse-usa.appspot.com/PaperCut%20Multiverse%20-%20Protecting%20your%20data%20%26%20privacy%20(Customers)%20(Danish).pdf',
                      ],
                      [
                        Language.French,
                        'https://storage.googleapis.com/us.artifacts.multiverse-usa.appspot.com/PaperCut%20Multiverse%20-%20Protecting%20your%20data%20%26%20privacy%20(Customers)%20(French).pdf',
                      ],
                      [
                        Language.German,
                        'https://storage.googleapis.com/us.artifacts.multiverse-usa.appspot.com/PaperCut%20Multiverse%20-%20Protecting%20your%20data%20%26%20privacy%20(Customers)%20(German).pdf',
                      ],
                      [
                        Language.Italian,
                        'https://storage.googleapis.com/us.artifacts.multiverse-usa.appspot.com/PaperCut%20Multiverse%20-%20Protecting%20your%20data%20%26%20privacy%20(Customers)%20(Italian).pdf',
                      ],
                      [
                        Language.Polish,
                        'https://storage.googleapis.com/us.artifacts.multiverse-usa.appspot.com/PaperCut%20Multiverse%20-%20Protecting%20your%20data%20%26%20privacy%20(Customers)%20(Polish).pdf',
                      ],
                      [
                        Language.PortugueseInPortugal,
                        'https://storage.googleapis.com/us.artifacts.multiverse-usa.appspot.com/PaperCut%20Multiverse%20-%20Protecting%20your%20data%20%26%20privacy%20(Customers)%20(Portuguese,%20Portugal).pdf',
                      ],
                      [
                        Language.Russian,
                        'https://storage.googleapis.com/us.artifacts.multiverse-usa.appspot.com/PaperCut%20Multiverse%20-%20Protecting%20your%20data%20%26%20privacy%20(Customers)%20(Russian).pdf',
                      ],
                      [
                        Language.SpanishInSpain,
                        'https://storage.googleapis.com/us.artifacts.multiverse-usa.appspot.com/PaperCut%20Multiverse%20-%20Protecting%20your%20data%20%26%20privacy%20(Customers)%20(Spanish,%20Spain).pdf',
                      ],
                      [
                        Language.Swedish,
                        'https://storage.googleapis.com/us.artifacts.multiverse-usa.appspot.com/PaperCut%20Multiverse%20-%20Protecting%20your%20data%20%26%20privacy%20(Customers)%20(Swedish).pdf',
                      ],
                      [
                        Language.Thai,
                        'https://storage.googleapis.com/us.artifacts.multiverse-usa.appspot.com/PaperCut%20Multiverse%20-%20Protecting%20your%20data%20%26%20privacy%20(Customers)%20(Thai).pdf',
                      ],
                    ])
                  }
                />,
                <Document
                  key='eula'
                  title='EULA'
                  type={'Policy'}
                  description={'Contractual agreement for terms of use of the Connector software.'}
                  linkText={'Download PDF'}
                  urls={
                    new Map([
                      [Language.English, 'https://storage.googleapis.com/us.artifacts.multiverse-usa.appspot.com/PaperCut%20Multiverse%20Connector%20License%20Agreement.pdf'],
                    ])
                  }
                />,
              ]}
            />

            <DocumentCategory
              href={'#hub-guide'}
              styleId={'hub-guide'}
              title={'Security & data'}
              description={
                'Provide your customers in-depth details for the number-one IT issue... security. Share the latest updates for data privacy and protection and add customer confidence through the data sovereignty and security document.'
              }
              initialOpenState={true}
              documents={[
                <Document
                  key='security-deep-dive'
                  title={'PaperCut Multiverse - Security & data privacy deep dive'}
                  type={'Guide'}
                  description={'A deep dive into security of the platform, handling of data and guidelines on protecting customer infrastructure.'}
                  linkText={'Download PDF'}
                  urls={
                    new Map([
                      [
                        Language.English,
                        'https://storage.googleapis.com/us.artifacts.multiverse-usa.appspot.com/PaperCut%20Multiverse%20-%20Security%20%26%20data%20privacy%20deep%20dive.pdf',
                      ],
                      [
                        Language.French,
                        'https://storage.googleapis.com/us.artifacts.multiverse-usa.appspot.com/PaperCut%20Multiverse%20-%20Security%20%26%20data%20privacy%20deep%20dive%20(French).pdf',
                      ],
                    ])
                  }
                />,
              ]}
            />

            <DocumentCategory
              href={'#hub-platform'}
              styleId={'hub-policy'}
              title={'Platform information'}
              description={'Everything you need to get the most out of PaperCut Multiverse.'}
              initialOpenState={true}
              documents={[
                <Document
                  key='updates'
                  title='Product updates'
                  type='Policy'
                  description='Find all the platform updates from the latest features available and known issues to date.'
                  linkText='Open'
                  urls={new Map([[Language.English, 'https://product-updates.multiverse.papercut.com']])}
                />,
                <Document
                  key='tos'
                  title={'Terms of service'}
                  type={'Policy'}
                  description={'The terms of service for users of PaperCut Multiverse.'}
                  linkText={'Open'}
                  urls={new Map([[Language.English, '/tos.html']])}
                />,
              ]}
            />
          </div>

          <div className='forty col'>
            <div className='empty'>
              <figure aria-labelledby='questions'>
                <img src={discuss} alt='' />
              </figure>
              <h4 id='questions'>Still have questions?</h4>
              <p>Get in touch with our support team.</p>
              <a href={`mailto:${supportEmail}?subject=PaperCut Multiverse feedback`} className='btn outline'>
                Send feedback
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfoCenter
