import React, { FunctionComponent, ReactNode, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import styles from './CustomModal.module.css'
import { useMountEffect } from '../../hooks/MountEffect'

interface Props {
  id: string
  width?: string
  close: () => void
  additionalClassName?: string
  parentClass?: string
  children?: ReactNode
}

const CustomModal: FunctionComponent<Props> = props => {
  const { close } = props
  const container = useRef(document.createElement('div', {}))
  if (props.parentClass) {
    container.current.classList.add(props.parentClass)
  }

  const onClose = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault()
    props.close()
  }

  useMountEffect(() => {
    const current = container.current
    document.body.appendChild(current)
    return () => {
      document.body.removeChild(current)
    }
  })

  useEffect(() => {
    const escapeListener = (e: KeyboardEvent) => {
      if (e.key === 'Escape' || e.keyCode === 27) close()
    }

    document.addEventListener('keyup', escapeListener)
    return () => {
      document.removeEventListener('keyup', escapeListener)
    }
  }, [close])

  return createPortal(
    <div
      onClick={e => {
        e.stopPropagation()
        props.close()
      }}
      className={`${styles.overlay} overlay`}
    >
      <div onClick={e => e.stopPropagation()} id={props.id} className={`popup ${styles.modal} ${props.additionalClassName}`} style={{ maxWidth: props.width ?? '480px' }}>
        <a href='/#' onClick={onClose} className='lines-button close' aria-label='Close'>
          <span className='lines' />
        </a>

        {props.children}
      </div>
    </div>,
    container.current
  )
}

export default CustomModal
