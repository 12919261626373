import React from 'react'

interface Props {
  numberOfCustomers: number
}

class Metrics extends React.Component<Props> {
  render() {
    return (
      <React.Fragment>
        <div id='stat-customers' className='col'>
          <h5>Customers</h5>
          <div className='num'>{this.props.numberOfCustomers}</div>
        </div>
      </React.Fragment>
    )
  }
}

export default Metrics
