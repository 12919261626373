import React, { FunctionComponent, useEffect, useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import Moment from 'react-moment'
import { defaultAuthHandler } from '../../auth'
import AuthRole from '../../role'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import Hamburger from '../Hamburger'
import { DisabledIcon, EnabledIcon } from '../Icons/Icons'
import Toaster from '../Toaster'
import { AuthType, isPendingMember, User, UserRole } from './Data'
import EditMemberAuthModal from './EditMemberAuthModal'
import InviteModal from './InviteModal'
import SelectRoleModal from './SelectRoleModal'

interface Props {
  user: User
  teamID: string
  checkboxState: boolean
  removeMember: (user: User) => void
  handleCheckboxChange: (user: User, checked: boolean) => void
  handleSaveAuthType: (user: User, authType: string) => void
  handleRoleChange: (user: User, newRoles: UserRole[]) => void
  onActionMenuClick: () => void
  handleReinvite: (email: string, roles: UserRole[]) => Promise<void | number>
}

const TeamMember: FunctionComponent<Props> = props => {
  const [showConfirmRemovalModal, setShowConfirmRemovalModal] = useState(false)
  const [showEditMemberAuthModal, setShowEditAuthenticationModal] = useState(false)
  const [checked, setChecked] = useState(props.checkboxState)
  const [showSelectRoleModal, setShowSelectRoleModal] = useState(false)
  const [showResetAuthenticatorModal, setShowResetAuthenticatorModal] = useState(false)
  const [showInviteModal, setShowInviteModal] = useState(false)
  const isYou = defaultAuthHandler.getUserID() === props.user.id
  const isSudoOwner = props.user.roles !== undefined && props.user.roles.indexOf(UserRole.Admin) > -1 && isYou
  const isPendingUser = isPendingMember(props.user)
  const isAdminUser = AuthRole.hasPermission('updateTeamSettings')
  const canUpdateUser = AuthRole.hasPermission('updateUser')
  const usernameClass = canUpdateUser ? 'username' : 'username-twocolumns'

  function capitalize(roleNames: UserRole[]) {
    if (roleNames !== undefined) {
      const CapitalRoleNames = roleNames.map(r => {
        if (r === UserRole.AccountManagerRO) {
          return 'Account manager (read-only)'
        }

        if (r === UserRole.TechServicesRO) {
          return 'Tech services (read-only)'
        }

        return r.charAt(0).toLocaleUpperCase() + r.slice(1)
      })

      return CapitalRoleNames.join(', ')
    }
  }

  const showConfirmationModal = () => {
    setShowConfirmRemovalModal(true)
  }

  const handleSaveAuthType = (authType: string) => {
    props.handleSaveAuthType(props.user, authType)
  }

  const resetAuthenticator = () => {
    axios
      .delete('/api/teams/' + props.teamID + '/users/' + props.user.id + '/authenticator')
      .then(_ => {
        Toaster.notifySuccess('Authenticator reset.')
      })
      .catch(_ => {
        Toaster.notifyFailure('Unable to reset authenticator.')
      })
  }

  const isInviteExpired = (user: User): boolean => {
    if (user.expiresAt) {
      return moment().isAfter(moment(user.expiresAt))
    }

    return false
  }

  useEffect(() => {
    setChecked(props.checkboxState)
  }, [props.checkboxState])

  return (
    <div id={props.user.id}>
      <div className='row row-link'>
        {isAdminUser && (
          <div className='col check'>
            <input
              type='checkbox'
              name='team-check'
              checked={checked}
              onChange={e => {
                setChecked(e.currentTarget.checked)
                props.handleCheckboxChange(props.user, e.currentTarget.checked)
              }}
            />
          </div>
        )}

        <div className={`col ${usernameClass}`} data-heap-redact-text>
          {props.user.email}

          {isYou && <em> (you)</em>}

          {isPendingUser && (
            <span className={`flag has-tip ${isInviteExpired(props.user) ? 'red' : 'yellow'}`}>
              Invite {isInviteExpired(props.user) ? 'expired' : 'sent'}
              <span className='tip'>
                Expiry <Moment format='D MMM YYYY h:mm A' date={props.user.expiresAt} />
              </span>
            </span>
          )}
        </div>

        {isAdminUser && (
          <div className='col mfa'>
            <div className='mfa status'>{props.user.authType === AuthType.MFA ? <EnabledIcon /> : <DisabledIcon />}</div>
          </div>
        )}

        <div className='col level'>{capitalize(props.user.roles)}</div>

        {isAdminUser && (
          <div className='col actions'>
            <Hamburger onClick={props.onActionMenuClick}>
              <ul>
                {AuthRole.hasPermission('removeMember') && !isSudoOwner && (
                  <li className='remove'>
                    <a onClick={showConfirmationModal}>{isPendingUser ? 'Uninvite' : 'Remove'} member</a>
                  </li>
                )}

                {AuthRole.hasPermission('inviteMember') && isPendingUser && !isSudoOwner && (
                  <li className='reinvite'>
                    <a onClick={() => setShowInviteModal(true)}>Reinvite member</a>
                  </li>
                )}

                {canUpdateUser && !isSudoOwner && (
                  <li className='revise'>
                    <a onClick={() => setShowSelectRoleModal(true)}>Edit roles</a>
                  </li>
                )}

                {canUpdateUser && (
                  <li className='access'>
                    <a onClick={() => setShowEditAuthenticationModal(true)}>Edit authentication</a>
                  </li>
                )}

                {canUpdateUser && props.user.authType === AuthType.MFA && !isPendingUser && (
                  <li className='reset'>
                    <a onClick={() => setShowResetAuthenticatorModal(true)}>Reset 2FA authenticator</a>
                  </li>
                )}
              </ul>
            </Hamburger>
          </div>
        )}
      </div>

      {showConfirmRemovalModal && (
        <ConfirmationModal
          title={'Remove ' + props.user.email}
          message={'Do you really want to remove ' + props.user.email + ' from the team?'}
          onClose={() => setShowConfirmRemovalModal(false)}
          onYes={() => props.removeMember(props.user)}
          yesLabel='Remove user'
        />
      )}

      {showResetAuthenticatorModal && (
        <ConfirmationModal
          title={'Reset ' + props.user.email + ' authenticator'}
          message={'Do you really want to reset the 2FA authenticator for ' + props.user.email + '?'}
          onClose={() => setShowResetAuthenticatorModal(false)}
          onYes={resetAuthenticator}
          yesLabel='Reset authenticator'
          cannotUndoWarning={false}
        />
      )}

      {showEditMemberAuthModal && (
        <EditMemberAuthModal
          isOpen={showEditMemberAuthModal}
          title={'Edit authentication for ' + props.user.email}
          close={() => setShowEditAuthenticationModal(false)}
          onSave={handleSaveAuthType}
          memberAuthType={props.user.authType}
        />
      )}

      {showSelectRoleModal && <SelectRoleModal isOpen={showSelectRoleModal} user={props.user} close={() => setShowSelectRoleModal(false)} onSave={props.handleRoleChange} />}

      {showInviteModal && (
        <InviteModal
          sendInviteEmail={props.handleReinvite}
          isOpen={showInviteModal}
          close={() => setShowInviteModal(false)}
          reinviteEmail={props.user.email}
          reinviteRoles={props.user.roles}
        />
      )}
    </div>
  )
}

export default TeamMember
