import React, { FunctionComponent } from 'react'
import { AccountIcon } from '../../Icons/Icons'

interface Props {
  onClick: () => void
  isOpen: boolean
}

const HiveAccountSummary: FunctionComponent<Props> = props => {
  const onClicked = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    props.onClick()
  }
  return (
    <li className={props.isOpen ? ' active' : ''} onClick={onClicked}>
      <a href='#tab-account'>
        <div className='title'>Account</div>
        <div className='status'>
          <i className='icon'>
            <AccountIcon />
          </i>
          <span className='state'></span>
        </div>
      </a>
    </li>
  )
}

export default HiveAccountSummary
