import React, { FunctionComponent } from 'react'
import { LogAccessEvent } from '../../api/events'
import canonDevice from '../../assets/img/mfd-canon.png'
import { defaultAuthHandler } from '../../auth'
import { useTypedSelector } from '../../redux/reducers'
import { getHostStatus, isDemo } from '../../redux/selectors'
import { LinkData } from '../Access/Data'
import { HostStatus } from '../Host/Data'
import { MfdIcon } from '../Icons/Icons'

interface Props {
  teamID: string
  hostID: string
  devices: LinkData[]
  allDevicesCount: number
}

export const DevicesTab: FunctionComponent<Props> = ({ teamID, hostID, devices, allDevicesCount }) => {
  const demoMode = useTypedSelector(isDemo)
  const hasNoMaintenanceAndSupport = useTypedSelector(getHostStatus(hostID)) === HostStatus.MaintenanceAndSupportRequired
  const userName = defaultAuthHandler.getUserName()

  const logEvent = (link: LinkData) => {
    LogAccessEvent(teamID, hostID, userName, link.name)
  }

  return (
    <div className='scroll'>
      <div id='tab-remote'>
        {hasNoMaintenanceAndSupport && <div className='content empty'>In order to manage devices, PaperCut Maintenance &amp; Support is required for this customer.</div>}
        {!hasNoMaintenanceAndSupport && (
          <>
            {allDevicesCount === 0 && (
              <div className='content empty'>
                <i className='icon thirtytwo'>
                  <MfdIcon />
                </i>

                <p>This customer has either not added MFDs or not enabled access.</p>

                <p>
                  To add MFDs, work with your customer to configure
                  <br />
                  the PaperCut Multiverse Connector.
                </p>
              </div>
            )}

            {devices.length === 0 && allDevicesCount > 0 && <div className='content empty'>No devices found.</div>}
            {devices.length > 0 && (
              <div className='list condensed'>
                <header>
                  <div className='flex flex-nowrap flex-end'>
                    <div className='col mfd-name'>Name</div>
                    <div className='col mfd-type'>Type</div>
                  </div>
                </header>
                <ul>
                  {devices.map(device => (
                    <li key={device.url}>
                      <a href={demoMode ? canonDevice : device.url} onClick={() => logEvent(device)} className='row' target='_blank' rel='noopener noreferrer'>
                        <div className='col mfd-name'>{device.name}</div>
                        <div className='col mfd-type'>{device.subName}</div>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}
