import React, { ReactNode } from 'react'
import { toast } from 'react-toastify'
import { ErrorIcon, TickIcon } from '../Icons/Icons'

class Toaster {
  static notifyContent(content: ReactNode, className: string, icon: ReactNode, durationMillis: number) {
    const toastContent = (
      <div className={`flex flex-ver flex-nowrap ${className}`}>
        <i className='icon bold sixteen flex-noshrink'>{icon}</i>

        <div className='indent' data-heap-redact-text>
          {content}
        </div>
      </div>
    )
    const options = {
      autoClose: durationMillis,
      draggable: false,
      hideProgressBar: true,
      position: toast.POSITION.BOTTOM_CENTER,
    }
    toast(toastContent, options)
  }

  static notifyFailure(message: string) {
    this.notifyFailureContent(<span>{message}</span>)
  }

  static notifyFailureContent(content: ReactNode) {
    this.notifyContent(content, 'toast-failure', <ErrorIcon />, 7000)
  }

  static notifySuccess(message: string) {
    this.notifySuccessContent(<span>{message}</span>)
  }

  static notifySuccessContent(content: ReactNode) {
    this.notifyContent(content, 'toast-success', <TickIcon />, 3000)
  }
}

export default Toaster
