import React, { FunctionComponent } from 'react'
import moment from 'moment'
import { useLocalStorageState } from '../../hooks/LocalStorageState'
import { useTypedSelector } from '../../redux/reducers'
import { getHostHealthSortValue } from '../../redux/selectors'
import { AccessConfig } from '../Access/Data'
import { HostData, HostDataUtil, Product } from '../Host/Data'
import { HiveOrg } from './HiveOrg'
import { Host } from './Host'

export enum View {
  HealthView = 'health',
  AccountView = 'account',
}

interface Props {
  view: View
  hosts: HostData[]
  teamID: string
  deleteCustomer: (ID: string) => void
  openAccessFrame: (config: AccessConfig) => void
  enabled: boolean
  limit: number
}

enum SortColumn {
  Health,
  Customer,
  Devices,
  ByProduct,
  Expiry,
}

enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export const HostList: FunctionComponent<Props> = props => {
  const [sortColumn, setSortColumn] = useLocalStorageState('host-sort-order', SortColumn.Customer)
  const [sortDirection, setSortDirection] = useLocalStorageState('host-sort-dir', SortDirection.Asc)
  const systemInfoMap = useTypedSelector(state => new Map(state.hosts.map(h => [h.id, h.systemInfo])))
  const accountDetailsMap = useTypedSelector(state => new Map(state.hosts.map(h => [h.id, h.accountDetails])))
  const healthSortValueMap = useTypedSelector(s => new Map(s.hosts.map(h => [h.id, getHostHealthSortValue(h.id)(s)])))

  const sortFunc = () => {
    const sortDirectionMultiplier = sortDirection === SortDirection.Asc ? 1 : -1
    const nameSort = (a: HostData, b: HostData) => a.name.localeCompare(b.name) * sortDirectionMultiplier
    switch (sortColumn) {
      case SortColumn.Health:
        return (a: HostData, b: HostData) => {
          const systemHealthA = healthSortValueMap.get(a.id) ?? -999
          const systemHealthB = healthSortValueMap.get(b.id) ?? -999
          const diff = (systemHealthA - systemHealthB) * sortDirectionMultiplier
          return diff === 0 ? nameSort(a, b) : diff
        }
      case SortColumn.Customer:
        return nameSort
      case SortColumn.Devices:
        return (a: HostData, b: HostData) => {
          const diff = ((systemInfoMap.get(a.id)?.embeddedCount ?? -1) - (systemInfoMap.get(b.id)?.embeddedCount ?? -1)) * sortDirectionMultiplier
          return diff === 0 ? nameSort(a, b) : diff
        }
      case SortColumn.ByProduct:
        return (a: HostData, b: HostData) => {
          const aVersion = HostDataUtil.getVersion(systemInfoMap.get(a.id), accountDetailsMap.get(a.id))
          const bVersion = HostDataUtil.getVersion(systemInfoMap.get(b.id), accountDetailsMap.get(b.id))
          // only one has version
          if ((aVersion || bVersion) && !(aVersion && bVersion)) {
            return aVersion.localeCompare(bVersion) * sortDirectionMultiplier
          } else if (!aVersion && !bVersion) {
            return a.product.localeCompare(b.product) * sortDirectionMultiplier
          }

          let aParts = aVersion.split(/[^\d]+/)
          let bParts = bVersion.split(/[^\d]+/)
          for (let i = 0; i < aParts.length; i++) {
            if (aParts[i] !== bParts[i]) return (+aParts[i] - +bParts[i]) * sortDirectionMultiplier
          }

          return nameSort(a, b)
        }
      case SortColumn.Expiry:
        return (a: HostData, b: HostData) => {
          const accountA = accountDetailsMap.get(a.id)
          const accountB = accountDetailsMap.get(b.id)
          const aDate = accountA?.maintenanceAndSupportExpiryDate || accountA?.licenseExpiryDate
          const bDate = accountB?.maintenanceAndSupportExpiryDate || accountB?.licenseExpiryDate
          const aValue = aDate ? moment(aDate).unix() : Number.MAX_VALUE
          const bValue = bDate ? moment(bDate).unix() : Number.MAX_VALUE
          if (aValue === bValue) {
            return nameSort(a, b)
          }
          return (aValue > bValue ? 1 : -1) * sortDirectionMultiplier
        }
    }
  }

  const sortableColumnClass = (column: SortColumn) => {
    return sortColumn === column ? sortDirection : ''
  }

  const defaultSortOrder = (column: SortColumn) => {
    switch (column) {
      case SortColumn.Health:
      case SortColumn.Devices:
        return SortDirection.Desc
      default:
        return SortDirection.Asc
    }
  }

  const toggleSort = (column: SortColumn) => {
    let newSortDir = defaultSortOrder(column)
    if (sortColumn === column) {
      newSortDir = sortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc
    }

    setSortColumn(column)
    setSortDirection(newSortDir)
  }

  if (!props.enabled) return <></>

  return (
    <div id='tab-customerssummary' className='rowview'>
      <div className={`list ${props.view === View.HealthView ? 'environment' : ''}`}>
        <header>
          <div className='flex flex-nowrap'>
            <div className={`col customer-status sortable ${sortableColumnClass(SortColumn.Health)}`} onClick={() => toggleSort(SortColumn.Health)}>
              Health
            </div>

            <div className='col customer-cert'>License</div>

            <div className={`col customer-name sortable ${sortableColumnClass(SortColumn.Customer)}`} onClick={() => toggleSort(SortColumn.Customer)}>
              Customer
            </div>

            <div className={`col customer-devices sortable ${sortableColumnClass(SortColumn.Devices)}`} onClick={() => toggleSort(SortColumn.Devices)}>
              Devices
            </div>

            <div className='col customer-access'>Access</div>

            <div className={`col customer-product sortable ${sortableColumnClass(SortColumn.ByProduct)}`} onClick={() => toggleSort(SortColumn.ByProduct)}>
              Product
            </div>

            <div className='col customer-platform'>Environment</div>

            <div className={`col customer-expiry sortable ${sortableColumnClass(SortColumn.Expiry)}`} onClick={() => toggleSort(SortColumn.Expiry)}>
              Expiry
            </div>

            <div className='col customer-usage'>Usage</div>

            <div className='col customer-actions' />
          </div>
        </header>

        <ul>
          {props.hosts
            .sort(sortFunc())
            .slice(0, props.limit)
            .map(host => {
              if (host.product === Product.Hive) {
                return (
                  <li key={host.id}>
                    <HiveOrg host={host} teamID={props.teamID} deleteCustomer={props.deleteCustomer} />
                  </li>
                )
              }

              return (
                <li key={host.id}>
                  <Host host={host} deleteCustomer={props.deleteCustomer} teamID={props.teamID} openAccessFrame={props.openAccessFrame} />
                </li>
              )
            })}
        </ul>

        {props.hosts.length === 0 && <div className='content empty'>No matching customers found.</div>}
      </div>
    </div>
  )
}
