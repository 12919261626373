import React, { FunctionComponent } from 'react'
import CustomModal from '../MyModal/CustomModal'

interface Props {
  onYes: () => void
  onCancel: () => void
}

const EndAccessModal: FunctionComponent<Props> = props => {
  return (
    <CustomModal id='popup-endaccess' close={props.onCancel}>
      <header className='center'>
        <h3>Are you sure you want to exit your session?</h3>
      </header>

      <footer>
        <button className='btn outline' onClick={props.onCancel}>
          Cancel
        </button>

        <button className={'btn'} onClick={props.onYes}>
          Yes, I'm sure
        </button>
      </footer>
    </CustomModal>
  )
}

export default EndAccessModal
