import * as React from 'react'
import { useLocation, useNavigate } from 'react-router'
import { defaultAuthHandler } from '../../auth'
import LoadingPage from './LoadingPage'
import { useMountEffect } from '../../hooks/MountEffect'
import { Location } from 'history'

const handleAuthentication = async (location: Location) => {
  if (location) {
    if (/access_token|id_token|error/.test(location.hash)) {
      await defaultAuthHandler.onLoginCallback()
      return
    }
  }

  throw new Error('cannot parse URL hash on authentication callback')
}

export const AuthenticationCallbackRoute = () => {
  const location = useLocation()
  const navigateTo = useNavigate()
  useMountEffect(() => {
    handleAuthentication(location)
      .then(_ => {
        navigateTo(new URLSearchParams(location.search).get('path') ?? '/')
      })
      .catch(_ => {
        // let forced logout handle redirect page
      })
  })
  return <LoadingPage />
}
