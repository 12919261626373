import { createStore } from 'redux'
import { rootReducer } from './reducers'
import throttle from 'lodash/throttle'

const storedFilter = window.localStorage.getItem('host-prop-filter')
const storedHosts = window.localStorage.getItem('hosts')
let filter = storedFilter ? JSON.parse(storedFilter) : undefined
let hosts = storedHosts ? JSON.parse(storedHosts) : undefined
let store = createStore(rootReducer, {
  filter: {
    filter: filter,
    showFilterPanel: false,
  },
  hosts: hosts,
})
let prevFilter = filter
store.subscribe(() => {
  let f = store.getState().filter.filter
  if (prevFilter === f) return
  if (f) {
    window.localStorage.setItem('host-prop-filter', JSON.stringify(f))
  } else {
    window.localStorage.removeItem('host-prop-filter')
  }
  prevFilter = f
})
store.subscribe(
  throttle(() => {
    let h = store.getState().hosts
    window.localStorage.setItem('hosts', JSON.stringify(h))
  }, 5000)
)
export default store
