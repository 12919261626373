import { GetDataCenterRegionString } from '../../env'

export interface User {
  id: string
  email: string
  roles: UserRole[]
  authType: string
  expiresAt?: string
}

export enum UserRole {
  Admin = 'admin',
  AccountManager = 'account manager',
  AccountManagerRO = 'account manager read-only',
  TechServices = 'tech services',
  TechServicesRO = 'tech services read-only',
}

export enum AuthType {
  MFA = 'mfa',
  Password = 'password',
}

export const isPendingMember = (user: User): boolean => {
  return user.expiresAt !== undefined
}

export const getSupportId = (teamId: string): string => GetDataCenterRegionString().toLowerCase() + ':' + teamId
