import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import AirDatepicker from 'air-datepicker'
import 'air-datepicker/air-datepicker.css'
import localeEn from 'air-datepicker/locale/en'
import axios from 'axios'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { deleteNotificationPause, updateNotificationPause } from '../../redux/notificationPause'
import { Timestamps } from '../../service/Timestamps'
import { HostData } from '../Host/Data'
import { EventIcon } from '../Icons/Icons'
import CustomModal from '../MyModal/CustomModal'
import Toaster from '../Toaster'

interface Props {
  close: () => void
  host: HostData
  isVisible: boolean
  teamID: string
  finishTime?: Date
  testing: boolean
}

const PauseNotificationsModal: FunctionComponent<Props> = props => {
  const url = '/api/teams/' + props.teamID + '/customers/' + props.host.id + '/notifications/pause'
  const dispatch = useDispatch()
  const [pauseFinishTime, setPauseFinishTime] = useState<Date | undefined>()
  const [activeItem, setActiveItem] = useState('')
  const [showDatePicker, setShowDatePicker] = useState(false)
  const airDateInput = useRef<HTMLInputElement>(null)

  const onResume = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault()
    axios
      .delete(url)
      .then(() => {
        dispatch(deleteNotificationPause(props.host.id))
        Toaster.notifySuccess(`Notifications resumed for ${props.host.name}`)
        onClose()
      })
      .catch(_ => Toaster.notifyFailure('Unable to resume notifications.'))
  }

  const onSave = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault()
    if (pauseFinishTime) {
      axios
        .put(url, { finishTime: pauseFinishTime })
        .then(() => {
          dispatch(updateNotificationPause({ customerId: props.host.id, finishesAt: pauseFinishTime }))
          Toaster.notifySuccess(`${props.finishTime ? 'Notification pause time updated' : 'Notifications paused'} for ${props.host.name}`)
          onClose()
        })
        .catch(_ => Toaster.notifyFailure('Unable to pause notifications.'))
    }
  }

  const onClose = () => {
    setActiveItem('')
    setPauseFinishTime(undefined)
    setShowDatePicker(false)
    props.close()
  }

  const pauseSelected = (label: string, m?: moment.Moment) => {
    setShowDatePicker(m === undefined)
    setPauseFinishTime(m ? m.toDate() : undefined)
    setActiveItem(label)
  }

  const pauseListItem = (label: string, m?: moment.Moment) => {
    return (
      <li className={label === activeItem ? 'active' : ''}>
        <a href='#' onClick={() => pauseSelected(label, m)}>
          {label}
        </a>
      </li>
    )
  }

  useEffect(() => {
    let airDatePicker: AirDatepicker | undefined
    if (props.isVisible && showDatePicker && airDateInput.current) {
      airDatePicker = new AirDatepicker(airDateInput.current, {
        classes: 'calendar',
        container: '#popup-notify',
        autoClose: true,
        locale: localeEn,
        startDate: moment()
          .add(1, 'days')
          .toDate(),
        minDate: moment()
          .add(1, 'day')
          .toDate(),
        onSelect: ({ date }) => {
          setPauseFinishTime(Array.isArray(date) ? date[0] : date)
        },
      })
    }
    return () => {
      airDatePicker?.destroy()
    }
  }, [props.isVisible, showDatePicker])

  return props.isVisible ? (
    <CustomModal id='popup-notify' close={onClose} width='640px' additionalClassName='basic-wide'>
      <header className='center'>
        <h3 className='btm'>Notifications for {props.host.name}</h3>
        <div className={`alert flex-ver pullout info center no-margin-bottom ${props.finishTime ? '' : 'hide'}`}>
          <i>
            <EventIcon />
          </i>
          <div className='message push'>
            Currently paused until: <strong>{props.finishTime ? Timestamps.format(props.finishTime) : ''}</strong>
          </div>
          <a href='#' className='btn outline sml' onClick={onResume}>
            Resume notifications
          </a>
        </div>
      </header>
      <div className='content'>
        <label>Pause email notifications for:</label>
        <ul className='flex center option-list no-margin-bottom'>
          {!props.testing && (
            <>
              {pauseListItem('1 day', moment().add(1, 'day'))}
              {pauseListItem('2 days', moment().add(2, 'days'))}
              {pauseListItem('1 week', moment().add(1, 'week'))}
            </>
          )}
          {props.testing && (
            <>
              {pauseListItem('2 minutes', moment().add(2, 'minutes'))}
              {pauseListItem('5 minutes', moment().add(5, 'minutes'))}
              {pauseListItem('12 minutes', moment().add(12, 'minutes'))}
            </>
          )}
          {pauseListItem('Custom')}
        </ul>
        <hr className='divider short' />
        <div className='flex'>
          <div className='push'>
            <i className='icon twenty'>
              <EventIcon />
            </i>
          </div>
          <p>
            Notifications will be paused until: <strong>{pauseFinishTime ? Timestamps.format(pauseFinishTime) : ''}</strong>
          </p>
        </div>
        {showDatePicker && (
          <div className='forty'>
            <input id='end-date' type='text' placeholder='Choose date' readOnly={true} ref={airDateInput} />
          </div>
        )}
      </div>
      <footer>
        <button className='btn outline' onClick={onClose}>
          Cancel
        </button>
        <button className={`btn ${pauseFinishTime ? '' : 'disabled'}`} onClick={onSave}>
          <span className='text'>Save changes</span>
        </button>
      </footer>
    </CustomModal>
  ) : (
    <></>
  )
}

export default PauseNotificationsModal
