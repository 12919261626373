import React, { FunctionComponent, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router'
import { defaultAuthHandler } from '../../auth'
import { useMountEffect } from '../../hooks/MountEffect'
import { useTypedSelector } from '../../redux/reducers'
import { isDemo } from '../../redux/selectors'
import AuthRole from '../../role'
import { Reseller } from '../ContactSupport/Data'
import { CreateTeamModal } from '../CreateTeamModal/CreateTeamModal'
import { BellIcon } from '../Icons/Icons'
import Metrics from '../Metrics'
import { getInitials, TeamSettingsData } from '../TeamSettings/TeamSettings'
import { useClickOutside } from '../UseClickOutside/UseClickOutside'
import UserActions from '../UserActions'

interface Props {
  numberOfCustomers: number
  team: TeamSettingsData
  teamID: string
  parents: Reseller[]
}

interface Team {
  name: string
  colour?: string
  initials?: string
  url: string
}

const Header: FunctionComponent<Props> = props => {
  const demoMode = useTypedSelector(isDemo)
  const [showCreateTeamModal, setShowCreateTeamModal] = useState(false)
  const canCreateTeam = AuthRole.hasPermission('createTeam') && !demoMode
  const [teams, setTeams] = useState<{ [team: string]: Team }>({
    [props.teamID]: {
      name: props.team.name,
      url: '',
    },
  })
  const hasMultipleTeams = Object.keys(teams).length > 1
  const [showPopup, setShowPopup] = useState(false)
  const navigateTo = useNavigate()

  const clickTeamNameHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    if (hasMultipleTeams || canCreateTeam) {
      setShowPopup(!showPopup)
    } else {
      navigateTo('/my-team/settings')
    }
  }

  useMountEffect(() => {
    const token = defaultAuthHandler.getToken()?.accessToken
    if (token) {
      axios
        .get<{ [teamID: string]: Team }>('/api/teams', { headers: { token } })
        .then(result => {
          setTeams(result.data)
        })
    }
  })

  const ref = useClickOutside(_ => setShowPopup(false))

  const createTeam = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    setShowPopup(false)
    setShowCreateTeamModal(true)
  }

  return (
    <>
      <header id='app-header'>
        {demoMode && <span className='sample'>DEMO</span>}

        <div className='auto'>
          <div className='row flex flex-ver'>
            <div id='logo' className='col'>
              <a href='index.html'>PaperCut Multiverse. One console, all your customers.</a>
            </div>

            {teams && (
              <div ref={ref} id='team-name' className={`col team-select ${showPopup ? 'open-teamselect' : ''}`}>
                <a href='#' onClick={clickTeamNameHandler} className={hasMultipleTeams || canCreateTeam ? '' : 'single'}>
                  <i className='avatar' style={{ background: props.team.colour }}>
                    {props.team.initials}
                  </i>

                  <div>
                    <h5>Team</h5>

                    <div>
                      <span className='truncate teamname' data-heap-redact-text>
                        {props.team.name}
                      </span>
                    </div>
                  </div>
                </a>

                <div className='popover'>
                  <ul>
                    {Object.keys(teams)
                      .filter(t => props.teamID !== t)
                      .map(key => (
                        <li key={key}>
                          <a href={teams[key].url}>
                            <i className='avatar' style={{ background: teams[key].colour }}>
                              {teams[key].initials || getInitials(teams[key].name)}
                            </i>
                            <span className='truncate'>{teams[key].name}</span>
                          </a>
                        </li>
                      ))}
                  </ul>

                  {canCreateTeam && (
                    <div style={{ padding: '1rem 0 0.5rem 0.5rem' }}>
                      <a onClick={createTeam} href='#'>
                        Add a new team
                      </a>
                    </div>
                  )}
                </div>
              </div>
            )}

            <Metrics numberOfCustomers={props.numberOfCustomers} />

            <div className='col rgt flex flex-ver'>
              <a className='beamerTrigger gift' href='#' title='Show product update news'>
                <i className='icon twentyfour'>
                  <BellIcon />
                </i>
              </a>

              <UserActions />
            </div>
          </div>
        </div>
      </header>

      {showCreateTeamModal && <CreateTeamModal onClose={() => setShowCreateTeamModal(false)} currentTeam={props.team} parents={props.parents} />}
    </>
  )
}

export default Header
