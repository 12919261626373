import React, { FunctionComponent, useState } from 'react'
import { WarningIcon } from '../Icons/Icons'
import { SystemHealthData } from './Data'
import Infrastructure from './Infrastructure'
import PrintManagement from './PrintManagement'
import UserActivity from './UserActivity'

interface Props {
  systemHealth: SystemHealthData
  hasMaintenanceAndSupport: boolean
  onClose: () => void
}

const SystemHealth: FunctionComponent<Props> = props => {
  const [expandedPanel, setExpandedPanel] = useState<string>('')

  const onInfrastructurePanelExpand = () => {
    setExpandedPanel('infrastructure')
  }

  const onPrintManagementPanelExpand = () => {
    setExpandedPanel('print-management')
  }

  const onUserActivityPanelExpand = () => {
    setExpandedPanel('user-activity')
  }

  const onSubPanelShrink = () => {
    setExpandedPanel('')
  }

  const onClose = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    props.onClose()
  }

  return (
    <div id='tab-health' className={`tab ${expandedPanel === '' ? 'visible' : ''}`}>
      <header>
        Print management system health at a glance
        <a href='/#' className='action' onClick={onClose} aria-label='Close System health tab' />
        {!props.hasMaintenanceAndSupport && (
          <div className='alert warning flex-ver'>
            <i className='icon'>
              <WarningIcon />
            </i>

            <div className='message'>In order to view the system health status and receive health alerts, PaperCut Maintenance &amp; Support is required for this customer.</div>
          </div>
        )}
      </header>

      <div className={`content ${(expandedPanel !== '' ? 'open' : '') + (!props.hasMaintenanceAndSupport ? 'upgrade-req' : '')}`}>
        <div className='row flex'>
          <Infrastructure data={props.systemHealth.infrastructure} onExpand={onInfrastructurePanelExpand} onShrink={onSubPanelShrink} />

          <PrintManagement data={props.systemHealth.printManagement} onExpand={onPrintManagementPanelExpand} onShrink={onSubPanelShrink} />

          <UserActivity data={props.systemHealth.userActivity} onExpand={onUserActivityPanelExpand} onShrink={onSubPanelShrink} />
        </div>
      </div>

      <footer>
        <a href='https://www.papercut.com/kb/' target='_blank' rel='noopener noreferrer' className='btn'>
          Knowledge Base
        </a>
      </footer>
    </div>
  )
}

export default SystemHealth
