import React, { FunctionComponent, useState } from 'react'
import styles from './TourModal.module.css'
import { TourStep1Figure, TourStep3Figure } from '../AnimatedSvg/AnimatedSvg'

interface Props {
  onClose: () => void
  isLoggedOn?: boolean
}

function getStepImage(stepNumber: number) {
  return <img src={require('../../assets/img/start-' + stepNumber + '.png')} srcSet={`${require('../../assets/img/start-' + stepNumber + '@2x.png')} 2x`} alt='' />
}

// Might be limited to 10 steps
export const TourModal: FunctionComponent<Props> = ({ isLoggedOn = false, onClose }) => {
  const [currentStep, setStep] = useState(1)
  const stepContents = [
    // step 1
    <>
      <h2>Any environment. Any PaperCut.</h2>
      <figure className='center'>
        <TourStep1Figure />
      </figure>
      <p>Remotely manage printing for all customers in any environment, every product, and including your existing PaperCut customer base.</p>
    </>,
    // step 2
    <>
      <h2>Be that proactive support</h2>
      <figure className='center'>{getStepImage(2)}</figure>
      <p>Remotely manage your customers' PaperCut admin console and MFD web-based config. No VPN, no extensive firewall config, it just works!</p>
    </>,
    // step 3
    <>
      <h2>Level up!</h2>
      <figure className='center'>
        <TourStep3Figure />
      </figure>
      <p>Level up your customers with tailored insight to help you understand their environment and how they are using (or not using) PaperCut.</p>
    </>,
    // step 4
    <>
      <h2>Info Center</h2>
      <figure className='center'>{getStepImage(4)}</figure>
      <p>
        Need to understand the nitty-gritty before taking the plunge, or want supporting material to win the confidence of your customers? Everything is right here in Info Center.
      </p>
    </>,
    // step 5
    <>
      <h2>Customize your access</h2>
      <figure className='center'>{getStepImage(5)}</figure>
      <p>Your customers can configure remote access to match their comfort levels, and you can configure your accounts to fit with your team structure.</p>
    </>,
    // step 6
    <>
      <h2>Future focused</h2>
      <figure className='center'>{getStepImage(6)}</figure>
      <p>We're keeping the future firmly in mind - as we develop our cloud portfolio, we'll look to support you and your customers with PaperCut Multiverse!</p>
    </>,
    // step 7
    <>
      <div className='planet' />
      <h2>Now then, let's dive in!</h2>
      <p>{isLoggedOn ? 'Get started by adding your customers and getting them set up.' : "Sign in to get started, we're looking forward to having you on board!"}</p>
    </>,
  ]
  const nextStep = () => {
    if (currentStep >= stepContents.length) onClose()
    setStep(currentStep + 1)
  }
  const width = 630
  return (
    <div className='overlay'>
      <div id='popup-start' className='popup center'>
        <a href='#' className='lines-button close' onClick={onClose}>
          <span className='lines' />
        </a>
        <div className='tour-slider slick-initialized slick-slider slick-dotted'>
          <div className='slick-list draggable'>
            <div className='slick-track' style={{ opacity: 1, width: '4410px' }}>
              {stepContents.map((stepContent, index) => {
                let isCurrent = currentStep === index + 1
                return (
                  <div
                    data-step={index + 1}
                    className={`${styles.step} content slick-slide ${isCurrent ? 'slick-current slick-active ' : ''}`}
                    data-slick-index={index}
                    aria-hidden={!isCurrent}
                    tabIndex={isCurrent ? 0 : -1}
                    role='tabpanel'
                    id={'slick-slide0' + index}
                    aria-describedby={'slick-slide-control0' + index}
                    style={{
                      width: width + 'px',
                      left: -index * width + 'px',
                      opacity: isCurrent ? 1 : 0,
                    }}
                    key={'step-' + (index + 1)}
                  >
                    {stepContent}
                  </div>
                )
              })}
            </div>
          </div>
          <button className='slick-next slick-arrow' aria-label='Next' type='button' style={{ display: 'inline-block' }} aria-disabled='false' onClick={nextStep}>
            {currentStep === stepContents.length ? "Let's go" : 'Next'}
          </button>
          <ul className='slick-dots' style={{ display: 'flex' }}>
            {stepContents.map((_, index) => {
              let isCurrent = currentStep === index + 1
              return (
                <li className={isCurrent ? 'slick-active' : ''} role='presentation' key={'step-' + (index + 1)}>
                  <button
                    type='button'
                    role='tab'
                    id={'slick-slide-control0' + index}
                    onClick={() => setStep(index + 1)}
                    aria-controls={'slick-slide0' + index}
                    aria-label={index + 1 + ' of 7'}
                    tabIndex={isCurrent ? 0 : -1}
                    aria-selected={isCurrent}
                  >
                    index + 1
                  </button>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default TourModal
