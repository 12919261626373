import * as React from 'react'
import { FunctionComponent, ReactNode } from 'react'
import { AuthStatus } from '../../auth/AuthHandler'
import LoadingPage from './LoadingPage'

export interface PrivateRouteProps {
  authenticationStatus: AuthStatus
  children?: ReactNode
}

const PrivateRoute: FunctionComponent<PrivateRouteProps> = props => {
  return (
    <>
      {props.authenticationStatus === AuthStatus.AUTHORIZED && props.children}
      {props.authenticationStatus === AuthStatus.UNAUTHORIZED && <LoadingPage />}
    </>
  )
}

export default PrivateRoute
