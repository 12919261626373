import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NotificationPause } from '../components/PauseNotificationsModal/Data'

type PauseState = {
  customerId: string
  finishesAt: Date
}
type PausesState = PauseState[]

const notificationPauseReducer = createSlice({
  name: 'pauses',
  initialState: [] as PausesState,
  reducers: {
    updateNotificationPause(state, action: PayloadAction<NotificationPause>) {
      let pause = state.find(p => p.customerId === action.payload.customerId)
      if (pause) {
        pause.finishesAt = action.payload.finishesAt
      } else {
        state.push(action.payload)
      }
    },
    deleteNotificationPause(state, action: PayloadAction<string>) {
      state.forEach((p, i) => {
        if (p.customerId === action.payload) {
          state.splice(i, 1)
        }
      })
    },
    setNotificationPauses(state, action: PayloadAction<NotificationPause[]>) {
      state.length = 0 // reset state: datastore is only source of truth
      action.payload.forEach(pause => state.push({ customerId: pause.customerId, finishesAt: pause.finishesAt }))
    },
  },
})

export const { setNotificationPauses, deleteNotificationPause, updateNotificationPause } = notificationPauseReducer.actions
export default notificationPauseReducer.reducer
