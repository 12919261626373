import React, { FunctionComponent, useState } from 'react'
import { ExclaimIcon } from '../Icons/Icons'
import CustomModal from '../MyModal/CustomModal'
import { User, UserRole } from './Data'
import RoleSelection from './RoleSelection'

interface Props {
  isOpen: boolean
  user: User
  close: () => void
  onSave: (user: User, newRoles: UserRole[]) => void
}

const SelectRoleModal: FunctionComponent<Props> = props => {
  const [roles, setRoles] = useState<UserRole[]>(props.user.roles)

  const handleSave = async () => {
    props.onSave(props.user, roles)
    props.close()
  }

  return props.isOpen ? (
    <CustomModal id='popup-team' close={props.close}>
      <header className='center'>
        <h3 data-heap-redact-text>{'Edit roles for ' + props.user.email}</h3>
      </header>

      <div className='alert error hide'>
        <i className='icon-exclaim'>
          <ExclaimIcon />
        </i>

        <div className='message' />
      </div>

      <div className='content'>
        <RoleSelection onRolesChange={setRoles} roles={roles} />
      </div>

      <footer className=''>
        <button id='cancel' className='btn outline' onClick={props.close}>
          Cancel
        </button>

        <button id='save' className={`btn ${roles.length > 0 ? '' : 'disabled'}`}>
          <span className='text' onClick={handleSave}>
            Save changes
          </span>
        </button>
      </footer>
    </CustomModal>
  ) : (
    <></>
  )
}

export default SelectRoleModal
