import React, { CSSProperties, FunctionComponent, useEffect } from 'react'
import c3, { PrimitiveArray } from 'c3'

interface Props {
  id: string
  style?: CSSProperties
  data: Array<[string, ...PrimitiveArray]>
}

const availableColors = ['#26AA27', '#8EC641', '#33C1AF', '#F0C002']

export const DonutChart: FunctionComponent<Props> = props => {
  useEffect(() => {
    const colors: any = {}
    props.data.forEach((d, i) => {
      colors[d[0]] = availableColors[i]
    })
    c3.generate({
      bindto: '#' + props.id,
      data: {
        columns: props.data,
        type: 'donut',
        colors: colors,
      },
      legend: {
        show: false,
      },
    })
  }, [props.data, props.id])

  return (
    <div id={props.id} style={props.style}>
      <div
        className='c3-tooltip-container'
        style={{
          position: 'absolute',
          pointerEvents: 'none',
          display: 'none',
        }}
      />
    </div>
  )
}
