import React, { FunctionComponent } from 'react'
import { HelpIcon } from '../Icons/Icons'

export const DisconnectedOrg: FunctionComponent = () => {
  return (
    <div className='add-on'>
      <div className='flex row'>
        <article className='col fp'>
          <figure className='center'>
            <img src={require('../../assets/img/jigsaw.png')} srcSet={`${require('../../assets/img/jigsaw@2x.png')} 2x`} alt='' />
          </figure>

          <div className='pullout alert info'>
            <i className='icon outline twenty'>
              <HelpIcon />
            </i>

            <div>
              <h6>PaperCut Multiverse connection required</h6>

              <p className='no-margin-bottom'>To connect your organization to PaperCut Multiverse, contact your support partner.</p>
            </div>
          </div>
        </article>
      </div>
    </div>
  )
}
