import React, { FunctionComponent } from 'react'
import { ErrorIcon, TickIcon } from '../Icons/Icons'

interface Props {
  used: boolean
}

const FeatureStatus: FunctionComponent<Props> = props => {
  return (
    <span>
      {props.used ? (
        <i className='icon sixteen'>
          <TickIcon />
        </i>
      ) : (
        <i className='icon sixteen idle'>
          <ErrorIcon />
        </i>
      )}
    </span>
  )
}
export default FeatureStatus
