import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react'
import axios from 'axios'
import { FilePond } from 'react-filepond'
import Moment from 'react-moment'
import { ExclaimIcon } from '../Icons/Icons'
import { LoadingDots } from '../LoadingDots/LoadingDots'
import CustomModal from '../MyModal/CustomModal'
import Toaster from '../Toaster'
import { AccountDataUtil, AccountDetails, NOT_AVAILABLE } from './Data'

const applyNewLicense = async (teamID: string, hostID: string): Promise<string> => {
  return axios
    .put(licensePath(teamID, hostID) + '/install')
    .then(() => Promise.resolve(''))
    .catch(error => Promise.reject(getResponseError(error)))
}

const applyPreviousLicense = async (teamID: string, hostID: string): Promise<string> => {
  return axios
    .put(licensePath(teamID, hostID) + '/reinstate')
    .then(() => Promise.resolve(''))
    .catch(error => Promise.reject(getResponseError(error)))
}

export async function checkApplyLicense(teamID: string, hostID: string): Promise<string> {
  return axios
    .get(licensePath(teamID, hostID) + '/check')
    .then(() => Promise.resolve(''))
    .catch(error => Promise.resolve(getResponseError(error, 'An internal error has occurred')))
}

const getCategoryCounts = (license?: AccountDetails, typeMap?: any): ReactNode => {
  return property(AccountDataUtil.getLicensedDeviceCategoryCounts(false, typeMap, license))
}

interface Licenses {
  current: AccountDetails
  previous: AccountDetails
}

const getLicenses = async (teamID: string, hostID: string): Promise<Licenses> => {
  return axios
    .get<Licenses>(licensePath(teamID, hostID))
    .then(resp => Promise.resolve(resp.data))
    .catch(error => Promise.reject(getResponseError(error)))
}

const getLicenseProperty = (details?: AccountDetails) => {
  if (!details) {
    return <>{NOT_AVAILABLE}</>
  }

  const expiryDate = details.licenseExpiryDate

  return (
    <span>
      {details.licenseType}

      {expiryDate && (
        <>
          <br />
          <Moment format='D MMM YYYY' date={expiryDate} />
        </>
      )}
    </span>
  )
}

const getMaintenanceAndSupportProperty = (details?: AccountDetails) => {
  if (!details) {
    return <>{NOT_AVAILABLE}</>
  }

  const expiryDate = details.maintenanceAndSupportExpiryDate

  return (
    <span>
      {details.maintenanceAndSupportStatus}

      {expiryDate && (
        <>
          <br />

          <Moment format='D MMM YYYY' date={expiryDate} />
        </>
      )}
    </span>
  )
}

interface NewLicense {
  new: AccountDetails
}

const getNewLicense = async (teamID: string, hostID: string): Promise<NewLicense> => {
  return axios
    .get<NewLicense>(newLicensePath(teamID, hostID))
    .then(resp => Promise.resolve(resp.data))
    .catch(error => Promise.reject(getResponseError(error)))
}

const getReleaseStationProperty = (details?: AccountDetails) => {
  if (!details) {
    return NOT_AVAILABLE
  }

  if (details.licensedReleaseStations === -1) {
    return 'Unlimited'
  }

  return details.licensedReleaseStations
}

const getResponseError = (error: any, defaultError?: string) => {
  const responseData = error.response.data.trim()
  let message = responseData
  switch (responseData) {
    default:
      if (defaultError) {
        message = defaultError
        console.log('unexpected response error', error.response)
      }
      break
    case 'no-server':
      message = "Requires a Connector installed on the customer's Application Server."
      break
    case 'remote-disabled':
      message = 'Requires PaperCut admin console access to be enabled.'
      break
    case 'version-not-supported':
      message = 'Requires version 20.1 or later.'
      break
    case 'request failed with HTTP 502 Bad Gateway':
      message = 'Check that the PaperCut Multiverse Connector is running as expected.'
      break
  }

  return message
}

const licenseExists = (details?: AccountDetails) => {
  return details && details.licenseType !== ''
}

const licensePath = (teamID: string, hostID: string): string => {
  return '/api/teams/' + teamID + '/customers/' + hostID + '/remote/license'
}

const newLicensePath = (teamID: string, hostID: string): string => {
  return licensePath(teamID, hostID) + '/new'
}

const property = (propertyValue: any) => {
  return propertyValue ? propertyValue : NOT_AVAILABLE
}

const removeNewLicense = (teamID: string, hostID: string) => {
  axios.delete(newLicensePath(teamID, hostID))
}

const isLicensedWithGrows = (details?: AccountDetails) => {
  return details && details.growsTreesPlanted > 0
}

interface Props {
  teamID: string
  hostID: string
  onClose: () => void
}

enum License {
  Previous,
  Current,
  New,
}

const ApplyLicenseModal: FunctionComponent<Props> = props => {
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const waiting = loading || saving

  const [currentLicense, setCurrentLicense] = useState<AccountDetails>()
  const [newLicense, setNewLicense] = useState<AccountDetails>()
  const [previousLicense, setPreviousLicense] = useState<AccountDetails>()
  const hasCurrent = licenseExists(currentLicense)
  const hasNew = licenseExists(newLicense)
  const hasPrevious = licenseExists(previousLicense)

  const [selected, setSelected] = useState(License.Current)
  const canSave = selected !== License.Current
  const showGrowsWarning = canSave && isLicensedWithGrows(selected === License.New ? newLicense : previousLicense)

  const getSelectionClass = (license: License) => {
    return license === selected ? 'selected' : ''
  }

  const getFractionClass = () => {
    if (!hasCurrent) {
      return 'half'
    }

    return hasPrevious ? 'qtr' : 'third'
  }

  const fractionClass = getFractionClass()
  const labelClasses = `col ${fractionClass} license-val`
  const previousLicenseClasses = `col ${fractionClass} center license-prev ${getSelectionClass(License.Previous)}`
  const currentLicenseClasses = `col ${fractionClass} center license-cur ${getSelectionClass(License.Current)}`
  const newLicenseClasses = `col ${fractionClass} center license-new ${getSelectionClass(License.New)}`

  const getSelectButton = (license: License) => {
    const licenseSelected = license === selected
    return (
      <button className={`btn sml ${licenseSelected ? 'disabled' : ''}`} onClick={() => setSelected(license)}>
        {licenseSelected ? 'Selected' : 'Select license'}
      </button>
    )
  }

  const handleClose = () => {
    removeNewLicense(props.teamID, props.hostID)
    props.onClose()
  }

  const handleNewLicense = (available: boolean) => {
    if (available) {
      getNewLicense(props.teamID, props.hostID).then(license => {
        setNewLicense(AccountDataUtil.getAccountDetailsFromResponse(license.new))
        setSelected(License.New)
      })
    } else {
      setNewLicense(undefined)
      if (selected === License.New) {
        setSelected(License.Current)
      }
    }
  }

  const handleRemoveNewLicense = (event: React.MouseEvent) => {
    event.preventDefault()
    removeNewLicense(props.teamID, props.hostID)
    handleNewLicense(false)
  }

  const handleSave = () => {
    if (selected === License.Previous) {
      setSaving(true)
      applyPreviousLicense(props.teamID, props.hostID)
        .then(_ => {
          Toaster.notifySuccess('Previous license applied - this may take a few minutes to update.')
          handleClose()
        })
        .catch(error => Toaster.notifyFailure('Unable to apply previous license: ' + error))
        .finally(() => setSaving(false))
    } else if (selected === License.New) {
      setSaving(true)
      applyNewLicense(props.teamID, props.hostID)
        .then(_ => {
          Toaster.notifySuccess('New license applied - this may take a few minutes to update.')
          handleClose()
        })
        .catch(error => Toaster.notifyFailure('Unable to apply new license: ' + error))
        .finally(() => setSaving(false))
    }
  }

  useEffect(() => {
    getLicenses(props.teamID, props.hostID)
      .then(licenses => {
        setCurrentLicense(AccountDataUtil.getAccountDetailsFromResponse(licenses.current))
        setPreviousLicense(AccountDataUtil.getAccountDetailsFromResponse(licenses.previous))
      })
      .catch(error => Toaster.notifyFailure('Unable to retrieve license information: ' + error))
      .finally(() => setLoading(false))
  }, [props.teamID, props.hostID])

  return (
    <CustomModal id='popup-license' additionalClassName='basic-wide' close={handleClose} width='720px'>
      <header className='center'>
        <h3>Apply license</h3>

        <p>Update, apply new, or revert licensing changes.</p>
      </header>

      <div className='content'>
        {waiting && (
          <div className='empty loading' style={{ height: 'calc(400px - 3rem)' }}>
            <figure aria-labelledby='status'>
              <LoadingDots />
            </figure>

            <br />
            <br />

            {loading && <span id='status'>Retrieving license information...</span>}

            {saving && <span id='status'>Saving...</span>}
          </div>
        )}
        {!waiting && (
          <div className='table alt'>
            {showGrowsWarning && (
              <div className='alert error flex-ver btm'>
                <i className='icon-exclaim'>
                  <ExclaimIcon />
                </i>

                <div className='message'>
                  NOTE: This license contains PaperCut Grows modules and trees. The PaperCut Application Server service will need to be manually restarted for this part of the
                  license to take effect.
                </div>
              </div>
            )}

            <div className='thead row'>
              <div className={labelClasses} />

              {hasPrevious && (
                <div className={previousLicenseClasses}>
                  <h6>Previous license</h6>

                  <div className='th'>
                    <h5>
                      <span className='ellipsis' data-heap-redact-text>
                        {previousLicense?.organisation}
                      </span>
                    </h5>

                    {getSelectButton(License.Previous)}
                  </div>
                </div>
              )}

              {hasCurrent && (
                <div className={currentLicenseClasses}>
                  <h6>Current license</h6>

                  <div className='th'>
                    <h5>
                      <span className='ellipsis' data-heap-redact-text>
                        {currentLicense?.organisation}
                      </span>
                    </h5>

                    {getSelectButton(License.Current)}
                  </div>
                </div>
              )}

              <div className={newLicenseClasses}>
                <h6>New license</h6>

                <div className='th'>
                  {hasNew && (
                    <>
                      <h5>
                        <span className='ellipsis' data-heap-redact-text>
                          {newLicense?.organisation}
                        </span>
                      </h5>

                      {(hasPrevious || hasCurrent) && getSelectButton(License.New)}
                    </>
                  )}

                  {hasNew && (
                    <a href='/#' className='action' onClick={handleRemoveNewLicense}>
                      Remove license
                    </a>
                  )}

                  {!hasNew && (
                    <form data-heap-redact-text>
                      <FilePond
                        allowProcess={false}
                        labelFileProcessing=''
                        labelFileProcessingComplete=''
                        labelFileProcessingError=''
                        labelFileSizeNotAvailable=''
                        labelIdle='Drag &amp; drop the license or <span class="filepond--label-action">browse</span>'
                        labelTapToCancel=''
                        labelTapToRetry=''
                        labelTapToUndo=''
                        onprocessfile={error => handleNewLicense(error === null)}
                        onremovefile={() => handleNewLicense(false)}
                        server={{
                          fetch: null,
                          load: null,
                          process: {
                            url: newLicensePath(props.teamID, props.hostID),
                            onerror: response => Toaster.notifyFailure(response),
                          },
                          revert: {
                            url: newLicensePath(props.teamID, props.hostID),
                          },
                        }}
                        styleButtonRemoveItemPosition='right'
                      />
                    </form>
                  )}
                </div>
              </div>
            </div>

            <div className='tbody'>
              <div className='row'>
                <div className={labelClasses}>
                  <strong>CRN</strong>
                </div>

                {hasPrevious && <div className={previousLicenseClasses}>{property(previousLicense?.crn)}</div>}

                {hasCurrent && <div className={currentLicenseClasses}>{property(currentLicense?.crn)}</div>}

                <div className={newLicenseClasses}>{hasNew && property(newLicense?.crn)}</div>
              </div>

              <div className='row'>
                <div className={labelClasses}>
                  <strong>Order reference</strong>
                </div>

                {hasPrevious && <div className={previousLicenseClasses}>{property(previousLicense?.orderReference)}</div>}

                {hasCurrent && <div className={currentLicenseClasses}>{property(currentLicense?.orderReference)}</div>}

                <div className={newLicenseClasses}>{hasNew && property(newLicense?.orderReference)}</div>
              </div>

              <div className='row'>
                <div className={labelClasses}>
                  <strong>Product</strong>
                </div>

                {hasPrevious && (
                  <div className={previousLicenseClasses}>
                    PaperCut {previousLicense?.paperCutEdition} {previousLicense?.paperCutVersion}
                  </div>
                )}

                {hasCurrent && (
                  <div className={currentLicenseClasses}>
                    PaperCut {currentLicense?.paperCutEdition} {currentLicense?.paperCutVersion}
                  </div>
                )}

                <div className={newLicenseClasses}>
                  {hasNew && (
                    <>
                      PaperCut {newLicense?.paperCutEdition} {newLicense?.paperCutVersion}
                    </>
                  )}
                </div>
              </div>

              <div className='row'>
                <div className={labelClasses}>
                  <strong>Maintenance &amp; support</strong>
                </div>

                {hasPrevious && <div className={previousLicenseClasses}>{getMaintenanceAndSupportProperty(previousLicense)}</div>}

                {hasCurrent && <div className={currentLicenseClasses}>{getMaintenanceAndSupportProperty(currentLicense)}</div>}

                <div className={newLicenseClasses}>{hasNew && getMaintenanceAndSupportProperty(newLicense)}</div>
              </div>

              <div className='row'>
                <div className={labelClasses}>
                  <strong>License type</strong>
                </div>

                {hasPrevious && <div className={previousLicenseClasses}>{getLicenseProperty(previousLicense)}</div>}

                {hasCurrent && <div className={currentLicenseClasses}>{getLicenseProperty(currentLicense)}</div>}

                <div className={newLicenseClasses}>{hasNew && getLicenseProperty(newLicense)}</div>
              </div>

              <div className='row'>
                <div className={labelClasses}>
                  <strong>Users</strong>
                </div>

                {hasPrevious && <div className={previousLicenseClasses}>{property(previousLicense?.registeredUsers)}</div>}

                {hasCurrent && <div className={currentLicenseClasses}>{property(currentLicense?.registeredUsers)}</div>}

                <div className={newLicenseClasses}>{hasNew && property(newLicense?.registeredUsers)}</div>
              </div>

              <div className='row'>
                <div className={labelClasses}>
                  <strong>MFDs</strong>
                </div>

                {hasPrevious && <div className={previousLicenseClasses}>{getCategoryCounts(previousLicense, previousLicense?.licensedMFDs)}</div>}

                {hasCurrent && <div className={currentLicenseClasses}>{getCategoryCounts(currentLicense, currentLicense?.licensedMFDs)}</div>}

                <div className={newLicenseClasses}>{hasNew && getCategoryCounts(newLicense, newLicense?.licensedMFDs)}</div>
              </div>

              <div className='row'>
                <div className={labelClasses}>
                  <strong>SFPs</strong>
                </div>

                {hasPrevious && <div className={previousLicenseClasses}>{getCategoryCounts(previousLicense, previousLicense?.licensedSFPs)}</div>}

                {hasCurrent && <div className={currentLicenseClasses}>{getCategoryCounts(currentLicense, currentLicense?.licensedSFPs)}</div>}

                <div className={newLicenseClasses}>{hasNew && getCategoryCounts(newLicense, newLicense?.licensedSFPs)}</div>
              </div>

              <div className='row'>
                <div className={labelClasses}>
                  <strong>Connectors</strong>
                </div>

                {hasPrevious && <div className={previousLicenseClasses}>{getCategoryCounts(previousLicense, previousLicense?.licensedConnectors)}</div>}

                {hasCurrent && <div className={currentLicenseClasses}>{getCategoryCounts(currentLicense, currentLicense?.licensedConnectors)}</div>}

                <div className={newLicenseClasses}>{hasNew && getCategoryCounts(newLicense, newLicense?.licensedConnectors)}</div>
              </div>

              <div className='row'>
                <div className={labelClasses}>
                  <strong>Release stations</strong>
                </div>

                {hasPrevious && <div className={previousLicenseClasses}>{getReleaseStationProperty(previousLicense)}</div>}

                {hasCurrent && <div className={currentLicenseClasses}>{getReleaseStationProperty(currentLicense)}</div>}

                <div className={newLicenseClasses}>{hasNew && getReleaseStationProperty(newLicense)}</div>
              </div>

              <div className='row'>
                <div className={labelClasses}>
                  <strong>Grows trees</strong>
                </div>

                {hasPrevious && <div className={previousLicenseClasses}>{property(previousLicense?.growsTreesPlanted)}</div>}

                {hasCurrent && <div className={currentLicenseClasses}>{property(currentLicense?.growsTreesPlanted)}</div>}

                <div className={newLicenseClasses}>{hasNew && property(newLicense?.growsTreesPlanted)}</div>
              </div>
            </div>
          </div>
        )}
      </div>

      <footer className='padded'>
        <button className='btn outline' onClick={handleClose}>
          Cancel
        </button>

        <button className={`btn ${canSave ? '' : 'disabled'}`} disabled={!canSave} onClick={handleSave}>
          Save changes
        </button>
      </footer>
    </CustomModal>
  )
}

export default ApplyLicenseModal
