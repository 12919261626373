import moment from 'moment'

export const Timestamps = {
  format: (ts: Date) => {
    return moment(ts).format('D MMM YYYY h:mm A')
  },

  zone: () => {
    return 'UTC' + moment().format('Z')
  },
}
