import React, { FunctionComponent } from 'react'
import { ErrorIcon, GlobeIcon, LockIcon, PeopleIcon, ScannerIcon, TickIcon } from '../../Icons/Icons'

interface Props {
  onClose: () => void
}

const HiveFeatureUsage: FunctionComponent<Props> = props => {
  const onClose = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    props.onClose()
  }

  return (
    <div id='tab-impact' className='tab'>
      <header>
        Tailored insights to help you delight your customer
        <a href='#' className='action' onClick={onClose} aria-label='Close Feature usage tab' />
      </header>

      <div className='content threecol upgrade-req coming-soon'>
        <span className='sample'>Need this?</span>
        <div className='row flex'>
          <div className='col qtr'>
            <div className='card'>
              <header>
                <i className='icon'>
                  <LockIcon />
                </i>
                <h6>Secure printing</h6>
              </header>
              <dl className='zebra'>
                <dt className='alt'>Secure print release</dt>
                <dd className='alt'>
                  <i className='icon sixteen'>
                    <TickIcon />
                  </i>
                </dd>
                <dt>Watermarking</dt>
                <dd>
                  <i className='icon sixteen idle'>
                    <ErrorIcon />
                  </i>
                </dd>
                <dt className='alt'>Print Archiving</dt>
                <dd className='alt'>
                  <i className='icon sixteen idle'>
                    <ErrorIcon />
                  </i>
                </dd>
              </dl>
            </div>
          </div>
          <div className='col qtr'>
            <div className='card'>
              <header>
                <i className='icon'>
                  <PeopleIcon />
                </i>
                <h6>Easy printing</h6>
              </header>
              <dl className='zebra'>
                <dt className='alt'>Find-Me printing</dt>
                <dd className='alt'>
                  <i className='icon sixteen'>
                    <TickIcon />
                  </i>
                </dd>
                <dt>Mobility Print</dt>
                <dd>
                  <i className='icon sixteen'>
                    <TickIcon />
                  </i>
                </dd>
                <dt className='alt'>Web Print</dt>
                <dd className='alt'>
                  <i className='icon sixteen idle'>
                    <ErrorIcon />
                  </i>
                </dd>
                <dt>Email to Print</dt>
                <dd>
                  <i className='icon sixteen idle'>
                    <ErrorIcon />
                  </i>
                </dd>
              </dl>
            </div>
          </div>
          <div className='col qtr'>
            <div className='card'>
              <header>
                <i className='icon'>
                  <ScannerIcon />
                </i>
                <h6>Scanning &amp; capture</h6>
              </header>
              <dl className='zebra'>
                <dt className='alt'>Integrated Scanning</dt>
                <dd className='alt'>
                  <i className='icon sixteen'>
                    <TickIcon />
                  </i>
                </dd>
                <dt>Scanning devices</dt>
                <dd>2</dd>
                <dt className='alt'>OCR</dt>
                <dd className='alt'>
                  <i className='icon sixteen idle'>
                    <ErrorIcon />
                  </i>
                </dd>
                <dt>Scan to Cloud Storage</dt>
                <dd>
                  <i className='icon sixteen idle'>
                    <ErrorIcon />
                  </i>
                </dd>
                <dt className='alt'>Scan to Folder</dt>
                <dd className='alt'>
                  <i className='icon sixteen'>
                    <TickIcon />
                  </i>
                </dd>
              </dl>
            </div>
          </div>
          <div className='col qtr'>
            <div className='card'>
              <header>
                <i className='icon'>
                  <GlobeIcon />
                </i>
                <h6>Responsible printing</h6>
              </header>
              <dl className='zebra'>
                <dt className='alt'>Reporting (scheduled)</dt>
                <dd className='alt'>
                  <i className='icon sixteen'>
                    <TickIcon />
                  </i>
                </dd>
                <dt>Print scripting</dt>
                <dd>
                  <i className='icon sixteen'>
                    <TickIcon />
                  </i>
                </dd>
                <dt className='alt'>Device scripting</dt>
                <dd className='alt'>
                  <i className='icon sixteen idle'>
                    <ErrorIcon />
                  </i>
                </dd>
              </dl>
              <h5>Sheets saved</h5>
              <dl className='zebra'>
                <dt className='alt'>Via hold/release</dt>
                <dd className='alt'>9%</dd>
                <dt>Via admin policy</dt>
                <dd>7%</dd>
                <dt className='alt'>At device</dt>
                <dd className='alt'>2%</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <a href='#' className='btn'>
          Email Insights
        </a>
      </footer>
    </div>
  )
}

export default HiveFeatureUsage
