import React, { FunctionComponent, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Features } from '../../features'
import { useTypedSelector } from '../../redux/reducers'
import { getConnectionStatus, getNotificationPause, getSystemInfo, isFeatureOn } from '../../redux/selectors'
import { showHost } from '../../redux/slideout'
import AuthRole from '../../role'
import { Timestamps } from '../../service/Timestamps'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import Hamburger from '../Hamburger'
import { ConnectionStatus, HostData, HostDataUtil } from '../Host/Data'
import { HostSlideoutTab } from '../HostSlideout/Data'
import { LicenseIcon, NoBellIcon, RemoteManageIcon, RemoteWarningIcon } from '../Icons/Icons'
import PauseNotificationsModal from '../PauseNotificationsModal/PauseNotificationsModal'

interface Props {
  host: HostData
  teamID: string
  deleteCustomer: (ID: string) => void
}

export const HiveOrg: FunctionComponent<Props> = props => {
  const hostId = props.host.id
  const systemInfo = useTypedSelector(getSystemInfo(hostId))
  const connectionDisabled = useTypedSelector(getConnectionStatus(hostId)) === ConnectionStatus.Disabled
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const dispatch = useDispatch()
  const [showPauseNotificationsModal, setShowPauseNotificationsModal] = useState(false)
  const notificationPauseFinishTime = useTypedSelector(getNotificationPause(hostId))?.finishesAt

  const openHost = (e: React.MouseEvent<HTMLElement, MouseEvent>, startTab?: HostSlideoutTab) => {
    e.stopPropagation()
    dispatch(showHost(props.host, startTab))
  }

  const getManageIcon = () => {
    let className = ''
    let icon = <RemoteManageIcon />
    let tip = ''
    if (!AuthRole.hasPermission('remoteManage')) {
      className = 'disabled'
      tip = 'To gain access, contact your Admin.'
    } else if (connectionDisabled) {
      className = 'error'
      icon = <RemoteWarningIcon />
      tip = 'The customer has either disabled access or not added the Partner Remote Assistance add-on.'
    }

    return (
      <i className={`icon twenty ${className}`}>
        {icon}

        {tip && <span className='tip'>{tip}</span>}
      </i>
    )
  }

  const shorterPausesEnabled = useTypedSelector(isFeatureOn(Features.NotificationPauseTesting))

  return (
    <>
      <div className='row row-link' id={hostId} onClick={e => openHost(e)}>
        <div className='col customer-status hive'></div>
        <div className='col customer-cert'>
          <i className='icon thirtytwo'>
            <LicenseIcon />
          </i>
        </div>
        <div className='col customer-name'>
          <div className='ellipsis' data-heap-redact-text>
            {props.host.name}
          </div>
          {notificationPauseFinishTime && (
            <i className='icon twentyfour no-bell'>
              <span className='tip center'>Notifications paused until: {Timestamps.format(notificationPauseFinishTime)}</span>

              <NoBellIcon />
            </i>
          )}
        </div>
        <div className='col customer-devices'>-</div>
        <div className='col customer-access'>{getManageIcon()}</div>
        <div className='col customer-product'>
          <div className='ellipsis'>{HostDataUtil.getProductText(systemInfo?.product ?? props.host.product)}</div>
        </div>
        <div className='col customer-platform'>
          <span data-heap-redact-text>{HostDataUtil.getInfrastructureShortName(props.host.infrastructure)}</span>
        </div>
        <div className='col customer-expiry'>-</div>
        <div className='col customer-usage'>-</div>
        <div className='col customer-actions'>
          {AuthRole.hasPermission('manageCustomer') && (
            <Hamburger>
              <ul>
                <li className='trash'>
                  <a onClick={() => setShowConfirmationModal(true)}>Remove customer</a>
                </li>
              </ul>
            </Hamburger>
          )}
        </div>
      </div>

      {showConfirmationModal && (
        <ConfirmationModal
          title={`Remove ${props.host.name}`}
          message={`Do you really want to remove ${props.host.name}? This will not delete the PaperCut Hive organization.`}
          yesLabel='Remove customer'
          onClose={() => setShowConfirmationModal(false)}
          onYes={() => props.deleteCustomer(props.host.id)}
        />
      )}

      <PauseNotificationsModal
        close={() => setShowPauseNotificationsModal(false)}
        finishTime={notificationPauseFinishTime}
        host={props.host}
        isVisible={showPauseNotificationsModal}
        teamID={props.teamID}
        testing={shorterPausesEnabled}
      />
    </>
  )
}
