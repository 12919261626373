import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getHostEvents } from '../../../api/host'
import { defaultAuthHandler } from '../../../auth'
import { Features } from '../../../features'
import { useTypedSelector } from '../../../redux/reducers'
import { getNotificationPause, getPmitcSessionLogoutLink, getPmitcSessionName, getPmitcSessionWindow, isFeatureOn } from '../../../redux/selectors'
import AuthRole from '../../../role'
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal'
import EventLog from '../../EventLog'
import EventLogSummary from '../../EventLogSummary'
import Hamburger from '../../Hamburger'
import { EditCustomerFunction, HostData, HostEvent } from '../../Host/Data'
import PauseNotificationsModal from '../../PauseNotificationsModal/PauseNotificationsModal'
import PmitcSessionModal from '../../PmitcSessionModal/PmitcSessionModal'
import HiveAccount from '../HiveAccount'
import HiveAccountSummary from '../HiveAccountSummary'
import HiveFeatureUsage from '../HiveFeatureUsage'
import HiveFeatureUsageSummary from '../HiveFeatureUsageSummary'
import HiveManage, { manageHiveCustomer } from '../HiveManage/HiveManage'
import HiveServiceHealth from '../HiveServiceHealth'
import HiveServiceHealthSummary from '../HiveServiceHealthSummary'
import HiveSystemInfo from '../HiveSystemInfo'

interface Props {
  data: HostData
  teamID: string
  editCustomer: EditCustomerFunction
  deleteCustomer: (ID: string) => void
}

const HiveOrg: FunctionComponent<Props> = props => {
  const accountSummaryPanelName = 'account'
  const serviceHealthSummaryPanelName = 'service-health'
  const featureUsageSummaryPanelName = 'feature-usage'
  const eventLogPanelName = 'event-log'
  const [activePanel, setActivePanel] = useState('')
  const canRemove = AuthRole.hasPermission('manageCustomer')
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const notificationPauseFinishTime = useTypedSelector(getNotificationPause(props.data.id))?.finishesAt

  const onPanelClosed = () => {
    setActivePanel('')
  }
  const onPanelClicked = (panelName: string) => {
    if (activePanel === panelName) {
      setActivePanel('')
      return
    }
    setActivePanel(panelName)
  }
  const onAccountSummaryClicked = () => {
    onPanelClicked(accountSummaryPanelName)
  }
  const onServiceHealthSummaryClicked = () => {
    onPanelClicked(serviceHealthSummaryPanelName)
  }
  const onFeatureUsageSummaryClicked = () => {
    onPanelClicked(featureUsageSummaryPanelName)
  }
  const onEventLogClicked = () => {
    onPanelClicked(eventLogPanelName)
  }

  const [hostEvents, setHostEvents] = useState<HostEvent[]>([])
  const getHostEventsCallback = useCallback(() => {
    getHostEvents(props.teamID, props.data.id).then(events => setHostEvents(events))
  }, [props.teamID, props.data.id])

  const [showEndPmitcSessionConfirmModal, setShowEndPmitcSessionConfirmModal] = useState(false)
  const dispatch = useDispatch()
  const existingSessionLogoutLink = useTypedSelector(getPmitcSessionLogoutLink)
  const existingSessionName = useTypedSelector(getPmitcSessionName)
  const existingSessionWindow = useTypedSelector(getPmitcSessionWindow)

  const [showPauseNotificationsModal, setShowPauseNotificationsModal] = useState(false)

  useEffect(() => {
    const randomIntervalSeconds = (min: number, max: number) => {
      return 1000 * randomInt(min, max)
    }

    const randomInt = (min: number, max: number) => {
      return Math.floor(Math.random() * (max - min + 1)) + min
    }

    if (activePanel === eventLogPanelName) {
      getHostEventsCallback()
      const hostEventTimer = setInterval(() => getHostEventsCallback(), randomIntervalSeconds(50, 70))
      return () => {
        clearInterval(hostEventTimer)
      }
    }
  }, [activePanel, getHostEventsCallback])

  const shorterPausesEnabled = useTypedSelector(isFeatureOn(Features.NotificationPauseTesting))

  return (
    <article id={props.data.id} className='customer'>
      <div className='flex flex-nowrap fp'>
        <HiveManage teamID={props.teamID} host={props.data} />
        <header>
          <div className='specs'>
            <HiveSystemInfo data={props.data} teamID={props.teamID} editCustomer={props.editCustomer} notificationPauseFinishTime={notificationPauseFinishTime} />

            {canRemove && (
              <Hamburger>
                <ul>
                  {
                    <li className='trash'>
                      <a onClick={() => setShowConfirmationModal(true)}>Remove customer</a>
                    </li>
                  }
                </ul>
              </Hamburger>
            )}
          </div>

          <div className='tabs article-nav'>
            <ul>
              <HiveAccountSummary onClick={onAccountSummaryClicked} isOpen={activePanel === accountSummaryPanelName} />
              <HiveServiceHealthSummary onClick={onServiceHealthSummaryClicked} isOpen={activePanel === serviceHealthSummaryPanelName} />
              <HiveFeatureUsageSummary onClick={onFeatureUsageSummaryClicked} isOpen={activePanel === featureUsageSummaryPanelName} />
              <EventLogSummary onClick={onEventLogClicked} isOpen={activePanel === eventLogPanelName} />
            </ul>
          </div>
        </header>
      </div>
      {activePanel === accountSummaryPanelName && <HiveAccount onClose={onPanelClosed} data={props.data} teamID={props.teamID} editCustomer={props.editCustomer} />}
      {activePanel === serviceHealthSummaryPanelName && <HiveServiceHealth onClose={onPanelClosed} />}
      {activePanel === featureUsageSummaryPanelName && <HiveFeatureUsage onClose={onPanelClosed} />}
      {activePanel === eventLogPanelName && <EventLog teamID={props.teamID} hostEvents={hostEvents} onClose={onPanelClosed} />}
      {showEndPmitcSessionConfirmModal && (
        <PmitcSessionModal
          customer={existingSessionName}
          onClose={() => setShowEndPmitcSessionConfirmModal(false)}
          onYes={() => {
            if (existingSessionWindow && existingSessionLogoutLink) {
              existingSessionWindow.location.href = existingSessionLogoutLink
            }

            manageHiveCustomer(dispatch, props.teamID, props.data, defaultAuthHandler.getUserName(), false)
          }}
        />
      )}

      {showConfirmationModal && (
        <ConfirmationModal
          title={`Remove ${props.data.name}`}
          message={`Do you really want to remove ${props.data.name}? This will not delete the PaperCut Hive organization.`}
          yesLabel='Remove customer'
          onClose={() => setShowConfirmationModal(false)}
          onYes={() => props.deleteCustomer(props.data.id)}
        />
      )}

      <PauseNotificationsModal
        close={() => setShowPauseNotificationsModal(false)}
        finishTime={notificationPauseFinishTime}
        host={props.data}
        isVisible={showPauseNotificationsModal}
        teamID={props.teamID}
        testing={shorterPausesEnabled}
      />
    </article>
  )
}

export default HiveOrg
