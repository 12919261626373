import React, { FunctionComponent, useEffect, useState } from 'react'
import axios from 'axios'
import confused from '../../assets/img/confused.png'
import { useDebounce } from '../../hooks/Debounce'
import { MaintenanceAndSupportStatus } from '../Account/Data'
import { supportEmail } from '../ContactSupport/Data'
import { InfoIcon } from '../Icons/Icons'
import { LoadingDots } from '../LoadingDots/LoadingDots'
import { getSupportId } from '../Team/Data'
import Toaster from '../Toaster'
import { License, LicenseRow } from './LicenseRow'

interface Props {
  teamID: string
}

enum SortColumn {
  Name,
  Devices,
  Expiry,
}

enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export const LicenseView: FunctionComponent<Props> = ({ teamID }) => {
  const [licenses, setLicenses] = useState<License[]>([])
  const [loading, setLoading] = useState(true)
  const [configProblem, setConfigProblem] = useState(false)
  const [filter, setFilter] = useState('')
  const [sortColumn, setSortColumn] = useState(SortColumn.Name)
  const [sortDirection, setSortDirection] = useState(SortDirection.Asc)

  const debouncedFilter = useDebounce(filter, 500)

  const filteredLicenses = licenses.filter(l => {
    const term = debouncedFilter.trim().toLowerCase()
    if (term) {
      return l.orgName.toLowerCase().includes(term) || (l.crn.toLowerCase().includes(term) ?? false)
    }

    return true
  })

  useEffect(() => {
    const cancelToken = axios.CancelToken
    const source = cancelToken.source()
    axios
      .get<any>('/api/teams/' + teamID + '/licenses', {
        cancelToken: source.token,
      })
      .then(result => {
        setLicenses(result.data.licenses)
      })
      .catch(e => {
        if (e.response) {
          switch (e.response.status) {
            default:
              Toaster.notifyFailure('Unable to load licenses.')
              break
            case 502: // bad gateway
              Toaster.notifyFailure('License server is not responding - try again later.')
              break
            case 503: // service unavailable
              setConfigProblem(true)
              break
          }
        }
      })
      .finally(() => setLoading(false))
    return () => source.cancel()
  }, [teamID])

  function toggleSort(sortBy: SortColumn) {
    // for now do front end sort as it's quicker...
    let newSortDir = SortDirection.Asc
    if (sortColumn === sortBy) {
      newSortDir = sortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc
    }

    // do sort
    switch (sortBy) {
      case SortColumn.Name:
        licenses.sort((a, b) => {
          return a.orgName.localeCompare(b.orgName)
        })
        break
      case SortColumn.Expiry:
        licenses.sort((a, b) => {
          if (a.supportStatus === MaintenanceAndSupportStatus.None) {
            return b.supportStatus === MaintenanceAndSupportStatus.None ? 0 : 1
          }

          if (b.supportStatus === MaintenanceAndSupportStatus.None) {
            return -1
          }

          if (a.supportExpiry === b.supportExpiry) {
            return 0
          }

          return (a.supportExpiry ?? new Date()) > (b.supportExpiry ?? new Date()) ? 1 : -1
        })
        break
      case SortColumn.Devices:
        licenses.sort((a, b) => {
          if (a.deviceCount === b.deviceCount) return 0
          return a.deviceCount > b.deviceCount ? 1 : -1
        })
        break
    }
    if (newSortDir === SortDirection.Desc) {
      licenses.reverse()
    }
    setSortColumn(sortBy)
    setSortDirection(newSortDir)
  }

  return (
    <>
      {!loading && !configProblem && (
        <>
          <div className='flex flex-start btm'>
            <div className='search'>
              <input type='search' placeholder='Search by CRN or organization' size={15} onChange={e => setFilter(e.target.value)} value={filter} />
            </div>
          </div>

          <div className='list'>
            <header>
              <div className='flex flex-nowrap'>
                <div className='col customer-ref'>
                  <div className='flex'>
                    CRN
                    <i className='icon-info'>
                      <InfoIcon />

                      <span className='tip'>
                        <span className='blk'>Customer Reference Number</span>
                      </span>
                    </i>
                  </div>
                </div>

                <div className={`col customer-name sortable ${sortColumn === SortColumn.Name && sortDirection}`} onClick={() => toggleSort(SortColumn.Name)}>
                  Organization
                </div>

                <div className='col customer-cert'>
                  <div>License</div>
                </div>

                <div className='col customer-type'>
                  <div>Type</div>
                </div>

                <div className={`col customer-devices sortable ${sortColumn === SortColumn.Devices && sortDirection}`} onClick={() => toggleSort(SortColumn.Devices)}>
                  Devices
                </div>

                <div className={`col customer-expiry sortable ${sortColumn === SortColumn.Expiry && sortDirection}`} onClick={() => toggleSort(SortColumn.Expiry)}>
                  Expiry
                </div>

                <div className='col customer-actions' />
              </div>
            </header>

            {filteredLicenses.length === 0 && (
              <div className='empty' style={{ padding: '40px' }}>
                {filter ? 'No matching licenses found.' : 'No licenses found.'}
              </div>
            )}

            <ul>
              {filteredLicenses.map(license => (
                <LicenseRow key={license.id} data={license} teamID={teamID} />
              ))}
            </ul>
          </div>
        </>
      )}

      {loading && (
        <div className='empty loading'>
          <LoadingDots />
        </div>
      )}

      {configProblem && (
        <div className='empty'>
          <figure>
            <img src={confused} alt='' />
          </figure>

          <h5>
            <span>Oops... there is a problem with your configuration</span>
          </h5>

          <p>
            Contact <a href={`mailto:${supportEmail}?subject=License view issue for ${getSupportId(teamID)}`}>PaperCut Multiverse support</a> to resolve this.
          </p>
        </div>
      )}
    </>
  )
}
