import React, { useRef } from 'react'
import { FunctionComponent } from 'react'
import AuthRole from '../../role'
import { HealthThresholdSettings } from '../HealthThresholdSettings/HealthThresholdSettings'
import { HealthNotificationSettings } from '../TeamNotifications/HealthNotificationSettings'
import { LicenseNotificationSettings } from '../TeamNotifications/LicenseNotificationSettings'
import { useTypedSelector } from '../../redux/reducers'
import { isDemo } from '../../redux/selectors'

interface Props {
  teamID: string
}

export const TeamMonitoring: FunctionComponent<Props> = ({ teamID }) => {
  const focusRef = useRef<HTMLDivElement>(null)
  const demoMode = useTypedSelector(isDemo)

  const scrollToRef = () => {
    if (focusRef.current) {
      focusRef.current.scrollIntoView()
    }
  }

  return (
    <div>
      <div id='tab-teamnotifications'>
        {AuthRole.hasPermission('updateTeamHealthSettings') && <HealthNotificationSettings teamID={teamID} onThresholdsLinkClick={scrollToRef} demoMode={demoMode} />}

        {AuthRole.hasPermission('updateTeamHealthSettings') && AuthRole.hasPermission('updateTeamLicenseSettings') && <hr className='divider' />}

        {AuthRole.hasPermission('updateTeamLicenseSettings') && <LicenseNotificationSettings teamID={teamID} demoMode={demoMode} />}
      </div>

      {(AuthRole.hasPermission('updateTeamHealthSettings') || AuthRole.hasPermission('updateTeamLicenseSettings')) && <hr className='divider' />}

      <div ref={focusRef}>
        <HealthThresholdSettings readOnly={!AuthRole.hasPermission('updateTeamHealthSettings')} teamID={teamID} />
      </div>
    </div>
  )
}
