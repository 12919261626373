import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { BooksIcon, CustomersIcon, GroupIcon, MagicIcon, ShopIcon } from '../Icons/Icons'
import { useLocation } from 'react-router'

interface Props {
  showServiceProviders: boolean
  showManageMultiverse: boolean
}

const NavBar: FunctionComponent<Props> = props => {
  const location = useLocation()

  const getNavLinkClass = (path: string) => {
    return location.pathname.startsWith(path) ? 'active' : ''
  }

  return (
    <nav className='tabs alt'>
      <ul className='auto'>
        <li className={getNavLinkClass('/my-customers')}>
          <Link to='/my-customers/list' aria-label='My customers'>
            <i className='icon twentyfour'>
              <CustomersIcon />
            </i>

            <span>My customers</span>
          </Link>
        </li>

        <li className={getNavLinkClass('/my-team')}>
          <Link to='/my-team' aria-label='My team'>
            <i className='icon twentyfour'>
              <GroupIcon />
            </i>

            <span>My team</span>
          </Link>
        </li>

        {props.showServiceProviders && (
          <li className={getNavLinkClass('/service-providers')}>
            <Link to='/service-providers' aria-label='Service providers'>
              <i className='icon twentyfour'>
                <ShopIcon />
              </i>

              <span>Service providers</span>
            </Link>
          </li>
        )}

        <li className={getNavLinkClass('/info-center')}>
          <Link to='/info-center' aria-label='Info Center'>
            <i className='icon twentyfour'>
              <BooksIcon />
            </i>

            <span>Info Center</span>
          </Link>
        </li>

        {props.showManageMultiverse && (
          <li className={getNavLinkClass('/manage')}>
            <Link to='/manage' aria-label='Manage PaperCut Multiverse'>
              <i className='icon twentyfour'>
                <MagicIcon />
              </i>

              <span>Manage PaperCut Multiverse</span>
            </Link>
          </li>
        )}
      </ul>
    </nav>
  )
}

export default NavBar
