import axios from 'axios'
import { AccountDataUtil, AccountDetails } from '../components/Account/Data'
import { HostEvent } from '../components/Host/Data'

export function getHostEvents(teamID: string, hostID: string): Promise<HostEvent[]> {
  return axios.get('/api/teams/' + teamID + '/hosts/' + hostID + '/events').then(result => {
    return Promise.resolve(result.data)
  })
}

export function getHostAccountDetails(teamID: string, hostID: string, signal: AbortSignal): Promise<AccountDetails> {
  return axios.get('/api/teams/' + teamID + '/customers/' + hostID + '/account', { signal }).then(resp => {
    return Promise.resolve(AccountDataUtil.getAccountDetailsFromResponse(resp.data))
  })
}
