import React, { FunctionComponent } from 'react'
import CustomModal from '../MyModal/CustomModal'

interface Props {
  onClose: () => void
}

const CustomerUpgradeRequired: FunctionComponent<Props> = props => {
  return (
    <CustomModal id='popup-upgraderequired' close={props.onClose}>
      <header className='center'>
        <h3>Customer upgrade required</h3>
      </header>

      <div className='content'>
        <p>Upgrade to a later PaperCut version may be required on the customer site.</p>
        <p>
          If you are unable to see the feature usage data for your customer, this is because PaperCut Multiverse requires PaperCut MF/NG Release 19.1 or later to be able to access
          the API and hence the customer information.
        </p>
      </div>

      <footer>
        <button className='btn' onClick={props.onClose}>
          <span className='text'>Close</span>
        </button>
      </footer>
    </CustomModal>
  )
}

export default CustomerUpgradeRequired
