import React, { FunctionComponent, useEffect, useState } from 'react'
import axios, { AxiosResponse } from 'axios'
import { useDispatch } from 'react-redux'
import { defaultAuthHandler } from '../auth'
import { AccessConfig } from '../components/Access/Data'
import { supportEmail } from '../components/ContactSupport/Data'
import { Customer, HiveCustomer, HostData, Product } from '../components/Host/Data'
import { PartnerData } from '../components/ServiceProviders/ServiceProviderInviteModal'
import { AuthType, getSupportId } from '../components/Team/Data'
import { getInitials, TeamSettingsData } from '../components/TeamSettings/TeamSettings'
import Toaster from '../components/Toaster'
import { loadAndApplyUserSettings } from '../components/UserActions/UserSettings'
import { useMountEffect } from '../hooks/MountEffect'
import { setAccessConfig } from '../redux/access'
import { setFeatureEnabled } from '../redux/features'
import { initHosts } from '../redux/hosts'
import { useTypedSelector } from '../redux/reducers'
import { setSettings } from '../redux/settings'
import { showHost } from '../redux/slideout'
import { initSupportState, SupportState } from '../redux/support'
import { HttpStatus } from '../service/HttpStatus'
import App from './App'

const AppContainer: FunctionComponent = () => {
  const teamID = defaultAuthHandler.getTeamID()
  const [hosts, setHosts] = useState<HostData[]>([])
  const [loading, setLoading] = useState(true)
  const activeAccessConfig = useTypedSelector(r => r.access.accessConfig)
  const activeSlideout = useTypedSelector(r => r.slideout)
  const [nextAccessConfig, setNextAccessConfig] = useState<AccessConfig>()
  const isAccessFrameActive = activeAccessConfig !== undefined
  const [confirmingCloseFrame, setConfirmingCloseFrame] = useState(false)
  const [parents, setParents] = useState([])
  const [team, setTeam] = useState<TeamSettingsData>()
  const [partners, setPartners] = useState<PartnerData[]>([])
  const dispatch = useDispatch()

  useMountEffect(() => {
    const handleInvalidSession = (details: any) => {
      console.warn('Token invalid, logging out user', details)
      defaultAuthHandler.logout()
    }
    axios.interceptors.response.use(
      response => {
        if (response.status === HttpStatus.Forbidden || response.status === HttpStatus.Unauthorized) {
          handleInvalidSession(response)
        }
        return response
      },
      err => {
        const errstring = err.toString()
        if (errstring.includes('401') || errstring.includes('403')) {
          handleInvalidSession(err)
        }
        return Promise.reject(err)
      }
    )
  })

  useEffect(() => {
    if (!isAccessFrameActive) {
      if (nextAccessConfig) {
        dispatch(setAccessConfig(nextAccessConfig))
      }
      exitConfirmClosePrompt()
    }
  }, [isAccessFrameActive, nextAccessConfig, dispatch])

  const loadCustomers = () => {
    return axios
      .get<{ customers: HostData[] }>('/api/teams/' + teamID + '/customers')
      .then(result => {
        setHosts(result.data.customers || [])
        dispatch(initHosts(result.data.customers || []))
      })
      .catch(_ => Toaster.notifyFailure('Unable to load customers.'))
  }

  useMountEffect(() => {
    Promise.all([
      loadCustomers(),
      axios.get<TeamSettingsData>('/api/teams/' + teamID + '/settings').then(res => {
        setTeam({
          ...res.data,
          initials: res.data.initials || getInitials(res.data.name),
          defaultAuthType: res.data.defaultAuthType || AuthType.Password,
        })
        dispatch(
          setSettings({
            demo: res.data.demoMode ?? false,
          })
        )
      }),
      axios.get<string[]>('/api/teams/' + teamID + '/feature').then(r => {
        r.data.forEach(f => dispatch(setFeatureEnabled(f, true)))
      }),
    ]).finally(() => setLoading(false))

    const customerPoll = setInterval(() => loadCustomers(), 2 * 60 * 1000)
    axios.get<any>('/api/teams/' + teamID + '/parents').then(res => setParents(res.data.parents))
    return () => clearInterval(customerPoll)
  })

  useMountEffect(() => {
    loadAndApplyUserSettings(defaultAuthHandler.getToken()?.accessToken ?? '')
  })

  useMountEffect(() => {
    axios.get<SupportState>('/api/teams/' + teamID + '/support-versions').then(res => {
      dispatch(initSupportState(res.data))
    })
  })

  useMountEffect(() => {
    axios
      .get<PartnerData[]>('/api/teams/' + teamID + '/partners')
      .then(res => {
        setPartners(res.data)
      })
      .catch(err => console.log('Unable to load partners: ' + err))
  })

  const openConfirmClosePrompt = () => {
    setConfirmingCloseFrame(true)
  }

  const exitConfirmClosePrompt = () => {
    setConfirmingCloseFrame(false)
    setNextAccessConfig(undefined)
  }

  const openAccessFrame = (config: AccessConfig) => {
    if (isAccessFrameActive && activeAccessConfig !== config) {
      setNextAccessConfig(config)
      openConfirmClosePrompt()
      return
    }

    dispatch(setAccessConfig(config))
  }

  const closeConfirmed = () => {
    dispatch(setAccessConfig(undefined))
  }

  const addCustomer = async (product: string, customer: Customer | HiveCustomer): Promise<boolean> => {
    let url = '/api/teams/' + teamID + '/customers'
    if (product === Product.Hive) {
      url = '/api/teams/' + teamID + '/' + product
    }
    return axios
      .put<any>(url, customer)
      .then(resp => {
        let hostList = hosts === null ? [] : hosts
        setHosts([resp.data].concat(hostList))
        Toaster.notifySuccess(`${resp.data.name} added.`)
        return true
      })
      .catch(e => {
        notifyAddCustomerFailure(product, customer, e)
        return false
      })
  }

  const deleteCustomer = (hostID: string): void => {
    axios
      .delete('/api/teams/' + teamID + '/customers/' + hostID)
      .then(() => {
        let hostList = hosts === null ? [] : hosts
        hostList = hostList.filter(host => {
          if (host.id !== hostID) {
            return host
          }
          Toaster.notifySuccess(`${host.name} removed.`)
          return null
        })
        setHosts(hostList)
      })
      .catch(_ => Toaster.notifyFailure('Unable to remove customer.'))
  }

  const editCustomer = async (product: string, customer: Customer, customerID?: string): Promise<boolean> => {
    return axios
      .put<Customer, AxiosResponse<HostData>>('/api/teams/' + teamID + '/customers/' + customerID, customer)
      .then(resp => {
        let hostList = hosts === null ? [] : hosts
        hostList = hostList.map(host => {
          if (host.id !== customerID) {
            return host
          }
          return resp.data
        })
        setHosts(hostList)

        if (activeAccessConfig && activeAccessConfig.hostID === customerID) {
          let config = activeAccessConfig
          config.hostName = customer.name
          dispatch(setAccessConfig(config))
        }

        if (activeSlideout.host) {
          dispatch(
            showHost({
              ...activeSlideout.host,
              name: customer.name,
              tags: customer.tags,
              infrastructure: customer.infrastructure,
            })
          )
        }
        Toaster.notifySuccess(`${resp.data.name} updated.`)
        return true
      })
      .catch(e => {
        console.log(e)
        Toaster.notifyFailure('Unable to update profile.')
        return false
      })
  }

  const modifyTeamSettings = async (newSettings: TeamSettingsData) => {
    return axios
      .put<TeamSettingsData>('/api/teams/' + teamID + '/settings', newSettings)
      .then(_ => {
        setTeam(newSettings)
        return Promise.resolve(true)
      })
      .catch(_ => {
        console.log('Failed to update team details')
        return Promise.reject(false)
      })
  }

  const notifyAddCustomerFailure = (product: string, customer: Customer | HiveCustomer, e: any) => {
    let messageContent = <span>Unable to add customer.</span>
    if (product === Product.Hive && e.response) {
      const hiveCustomer = customer as HiveCustomer
      if (hiveCustomer.existingOrg && e.response?.status === HttpStatus.Conflict) {
        const subject = `Request to connect PaperCut Hive organization ${hiveCustomer.orgId} to team ${getSupportId(teamID)}`
        messageContent = (
          <span>
            Unable to add customer.
            <br />
            Contact <a href={`mailto:${supportEmail}?subject=${subject}`}>PaperCut Multiverse support</a> to connect this organization.
          </span>
        )
      } else if (e.response.data) {
        messageContent = <span>Unable to add customer: {e.response.data.trim()}.</span>
      }
    }

    Toaster.notifyFailureContent(messageContent)
  }

  return loading ? (
    <></>
  ) : (
    <App
      teamID={teamID}
      team={
        team !== undefined
          ? team
          : {
              name: '',
              publicName: '',
              supportEmail: '',
              defaultAuthType: AuthType.Password,
            }
      }
      hosts={hosts}
      slideout={activeSlideout}
      activeAccessConfig={activeAccessConfig}
      isAccessFrameActive={isAccessFrameActive}
      confirmingCloseFrame={confirmingCloseFrame}
      addCustomer={addCustomer}
      deleteCustomer={deleteCustomer}
      editCustomer={editCustomer}
      openAccessFrame={openAccessFrame}
      closeConfirmed={closeConfirmed}
      exitConfirmClosePrompt={exitConfirmClosePrompt}
      openConfirmClosePrompt={openConfirmClosePrompt}
      parents={parents}
      modifyTeamSettings={modifyTeamSettings}
      partners={partners}
    />
  )
}

export default AppContainer
