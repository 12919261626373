import React, { FunctionComponent } from 'react'
import CustomModal from '../MyModal/CustomModal'

interface Props {
  customer?: string
  onClose: () => void
  onYes: () => void
  exiting?: boolean
}

const PmitcSessionModal: FunctionComponent<Props> = ({ customer = 'not found', onClose, onYes, exiting = false }) => {
  const confirmAction = () => {
    onYes()
    onClose()
  }

  return (
    <CustomModal id='popup-delete' close={onClose}>
      <form>
        <header className='center'>
          <h3>Existing session found</h3>
        </header>

        <div className='content'>
          <p>
            An open session for <strong>{customer}</strong> has been found.
          </p>

          {exiting && <p>Continuing to sign out from PaperCut Multiverse will close this PaperCut Cloud session.</p>}

          {!exiting && (
            <>
              <p>Only one PaperCut Cloud session can be active at a time.</p>

              <p>Do you want to close this session and open a new one?</p>
            </>
          )}
        </div>

        <footer>
          <button className='btn outline' onClick={onClose}>
            Cancel
          </button>

          <button className='btn' onClick={confirmAction}>
            <span className='text'>OK</span>
          </button>
        </footer>
      </form>
    </CustomModal>
  )
}

export default PmitcSessionModal
